import classNames from "classnames";
import FormError from "../../form/error";
import FormSuccess from "../../form/success";

const FormGroup = ({
  children,
  className,
  error,
  success,
  hideError = false,
}: {
  children: JSX.Element | JSX.Element[];
  className?: string;
  error?: string;
  success?: string;
  hideError?: boolean;
}) => {
  return (
    <div className={classNames("mt-1", className)}>
      {children}

      {!hideError && (
        <div className="mt-2">
          {error ? (
            <FormError>{error}</FormError>
          ) : success ? (
            <FormSuccess>{success}</FormSuccess>
          ) : (
            <p className="text-sm not-sr-only tablet:text-base">&nbsp;</p>
          )}
        </div>
      )}
    </div>
  );
};

export default FormGroup;
