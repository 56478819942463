/* eslint-disable max-len */
const Office365Icon = ({ className }: { className?: string }) => {
  return (
    <svg
      height="2500"
      viewBox="0 0 78.799 96"
      width="2052"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="16.942"
        x2="85.671"
        y1="83.36"
        y2="89.583"
      >
        <stop offset="0" stopColor="#f32b44" />
        <stop offset=".6" stopColor="#a4070a" />
      </linearGradient>
      <linearGradient id="b">
        <stop offset="0" stopOpacity=".4" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="c"
        gradientUnits="userSpaceOnUse"
        x1="63.515"
        x2="33.003"
        xlinkHref="#b"
        y1="87.298"
        y2="84.535"
      />
      <linearGradient
        id="d"
        gradientUnits="userSpaceOnUse"
        x1="44.738"
        x2="-5.901"
        y1="-3.312"
        y2="71.527"
      >
        <stop offset="0" stopColor="#800600" />
        <stop offset=".6" stopColor="#c72127" />
        <stop offset=".728" stopColor="#c13959" />
        <stop offset=".847" stopColor="#bc4b81" />
        <stop offset=".942" stopColor="#b95799" />
        <stop offset="1" stopColor="#b85ba2" />
      </linearGradient>
      <linearGradient
        id="e"
        gradientUnits="userSpaceOnUse"
        x1="45.823"
        x2="35.099"
        xlinkHref="#b"
        y1="-4.81"
        y2="11.039"
      />
      <linearGradient
        id="f"
        gradientUnits="userSpaceOnUse"
        x1="61.486"
        x2="61.486"
        y1="-4.887"
        y2="88.781"
      >
        <stop offset="0" stopColor="#ffb900" />
        <stop offset=".166" stopColor="#ef8400" />
        <stop offset=".313" stopColor="#e25c01" />
        <stop offset=".429" stopColor="#db4401" />
        <stop offset=".5" stopColor="#d83b01" />
      </linearGradient>

      <path
        d="m19.143 75.558c-2.724 0-4.945 2.121-4.945 4.753 0 1.789 1.031 3.322 2.565 4.14l19.118 10.246a10.11 10.11 0 0 0 4.969 1.303c1.164 0 2.275-.204 3.306-.562l6.531-1.814v-18.091c.027.025-31.519.025-31.545.025z"
        fill="url(#a)"
      />
      <path
        d="m19.143 75.558c-2.724 0-4.945 2.121-4.945 4.753 0 1.789 1.031 3.322 2.565 4.14l19.118 10.246a10.11 10.11 0 0 0 4.969 1.303c1.164 0 2.275-.204 3.306-.562l6.531-1.814v-18.091c.027.025-31.519.025-31.545.025z"
        fill="url(#c)"
      />
      <path
        d="m43.736.383a9.968 9.968 0 0 0 -2.777-.383c-1.56 0-3.12.307-4.522 1.022-.29.128-31.096 16.864-31.096 16.864-.423.205-.82.46-1.19.716-.052.025-.079.051-.132.077-.238.178-.45.357-.687.536-.106.077-.212.18-.291.256-.132.127-.265.255-.37.383-.37.383-1.005 1.2-1.005 1.2a9.15 9.15 0 0 0 -1.666 5.291v44.46c0 2.633 2.221 4.754 4.945 4.754.687 0 1.322-.128 1.904-.384l8.805-4.778c1.586-.766 2.856-2.07 3.517-3.68.158-.332.29-.74.37-1.15.026-.102.053-.23.053-.332 0-.05.026-.127.026-.178.027-.18.053-.384.053-.562 0-.154.027-.282.027-.435v-23.662-7.385c0-2.07.925-3.935 2.38-5.238 0 0-.688.613 0 0 .687-.613 1.586-1.15 2.644-1.507 1.057-.384 26.072-9.122 26.072-9.122v-14.744z"
        fill="url(#d)"
      />
      <path
        d="m43.736.383a9.968 9.968 0 0 0 -2.777-.383c-1.56 0-3.12.307-4.522 1.022-.29.128-31.096 16.864-31.096 16.864-.423.205-.82.46-1.19.716-.052.025-.079.051-.132.077-.238.178-.45.357-.687.536-.106.077-.212.18-.291.256-.132.127-.265.255-.37.383-.37.383-1.005 1.2-1.005 1.2a9.15 9.15 0 0 0 -1.666 5.291v44.46c0 2.633 2.221 4.754 4.945 4.754.687 0 1.322-.128 1.904-.384l8.805-4.778c1.586-.766 2.856-2.07 3.517-3.68.158-.332.29-.74.37-1.15.026-.102.053-.23.053-.332 0-.05.026-.127.026-.178.027-.18.053-.384.053-.562 0-.154.027-.282.027-.435v-23.662-7.385c0-2.07.925-3.935 2.38-5.238 0 0-.688.613 0 0 .687-.613 1.586-1.15 2.644-1.507 1.057-.384 26.072-9.122 26.072-9.122v-14.744z"
        fill="url(#e)"
      />
      <path
        d="m71.898 8.35-27.738-7.843c4.019 1.508 6.53 4.906 6.53 9.046 0 0-.025 75.2 0 77.014.027 4.088-2.67 7.589-6.53 8.892.846-.23 27.738-7.717 27.738-7.717 3.992-1.226 6.875-4.804 6.875-9.07v-61.252c.026-4.24-2.883-7.844-6.875-9.07z"
        fill="url(#f)"
      />
    </svg>
  );
};

export default Office365Icon;
