import { Button, PatientOverviewCard, PillIcon } from "@mwi/ui";
import { useGetPatientsQuery } from "api";
import Patient from "api/types/models/patient";
import { useCreatePrescriptionsMutation } from "./../../../api";
import { gtmEvent } from "helpers/gtm";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRequestStatus, setStep } from "slices/prescription";
import { RootState } from "store";
import { SelectedPrescription } from "types/prescription";

type FormattedPatient = {
  patient: Patient;
  prescriptions: SelectedPrescription[];
};

type PrescriptionListProps = Pick<FormattedPatient, "prescriptions">;

const PrescriptionList = ({
  prescriptions,
}: PrescriptionListProps): JSX.Element => {
  return (
    <div className="space-y-2">
      <p className="text-lg font-semibold">Request Details:</p>
      <ul className="space-y-2">
        {prescriptions?.map((prescription, idx) => (
          <li key={idx}>
            <div className="flex items-center gap-3">
              <div className="grid self-start w-8 h-8 bg-white border-2 rounded-full shadow-md border-primary place-content-center shrink-0">
                <PillIcon className="w-5 h-5" />
              </div>
              <p className="text-lg break-all">{prescription.item_name}</p>
            </div>
            {prescription.notes && (
              <p className="text-lg">
                <span className="font-semibold">Notes:</span>{" "}
                {prescription.notes}
              </p>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const ReviewPrescriptionsView = (): JSX.Element => {
  const dispatch = useDispatch();

  const { data: patients } = useGetPatientsQuery();

  const [createPrescriptions, { isLoading: isCreating }] =
    useCreatePrescriptionsMutation();

  const { selectedPatients } = useSelector((state: RootState) => ({
    selectedPatients: state.prescription.formState.patients,
  }));

  useEffect(() => gtmEvent({ name: "prescriptionRequestReviewPageView" }), []);

  const handleSubmit = async () => {
    gtmEvent({ name: "prescriptionRequestSubmit" });
    await createPrescriptions({
      requests: selectedPatients.map((patient) => ({
        patient_uuid: patient.patientUuid,
        prescriptions: patient.prescriptions.map((prescription) => ({
          formulary_uuid:
            prescription.formulary_uuid !== "new-item"
              ? prescription.formulary_uuid
              : null,
          item_name:
            prescription.formulary_uuid === "new-item"
              ? prescription.item_name
              : null,
          notes: prescription.notes,
        })),
      })),
    })
      .unwrap()
      .then(() => {
        gtmEvent({ name: "prescriptionRequestSuccess" });
        dispatch(setRequestStatus({ status: "complete" }));
      })
      .catch(() => {
        gtmEvent({ name: "prescriptionRequestError" });
        dispatch(setRequestStatus({ status: "error" }));
      });

    dispatch(setStep({ step: "confirmation" }));
  };

  const patientRequests = useMemo(() => {
    const formattedPrescriptions: FormattedPatient[] = [];
    selectedPatients?.forEach((selectedPatient) => {
      const patient = patients?.find(
        (patient) => patient.id === selectedPatient.patientUuid,
      );
      if (patient) {
        formattedPrescriptions.push({
          patient,
          prescriptions: selectedPatient.prescriptions,
        });
      }
    });
    return formattedPrescriptions;
  }, [patients, selectedPatients]);

  return (
    <>
      <div className="space-y-8">
        {patientRequests.map((request) => (
          <div
            key={request.patient.id}
            className="flex flex-col gap-4 tablet:gap-8 tablet:flex-row"
          >
            <div className="w-full p-1 space-y-6 tablet:space-y-3">
              <PatientOverviewCard patient={request.patient} />

              {/* Mobile Prescription List */}
              <div className="max-w-2xl tablet:hidden">
                <PrescriptionList prescriptions={request.prescriptions} />
              </div>
            </div>

            {/* Desktop Prescription List */}
            <div className="hidden w-full max-w-sm tablet:block">
              <PrescriptionList prescriptions={request.prescriptions} />
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-end pt-16 mt-auto tablet:pt-8">
        <Button
          className="w-full tablet:max-w-[200px]"
          loading={isCreating}
          onClick={handleSubmit}
        >
          Submit Request
        </Button>
      </div>
    </>
  );
};
