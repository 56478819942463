import { Modal } from "@mwi/ui";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetPrescriptionState } from "slices/prescription";
import { PrescriptionRootView } from "../../views";

type PrescriptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const PrescriptionModal = ({
  isOpen,
  onClose,
}: PrescriptionModalProps): JSX.Element => {
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
    dispatch(resetPrescriptionState());
  };

  // Reset any previous state after initial render
  useEffect(() => {
    dispatch(resetPrescriptionState());
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      className="tablet:!max-w-[950px] tablet:min-h-[500px]"
    >
      <PrescriptionRootView onClose={onClose} />
    </Modal>
  );
};
