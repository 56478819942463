import classNames from "classnames";

const List = ({
  listKey,
  items,
  className,
}: {
  listKey: string;
  items: string[];
  className?: string;
}): JSX.Element => {
  return (
    <ol className={classNames("space-y-4", className)}>
      {items.map((item, idx) => (
        <li key={`${listKey}-${idx}`} className="flex gap-x-6">
          <span className="flex-shrink-0 w-6 lowercase">
            ({String.fromCharCode(idx + 65)})
          </span>
          <span>{item}</span>
        </li>
      ))}
    </ol>
  );
};

export default List;
