import { DateTime } from "luxon";
import classNames from "classnames";
import DateTimeFormat from "../../date_time_format";

type AvailableDayOverviewProps = {
  day: DateTime;
  onClick: () => void;
  isSelected: boolean;
  disabled?: boolean;
};

const AvailableDayOverview = ({
  day,
  onClick,
  disabled,
  isSelected,
}: AvailableDayOverviewProps): JSX.Element => {
  const classes = classNames(
    `
    cursor-pointer
    w-[80px] h-[80px]
    px-1 py-[3px]
    flex flex-col justify-center items-center gap-1
    border rounded-lg border-grey-light
  `,
    {
      "bg-grey-lightest text-grey-dark !border-transparent cursor-default":
        disabled,
      "border-light shadow-focus-small border-2": isSelected,
    },
  );

  const isToday = day.hasSame(DateTime.now(), "day");

  return (
    <button onClick={onClick} disabled={disabled} className={classes}>
      <span className="text-sm leading-[18px] font-semibold">
        {isToday ? "Today" : <DateTimeFormat date={day} format="ccc" />}
      </span>

      <span className="text-lg leading-[27px]">
        <DateTimeFormat date={day} format="d" />
      </span>

      <span className="text-sm leading-[18px]">
        <DateTimeFormat date={day} format="LLL" />
      </span>
    </button>
  );
};

export default AvailableDayOverview;
