import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { login } from "slices/auth";
import { useRegisterMutation } from "api";
import { Button, FormGroup, Label, Select, SelectOption } from "@mwi/ui";
import useErrorHandler from "hooks/useErrorHandler";
import { setVerifyMethods, updateFormState } from "slices/registration";
import { RootState } from "store";
import { gtmEvent } from "helpers/gtm";

type Inputs = {
  vet_site: string;
};

const RegistrationPractice = () => {
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const { formState, oldFormState, locations } = useSelector(
    (state: RootState) => ({
      formState: state.registration.formState,
      oldFormState: state.registration.oldFormState,
      locations: state.registration.locations,
    }),
  );

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: oldFormState,
  });

  const [submitRegister, { data: registerResult, isLoading, error }] =
    useRegisterMutation();

  useEffect(() => {
    if (error) {
      errorHandler(error, setError);
    }
  }, [error]);

  useEffect(() => {
    if (registerResult) {
      dispatch(updateFormState(getValues()));
      dispatch(login({ user: registerResult }));
      dispatch(
        setVerifyMethods([
          { method: "sms", display: formState.phone! },
          { method: "email", display: formState.email },
        ]),
      );
    }
  }, [registerResult]);

  const submitForm = (data: Inputs) => {
    submitRegister({
      ...formState,
      first_name: formState.first_name!,
      last_name: formState.last_name!,
      phone: formState.phone!,
      line_1: formState.line_1!,
      city: formState.city!,
      county: formState.county!,
      postcode: formState.postcode!,
      ...data,
    });
  };

  useEffect(() => gtmEvent({ name: "onRegFlowVetLocationStepPageView" }), []);

  return (
    <div className="py-6">
      <h1 className="text-4xl font-bold tablet:text-6xl">
        Which vet practice will you visit?
      </h1>

      <p className="mt-6 tablet:mt-8 tablet:text-lg">
        This is where you&apos;ll be registered.
      </p>

      <form
        className="flex flex-col flex-grow mt-8 tablet:mt-12"
        onSubmit={handleSubmit(submitForm)}
      >
        <FormGroup error={errors?.vet_site?.message}>
          <Label>Vet Practice</Label>
          <Select
            name="vet_site"
            placeholder="Select vet practice site"
            control={control}
          >
            {(locations ?? []).map((l) => (
              <SelectOption key={l.id} value={l.id}>
                {l.name}
              </SelectOption>
            ))}
          </Select>
        </FormGroup>

        <div className="mt-auto tablet:mt-12 tablet:max-w-xs">
          <Button type="submit" block loading={isLoading}>
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RegistrationPractice;
