import classNames from "classnames";
import { ElementType } from "react";

const TableItem = (props: {
  children?: any;
  fullWidth?: boolean;
  className?: string;
  as?: ElementType;
  shouldWrap?: boolean;
  colSpan?: number;
}) => {
  const {
    as: Cmp = "td",
    children,
    fullWidth = false,
    className,
    shouldWrap = false,
    colSpan,
  } = props;

  return (
    <Cmp
      className={classNames(
        "px-6 py-4 text-left",
        {
          "w-full": fullWidth,
          "font-semibold": Cmp === "th",
          "whitespace-nowrap": !shouldWrap,
        },
        className,
      )}
      colSpan={colSpan}
    >
      {children}
    </Cmp>
  );
};

export default TableItem;
