import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectedPatient } from "types/booking-flow/SelectedPatient";
import {
  AvailableDay,
  AvailableMergedSlot,
  AvailbilityDateRange,
} from "api/types/models/availability";
import ReasonNotification from "api/types/models/reason_notification";

export interface FormState {
  notification: ReasonNotification;

  vet_site_uuid: string;

  patients: SelectedPatient[];

  selected_date: string; // used for desktop select date only

  selected_day: AvailableDay;

  selected_slot: AvailableMergedSlot;
  confirmed_selection: boolean;

  availability_date_range: AvailbilityDateRange;

  appointment_confirmed: boolean;

  error_message: string | null;
}

export type BookingFlowSteps = "1" | "2" | "3" | "3a" | "4" | "5";

export interface BookingFlowState {
  step: BookingFlowSteps;
  formState: FormState;
  patient_index: number;
}

const initialState: BookingFlowState = {
  step: "1",
  patient_index: 0,
  formState: JSON.parse(
    localStorage.getItem("booking_flow_state") ??
      `{
      "notification": {
        "is_active": false,
        "type": null,
        "message": null
      },
      "vet_site_uuid": "",
      "patients": [],
      "patient_index": 0,
      "selected_date": "",
      "selected_day": {},
      "selected_slot": {},
      "confirmed_selection": false,
      "availability_date_range": {},
      "appointment_confirmed": false,
      "error_message": null
    }`,
  ),
};

export const bookingSlice = createSlice({
  name: "bookingSlice",
  initialState,
  reducers: {
    setStep: (
      state,
      action: PayloadAction<Partial<FormState & { step: BookingFlowSteps }>>,
    ) => {
      if (action.payload.step) {
        state.step = action.payload.step;
      }

      // prevent adding step into formState
      delete action.payload.step;

      state.formState = {
        ...state.formState,
        ...action.payload,
      };

      localStorage.setItem(
        "booking_flow_state",
        JSON.stringify(state.formState),
      );
    },

    setNotification: (state, formState: PayloadAction<ReasonNotification>) => {
      state.formState.notification = formState.payload;
    },

    setSelectedDay: (state, formState: PayloadAction<AvailableDay>) => {
      state.formState.selected_day = formState.payload;
      state.formState.selected_date = formState.payload.date;
      state.formState.selected_slot = {} as AvailableMergedSlot;
    },

    setSelectedSlot: (state, formState: PayloadAction<AvailableMergedSlot>) => {
      state.formState.selected_slot = formState.payload;
    },

    setConfirmedSelection: (state, formState: PayloadAction<boolean>) => {
      state.formState.confirmed_selection = formState.payload;
    },

    setSelectedVetSite: (state, formState: PayloadAction<string>) => {
      state.formState.vet_site_uuid = formState.payload;
    },

    clearSelectedAvailability: (state) => {
      state.formState.selected_slot = {} as AvailableMergedSlot;
      state.formState.selected_day = {} as AvailableDay;
      state.formState.confirmed_selection = false;
    },

    setAvailabilityDateRange: (
      state,
      formState: PayloadAction<AvailbilityDateRange>,
    ) => {
      state.formState.availability_date_range = formState.payload;
    },

    setPatients: (state, formState: PayloadAction<Partial<FormState>>) => {
      if (!formState.payload.patients) {
        return;
      }

      state.formState.patients = formState.payload.patients;
      localStorage.setItem(
        "booking_flow_state",
        JSON.stringify(state.formState),
      );
    },

    setPatientReasonIndex: (state, action: PayloadAction<number>) => {
      state.patient_index = action.payload;
    },

    clearBookingState: (state) => {
      localStorage.removeItem("booking_flow_state");

      state.step = "1";
      state.patient_index = 0;
      state.formState = {
        notification: {
          is_active: false,
          type: undefined,
          message: undefined,
        },
        patients: [],
        vet_site_uuid: "",
        selected_date: "",
        selected_day: {} as AvailableDay,
        selected_slot: {} as AvailableMergedSlot,
        confirmed_selection: false,
        availability_date_range: {
          date_start: "",
          date_end: "",
        },
        appointment_confirmed: false,
        error_message: null,
      };
    },
  },
});

export const {
  setStep,
  setSelectedDay,
  setSelectedSlot,
  setConfirmedSelection,
  setSelectedVetSite,
  setNotification,
  setPatients,
  clearSelectedAvailability,
  setPatientReasonIndex,
  setAvailabilityDateRange,
  clearBookingState,
} = bookingSlice.actions;

export default bookingSlice.reducer;
