import { useSelector } from "react-redux";
import { RootState } from "store";
import { Select, TrashIcon, SelectOption } from "@mwi/ui";
import { useGetPatientsQuery, useGetReasonsQuery } from "api";

type AddPatientSectionProps = {
  index: number;
  control: any;
  onRemove: () => void;
};

const AddPatientSection = ({
  index,
  control,
  onRemove,
}: AddPatientSectionProps): JSX.Element => {
  const { vetSite, registeredSite, selectedPatients } = useSelector(
    (state: RootState) => ({
      registeredSite: state.auth.registered_site,
      vetSite: state.bookingFlow.formState.vet_site_uuid,
      selectedPatients: state.bookingFlow.formState.patients,
    }),
  );

  const { data: patients } = useGetPatientsQuery();

  const { data: reasons } = useGetReasonsQuery({
    locationUuid: vetSite ? vetSite : registeredSite!.id,
  });

  return (
    <div className="flex flex-wrap items-center gap-6">
      <div className="w-6 h-6 cursor-pointer" onClick={onRemove}>
        <span className="sr-only">Remove pet from appointment</span>
        <TrashIcon aria-hidden className="text-error-light" />
      </div>

      <div className="flex-shrink-0 w-full max-w-[240px]">
        <Select
          control={control}
          placeholder="Select a pet"
          name={`patients.${index}.patient_uuid`}
        >
          {patients?.map((patient) => {
            const isPatientSelected = selectedPatients.some(
              (p) => p.patient_uuid === patient.id,
            );
            return (
              <SelectOption
                key={patient.id}
                value={patient.id}
                disabled={isPatientSelected}
              >
                {patient.name}
              </SelectOption>
            );
          })}
        </Select>
      </div>

      <div className="flex-shrink-0 w-full max-w-[240px]">
        <Select
          control={control}
          placeholder="Select a reason"
          name={`patients.${index}.reason_uuid`}
          disabled={!selectedPatients[index]?.patient_uuid}
        >
          {reasons?.data?.map((reason) => (
            <SelectOption key={reason.id} value={reason.id}>
              {reason?.translation ?? reason.name}
            </SelectOption>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default AddPatientSection;
