import classNames from "classnames";
import { MouseEventHandler } from "react";

type OptionProps = {
  value: string;
  children: string;
  disabled?: boolean;
  selected?: boolean;
  onClick?: MouseEventHandler;
};

const Option = ({ selected, children, onClick = () => {} }: OptionProps) => {
  return (
    <div
      onClick={onClick}
      className={classNames("p-4 text-lg transition", {
        "bg-grey-lightest rounded-2xl font-semibold": selected,
      })}
    >
      {children}
    </div>
  );
};

export default Option;
