type EmptyStateCardProps = {
  icon?: JSX.Element;
  title?: string;
  message?: string;
  cta?: JSX.Element;
};

const EmptyStateCard = ({ icon, title, message, cta }: EmptyStateCardProps) => {
  return (
    <div className="w-full bg-white shadow-default">
      <div className="flex flex-col items-center justify-center w-full px-5 py-6">
        {icon && (
          <div className="mb-3 tablet:mb-4">
            <div className="w-[50px] h-[50px] tablet:w-[60px] tablet:h-[60px] rounded-full bg-lightest">
              <div className="flex items-center justify-center w-full h-full">
                <div className="w-8 h-8 tablet:w-10 tablet:h-10 text-primary">
                  {icon}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mb-1 tablet:mb-2">
          <h3 className="text-base font-bold text-center tablet:text-lg">
            {title}
          </h3>
        </div>

        {message && (
          <div className="mb-4 tablet:mb-6">
            <p className="text-xs leading-[21px] tablet:text-base text-center">
              {message}
            </p>
          </div>
        )}

        {cta && cta}
      </div>
    </div>
  );
};

export default EmptyStateCard;
