import classNames from "classnames";

type SectionProps = {
  className?: string;
  children: JSX.Element | JSX.Element[];
};

const Section = ({ children, className }: SectionProps): JSX.Element => {
  return <div className={classNames("p-4", className)}>{children}</div>;
};

export default Section;
