import React from "react";

import CrossIcon from "../../../icons/CrossIcon";

type SelectedProps = {
  value: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

const Selected = ({ value, onClick }: SelectedProps) => {
  return (
    <div className="rounded-[4px] bg-lightest py-[2px] px-2 flex items-center gap-2 transition-all">
      <p className="text-lg leading-7">{value}</p>
      <div className="flex-shrink-0 w-4 h-4" onClick={onClick}>
        <CrossIcon className="cursor-pointer" />
      </div>
    </div>
  );
};

export default Selected;
