import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Input,
  Label,
  FormGroup,
  PasswordStrength,
  NotifierNotification,
} from "@mwi/ui";
import { useUpdatePasswordMutation } from "api";
import useErrorHandler from "hooks/useErrorHandler";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useNotifier } from "react-headless-notifier";

type Inputs = {
  password: string;
  password_confirmation: string;
};

const ResetPasswordPage = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const errorHandler = useErrorHandler();
  const { notify } = useNotifier();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<Inputs>();

  const [updatePassword, { error, isSuccess, isError, isLoading: isUpdating }] =
    useUpdatePasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      notify(
        <NotifierNotification
          title="Password Changed"
          message="Your password has been updated"
          type="success"
        />,
      );
      navigate("/");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      errorHandler(error, setError);
    }
  }, [isError]);

  const handleOnSubmit = (data: Inputs) => {
    const userId = searchParams.get("user_id");
    const token = searchParams.get("token");

    if (userId && token) {
      updatePassword({
        ...data,
        token: token,
        user_id: parseInt(userId),
      });
    }
  };

  return (
    <div className="flex flex-col justify-start flex-grow w-full mx-auto tablet:justify-center tablet:max-w-lg desktop:max-w-3xl">
      <div className="mt-24 mb-6 tablet:mb-8 tablet:mt-0">
        <h1 className="text-4xl font-bold leading-10 tablet:text-6xl">
          Reset Password
        </h1>
      </div>

      <p className="mb-10 tablet:text-lg">Enter a new password</p>

      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        className="mt-10 tablet:mt-14"
      >
        <FormGroup>
          <Label>Password</Label>
          <Input type="password" autoComplete="off" {...register("password")} />
          <PasswordStrength
            className="mt-2"
            password={watch("password")}
            error={errors?.password?.message}
          />
        </FormGroup>

        <FormGroup
          error={
            errors?.password_confirmation?.message ??
            (watch("password") !== "" &&
            watch("password_confirmation") !== "" &&
            watch("password") !== watch("password_confirmation")
              ? "Password Doesn't Match"
              : undefined)
          }
          success={
            watch("password") &&
            watch("password") === watch("password_confirmation")
              ? "Password Matches"
              : undefined
          }
        >
          <Label>Confirm Password</Label>
          <Input
            type="password"
            autoComplete="off"
            {...register("password_confirmation")}
          />
        </FormGroup>

        <div className="mt-9">
          <Button
            block
            type="submit"
            loading={isUpdating}
            disabled={!watch("password") || !watch("password_confirmation")}
          >
            Set new password
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordPage;
