import { Button, Loader, PatientOverviewCard } from "@mwi/ui";
import { useGetPatientsQuery } from "api";
import Patient from "api/types/models/patient";
import { useVerifyPatientsMutation } from "./../../../api";
import { gtmEvent } from "helpers/gtm";
import { useToast } from "hooks/useToast";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPatients, setStep } from "slices/prescription";
import { RootState } from "store";

type SelectPatientsViewProps = {
  onClose?: () => void;
};

export const SelectPatientsView = ({
  onClose,
}: SelectPatientsViewProps): JSX.Element => {
  const dispatch = useDispatch();
  const { errorToast } = useToast();

  const {
    data: patients,
    isFetching: loadingPatients,
    isError: patientsError,
  } = useGetPatientsQuery();

  const [verifyPatients, { isLoading: verifying }] =
    useVerifyPatientsMutation();

  const { selectedPatients } = useSelector((state: RootState) => ({
    selectedPatients: state.prescription.formState.patients,
  }));

  useEffect(
    () => gtmEvent({ name: "prescriptionRequestSelectPatientsPageView" }),
    [],
  );

  const handlePatientSelect = (selectedPatient: Patient) => {
    const isSelected = selectedPatients.some(
      (patient) => patient.patientUuid === selectedPatient.id,
    );
    if (isSelected) {
      dispatch(
        setPatients(
          selectedPatients.filter(
            (patient) => patient.patientUuid !== selectedPatient.id,
          ),
        ),
      );
      return;
    }

    const patient = {
      patientUuid: selectedPatient.id,
      prescriptions: [],
      additionalNotes: null,
    };
    dispatch(setPatients([...selectedPatients, patient]));
  };

  const handleVerify = async () => {
    await verifyPatients({
      patients: selectedPatients.map((patient) => patient.patientUuid),
    })
      .unwrap()
      .then(() => dispatch(setStep({ step: "prescriptions" })))
      .catch(() => {
        if (onClose) {
          onClose();
        }

        errorToast({
          title: "Verify Pets",
          message:
            "We failed to verify your pets for a repeat prescription. Please try again later or contact your vet practice.",
        });
      });
  };

  if (loadingPatients) {
    return (
      <div className="grid min-h-full place-content-center tablet:h-[500px]">
        <Loader />
      </div>
    );
  }

  if (patientsError) {
    return (
      <div className="grid min-h-full place-content-center">
        <p className="text-lg font-medium text-error">
          We failed to retrieve your pets! Please try again later or contact
          your vet practice.
        </p>
      </div>
    );
  }

  return (
    <>
      <div className="tablet:max-h-[450px] overflow-y-auto p-1 scrollbar-none">
        <div className="grid grid-cols-1 gap-4 tablet:grid-cols-2 tablet:gap-8">
          {patients?.map((patient) => (
            <PatientOverviewCard
              key={patient.id}
              patient={patient}
              onClick={() => handlePatientSelect(patient)}
              isSelected={selectedPatients.some(
                (selectedPatient) => selectedPatient.patientUuid === patient.id,
              )}
            />
          ))}
        </div>
      </div>

      <div className="flex justify-end pt-16 mt-auto tablet:pt-8">
        <Button
          loading={verifying}
          onClick={handleVerify}
          className="w-full tablet:w-min"
          disabled={!selectedPatients?.length}
        >
          Continue
        </Button>
      </div>
    </>
  );
};
