import { useMemo } from "react";
import MapPinIcon from "../../../icons/MapPinIcon";
import RightArrowIcon from "../../../icons/RightArrowIcon";

type Address = {
  name_no?: string;
  line1: string;
  line2?: string;
  city: string;
  county: string;
  postcode: string;
};

type AddressItemProps = {
  value: Address;
};

const AddressItem = ({ value }: AddressItemProps): JSX.Element => {
  const formattedAddress = useMemo(
    () =>
      Object.values(value)
        .filter((v) => v)
        .join(", "),
    [value],
  );

  return (
    <div className="px-4 pointer-events-none hover:bg-grey">
      <div className="flex justify-between w-full py-4 border-b border-b-grey-light">
        <div className="flex gap-2">
          <div className="flex-shrink-0">
            <MapPinIcon className="w-6 h-6" />
          </div>

          <div className="flex flex-col">
            <span className="block text-base font-semibold">
              {value?.postcode ?? ""}
            </span>
            <span className="text-sm font-semibold text-grey-dark">
              {formattedAddress}
            </span>
          </div>
        </div>

        <div className="flex-shrink-0">
          <RightArrowIcon className="w-6 h-6" />
        </div>
      </div>
    </div>
  );
};

export default AddressItem;
