import { useEffect } from "react";
import { RootState } from "store";
import { Modal, BackButton } from "@mwi/ui";
import { useDispatch, useSelector } from "react-redux";

import { setStep } from "slices/booking";
import {
  AvailableDay,
  AvailableMergedSlot,
} from "api/types/models/availability";
import BookAppointmentModalStep4 from "components/modals/appointment/book/steps/4_book";
import BookAppointmentModalStep5 from "components/modals/appointment/book/steps/5_status";
import BookAppointmentModalStep3a from "components/modals/appointment/book/steps/3a_site";
import BookAppointmentModalStep3 from "components/modals/appointment/book/steps/3_appointment";

type BookAppointmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const BookAppointmentModal = ({
  isOpen,
  onClose,
  onConfirm,
}: BookAppointmentModalProps): JSX.Element => {
  const dispatch = useDispatch();

  const { step, selectedPatients } = useSelector((state: RootState) => ({
    step: state.bookingFlow.step,
    selectedPatients: state.bookingFlow.formState.patients,
  }));

  useEffect(() => {
    // close modal when site changes
    if (step === "1" || step === "2") {
      onClose();
    }
  }, [step]);

  // Handle vet practice site change
  const handleConfirm = () => {
    dispatch(
      setStep({
        step: "2",
        patients: selectedPatients.map((p) => ({
          ...p,
          reason_uuid: "",
          duration_minutes: 0,
        })),
        selected_slot: {} as AvailableMergedSlot,
        selected_day: {} as AvailableDay,
        availability_date_range: {
          date_start: "",
          date_end: "",
        },
        confirmed_selection: false,
      }),
    );

    onConfirm();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="min-h-[500px]">
      {(step === "3a" || step === "4") && (
        <div className="mb-9">
          <BackButton onClick={() => dispatch(setStep({ step: "3" }))} />
        </div>
      )}

      {step === "3" && <BookAppointmentModalStep3 />}
      {step === "3a" && (
        <BookAppointmentModalStep3a onConfirm={handleConfirm} />
      )}
      {step === "4" && <BookAppointmentModalStep4 />}
      {step === "5" && <BookAppointmentModalStep5 onClose={onClose} />}
    </Modal>
  );
};

export default BookAppointmentModal;
