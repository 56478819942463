const LoadingIcon = ({ className }: { className?: string }) => {
  return (
    <svg x="0px" y="0px" className={className} viewBox="0 0 400 100">
      <circle fill="currentColor" stroke="none" cx="50" cy="50" r="50">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill="currentColor" stroke="none" cx="200" cy="50" r="50">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle fill="currentColor" stroke="none" cx="350" cy="50" r="50">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
    </svg>
  );
};

export default LoadingIcon;
