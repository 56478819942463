import { BackButton, TermsTemplate } from "@mwi/ui";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";

const TermsPage = (): JSX.Element => {
  const navigate = useNavigate();

  const practice = useSelector((state: RootState) => state.practice);

  const handleBack = () => {
    window.history.length > 0 ? navigate(-1) : navigate("/");
  };

  return (
    <>
      <BackButton className="mb-14" onClick={handleBack} />

      <h1 className="mb-8">Terms & Conditions Policy</h1>

      <TermsTemplate
        email={practice?.email ?? null}
        phone={practice?.phone ?? null}
        address={practice?.address ?? null}
        displayName={practice?.displayName ?? null}
        privacyLink={practice?.privacyLink ?? null}
      />
    </>
  );
};

export default TermsPage;
