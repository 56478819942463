import classNames from "classnames";
import { MouseEventHandler } from "react";
import { NavLink } from "react-router-dom";

type ItemProps = {
  to: string;
  title: string;
  icon: JSX.Element;
  end?: boolean;
  onClick?: MouseEventHandler;
};

const Item = ({
  to,
  title,
  icon,
  end = false,
  onClick,
}: ItemProps): JSX.Element => {
  return (
    <NavLink
      end={end}
      to={to}
      onClick={onClick}
      className={({ isActive }) =>
        classNames(
          "tablet:border-l-4 transition tablet:h-10 focus:outline-none focus:text-light",
          {
            "border-transparent text-grey-dark tablet:text-on-dark": !isActive,
            "border-light text-light": isActive,
          },
        )
      }
    >
      {({ isActive }) => (
        <div className="flex flex-col items-center h-full font-semibold tablet:flex-row tablet:ml-[18px] desktop:ml-10">
          <div className="w-8 h-8 desktop:hidden">{icon}</div>

          <span className="hidden desktop:inline">{title}</span>

          <div
            className={classNames(
              "tablet:hidden w-full h-[3px] rounded-full mt-2",
              {
                "bg-light": isActive,
              },
            )}
          />
        </div>
      )}
    </NavLink>
  );
};

export default Item;
