/* eslint-disable max-len */
const CameraIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33333 4.66667H4C4.73638 4.66667 5.33333 4.06971 5.33333 3.33333C5.33333 2.96514 5.63181 2.66667 6 2.66667H10C10.3682 2.66667 10.6667 2.96514 10.6667 3.33333C10.6667 4.06971 11.2636 4.66667 12 4.66667H12.6667C13.403 4.66667 14 5.26362 14 6V12C14 12.7364 13.403 13.3333 12.6667 13.3333H3.33333C2.59695 13.3333 2 12.7364 2 12V6C2 5.26362 2.59695 4.66667 3.33333 4.66667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="8"
        cy="8.66667"
        rx="2"
        ry="2"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CameraIcon;
