import classNames from "classnames";
import { ChangeEvent, ClipboardEvent, KeyboardEvent, useEffect } from "react";
import Input from "../input";

type CodeProps = {
  fields: number;
  value: string;
  disabled: boolean;
  onChange: (value: string) => void;
  capitalize?: boolean;
};

const allowedRegex = /^([a-zA-Z0-9]|Backspace|Escape|Tab|Enter)$/;

const Code = ({
  fields,
  onChange,
  value,
  disabled = false,
  capitalize = false,
}: CodeProps) => {
  const inputsRef: HTMLInputElement[] = [];

  const nextInput = (idx: number) => {
    inputsRef[idx + 1].focus();
    inputsRef[idx + 1].select();
  };

  const prevInput = (idx: number) => {
    inputsRef[idx - 1].focus();
    inputsRef[idx - 1].select();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, idx: number) => {
    if (!allowedRegex.test(inputsRef[idx].value)) {
      e.preventDefault();
      inputsRef[idx].value = "";
      return;
    }

    if (e.target.value) {
      idx + 1 !== inputsRef?.length ? nextInput(idx) : inputsRef[idx].blur();
    }

    const output = inputsRef.map((ref) => ref.value ?? "")?.join("");
    onChange(capitalize ? output?.toUpperCase() : output);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, idx: number) => {
    const pressedKey = e.key.toLowerCase();

    if (inputsRef[idx].value === e.key && idx + 1 !== inputsRef?.length) {
      nextInput(idx);
      return;
    }

    if (pressedKey === "backspace") {
      e.preventDefault();
      inputsRef[idx].value = "";
      if (idx > 0) {
        prevInput(idx);
      }

      const output = inputsRef.map((ref) => ref.value ?? "")?.join("");
      onChange(capitalize ? output?.toUpperCase() : output);
    }

    if (pressedKey === "arrowright") {
      e.preventDefault();
      if (idx + 1 !== inputsRef?.length) {
        nextInput(idx);
      }
    }

    if (pressedKey === "arrowleft") {
      e.preventDefault();
      if (idx > 0) {
        prevInput(idx);
      }
    }

    if (
      pressedKey === "arrowup" ||
      pressedKey === "arrowdown" ||
      pressedKey === "meta"
    ) {
      e.preventDefault();
    }
  };

  const onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const data = e.clipboardData?.getData("text");
    if (data && data.length === fields) {
      const split = data.split("");
      inputsRef.forEach((input, idx) => {
        input.value = split[idx];
      });
      inputsRef[inputsRef.length - 1].focus();

      onChange(capitalize ? data?.toUpperCase() : data);
    }
  };

  useEffect(() => {
    const splitValue = value?.split("");
    if (fields >= splitValue?.length) {
      inputsRef.forEach((ref, i) => (ref.value = splitValue[i] ?? ""));
    }
  }, []);

  useEffect(() => {
    if (!value) {
      inputsRef.forEach((ref) => (ref.value = ""));
    }
  }, [value]);

  return (
    <div className="flex justify-between max-w-[280px] tablet:max-w-xs">
      {Array.from({ length: fields }).map((_, idx) => (
        <div className="w-[3.5rem]" key={idx}>
          <Input
            ref={(ref) => {
              inputsRef[idx] = ref!;
            }}
            centered
            maxChars={1}
            disabled={disabled}
            className={classNames({ uppercase: capitalize })}
            onPaste={(e) => onPaste(e)}
            onChange={(e) => handleChange(e, idx)}
            onKeyDown={(e) => handleKeyDown(e, idx)}
          />
        </div>
      ))}
    </div>
  );
};

export default Code;
