import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";
import { QueryTags } from "api/types/QueryTags";
import Reason from "api/types/models/reason";
import { GetReasonRequest, GetReasonsRequest } from "api/types/requests";
import PaginatedResponse from "api/types/PaginatedResponse";

const reasonEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    QueryTags,
    "data"
  >,
) => ({
  getReason: builder.query<Reason, GetReasonRequest>({
    query: ({ reasonUuid }) => ({
      url: `/reasons/${reasonUuid}`,
    }),
    providesTags: ["Reason"],
  }),

  getReasons: builder.query<PaginatedResponse<Reason[]>, GetReasonsRequest>({
    query: ({ locationUuid, page, size }) => ({
      url: "/reasons",
      params: {
        ...(locationUuid && { location_uuid: locationUuid }),
        ...(page && { page }),
        ...(size && { size }),
      },
    }),
    keepUnusedDataFor: 300, // 5 minutes
    providesTags: ["Reasons"],
  }),
});

export default reasonEndpoints;
