import AppleIcon from "../../../icons/brands/Apple";
import GoogleCalendarIcon from "../../../icons/brands/GoogleCalendar";
import Office365Icon from "../../../icons/brands/Office365";
import OutlookIcon from "../../../icons/brands/Outlook";
import CalendarIcon from "../../../icons/CalendarIcon";
import Modal from "../../modal";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

type Appointment = {
  id: string;
  start_date: string;
  end_date: string;
  reason: string;
  patient_name: string;
  patient_profile_image: string;
  location_uuid: string;
  site?: {
    id: string;
    name: string;
    address_1: string;
    address_2: string;
    city: string;
    county: string;
    postcode: string;
    phone_number: string;
    practice_name: string;
    distance?: number | null;
    enabled?: boolean;
  };
};

const AddToCalendarModal = ({
  isOpen,
  onClose,
  appointment,
}: {
  isOpen: boolean;
  onClose: () => void;
  appointment: Appointment;
}) => {
  const [icalLink, setIcalLink] = useState<string>();

  const startDate = DateTime.fromISO(appointment.start_date);
  const endDate = DateTime.fromISO(appointment.end_date);
  const location = [
    appointment?.site?.address_1,
    appointment?.site?.address_2,
    appointment?.site?.city,
    appointment?.site?.county,
    appointment?.site?.postcode,
  ]
    .filter((v) => v)
    .join(", ");
  const encodedLocation = encodeURIComponent(location);
  const name = `Appointment for ${appointment.patient_name}`;

  const ical = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "CALSCALE:GREGORIAN",
    "PRODID:-//Calendar//Exported",
    "BEGIN:VEVENT",
    `UID:${startDate.toMillis()}${endDate.toMillis()}${name.replaceAll(" ", "")}${location.replaceAll(" ", "")}`,
    `DTSTAMP:${startDate.toFormat("yyyyMMdd'T'HHmmss")}`,
    `DTSTART:${startDate.toFormat("yyyyMMdd'T'HHmmss")}`,
    `DTEND:${endDate.toFormat("yyyyMMdd'T'HHmmss")}`,
    `SUMMARY:${name}`,
    `DESCRIPTION:${appointment.reason}`,
    `LOCATION:${location}`,
    "STATUS:CONFIRMED",
    `LAST-MODIFIED:${DateTime.now().toFormat("yyyyMMdd'T'HHmmss")}`,
    "SEQUENCE:0",
    "END:VEVENT",
    "END:VCALENDAR",
  ];

  useEffect(() => {
    setIcalLink(URL.createObjectURL(new Blob([ical.join("\r\n")])));
    return () => (icalLink ? URL.revokeObjectURL(icalLink) : undefined);
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="max-w-md">
      <Modal.Content>
        <h1 className="text-2xl font-bold">Add to Calendar</h1>

        <div className="flex flex-col gap-4 mt-6">
          <a
            href={icalLink}
            download="apple_calendar.ics"
            className="flex items-center gap-4"
            target="_blank"
            rel="noreferrer"
          >
            <AppleIcon className="w-8 h-8" />
            <p>Apple Calendar</p>
          </a>

          <a
            href={`https://www.google.com/calendar/render?action=TEMPLATE&dates=${startDate.toFormat("yyyyMMdd'T'HHmmss")}%2F${endDate.toFormat("yyyyMMdd'T'HHmmss")}&text=${encodeURIComponent(name)}&details=${appointment.reason}&location=${encodedLocation}`}
            className="flex items-center gap-4"
            target="_blank"
            rel="noreferrer"
          >
            <GoogleCalendarIcon className="w-8 h-8" />
            <p>Google Calendar</p>
          </a>

          <a
            href={`https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=${appointment.reason}&enddt=${encodeURIComponent(endDate.toISO()!)}&location=${encodedLocation}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${encodeURIComponent(startDate.toISO()!)}&subject=${encodeURIComponent(name)}`}
            className="flex items-center gap-4"
            target="_blank"
            rel="noreferrer"
          >
            <OutlookIcon className="w-8 h-8" />
            <p>Outlook</p>
          </a>

          <a
            href={`https://outlook.office.com/calendar/0/deeplink/compose?allday=false&body=${appointment.reason}&enddt=${encodeURIComponent(endDate.toISO()!)}&location=${encodedLocation}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${encodeURIComponent(startDate.toISO()!)}&subject=${encodeURIComponent(name)}`}
            className="flex items-center gap-4"
            target="_blank"
            rel="noreferrer"
          >
            <Office365Icon className="w-8 h-8" />
            <p>Office 365</p>
          </a>

          <a
            href={`data:text/calendar;charset=utf-8,${ical.join("\r\n")}`}
            download="appointment.ics"
            className="flex items-center gap-4"
            target="_blank"
            rel="noreferrer"
          >
            <CalendarIcon className="w-8 h-8" />
            <p>iCal File</p>
          </a>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AddToCalendarModal;
