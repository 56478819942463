/* eslint-disable max-len */
import classNames from "classnames";
import { Transition } from "@headlessui/react";
import { createRef, Fragment, useEffect, useMemo, useState } from "react";

import Input from "../input";
import Divider from "../../divider";
import CrossIcon from "../../../icons/CrossIcon";
import ChevronLeftIcon from "../../../icons/ChevronLeftIcon";
import { useDebounce } from "use-debounce";

type SearchProps = {
  placeholder: string;
  maxChars?: number;
  prefixIcon?: JSX.Element;
  setSelected: (value: any) => void;
  onChange: (value: string) => void;
  children?: JSX.Element | JSX.Element[] | undefined;
};

const Search = ({
  onChange,
  children,
  maxChars,
  prefixIcon,
  placeholder,
  setSelected,
}: SearchProps): JSX.Element => {
  const childrenArray = useMemo(
    () => (children ? (Array.isArray(children) ? children : [children]) : []),
    [children],
  );

  const md = window.innerWidth > 768;

  const [internalValue, setInternalValue] = useState<string>("");
  const [debouncedValue] = useDebounce(internalValue, 500);

  const [focused, setFocused] = useState<boolean>(false);

  const [isAtTop, setIsAtTop] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(childrenArray.length < 5);

  const scrollRef = createRef<HTMLUListElement>();

  const onScroll = () => {
    if (scrollRef.current?.scrollTop === 0) {
      setIsAtTop(true);
    } else if (
      scrollRef.current!.scrollHeight - scrollRef.current!.scrollTop ===
      scrollRef.current?.clientHeight
    ) {
      setIsAtEnd(true);
    }
    if (scrollRef.current!.scrollTop > 0 && isAtTop) {
      setIsAtTop(false);
    }
    if (
      scrollRef.current!.scrollHeight - scrollRef.current!.scrollTop >
        scrollRef.current!.clientHeight &&
      isAtEnd
    ) {
      setIsAtEnd(false);
    }
  };

  const handleSelected = (value: any) => {
    setFocused(false);
    setSelected(value);
  };

  const handleClose = () => {
    setFocused(false);
  };

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue]);

  const handleOnChange = (value: string) => {
    if (maxChars && value.length > maxChars) {
      return;
    }

    setInternalValue(value);
  };

  const handleFocus = (type: "focus" | "blur") => {
    type === "blur" && md ? setFocused(false) : setFocused(true);
  };

  return (
    <div
      className="relative w-full"
      onFocus={() => handleFocus("focus")}
      onBlur={() => handleFocus("blur")}
    >
      <Input
        value={internalValue}
        prefixIcon={prefixIcon}
        onChange={(e) => handleOnChange(e.target.value)}
      />

      <Transition
        as={Fragment}
        show={focused && childrenArray?.length > 0}
        enter="ease-out duration-300 tablet:transition-all"
        enterFrom="opacity-0 scale-95 tablet:-translate-y-4"
        enterTo="opacity-100 scale-100 tablet:translate-y-0 tablet:opacity-1"
        leave="ease-in duration-200 tablet:transition-all"
        leaveFrom="opacity-100 scale-100 tablet:translate-y-0 tablet:opacity-0"
        leaveTo="scale-95 tablet:-translate-y-4 opacity-0"
      >
        <div className="fixed inset-0 z-10 tablet:relative tablet:top-2">
          <div
            className={classNames(
              "absolute",
              "bg-white",
              "w-full h-screen tablet:h-max",
              "tablet:border tablet:border-light",
              "tablet:shadow-default",
              {
                "tablet:shadow-focus-small": focused,
              },
            )}
          >
            <div className="w-full mt-11 tablet:mt-0">
              {/* Mobile Navbar */}
              <div className="flex items-center justify-between w-full gap-4 p-4 tablet:hidden">
                <div className="flex items-center flex-grow gap-3">
                  <div
                    tabIndex={0}
                    className="flex-shrink-0 cursor-pointer"
                    onClick={handleClose}
                  >
                    <ChevronLeftIcon className="w-6 h-6" />
                  </div>

                  <Input
                    autoFocus
                    value={internalValue}
                    placeHolder={placeholder}
                    onChange={(e) => handleOnChange(e.target.value)}
                    className="w-full p-0 border-none shadow-none focus:shadow-none"
                  />
                </div>

                <div
                  tabIndex={0}
                  className="flex-shrink-0 cursor-pointer"
                  onClick={handleClose}
                >
                  <CrossIcon className="w-6 h-6" />
                </div>
              </div>

              <Divider className="tablet:hidden border-grey-light" />

              <ul
                ref={scrollRef}
                onScroll={onScroll}
                className={classNames(
                  "min-h-[168px] h-screen tablet:max-h-[300px] overflow-y-auto pb-[110px] tablet:pb-0",
                )}
              >
                {childrenArray?.map((child) => (
                  <li
                    tabIndex={0}
                    key={child.key}
                    onClick={() => handleSelected(child.props.value)}
                    onKeyPress={() => handleSelected(child.props.value)}
                    className="cursor-pointer"
                  >
                    {child}
                  </li>
                ))}
              </ul>

              <Transition
                show={!isAtTop}
                enter="transition"
                enterFrom="opacity-0"
                enterTo="opacity-1"
                leave="transition"
                leaveFrom="opacity-1"
                leaveTo="opacity-0"
                className={classNames(
                  "absolute inset-x-4 tablet:inset-x-0 h-20 top-[76px] hidden tablet:block",
                  "tablet:top-0 bg-gradient-scroll-start pointer-events-none",
                )}
              />

              <Transition
                show={!isAtEnd}
                enter="transition"
                enterFrom="opacity-0"
                enterTo="opacity-1"
                leave="transition"
                leaveFrom="opacity-1"
                leaveTo="opacity-0"
                className={classNames(
                  "absolute inset-x-4 h-20 bottom-[104px] bg-gradient-scroll-end hidden tablet:block",
                  "tablet:bottom-0 tablet:inset-x-0 pointer-events-none",
                )}
              />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Search;
