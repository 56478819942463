import { Navigate, useLocation, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./store";

const ProtectedRoute = () => {
  const { authenticated } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  if (!authenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
