import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";
import Breed from "api/types/models/breed";
import Gender from "api/types/models/gender";
import Species from "api/types/models/species";
import { QueryTags } from "api/types/QueryTags";
import PaginatedResponse from "api/types/PaginatedResponse";
import { GetSpeciesRequest } from "api/types/requests";

const speciesEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    QueryTags,
    "data"
  >,
) => ({
  getSpecies: builder.query<PaginatedResponse<Species[]>, GetSpeciesRequest>({
    query: ({ page, size }) => ({
      url: "/species",
      params: {
        ...(page && { page }),
        ...(size && { size }),
      },
    }),
    keepUnusedDataFor: 3600, // 1 hour
  }),

  getBreedsForSpecies: builder.query<Breed[], string>({
    query: (speciesId) => ({ url: `/species/${speciesId}/breeds` }),
  }),

  getGendersForSpecies: builder.query<Gender[], string>({
    query: (speciesUuid) => ({ url: `/species/${speciesUuid}/genders` }),
  }),
});

export default speciesEndpoints;
