import { RootState } from "store";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  InlineLink,
  CalendarOffIcon,
  GraphicBackground,
  CalendarCheckIcon,
} from "@mwi/ui";
import { clearBookingState, setStep } from "slices/booking";
import {
  AvailableDay,
  AvailableMergedSlot,
} from "api/types/models/availability";
import { useEffect } from "react";
import { gtmEvent } from "helpers/gtm";

const BookingFlowStep5 = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, apptConfirmed, errorMessage } = useSelector(
    (state: RootState) => ({
      user: state.auth.user,
      apptConfirmed: state.bookingFlow.formState.appointment_confirmed,
      errorMessage: state.bookingFlow.formState.error_message,
    }),
  );

  const handleButton = () => {
    if (apptConfirmed) {
      dispatch(clearBookingState());
      navigate("/home");
    } else {
      dispatch(
        setStep({
          step: "3",
          selected_slot: {} as AvailableMergedSlot,
          selected_day: {} as AvailableDay,
          confirmed_selection: false,
          appointment_confirmed: false,
          availability_date_range: {
            date_start: "",
            date_end: "",
          },
        }),
      );
    }
  };

  const handleLink = () => {
    dispatch(clearBookingState());
    navigate(apptConfirmed ? "/appointments/book" : "/home");
  };

  useEffect(() => {
    if (apptConfirmed !== undefined) {
      gtmEvent({
        name: apptConfirmed
          ? "onMobileApptFlowConfirmedApptPageView"
          : "onMobileApptFlowFailedApptPageView",
      });
    }
  }, [apptConfirmed]);

  return (
    <div className="flex flex-col flex-grow items-center pt-[74px]">
      <div className="mb-10">
        <div className="relative w-[240px] h-[193px] flex items-center justify-center">
          <GraphicBackground
            className={classNames("text-lightest", {
              "!text-error-lightest": !apptConfirmed,
            })}
          />

          <div className="absolute w-[103px] h-[103px]">
            {apptConfirmed ? (
              <CalendarCheckIcon className="text-primary" />
            ) : (
              <CalendarOffIcon className="text-error" />
            )}
          </div>
        </div>
      </div>

      <div className="mb-6">
        <h1 className="text-4xl mobile:text-5xl font-bold leading-[43px] text-center">
          {apptConfirmed
            ? "Your booking is confirmed!"
            : "We could not confirm your booking."}
        </h1>
      </div>

      <div className="mb-16">
        <p className="text-base text-center mobile:text-lg">
          {apptConfirmed ? (
            <>
              <span className="block">We sent a confirmation email to</span>
              <span className="block font-bold">{user?.email}.</span>
            </>
          ) : (
            (errorMessage ??
            `
                  Unfortunately the timeslot you picked got
                  blocked before you got a chance to confirm your booking.
              `)
          )}
        </p>
      </div>

      <div className="flex flex-col items-center w-full gap-6 mt-auto">
        <Button block size="medium" onClick={handleButton}>
          {apptConfirmed ? "Go to your dashboard" : "Find another timeslot"}
        </Button>
        <InlineLink
          onClick={handleLink}
          className="!border-b !pb-[2px] w-max !text-sm !mobile:text-base"
        >
          {apptConfirmed ? "Book another appointment" : "Go to your dashboard"}
        </InlineLink>
      </div>
    </div>
  );
};

export default BookingFlowStep5;
