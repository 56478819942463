import classNames from "classnames";
import AlertIcon from "../../icons/AlertIcon";
import CrossIcon from "../../icons/CrossIcon";
import InfoIcon from "../../icons/InfoIcon";
import SuccessIcon from "../../icons/SuccessIcon";
import WarningIcon from "../../icons/WarningIcon";

type NotificationProps = {
  title: string;
  message?: string;
  onDismiss?: () => void;
  type?: "success" | "error" | "warn" | "info";
  block?: boolean;
};

const NotifierNotification = ({
  title,
  message,
  onDismiss,
  type = "success",
  block = false,
}: NotificationProps): JSX.Element => {
  const containerClasses = classNames("flex w-full z-[999]", {
    "bg-success-lightest shadow-success": type === "success",
    "bg-error-lightest shadow-error": type === "error",
    "bg-warning-lightest shadow-warn": type === "warn",
    "bg-grey-lightest shadow-info": type === "info",
    "max-w-[350px]": !block,
  });

  const iconClasses = classNames(
    "w-8 h-8 rounded-full mb-auto flex-shrink-0 p-1",
    {
      "bg-success-light text-success": type === "success",
      "bg-error-light text-error": type === "error",
      "bg-warning-light text-warning": type === "warn",
      "bg-grey-light text-black": type === "info",
    },
  );

  const closeClasses = classNames(
    "w-6 h-6 mb-auto flex-shrink-0 cursor-pointer",
    {
      "text-success": type === "success",
      "text-error": type === "error",
      "text-warning": type === "warn",
      "text-grey": type === "info",
    },
  );

  return (
    <div className={containerClasses}>
      <div className="p-4">
        <div className={classNames("flex", { "items-center": !message })}>
          <div className={iconClasses}>
            {type === "success" && <SuccessIcon />}
            {type === "error" && <AlertIcon />}
            {type === "warn" && <WarningIcon />}
            {type === "info" && <InfoIcon />}
          </div>

          <div className="px-4 select-none">
            <span className="text-base font-semibold">{title}</span>

            {message && <p className="text-sm font-normal">{message}</p>}
          </div>

          {onDismiss && type !== "error" && (
            <div className={closeClasses} onClick={onDismiss}>
              <CrossIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotifierNotification;
