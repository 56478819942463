import { QueryTags } from "api/types/QueryTags";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";
import Address from "api/types/models/address";
import { GetPostcodeAddresses } from "api/types/requests";

const addressEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    QueryTags,
    "data"
  >,
) => ({
  getPostcodeAddresses: builder.query<Address[], GetPostcodeAddresses>({
    query: ({ searchTerm }) => ({
      url: "/addresses/postcode",
      params: {
        search_term: searchTerm,
      },
    }),
  }),
});

export default addressEndpoints;
