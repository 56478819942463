import { FormEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  setFlow,
  setVerifyMethods,
  setVerifyMethod,
  setPatientOptions,
  updateFormState,
  setLocations,
} from "slices/registration";
import { RootState } from "store";
import {
  Divider,
  Label,
  Input,
  Search,
  Button,
  FormGroup,
  SearchIcon,
  AddressItem,
  Notice,
} from "@mwi/ui";
import useErrorHandler from "hooks/useErrorHandler";
import { useCheckForMatchesMutation, useGetPostcodeAddressesQuery } from "api";
import { gtmEvent } from "helpers/gtm";
import { isFetchBaseQueryError } from "helpers/rtk";

type Inputs = {
  search_term: string;
  name_no?: string;
  line_1: string;
  line_2: string;
  city: string;
  county: string;
  postcode: string;
};

const RegistrationAddress = () => {
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchError, setSearchError] = useState<string>("");

  const { formState, oldFormState } = useSelector((state: RootState) => ({
    formState: state.registration.formState,
    oldFormState: state.registration.oldFormState,
  }));

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: oldFormState,
  });

  const [
    submitForMatches,
    { data: matchResult, isLoading, error: matchError },
  ] = useCheckForMatchesMutation();

  const { data: postcodeAddresses, error: postcodeSearchError } =
    useGetPostcodeAddressesQuery(
      { searchTerm },
      {
        skip: !searchTerm,
      },
    );

  useEffect(() => {
    if (matchError) {
      errorHandler(matchError, setError);
    }

    if (postcodeSearchError) {
      setSearchError("");
      // We manually set an error message for >= 500 errors in JSX below for address step
      if (
        isFetchBaseQueryError(postcodeSearchError) &&
        typeof postcodeSearchError?.status === "number" &&
        postcodeSearchError?.status < 500
      ) {
        errorHandler(postcodeSearchError, setError);
      } else if (errors.search_term?.message) {
        clearErrors("search_term");
      }
    } else if (postcodeAddresses === null) {
      setSearchError(
        "Sorry, we are unable to find your postcode. Please enter your address using the form below.",
      );
      clearErrors("search_term");
    }
  }, [matchError, postcodeSearchError, postcodeAddresses]);

  useEffect(() => {
    if (matchResult) {
      dispatch(updateFormState(getValues()));
      if (matchResult.flow === "match") {
        dispatch(setFlow(matchResult.flow));
        dispatch(setVerifyMethods(matchResult.verify_methods!));
        if (matchResult.verify_methods?.length === 1) {
          dispatch(setVerifyMethod(matchResult.verify_methods![0].method));
        }
        dispatch(setPatientOptions(matchResult.patient_options));
      } else {
        dispatch(setLocations(matchResult.locations));
      }
    }
  }, [matchResult]);

  const submitHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    clearErrors();
    return handleSubmit(submitForm)(e);
  };

  const submitForm = (data: Inputs) => {
    clearErrors();
    setSearchError("");
    let error = false;
    if (!data.name_no) {
      error = true;
      setError("name_no", {
        message: "Your building name or number is required",
      });
    }
    if (!data.line_1) {
      error = true;
      setError("line_1", {
        message: "The first line of your address is required",
      });
    }
    if (!data.city) {
      error = true;
      setError("city", { message: "Your town / city is required" });
    }
    if (!data.postcode) {
      error = true;
      setError("postcode", { message: "Your postcode is required" });
    }

    if (!error) {
      submitForMatches({
        ...formState,
        ...data,
      });
    }
  };

  useEffect(() => gtmEvent({ name: "onRegFlowAddressStepPageView" }), []);

  return (
    <>
      <h1 className="text-4xl font-bold tablet:text-6xl">Where do you live?</h1>

      <Notice className="mt-10 mb-0">
        <p className="font-bold">Welcome to our new client registration.</p>
        <br />
        <p>
          If you are already a client of our practice, it appears that the
          details we hold for you do not match those you have entered. Please
          contact us before proceeding to update your details.
        </p>
      </Notice>

      <div className="mt-8 tablet:mt-12">
        <FormGroup
          error={
            searchError ||
            errors.search_term?.message ||
            (postcodeSearchError &&
            isFetchBaseQueryError(postcodeSearchError) &&
            typeof postcodeSearchError?.status === "number" &&
            postcodeSearchError?.status >= 500
              ? "Sorry, we are unable to find your postcode. Please enter your address using the form below."
              : undefined)
          }
        >
          <Label>Enter Postcode</Label>
          <Search
            prefixIcon={<SearchIcon />}
            placeholder="Start typing your postcode..."
            onChange={(value) => setSearchTerm(value)}
            setSelected={(value) => {
              clearErrors();
              setSearchError("");
              setValue("name_no", value?.name_no);
              setValue("line_1", value?.line1);
              setValue("line_2", value?.line2);
              setValue("city", value?.city);
              setValue("county", value?.county);
              setValue("postcode", value?.postcode);
            }}
          >
            {postcodeAddresses &&
              postcodeAddresses.map((address, idx) => (
                <AddressItem key={idx} value={address} />
              ))}
          </Search>
        </FormGroup>

        <Divider className="mt-2 mb-8" />

        <form onSubmit={submitHandler}>
          <FormGroup error={errors?.name_no?.message}>
            <Label>Building Name or Number</Label>
            <Input {...register("name_no")} />
          </FormGroup>
          <FormGroup error={errors?.line_1?.message}>
            <Label>Address Line 1</Label>
            <Input {...register("line_1")} />
          </FormGroup>

          <FormGroup error={errors?.line_2?.message}>
            <Label>Address Line 2</Label>
            <Input {...register("line_2")} />
          </FormGroup>

          <div className="grid grid-cols-1 tablet:gap-6 tablet:grid-cols-2">
            <FormGroup error={errors?.city?.message}>
              <Label>Town / City</Label>
              <Input {...register("city")} />
            </FormGroup>

            <FormGroup error={errors?.county?.message}>
              <Label>County</Label>
              <Input {...register("county")} />
            </FormGroup>

            <FormGroup error={errors?.postcode?.message}>
              <Label>Postcode</Label>
              <Input {...register("postcode")} />
            </FormGroup>
          </div>

          <div className="mt-auto tablet:mt-12 tablet:max-w-xs">
            <Button type="submit" loading={isLoading} block>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default RegistrationAddress;
