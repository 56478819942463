import { useMemo } from "react";
import classNames from "classnames";
import { zxcvbn, ZxcvbnResult, ZxcvbnOptions } from "@zxcvbn-ts/core";
import zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import {
  dictionary as zxcvbnEnDictionary,
  translations as zxcvbnEnTranslations,
} from "@zxcvbn-ts/language-en";

type StrengthLevel = undefined | 1 | 2 | 3;

ZxcvbnOptions.setOptions({
  translations: {
    ...zxcvbnEnTranslations,
    suggestions: {
      ...zxcvbnEnTranslations.suggestions,
      repeated: "Password must not contain repeating words and characters",
      anotherWord: "Password must contain another word that is less common",
      // Don't show zxcvbn's version of capitalization
      capitalization: "removed_capitalization",
    },
  },
  dictionary: {
    ...zxcvbnEnDictionary,
    ...zxcvbnCommonPackage.dictionary,
  },
});

const PasswordStrength = ({
  className,
  password,
  error,
}: {
  className?: string;
  password?: string;
  error?: string;
}) => {
  const { strengthLevel, suggestions } = useMemo(() => {
    let strengthLevel: StrengthLevel;
    const suggestions: string[] = [];

    if (!password) {
      return {
        strengthLevel,
        suggestions,
      };
    }

    const result = password ? (zxcvbn(password) as ZxcvbnResult) : undefined;

    const results = {
      charCount: password.length >= 8,
      uppercase: /[A-Z]+/.test(password),
      lowercase: /[a-z]+/.test(password),
      number: /\d+/.test(password),
      symbol: /[~!@#$£%^&*]+/.test(password),
    };

    if (!results.charCount) {
      suggestions.push("Password must be at least 8 characters");
      strengthLevel = 1;
    }

    if (!results.uppercase) {
      suggestions.push(
        "Password must contain at least one uppercase character",
      );
      strengthLevel = 1;
    }

    if (!results.lowercase) {
      suggestions.push(
        "Password must contain at least one lowercase character",
      );
      strengthLevel = 1;
    }

    if (!results.number) {
      suggestions.push("Password must contain at least one number");
      strengthLevel = 1;
    }

    if (!results.symbol) {
      suggestions.push(
        "Password must contain at least one symbol (~ ! @ # $ £ % ^ & *)",
      );
      strengthLevel = 1;
    }

    if (result?.feedback?.suggestions?.length) {
      result.feedback.suggestions.forEach((suggestion) => {
        if (suggestion !== "removed_capitalization") {
          suggestions.push(suggestion);
        }
      });
      strengthLevel = 1;
    }

    if (suggestions.length === 0) {
      strengthLevel =
        result?.score === undefined
          ? undefined
          : result?.score >= 3
            ? 3
            : result?.score >= 2
              ? 2
              : 1;
    }

    return {
      strengthLevel,
      suggestions,
    };
  }, [password]);

  const Block = ({ level }: { level: number }) => {
    return (
      <div
        className={classNames("w-full h-[5px] transition", {
          "bg-grey-light": strengthLevel === undefined || strengthLevel < level,
          "bg-error-light": strengthLevel === 1 && strengthLevel >= level,
          "bg-warning-light": strengthLevel === 2 && strengthLevel >= level,
          "bg-success-light": strengthLevel === 3 && strengthLevel >= level,
        })}
      />
    );
  };

  return (
    <div className="relative">
      <div className={classNames("flex gap-2", className)}>
        <Block level={1} />
        <Block level={2} />
        <Block level={3} />
      </div>

      <p
        className={classNames(
          "mt-2 text-sm font-semibold tablet:text-base transition",
          {
            "text-error": strengthLevel === 1 || error,
            "text-warning": strengthLevel === 2 && !error,
            "text-success": strengthLevel === 3 && !error,
          },
        )}
      >
        {error}
        {!error && strengthLevel === undefined && <>&nbsp;</>}
        {!error && strengthLevel === 1 && "Very Weak Password"}
        {!error && strengthLevel === 2 && "Average Password"}
        {!error && strengthLevel === 3 && "Very Strong Password"}
      </p>

      <ul className="list-disc list-inside text-error">
        {suggestions.map((suggestion) => (
          <li key={suggestion}>{suggestion}</li>
        ))}
      </ul>
    </div>
  );
};

export default PasswordStrength;
