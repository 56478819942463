import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  Label,
  Select,
  FormGroup,
  Button,
  TextArea,
  Loader,
  AlertIcon,
  SelectOption,
  PatientOverviewCard,
  WarningIcon,
  FormInputLimit,
} from "@mwi/ui";
import Patient from "api/types/models/patient";
import { SelectedPatient } from "types/booking-flow/SelectedPatient";
import {
  setStep,
  setNotification,
  setPatientReasonIndex,
  setPatients,
} from "slices/booking";
import {
  useGetPatientsQuery,
  useGetReasonsQuery,
  useGetSiteQuery,
  useVerifyAppointmentsMutation,
} from "api";
import ReasonType from "enums/ReasonType";
import classNames from "classnames";
import { gtmEvent } from "helpers/gtm";

type Inputs = {
  patients: SelectedPatient[];
};

const BookingFlowStep2 = (): JSX.Element => {
  const dispatch = useDispatch();

  const {
    registeredSite,
    patientIndex,
    vetSite,
    currentNotification,
    selectedPatients,
  } = useSelector((state: RootState) => ({
    registeredSite: state.auth.registered_site,
    patientIndex: state.bookingFlow.patient_index,
    vetSite: state.bookingFlow.formState.vet_site_uuid,
    currentNotification: state.bookingFlow.formState.notification,
    selectedPatients: state.bookingFlow.formState.patients,
  }));

  const [currentPatient, setCurrentPatient] = useState<Patient | null>(null);

  const { data: patients } = useGetPatientsQuery();

  const { data: reasons, isFetching: fetchingReasons } = useGetReasonsQuery({
    locationUuid: vetSite ? vetSite : registeredSite!.id,
  });

  const { data: site } = useGetSiteQuery(
    vetSite ? vetSite : registeredSite!.id,
  );

  const [
    verifyReasons,
    { isSuccess: didVerify, isLoading: verifying, reset: resetVerify },
  ] = useVerifyAppointmentsMutation();

  const { register, handleSubmit, watch, control, reset, getValues } =
    useForm<Inputs>({
      defaultValues: {
        patients: selectedPatients,
      },
    });

  const handleOnSubmit = (data: Inputs) => {
    const patient = data.patients[patientIndex];

    if (currentNotification?.type === ReasonType.EMERGENCY) {
      window.location.assign(`tel:${site?.phone_number}`);
      return;
    }

    verifyReasons({
      step: "2",
      patients:
        data.patients.length === patientIndex + 1 ? data.patients : [patient],
    });

    dispatch(
      setPatients({ patients: getValues("patients").map((p) => ({ ...p })) }),
    );
  };

  useEffect(() => {
    if (didVerify) {
      if (selectedPatients?.length === patientIndex + 1) {
        dispatch(
          setStep({
            step: "3",
            patients: selectedPatients,
          }),
        );
        dispatch(setPatientReasonIndex(0));
      } else {
        dispatch(setPatientReasonIndex(patientIndex + 1));
        resetVerify();
      }
    }
  }, [didVerify]);

  useEffect(() => {
    if (patients || patientIndex) {
      const patient = patients?.find(
        (p) => p.id === selectedPatients[patientIndex]?.patient_uuid,
      );
      if (patient) {
        setCurrentPatient(patient);
        reset({ patients: selectedPatients });
      }
    }
  }, [patientIndex, patients]);

  useEffect(() => {
    const patientReason = getValues(`patients.${patientIndex}.reason_uuid`);
    if (patientReason) {
      const reason = reasons?.data?.find((r) => r.id === patientReason);
      if (reason) {
        reason?.notification
          ? dispatch(setNotification(reason.notification))
          : dispatch(
              setNotification({
                type: undefined,
                is_active: false,
                message: undefined,
              }),
            );

        // add duration minutes to patient reason
        const patientReasons = getValues("patients").map((p) => {
          if (selectedPatients[patientIndex].patient_uuid === p.patient_uuid) {
            p.duration_minutes = reason.duration_minutes;
          }

          return { ...p };
        });

        dispatch(setPatients({ patients: patientReasons }));
      }
    }
  }, [watch(`patients.${patientIndex}.reason_uuid`)]);

  useEffect(
    () => gtmEvent({ name: "onMobileApptFlowSelectReasonsPageView" }),
    [],
  );

  return (
    <>
      {fetchingReasons && (
        <div className="grid h-full place-content-center">
          <Loader />
        </div>
      )}

      {!fetchingReasons && selectedPatients && selectedPatients?.length > 0 && (
        <>
          {currentNotification && currentNotification.is_active && (
            <div className="mb-10">
              <div
                className={classNames("w-full", {
                  "bg-error-lightest shadow-error":
                    currentNotification.type === ReasonType.EMERGENCY,
                  "bg-warning-lightest shadow-warn":
                    currentNotification.type === ReasonType.NOTIFICATION,
                })}
              >
                <div className="flex items-center gap-4 p-4">
                  <div
                    className={classNames(
                      "flex-shrink-0 w-8 h-8 p-1 rounded-full ",
                      {
                        "bg-error":
                          currentNotification.type === ReasonType.EMERGENCY,
                        "bg-warning":
                          currentNotification.type === ReasonType.NOTIFICATION,
                      },
                    )}
                  >
                    <span className="text-white">
                      {currentNotification.type === ReasonType.EMERGENCY && (
                        <AlertIcon className="text-white" />
                      )}
                      {currentNotification.type === ReasonType.NOTIFICATION && (
                        <WarningIcon className="text-white" />
                      )}
                    </span>
                  </div>

                  <div>
                    {currentNotification.type === ReasonType.EMERGENCY && (
                      <span className="text-base font-semibold">
                        Oh, no! This is an emergency.
                      </span>
                    )}
                    {currentNotification.type === ReasonType.NOTIFICATION && (
                      <span className="text-base font-semibold">Hold up!</span>
                    )}

                    <p className="text-sm font-normal">
                      {currentNotification.message
                        ? currentNotification.message
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="mb-8">
            <h2 className="text-4xl font-bold leading-[43px]">
              What is the reason for your booking?
            </h2>
          </div>

          <div className="mb-10">
            <p className="text-base">Select an option from the list below.</p>
          </div>

          {currentPatient && (
            <>
              <div className="mb-4">
                <PatientOverviewCard
                  patient={currentPatient}
                  showLastAppointment
                />
              </div>

              <form
                className="flex flex-col flex-grow"
                onSubmit={handleSubmit(handleOnSubmit)}
              >
                <div className="mb-10">
                  <Select
                    control={control}
                    placeholder="I need an appointment for"
                    name={`patients.${patientIndex}.reason_uuid`}
                  >
                    {reasons?.data?.map((reason) => (
                      <SelectOption key={reason.id} value={reason.id}>
                        {reason?.translation ?? reason.name}
                      </SelectOption>
                    ))}
                  </Select>
                </div>

                {currentNotification?.type !== ReasonType.EMERGENCY &&
                  watch(`patients.${patientIndex}.reason_uuid`) && (
                    <div className="mb-10">
                      <div className="transition-all">
                        <FormGroup>
                          <Label>Additional Notes:</Label>
                          <TextArea
                            rows={2}
                            maxLength={250}
                            placeholder="Please provide more detail on the reason for your visit."
                            {...register(`patients.${patientIndex}.comment`)}
                          />
                          <FormInputLimit
                            value={
                              watch(`patients.${patientIndex}.comment`)
                                ?.length ?? 0
                            }
                            limit={250}
                          />
                        </FormGroup>
                      </div>
                    </div>
                  )}

                <div className="mt-auto">
                  <Button
                    block
                    type="submit"
                    loading={verifying}
                    danger={currentNotification?.type === ReasonType.EMERGENCY}
                    disabled={!watch(`patients.${patientIndex}.reason_uuid`)}
                  >
                    {currentNotification?.type === ReasonType.EMERGENCY
                      ? "Call Now"
                      : "Continue"}
                  </Button>
                </div>
              </form>
            </>
          )}
        </>
      )}
    </>
  );
};

export default BookingFlowStep2;
