import { useFormContext } from "react-hook-form";
import { Button, Label, Input, FormGroup, InlineLink } from "@mwi/ui";

const ChangePasswordStep1 = ({
  isLoading,
}: {
  isLoading: boolean;
}): JSX.Element => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <h2 className="my-8">Change password</h2>

      <p className="mb-10">Enter your current password to continue.</p>

      <div className="w-full mb-4 mobile:max-w-2xl">
        <FormGroup error={errors.current_password?.message?.toString()}>
          <Label>Current Password</Label>
          <Input
            type="password"
            autoComplete="off"
            {...register("current_password")}
          />
        </FormGroup>
      </div>

      <p>
        Can&apos;t remember your password?{" "}
        <InlineLink size="medium" to="/forgot">
          Reset Password
        </InlineLink>
      </p>

      <div className="flex items-end flex-grow w-full mt-20 tablet:items-start mobile:max-w-sm">
        <Button
          block
          type="submit"
          loading={isLoading}
          disabled={!watch("current_password")}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default ChangePasswordStep1;
