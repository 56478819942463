import classNames from "classnames";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import CrossIcon from "../../icons/CrossIcon";

type SheetProps = {
  title: string;
  closeOptions?: () => void;
  children:
    | JSX.Element
    | JSX.Element[]
    | ((params: { close: () => void }) => JSX.Element);
  trigger: JSX.Element;
};

const Sheet = ({
  title,
  closeOptions,
  children,
  trigger,
}: SheetProps): JSX.Element => {
  return (
    <Popover as={Fragment}>
      <Popover.Button as={Fragment}>{trigger}</Popover.Button>

      <div className="relative">
        <Transition as={Fragment}>
          <Popover.Panel className="fixed inset-0 z-[30] tablet:hidden">
            {({ close }) => {
              return (
                <>
                  {/* Overlay */}
                  <Transition.Child
                    enter="transition"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="absolute inset-0 bg-black/30"
                    onClick={() => {
                      if (closeOptions) {
                        closeOptions();
                      }
                      close();
                    }}
                  />

                  {/* Panel */}
                  <Transition.Child
                    enter="transition-all"
                    enterFrom="translate-y-full"
                    enterTo="translate-y-0"
                    leave="transition-all"
                    leaveFrom="translate-y-0"
                    leaveTo="translate-y-full"
                    className={classNames(
                      "absolute inset-x-0 bottom-0 px-4 pt-8 pb-6 bg-white rounded-t-3xl",
                      "overflow-x-hidden max-h-[100vh]",
                    )}
                  >
                    <div
                      className="absolute right-4 top-8"
                      onClick={() => {
                        if (closeOptions) {
                          closeOptions();
                        }
                        close();
                      }}
                    >
                      <CrossIcon className="w-6 h-6" />
                    </div>

                    {title && (
                      <h3 className="text-lg font-semibold text-center">
                        {title}
                      </h3>
                    )}

                    {typeof children === "function"
                      ? children({ close })
                      : children}
                  </Transition.Child>
                </>
              );
            }}
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
};

export default Sheet;
