import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { RootState } from "store";
import useTitle from "hooks/useTitle";
import ChangePasswordStep1 from "./step/ChangePasswordStep1";
import ChangePasswordStep2 from "./step/ChangePasswordStep2";
import ChangePasswordStep3 from "./step/ChangePasswordStep3";
import { useUpdateUserMutation, useVerifyPasswordMutation } from "api";
import { nextChangePasswordStep } from "slices/auth";
import useErrorHandler from "hooks/useErrorHandler";

type Inputs = {
  current_password: string;

  password: string;
  password_confirmation: string;
};

const ChangePasswordPage = ({
  onDone,
}: {
  onDone?: () => void;
}): JSX.Element => {
  useTitle("Change Password");

  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const methods = useForm<Inputs>();

  const authUser = useSelector((state: RootState) => state.auth.user);
  const step = useSelector(
    (state: RootState) => state.auth.change_password_step,
  );

  const [
    verifyPassword,
    { isLoading: isVerifying, isSuccess: didVerify, error: errorVerifying },
  ] = useVerifyPasswordMutation();

  const [
    updateUser,
    { isLoading: isUpdating, isSuccess: didUpdate, error: errorUpdating },
  ] = useUpdateUserMutation();

  const handleOnSubmit = (data: Inputs) => {
    if (step === 1) {
      verifyPassword({ current_password: data.current_password });
    }

    if (step === 2) {
      updateUser({ id: authUser!.id, body: data });
    }
  };

  useEffect(() => {
    if (didVerify || didUpdate) {
      dispatch(nextChangePasswordStep());
    }
  }, [didVerify, didUpdate]);

  useEffect(() => {
    if (errorVerifying) {
      errorHandler(errorVerifying, methods.setError);
      methods.setValue("current_password", "");
    }

    if (errorUpdating) {
      errorHandler(errorUpdating, methods.setError);
    }
  }, [errorVerifying, errorUpdating]);

  return (
    <>
      {step === 1 || step === 2 ? (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(handleOnSubmit)}
            className="flex flex-col h-full"
          >
            {step === 1 && <ChangePasswordStep1 isLoading={isVerifying} />}
            {step === 2 && <ChangePasswordStep2 isLoading={isUpdating} />}
          </form>
        </FormProvider>
      ) : (
        <ChangePasswordStep3 onDone={onDone} />
      )}
    </>
  );
};

export default ChangePasswordPage;
