import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useCheckForMatchesMutation } from "api";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "store";
import {
  Input,
  Button,
  FormGroup,
  Label,
  PracticeLogo,
  Notice,
  InlineLink,
} from "@mwi/ui";
import useErrorHandler from "hooks/useErrorHandler";
import useTitle from "hooks/useTitle";
import {
  setFlow,
  setLocations,
  setPatientOptions,
  setVerifyMethod,
  setVerifyMethods,
  updateFormState,
} from "slices/registration";
import { login } from "slices/auth";
import TermsModal from "components/modals/terms";
import PrivacyModal from "components/modals/privacy";

type Inputs = {
  email: string;
};

const StartPage = () => {
  useTitle("Welcome");

  const dispatch = useDispatch();
  const location = useLocation();
  const errorHandler = useErrorHandler();
  const navigate = useNavigate();

  const [isTermsModalOpen, setTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();

  const [checkMatches, { data: matchResult, isLoading, error }] =
    useCheckForMatchesMutation();

  const { user, isAuthenticated, theme, practiceName, registrationsBlocked } =
    useSelector((state: RootState) => ({
      user: state.auth.user,
      isAuthenticated: state.auth.authenticated,
      theme: state.practice.theme,
      practiceName: state.practice.name,
      registrationsBlocked: state.practice.registrations_blocked,
    }));

  useEffect(() => {
    if (error) {
      errorHandler(error, setError);
    }
  }, [error]);

  useEffect(() => {
    if (matchResult) {
      dispatch(
        updateFormState({
          email: getValues("email"),
        }),
      );

      if (matchResult.flow === "login") {
        navigate("/login");
        return;
      }

      if (matchResult.flow === "match") {
        dispatch(setFlow(matchResult.flow));
        dispatch(setVerifyMethods(matchResult.verify_methods));
        if (matchResult.verify_methods?.length === 1) {
          dispatch(setVerifyMethod(matchResult.verify_methods![0].method));
        }
        dispatch(setPatientOptions(matchResult.patient_options));
        dispatch(setLocations(matchResult.locations));
        if (matchResult?.user && matchResult?.registered_location) {
          dispatch(
            login({
              user: matchResult.user,
              registered_site: matchResult.registered_location,
            }),
          );
        }
        navigate("/register");
      }

      if (matchResult.flow === "register") {
        dispatch(setFlow(matchResult.flow));
        dispatch(updateFormState({ email: getValues("email") }));
        navigate("/register");
      }
    }
  }, [dispatch, matchResult]);

  if (isAuthenticated && user?.onboarding_completed_at) {
    const to = (location.state as any)?.from?.pathname ?? "/home";
    return <Navigate to={to} replace />;
  }

  return (
    <form
      onSubmit={handleSubmit(checkMatches)}
      className="flex flex-col h-full pt-10 tablet:pt-1"
      noValidate
    >
      <div className="w-full mx-auto max-w-[37.5rem]">
        <PracticeLogo
          logo={theme?.logoUrl}
          name={practiceName}
          className="mb-6 desktop:hidden"
        />
      </div>

      <div className="hidden desktop:block fixed inset-y-0 left-0 right-[70%]">
        <img
          src={
            theme?.customLoginImage ??
            theme?.defaultLoginImages.find(
              (img) => img.id === theme?.defaultLoginImage,
            )?.url ??
            theme.defaultLoginImages[0].url
          }
          alt=""
          className="object-cover w-full h-full"
        />
        <PracticeLogo
          logo={theme?.logoUrl}
          name={practiceName}
          className="absolute top-5 left-20"
        />
      </div>

      <div className="desktop:fixed inset-y-0 right-0 left-[30%] flex-grow">
        <div className="flex flex-col justify-between w-full h-full mx-auto max-w-[37.5rem]">
          <div className="flex flex-col justify-center h-full tablet:-mt-16">
            <h1 className="mt-16 text-4xl font-bold leading-10 tablet:text-6xl">
              Welcome
            </h1>

            <p className="mt-8 mb-2 text-lg">Enter your email address to:</p>
            <ul className="pl-3 space-y-1 list-disc text-md">
              <li>
                <span>Log in</span>
              </li>
              <li>
                <span>Register as a new client</span>
              </li>
              <li>
                <span>Sign up as an existing practice client</span>
              </li>
            </ul>

            {registrationsBlocked && (
              <Notice className="mt-10 tablet:mt-6">
                Sorry, we are currently unable to take new client registrations
              </Notice>
            )}

            <FormGroup
              className="!mt-10 tablet:!mt-14"
              error={errors.email?.message}
            >
              <Label>Email Address</Label>
              <Input type="email" {...register("email")} autoComplete="off" />
            </FormGroup>

            <div className="pt-8 mt-auto tablet:mt-0">
              <Button
                className="!px-[60px] !py-[18px] w-full tablet:w-max"
                type="submit"
                size="large"
                loading={isLoading}
              >
                Continue
              </Button>
            </div>
          </div>

          <div className="flex flex-wrap justify-center w-full gap-4 pt-6 pb-12 tablet:justify-start">
            <InlineLink onClick={() => setTermsModalOpen(true)} size="medium">
              Terms & Conditions
            </InlineLink>
            <div>
              <span className="mr-4">•</span>
              <InlineLink
                onClick={() => setPrivacyModalOpen(true)}
                size="medium"
              >
                Privacy Policy
              </InlineLink>
            </div>
          </div>
        </div>
      </div>

      <TermsModal
        isOpen={isTermsModalOpen}
        onClose={() => setTermsModalOpen(false)}
      />
      <PrivacyModal
        isOpen={isPrivacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
      />
    </form>
  );
};

export default StartPage;
