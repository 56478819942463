import { Button, FormGroup, ReadOnly } from "@mwi/ui";
import { useSelector } from "react-redux";
import { RootState } from "store";

const SettingsPractice = () => {
  const registeredSite = useSelector(
    (state: RootState) => state.auth.registered_site,
  );

  return (
    <>
      <h2 className="my-8 tablet:hidden">Contact Details</h2>

      {registeredSite?.email && (
        <FormGroup hideError>
          <ReadOnly
            heading="Email Address"
            value={registeredSite.email!}
            hasDivider
          />
        </FormGroup>
      )}

      {registeredSite?.phone && (
        <FormGroup hideError>
          <ReadOnly
            heading="Phone Number"
            value={registeredSite.phone!}
            hasDivider
          />
        </FormGroup>
      )}

      {registeredSite?.address && (
        <FormGroup hideError>
          <ReadOnly
            heading="Preferred Practice Location"
            value={[
              registeredSite.address?.name_no,
              registeredSite.address?.line1,
              registeredSite.address?.line2,
              registeredSite.address?.city,
              registeredSite.address?.county,
              registeredSite.address?.postcode,
            ]}
            hasDivider
          />
        </FormGroup>
      )}

      <div className="flex items-end flex-grow tablet:items-start tablet:max-w-xs">
        {registeredSite?.phone && (
          <Button
            block
            to={`tel:${registeredSite.phone}`}
            type="link"
            variant="outlined"
            className="tablet:hidden"
          >
            Call us now
          </Button>
        )}
        {registeredSite?.email && (
          <Button
            block
            to={`mailto:${registeredSite.email}`}
            type="link"
            variant="outlined"
            className="hidden tablet:block"
          >
            Contact us now
          </Button>
        )}
      </div>
    </>
  );
};

export default SettingsPractice;
