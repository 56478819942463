import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { AvailableDay } from "api/types/models/availability";
import { ChangeWeekButton, AvailableDayOverview } from "@mwi/ui";
import { setAvailabilityDateRange, setSelectedDay } from "slices/booking";

const WeekView = ({
  availability,
}: {
  availability: AvailableDay[];
}): JSX.Element => {
  const dispatch = useDispatch();

  const { selectedDay, availabilityDateRange } = useSelector(
    (state: RootState) => ({
      selectedDay: state.bookingFlow.formState.selected_day,
      availabilityDateRange:
        state.bookingFlow.formState.availability_date_range,
    }),
  );

  const handleNextWeek = () => {
    const nextWeek = DateTime.fromISO(availabilityDateRange.date_start).plus({
      week: 1,
    });

    dispatch(
      setAvailabilityDateRange({
        date_start: nextWeek.hasSame(DateTime.now(), "week")
          ? nextWeek.toISO()!
          : nextWeek.startOf("week").toISO()!,
        date_end: nextWeek.endOf("week").toISO()!,
      }),
    );
  };

  const handlePrevWeek = () => {
    const prevWeek = DateTime.fromISO(availabilityDateRange.date_start).minus({
      week: 1,
    });

    dispatch(
      setAvailabilityDateRange({
        date_start: prevWeek.hasSame(DateTime.now(), "week")
          ? DateTime.now().toISO()!
          : prevWeek.toISO()!,
        date_end:
          prevWeek < DateTime.now()
            ? DateTime.now().endOf("week").toISO()!
            : prevWeek.endOf("week").toISO()!,
      }),
    );
  };

  return (
    <div className="relative w-full overflow-auto full-width">
      <div className="flex w-full gap-2 px-4 overflow-x-auto snap-x scrollbar-none scroll-smooth">
        {DateTime.fromISO(availabilityDateRange.date_start).isValid &&
          DateTime.fromISO(availabilityDateRange.date_start) >
            DateTime.now() && (
            <div className="flex flex-shrink-0 py-1 scroll-mx-4 snap-start">
              <ChangeWeekButton type="previous" onClick={handlePrevWeek} />
            </div>
          )}

        {availability &&
          availability?.length > 0 &&
          availability.map((day) => (
            <div
              key={day.date}
              className="flex flex-shrink-0 py-1 scroll-mx-4 snap-start"
            >
              <AvailableDayOverview
                day={DateTime.fromISO(day.date)}
                disabled={day?.mergedSlots?.length === 0}
                onClick={() => dispatch(setSelectedDay(day))}
                isSelected={
                  DateTime.fromISO(selectedDay.date).isValid &&
                  DateTime.fromISO(selectedDay.date).hasSame(
                    DateTime.fromISO(day.date),
                    "day",
                  )
                }
              />
            </div>
          ))}

        <div className="flex flex-shrink-0 py-1 scroll-mx-4 snap-start">
          <ChangeWeekButton type="next" onClick={handleNextWeek} />
        </div>
      </div>
    </div>
  );
};

export default WeekView;
