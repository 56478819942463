import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLoginMutation } from "api";
import { useDispatch, useSelector } from "react-redux";
import { login } from "slices/auth";
import { setPractice } from "slices/practice";
import { Navigate, useNavigate } from "react-router-dom";
import { RootState } from "store";
import {
  Input,
  Button,
  FormGroup,
  Label,
  InlineLink,
  BackButton,
  PracticeLogo,
} from "@mwi/ui";
import useErrorHandler from "hooks/useErrorHandler";
import useTitle from "hooks/useTitle";
import TermsModal from "components/modals/terms";
import PrivacyModal from "components/modals/privacy";

type Inputs = {
  password: string;
};

const LoginPage = () => {
  useTitle("Login");

  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();
  const navigate = useNavigate();

  const [isTermsModalOpen, setTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Inputs>();

  const [submitLogin, { data: loginResult, isLoading, error }] =
    useLoginMutation();

  const { theme, practiceName, email } = useSelector((state: RootState) => ({
    theme: state.practice.theme,
    practiceName: state.practice.name,
    email: state.registration.formState.email,
  }));

  useEffect(() => {
    if (error) {
      errorHandler(error, setError);
    }
  }, [error]);

  useEffect(() => {
    if (loginResult) {
      dispatch(
        login({
          user: loginResult.user,
          registered_site: loginResult.registered_location,
        }),
      );
      dispatch(setPractice(loginResult.practice));
      navigate("/home");
    }
  }, [dispatch, loginResult]);

  const triggerLogin = (data: Inputs) => {
    submitLogin({
      ...data,
      email,
    });
  };

  if (!email) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <div className="desktop:pl-[30%] relative">
        <BackButton to="/" replace className="absolute z-10" />
      </div>

      <form
        onSubmit={handleSubmit(triggerLogin)}
        className="flex flex-col h-full pt-10 tablet:pt-1"
        noValidate
      >
        <div className="hidden desktop:block fixed inset-y-0 left-0 right-[70%]">
          <img
            src={
              theme?.customLoginImage ??
              theme?.defaultLoginImages.find(
                (img) => img.id === theme?.defaultLoginImage,
              )?.url ??
              theme.defaultLoginImages[0].url
            }
            alt=""
            className="object-cover w-full h-full"
          />
          <PracticeLogo
            logo={theme?.logoUrl}
            name={practiceName}
            className="absolute top-5 left-20"
          />
        </div>

        <div className="desktop:fixed inset-y-0 right-0 left-[30%] flex-grow">
          <div className="flex flex-col w-full h-full mx-auto max-w-[37.5rem]">
            <div className="flex flex-col justify-center h-full tablet:-mt-16">
              <h1 className="mt-16 text-4xl font-bold tablet:text-6xl">
                Welcome Back
              </h1>

              <p className="hidden mt-8 text-lg tablet:block">
                Enter your password to login
              </p>

              <FormGroup
                className="!mt-10 tablet:!mt-12"
                error={errors.password?.message}
              >
                <Label>Password</Label>
                <Input
                  type="password"
                  {...register("password")}
                  autoComplete="off"
                />
              </FormGroup>

              <InlineLink to="/forgot" size="medium">
                Forgot password?
              </InlineLink>

              <div className="pt-16 mt-auto tablet:mt-0 tablet:max-w-xs">
                <Button
                  block
                  type="submit"
                  loading={isLoading}
                  className="!px-[60px] !py-[18px] w-full tablet:w-max"
                >
                  Continue
                </Button>
              </div>
            </div>

            <div className="flex flex-wrap justify-center w-full gap-4 pt-6 pb-12 tablet:justify-start">
              <InlineLink onClick={() => setTermsModalOpen(true)} size="medium">
                Terms & Conditions
              </InlineLink>
              <div>
                <span className="mr-4">•</span>
                <InlineLink
                  onClick={() => setPrivacyModalOpen(true)}
                  size="medium"
                >
                  Privacy Policy
                </InlineLink>
              </div>
            </div>
          </div>
        </div>
      </form>

      <TermsModal
        isOpen={isTermsModalOpen}
        onClose={() => setTermsModalOpen(false)}
      />
      <PrivacyModal
        isOpen={isPrivacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
      />
    </>
  );
};

export default LoginPage;
