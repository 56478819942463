import List from "./components/List";
import Section from "./components/Section";
import InlineLink from "./../../navigation/inline_link";

/* 
  All fields are relating to the practice details
*/
type TermsTemplateProps = {
  phone: string | null;
  email: string | null;
  address: string | null;
  displayName: string | null;
  privacyLink: string | null;
};

const TermsTemplate = ({
  phone,
  email,
  address,
  privacyLink,
  displayName,
}: TermsTemplateProps): JSX.Element => {
  return (
    <div className="flex flex-col text-lg">
      <span className="mb-4">Last updated: 1st August 2022</span>
      <br />
      <h2 className="self-center mb-12 text-3xl font-bold">
        MWI Animal Health Terms of Use
      </h2>
      <p>
        These terms of use are entered into by and between you and us. The
        following terms (&quot;<strong>Terms of Use</strong>&quot;), govern your
        access to and use of our services (the &quot;<strong>Services</strong>
        &quot;) made available through this portal, or any other mobile or web
        interface now existing or later developed, including any content and
        functionality offered on or through these (the &quot;
        <strong>Portal</strong>&quot;). The terms &quot;<strong>you</strong>
        &quot; and &quot;<strong>your</strong>&quot; as used in these Terms of
        Use, means any person or entity who accesses or uses the Portal.
      </p>
      <br />

      <Section prefix="1." title="WHO WE ARE AND HOW TO CONTACT US">
        <p>
          The Portal is operated by MWI Animal Health (&quot;
          <strong>MWI</strong>&quot;, &quot;<strong>we</strong>&quot;, &quot;
          <strong>our</strong>&quot; or &quot;<strong>us</strong>&quot;) a
          company incorporated under the laws of England and Wales (company
          number 00787385) and located at Centaur House, Torbay Road, Castle
          Cary, BA7 7EU, United Kingdom. Our registered VAT no. is 736740226.
        </p>
        <p>For any general administrative queries, you can contact us at: </p>
        <span>Email: support@mwiah.co.uk </span>
        <p className="font-semibold">
          For any concerns or issues linked with the Portal, please contact{" "}
          {displayName ?? ""} (the &quot;Practice&quot;) directly at:
        </p>
        <span>
          {displayName ?? ""}
          {address ? `, ${address}` : ""}
        </span>
        <span>{phone ?? ""}</span>
        <span>{email ?? ""}</span>
      </Section>

      <br />

      <Section prefix="2." title="ACCEPTANCE OF THESE TERMS OF USE">
        <p>
          Please read these Terms of Use carefully before you use the Portal. By
          using the Portal, or otherwise agreeing, you accept and agree to be
          bound by these Terms of Use.
        </p>
      </Section>

      <br />

      <Section prefix="3." title="CHANGES TO THE TERMS OF USE & PORTAL">
        <p>
          We may revise and update these Terms of Use from time to time. All
          changes are effective immediately when we post them or otherwise
          notify you of the changes (or are effective on any other date we may
          specify), and apply to all access to and use of the Portal thereafter.
          Your continued use of the Portal following the posting of revised
          Terms of Use means that you accept and agree to the changes. If you do
          not agree to the revisions, you must stop using the Portal.
        </p>
      </Section>

      <br />

      <Section prefix="4." title="ACCESSING THE PORTAL">
        <p>
          You may access the Portal and use the Services only as permitted by
          these Terms of Use. You are also responsible for ensuring that all
          persons who access the Portal through your internet connection are
          aware of these Terms of Use and that they comply with them.
        </p>
        <p>
          There is currently no charge to access the Portal and/or use the
          Services. However, you are responsible for obtaining and configuring
          (at your own cost) all necessary devices, computer programs, IT,
          platforms and telecommunications services required to access and/or
          use the Portal.
        </p>
        <p>
          We reserve the right to suspend, withdraw, alter, or amend all or any
          part of the Portal, any Service or material we provide on the Portal.
          We will not be liable if for any reason all or any part of the Portal
          is unavailable at any time or for any period. We will try to give you
          reasonable notice or any suspension or withdrawal.
        </p>
      </Section>

      <br />

      <Section prefix="5." title="USER ACCOUNT">
        <span className="text-xl font-semibold">Creating An Account</span>
        <p>
          In order to access the Services of the Portal, you are required to
          create an account. When you create an account, you will be asked to
          create a user ID and choose a password. You are responsible for
          keeping your user ID and password secure and you are solely
          responsible for all activities that occur using your user ID and
          password. If you know or suspect that anyone other than you knows your
          password or has accessed your account, you must promptly notify the
          Practice using the contact details set out at Section 1.
        </p>
        <span className="text-xl font-semibold">Closing Your Account</span>
        <p>
          You may close your account with the Portal at any time, and by doing
          so you will no longer have access to booking appointments online, but
          your registration with the Practice will remain active (and you will
          still be able to book appointments with the Practice using their
          contact details). To close your account with the Portal, please email
          the Practice using their contact details in Section 1 and provide
          your: name, telephone number, and email address used to create the
          account.
        </p>
        <p className="text-xl font-semibold">
          When you close your account, any appointments already confirmed are
          not automatically cancelled because you will remain registered with
          the Practice and any cancellations will need to done with the Practice
          directly.
        </p>
      </Section>

      <br />

      <Section prefix="6." title="USE OF THE SERVICES">
        <p>
          Our Services include providing the Portal, which is a platform that
          allows users to view, book, and/or cancel appointments with the MWI is
          separate to the Practice, as it is only providing the platform for the
          Services, and when you make any appointments, these are made directly
          with the Practice (and not with us).
        </p>
        <p>
          By using our Services, you promise that (i) all information you supply
          is true, accurate, current and complete, and (ii) you are 18 years of
          age or older.
        </p>
        <p>
          Your use of the Services is permitted only for personal,
          non-commercial use and/or to make legitimate appointments with the You
          agree not to use the Service to make any speculative, false, or
          fraudulent appointments.
        </p>
        <p>
          We retain the right at our sole discretion to deny access to the
          Services to you at any time for any reason, including, but not limited
          to, the violation of these Terms of Use.
        </p>
      </Section>

      <br />

      <Section prefix="7." title="INTELLECTUAL PROPERTY RIGHTS">
        <p>
          The Portal and its entire contents, features and functionality
          (including but not limited to all information, software, text,
          displays, podcasts, images, video and audio, and the design, selection
          and arrangement thereof), are owned by MWI, its licensors or other
          providers of such material and are protected by United Kingdom and
          international copyright, trademark, patent, trade secret and other
          intellectual property or proprietary rights laws and treaties around
          the world.
        </p>
        <p>
          The Portal and its content are licensed to you in accordance with
          these Terms of Use which permit you to use the Portal for your
          personal, non-commercial use only. Subject to your continued
          compliance with these Terms of Use, we hereby grant you a
          non-exclusive, non-transferable, non-sublicensable, personal,
          revocable, limited licence to access and use the content for solely
          viewing within the Portal. You must not reproduce, distribute, modify,
          create derivative works of, publicly display, publicly perform,
          republish, download, store or transmit any of the material on our
          Portal except for your personal use. Our status (and that of any
          identified contributors) as the authors of any content on the Portal
          must always be acknowledged.
        </p>
        <span className="my-4 text-xl font-semibold">You must not:</span>
        <List
          listKey="intellectual-property-rights"
          items={[
            "Modify copies of any materials from the Portal.",
            "Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.",
            "Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from the Portal.",
          ]}
        />
        <p>
          No right, title or interest in or to the Portal or any content on the
          Portal is transferred to you under any circumstances, and all rights
          not expressly granted are reserved by MWI and its licensors. Any use
          of the Portal not expressly permitted by these Terms of Use is a
          breach of these Terms of Use and may violate copyright, trademark and
          other applicable laws.
        </p>
      </Section>

      <br />

      <Section prefix="8." title="TRADEMARKS">
        <p>
          All trademarks, service marks, logos and trade names which appear on
          the Portal, whether registered or not (including but not limited to:
          the word mark “MWI”, the MWI logo, any AmerisourceBergen marks and any
          Practice marks) (the “<strong>Marks</strong>”) are and shall remain
          the exclusive property of MWI, the Practice or its / their licensors
          (as applicable) and are protected by applicable intellectual property
          and trademark laws and treaties. You may not use, copy, reproduce,
          republish, upload, post, transmit, distribute, or modify any of the
          Marks in any way, including in advertising or publicity pertaining to
          distribution of materials on the Portal, without the applicable
          party&apos;s prior written consent, which shall be in their sole
          discretion.
        </p>
      </Section>

      <br />

      <Section prefix="9." title="PROHIBITED USES">
        <p>
          You may use the Portal only for lawful purposes and in accordance with
          these Terms of Use. You agree not to use the Portal:
        </p>
        <List
          listKey="prohibited-use-part-1"
          items={[
            "For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.",
            "To send, knowingly receive, upload, download, use or re-use any material which does not comply with these Terms of Use.",
            'To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter" or "spam" or any other similar solicitation.',
            "To impersonate or attempt to impersonate MWI, a MWI employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the foregoing).",
            "To engage in any other conduct that restricts or inhibits anyone&apos;s use or enjoyment of the Portal, or which, as determined by us, may harm MWI or users of the Portal or expose them to liability.",
          ]}
        />
        <span className="my-4">Additionally, you agree not to:</span>
        <List
          listKey="prohibited-use-part-2"
          items={[
            "Use the Portal or any device, software or routine that in any manner that could disable, overburden, damage, or impair the proper working of the Portal or interfere with any other party&apos;s use of the Portal, including their ability to engage in real time activities through the Portal.",
            "Use any robot, spider or other automatic device, process or means to access the Portal for any purpose, including monitoring or copying any of the material on the Portal.",
            "Introduce any viruses, Trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.",
            "Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Portal, the server on which the Portal is stored, or any server, computer or database connected to the Portal.",
            "Attack the Portal via a denial-of-service attack or a distributed denial-of-service attack.",
            "Otherwise attempt to interfere with the proper working of the Portal.",
          ]}
        />
      </Section>

      <br />

      <Section prefix="10." title="USER CONTRIBUTIONS">
        <p>
          The Portal may allow you to submit photos and videos (collectively
          “photos”) to MWI. If you do, such photos will be considered “User
          Contributions” and all applicable terms in these Terms of Use shall
          apply, including the Content Standards set out in Section 12. Please
          do not provide any photos that you did not take yourself or that you
          do not have permission to provide to us. By sending us photos, you
          promise to MWI that you have taken the photos yourself, you own all
          copyright, intellectual property rights and other rights to the
          photos, or that you have received the express written permission from
          the all relevant rights holder(s).
        </p>
        <p>
          If other persons appear in the photos, you promise that you have
          received express permission from all such persons to take their photo
          and to provide it to MWI in accordance with these Terms of Use (which
          you have provided a copy of to all such persons). DO NOT PROVIDE ANY
          PHOTOS THAT CONTAIN THE IMAGE OF ANY MINORS UNDER 18 YEARS OLD.
        </p>
      </Section>

      <br />

      <Section prefix="11." title="MONITORING AND ENFORCEMENT; TERMINATION">
        <span>We have the right (but not the obligation) to:</span>
        <List
          listKey="monitoring-and-emergency"
          items={[
            "Remove or refuse to post any User Contributions for any or no reason in our sole discretion.",
            "Take any action with respect to any User Contributions that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Portal or the public or could create liability for MWI.",
            "Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Portal.",
          ]}
        />
        <p>
          We do not undertake to review all material before it is posted on the
          Portal and cannot ensure prompt removal of objectionable material
          after it has been posted. We have no liability or responsibility to
          anyone for performance or non-performance of the activities described
          in this section.
        </p>
      </Section>

      <br />

      <Section prefix="12." title="CONTENT STANDARDS">
        <p>
          These content standards apply to any and all User Contributions. User
          Contributions must in their entirety comply with all applicable laws.
          Without limiting the foregoing, User Contributions must not:
        </p>
        <List
          listKey="content-standards"
          items={[
            "Contain any material which is defamatory, obscene, indecent, abusive, sexual, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.",
            "Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.",
            "Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.",
            "Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Use and our Privacy Policy.",
            "Be likely to deceive any person.",
            "Contain any personal information such as contact information or social media handles.",
            "Promote any illegal or fraudulent activity, or advocate, promote or assist any unlawful or fraudulent act.",
            "Contain any medical advice, or give the impression that it contains medical advice.",
            "Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.",
            "Impersonate any person, or misrepresent your identity or affiliation with any person or organization.",
            "Promote any third party organization or business.",
            "Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.",
            "Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.",
          ]}
        />
      </Section>

      <br />

      <Section prefix="13." title="NO RELIANCE ON INFORMATION POSTED">
        <p>
          The information presented on or through the Portal is made available
          solely for the purposes of the Services. However, we do not give any
          assurances as to the accuracy, completeness, timeliness, or usefulness
          of this information. All features, content, specifications,
          appointment times and Services described or depicted on the Portal are
          subject to change at any time without notice. We make all reasonable
          efforts to accurately display appointment availabilities but any times
          provided at any particular time do not imply or promise that those
          appointment times are available until such time the appointment is
          confirmed by email.
        </p>
      </Section>

      <br />

      <Section
        prefix="14."
        title="INFORMATION ABOUT YOU AND YOUR VISITS TO THE PORTAL"
      >
        <p>
          All information we collect about you on the Portal will be treated and
          processed securely and strictly in accordance with applicable laws.
          Please refer to the{" "}
          <InlineLink target="_blank" to={privacyLink ?? ""}>
            Practice&apos;s Privacy Policy
          </InlineLink>{" "}
          for important information about the collection, use and sharing of
          your personal information.
        </p>
      </Section>

      <br />

      <Section prefix="15." title="GEOGRAPHIC RESTRICTIONS">
        <p>
          Our Portal is hosted from and is based in the United Kingdom. We make
          no claims that the Portal or any of its content is accessible or
          appropriate outside of the United Kingdom. Access to the Portal may
          not be legal by certain persons or in certain countries. If you access
          the Portal from outside the United Kingdom, you do so on your own
          initiative and are responsible for compliance with all local laws.
        </p>
      </Section>

      <br />

      <Section prefix="16." title="LIMITATION ON LIABILITY">
        <p>
          If we fail to comply with these Terms of Use, we are responsible for
          loss or damage you suffer that is a foreseeable result of our breaking
          this contract or our failing to use reasonable care and skill, but we
          are not responsible for any loss or damage that is not foreseeable.
          Loss or damage is foreseeable if either it is obvious that it will
          happen or if, at the time the contract was made, both we and you knew
          it might happen.
        </p>
        <p>
          We do not exclude or limit in any way our liability to you where it
          would be unlawful to do so. This includes liability for death or
          personal injury resulting from our negligence or the negligence of our
          employees, agents or subcontractors and for fraud or fraudulent
          misrepresentation.
        </p>
        <p>
          The Portal and its content are for domestic and private use only. You
          agree not to use the Portal or its content for any commercial or
          business purposes, and we have no liability to you for any business
          losses, loss of profits or savings or anticipated profits or savings,
          loss of opportunity, loss or reputation, goodwill or business or any
          economic loss even if we are advised in advance of the possibility of
          such loss.
        </p>
      </Section>

      <br />

      <Section prefix="17." title="OTHER IMPORTANT TERMS">
        <p>
          We may transfer our rights and obligations under these Terms of Use to
          another organisation. We will always tell you in writing if this
          happens and we will ensure that the transfer will not affect your
          rights under the contract. You may only transfer your rights or your
          obligations under these Terms of Use to another person if we agree to
          this in writing.{" "}
        </p>
        <p>
          A contract is between you and us. No other person shall have any
          rights to enforce any of its terms. Neither of us will need to get the
          agreement of any other person in order to end the contract or make any
          changes to these Terms of Use.
        </p>
        <p>
          Each of the paragraphs of these Terms of Use operates separately. If
          any court or relevant authority decides that any of them are unlawful,
          the remaining paragraphs will remain in full force and effect.
        </p>
        <p>
          If we do not insist immediately that you do anything you are required
          to do under these Terms of Use, or if we delay in taking steps against
          you in respect of your breaking this contract, that will not mean that
          you do not have to do those things and it will not prevent us taking
          steps against you at a later date.
        </p>
        <p>
          You and we agree that English law applies to these Terms of Use. You
          can bring legal proceedings under these Terms of Use in the English
          courts.
        </p>
      </Section>
    </div>
  );
};

export default TermsTemplate;
