import { useLogoutMutation } from "api";
import classNames from "classnames";
import {
  BackButton,
  Button,
  ChevronRightIcon,
  Divider,
  InlineLink,
} from "@mwi/ui";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NavLink,
  Outlet,
  To,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { logout } from "slices/auth";

import { RootState } from "store";
import useTitle from "hooks/useTitle";
import PrivacyModal from "components/modals/privacy";
import TermsModal from "components/modals/terms";

const SettingsPage = (): JSX.Element => {
  useTitle("Your Account");

  const [isPrivacyModal, setPrivacyModal] = useState(false);
  const [isTermsModal, setTermsModal] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const tablet = window.innerWidth > 1024;

  const { user, practice } = useSelector((state: RootState) => ({
    user: state.auth.user,
    practice: state.practice,
  }));

  const [submitLogout] = useLogoutMutation();

  const triggerLogout = async () => {
    await submitLogout();
    dispatch(logout());
  };

  useEffect(() => {
    if (tablet && location.pathname === "/settings") {
      navigate("/settings/account", { replace: true });
    }
  }, []);

  const Item = ({ children, to }: { children: string; to: To }) => {
    const className = classNames(
      "flex items-center justify-between py-4 tablet:p-3 font-semibold border-b border-pinball tablet:border-0",
      "focus:shadow-focus-medium focus:outline-none",
    );
    const Inner = () => (
      <>
        <p>{children}</p>
        <ChevronRightIcon className="w-6 h-6 tablet:hidden" />
      </>
    );
    return to.toString().startsWith("https://") ? (
      <a
        href={to.toString()}
        target="_blank"
        rel="noreferrer"
        className={className}
      >
        <Inner />
      </a>
    ) : (
      <NavLink
        to={to}
        className={({ isActive }) =>
          classNames(className, {
            "tablet:bg-light tablet:text-white": isActive,
          })
        }
      >
        <Inner />
      </NavLink>
    );
  };

  return (
    <>
      {(tablet || location.pathname === "/settings") && (
        <div className="mb-8 tablet:mb-[72px]">
          <h1 className="text-4xl tablet:text-5xl">
            {`${user?.first_name} ${user?.last_name}`}
          </h1>
        </div>
      )}

      <div className="flex h-full tablet:gap-32">
        {(tablet || location.pathname === "/settings") && (
          <div className="flex flex-col h-full tablet:gap-3 w-full tablet:max-w-[196px]">
            <Item to="/settings/account">Account Details</Item>
            <Item to="/settings/practice">Vet Practice Details</Item>
            {practice.links.map((link, index) => (
              <Item key={index} to={link.url}>
                {link.title}
              </Item>
            ))}

            <Divider className="!my-4" />

            <div className="flex flex-col justify-between">
              <InlineLink
                size="medium"
                className="py-6 tablet:py-1 tablet:ml-3"
                onClick={() => setPrivacyModal(true)}
              >
                Privacy Policy
              </InlineLink>
              <InlineLink
                size="medium"
                className="py-6 tablet:pt-6 tablet:ml-3"
                onClick={() => setTermsModal(true)}
              >
                Terms & Conditions
              </InlineLink>
            </div>

            <div className="flex items-end flex-grow mt-4 tablet:max-w-sm tablet:hidden">
              <Button variant="outlined" block onClick={triggerLogout}>
                Log out
              </Button>
            </div>
          </div>
        )}

        {(tablet || location.pathname !== "/settings") && (
          <div className="flex flex-col w-full tablet:max-w-lg">
            <BackButton to="/settings" className="tablet:hidden" />
            <Outlet />
          </div>
        )}
      </div>

      <PrivacyModal
        isOpen={isPrivacyModal}
        onClose={() => setPrivacyModal(false)}
      />
      <TermsModal isOpen={isTermsModal} onClose={() => setTermsModal(false)} />
    </>
  );
};

export default SettingsPage;
