import classNames from "classnames";
import { Controller } from "react-hook-form";

import FormGroup from "../../form/group";

type ItemProps = {
  name: string;
  control: any;
  title: string;
  hasDivider?: boolean;
  radioNames: { id: string; display: string }[];
};

const Item = ({
  name,
  title,
  control,
  radioNames,
  hasDivider = false,
}: ItemProps): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <div
            className={classNames("w-full", {
              "border-b border-grey-dark pb-3": hasDivider,
            })}
          >
            <div className="flex items-center justify-between gap-1 mb-4">
              <p className="font-semibold text-md">{title}</p>

              <button
                disabled={!field.value}
                type="button"
                onClick={() => field.onChange(undefined)}
                className={classNames("font-medium text-primary text-sm", {
                  "text-grey-dark": !field.value,
                })}
              >
                Clear
              </button>
            </div>

            <FormGroup hideError>
              <div className="flex flex-wrap gap-y-2 gap-x-6">
                {radioNames &&
                  radioNames?.length > 0 &&
                  radioNames.map((name) => (
                    <label key={name.id} className="flex items-center gap-x-5">
                      <input
                        type="radio"
                        name={title}
                        checked={field.value === name.id}
                        onChange={() => field.onChange(name.id)}
                        style={{
                          backgroundImage:
                            'url("data:image/svg+xml,%3csvg viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27white%27 stroke-width=%272%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M5 13l4 4L19 7%27/%3e%3c/svg%3e")',
                          backgroundSize: 0,
                        }}
                        className={classNames(
                          "transition border rounded-full appearance-none w-5 h-5 border-grey-dark checked:bg-primary",
                          "hover:border-light",
                          "focus:outline-none focus:border-light focus:shadow-focus-small",
                        )}
                      />
                      <p>{name.display}</p>
                    </label>
                  ))}
              </div>
            </FormGroup>
          </div>
        );
      }}
    />
  );
};

export default Item;
