import { DateTime } from "luxon";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";
import { RootState } from "store";
import {
  Select,
  DatePicker,
  Button,
  AlertIcon,
  SelectOption,
  NotifierNotification,
  WarningIcon,
  ErrorMessage,
} from "@mwi/ui";
import { useNotifier } from "react-headless-notifier";
import AddPatientSection from "components/widgets/appointment/components/AddPatientSection";
import { SelectedPatient } from "types/booking-flow/SelectedPatient";
import BookAppointmentModal from "components/modals/appointment/book";
import {
  AvailableDay,
  AvailableMergedSlot,
} from "api/types/models/availability";
import {
  clearBookingState,
  setNotification,
  setPatients,
  setStep,
} from "slices/booking";
import {
  useGetAvailabilityQuery,
  useGetPatientsQuery,
  useGetReasonsQuery,
  useVerifyAppointmentsMutation,
} from "api";
import ReasonType from "enums/ReasonType";
import ReasonNotification from "api/types/models/reason_notification";
import { Modifier } from "react-day-picker";
import { gtmEvent } from "helpers/gtm";

type Inputs = {
  selected_date: DateTime;
  patients: Omit<SelectedPatient, "duration_minutes">[];
};

const AppointmentWidget = (): JSX.Element => {
  const dispatch = useDispatch();
  const { notify } = useNotifier();

  const [isBookApptModalOpen, setIsBookApptModalOpen] =
    useState<boolean>(false);

  const {
    step,
    vetSite,
    practice,
    formState,
    registeredSite,
    currentNotification,
  } = useSelector((state: RootState) => ({
    practice: state.practice,
    step: state.bookingFlow.step,
    formState: state.bookingFlow.formState,
    registeredSite: state.auth.registered_site,
    vetSite: state.bookingFlow.formState.vet_site_uuid,
    currentNotification: state.bookingFlow.formState.notification,
  }));

  const { control, watch, getValues, handleSubmit, reset } = useForm<Inputs>({
    defaultValues: {
      selected_date: DateTime.fromISO(formState.selected_date).isValid
        ? DateTime.fromISO(formState.selected_date)
        : "",
      patients: formState.patients.map((p) => ({
        patient_uuid: p.patient_uuid,
        reason_uuid: p.reason_uuid,
        comment: p.comment,
      })),
    },
  });

  const { data: patients, isFetching: isFetchingPatients } =
    useGetPatientsQuery();

  const { data: reasons, isFetching: isFetchingReasons } = useGetReasonsQuery({
    locationUuid: vetSite ? vetSite : registeredSite?.id,
  });

  const [currentMonth, setCurrentMonth] = useState(watch("selected_date"));

  const [dateStart, dateEnd] = useMemo(() => {
    if (!currentMonth) {
      return [
        DateTime.now().toFormat("yyyy-MM-dd"),
        DateTime.now().endOf("month").toFormat("yyyy-MM-dd"),
      ];
    }
    return [
      currentMonth < DateTime.now()
        ? DateTime.now().toFormat("yyyy-MM-dd")
        : currentMonth.startOf("month").toFormat("yyyy-MM-dd"),
      currentMonth.endOf("month").toFormat("yyyy-MM-dd"),
    ];
  }, [currentMonth]);

  const {
    data: availability,
    isFetching: fetchingAvailability,
    isError: availabilityError,
  } = useGetAvailabilityQuery(
    {
      date_end: dateEnd,
      date_start: dateStart,
      reasons: watch("patients")?.map((p) => p?.reason_uuid),
      patients: watch("patients")?.map((p) => p.patient_uuid),
      location_uuid: vetSite ? vetSite : (registeredSite?.id ?? ""),
    },
    {
      skip:
        !watch("patients.0.patient_uuid") || !watch("patients.0.reason_uuid"),
    },
  );

  const modifiers = useMemo(() => {
    const unavailableDays = availability?.filter(
      (a) => a.mergedSlots.length === 0,
    );

    const disabledDays: Modifier[] = [{ before: new Date() }];
    if (unavailableDays) {
      unavailableDays.forEach((day) => {
        disabledDays.push(DateTime.fromISO(day.date).toJSDate());
      });
    }

    return disabledDays;
  }, [availability]);

  const [
    verify,
    { isSuccess: verified, isError: failedVerifying, reset: resetVerify },
  ] = useVerifyAppointmentsMutation();

  const [focused, setFocused] = useState<boolean>(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "patients",
  });

  const handleAddPet = () => {
    append({
      patient_uuid: "",
      reason_uuid: "",
      comment: "",
    });
  };

  const handleOnSubmit = () => {
    if (currentNotification?.type === ReasonType.EMERGENCY) {
      window.location.assign(`tel:${registeredSite?.phone}`);
      return;
    }

    verify({
      step: "2",
      patients: formState.patients,
    });
  };

  useEffect(() => {
    reset({
      selected_date: "",
      patients: [
        {
          patient_uuid: "",
          reason_uuid: "",
          comment: "",
        },
      ],
    });

    dispatch(clearBookingState());
  }, []);

  useEffect(() => {
    if (step === "1" || step === "2") {
      const formStatePatients = formState.patients.map((p) => ({
        patient_uuid: p.patient_uuid,
        reason_uuid: p.reason_uuid,
        comment: p.comment,
      }));

      if (!isEqual(formStatePatients, getValues("patients"))) {
        const patients = getValues("patients").map((p) => ({
          ...p,
          duration_minutes: 0,
        }));

        let activeNotification: ReasonNotification | undefined;
        patients.forEach((p) => {
          const notification = reasons?.data?.find(
            (r) => r.id === p.reason_uuid,
          )?.notification;
          if (
            notification &&
            activeNotification?.type !== ReasonType.EMERGENCY
          ) {
            activeNotification = notification;
          }
        });

        activeNotification
          ? dispatch(setNotification(activeNotification))
          : dispatch(
              setNotification({
                is_active: false,
                message: undefined,
                type: undefined,
              }),
            );

        // add duration minutes to patient reason
        const patientReasons = patients.map((p) => {
          const reason = reasons?.data?.find((r) => r.id === p.reason_uuid);
          if (reason) {
            p.duration_minutes = reason.duration_minutes;
          }

          return p;
        });

        dispatch(setPatients({ patients: patientReasons }));
      }
    }
  }, [watch()]);

  useEffect(() => {
    if (
      verified &&
      getValues("selected_date") &&
      getValues("selected_date")?.toISO()
    ) {
      dispatch(
        setStep({
          step: "3",
          selected_date: getValues("selected_date").toISO()!,
        }),
      );

      setIsBookApptModalOpen(true);
    }

    if (failedVerifying) {
      notify(
        <NotifierNotification
          type="error"
          title="Verification failed"
          message="Please ensure you've selected a pet and their reason for appointment when adding additional pets"
        />,
      );

      resetVerify();
    }

    if (verified || failedVerifying) {
      gtmEvent({ name: "onDesktopApptFlowSelectPatientAndReasonPageView" });
    }
  }, [verified, failedVerifying]);

  useEffect(() => {
    if (!isBookApptModalOpen) {
      if (step === "3" || step === "4") {
        dispatch(
          setStep({
            step: "2",
            selected_slot: {} as AvailableMergedSlot,
            selected_day: {} as AvailableDay,
            availability_date_range: {
              date_start: "",
              date_end: "",
            },
          }),
        );
      }

      if (step === "5") {
        dispatch(clearBookingState());
        reset({
          selected_date: "",
          patients: [
            {
              patient_uuid: "",
              reason_uuid: "",
              comment: "",
            },
          ],
        });
      }

      if (step === "3a") {
        dispatch(
          setStep({
            step: "2",
            selected_slot: {} as AvailableMergedSlot,
            selected_day: {} as AvailableDay,
            availability_date_range: {
              date_start: "",
              date_end: "",
            },
            confirmed_selection: false,
          }),
        );
      }
    }
  }, [isBookApptModalOpen]);

  const handleConfirm = () => {
    reset({
      selected_date: DateTime.fromISO(formState.selected_date).isValid
        ? DateTime.fromISO(formState.selected_date)
        : "",
      patients: getValues("patients").map((p) => ({
        ...p,
        reason_uuid: "",
      })),
    });
  };

  return (
    <div
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className={classNames(
        "w-full bg-white outline-none shadow-default transition-all relative",
        {
          "shadow-focus-large": focused,
        },
      )}
    >
      {practice.appointments_blocked && (
        <div className="absolute inset-0 z-10 grid text-lg font-semibold place-content-center">
          <ErrorMessage message="Sorry, this practice currently isn't taking bookings" />
        </div>
      )}

      <div
        className={classNames("p-6", {
          "blur-sm": practice.appointments_blocked,
        })}
      >
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className="flex flex-wrap justify-between gap-6"
        >
          <div className="w-full max-w-[840px]">
            {currentNotification && currentNotification.is_active && (
              <div className="mb-10">
                <div
                  className={classNames("w-full", {
                    "bg-error-lightest shadow-error":
                      currentNotification.type === ReasonType.EMERGENCY,
                    "bg-warning-lightest shadow-warn":
                      currentNotification.type === ReasonType.NOTIFICATION,
                  })}
                >
                  <div className="flex items-center gap-4 p-4">
                    <div
                      className={classNames(
                        "flex-shrink-0 w-8 h-8 p-1 rounded-full ",
                        {
                          "bg-error":
                            currentNotification.type === ReasonType.EMERGENCY,
                          "bg-warning":
                            currentNotification.type ===
                            ReasonType.NOTIFICATION,
                        },
                      )}
                    >
                      <span className="text-white">
                        {currentNotification.type === ReasonType.EMERGENCY && (
                          <AlertIcon className="text-white" />
                        )}
                        {currentNotification.type ===
                          ReasonType.NOTIFICATION && (
                          <WarningIcon className="text-white" />
                        )}
                      </span>
                    </div>

                    <div>
                      {currentNotification.type === ReasonType.EMERGENCY && (
                        <span className="text-base font-semibold">
                          Oh, no! This is an emergency.
                        </span>
                      )}
                      {currentNotification.type === ReasonType.NOTIFICATION && (
                        <span className="text-base font-semibold">
                          Hold up!
                        </span>
                      )}

                      <p className="text-sm font-normal">
                        {currentNotification.message
                          ? currentNotification.message
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Initlal patient & reason */}
            <div className="flex flex-col gap-y-6">
              <div className="flex flex-wrap gap-6">
                <div className="flex-shrink-0 w-full max-w-[240px]">
                  <Select
                    control={control}
                    placeholder="Select a pet"
                    name="patients.0.patient_uuid"
                    size="medium"
                    loading={isFetchingPatients}
                    disabled={isFetchingPatients}
                  >
                    {patients?.map((patient) => {
                      const isPatientSelected = formState.patients.some(
                        (p) => p.patient_uuid === patient.id,
                      );

                      return (
                        <SelectOption
                          key={patient.id}
                          value={patient.id}
                          disabled={isPatientSelected}
                        >
                          {patient.name}
                        </SelectOption>
                      );
                    })}
                  </Select>
                </div>

                <div className="flex-shrink-0 w-full max-w-[240px]">
                  <Select
                    control={control}
                    placeholder="Select a reason"
                    emptyStateErrorMessage="No reasons available"
                    name="patients.0.reason_uuid"
                    disabled={!watch("patients.0.patient_uuid")}
                    loading={isFetchingReasons}
                    size="medium"
                  >
                    {reasons?.data?.map((reason) => (
                      <SelectOption key={reason.id} value={reason.id}>
                        {reason?.translation ?? reason.name}
                      </SelectOption>
                    ))}
                  </Select>
                </div>

                <div className="flex-shrink-0 w-full max-w-[310px]">
                  <DatePicker
                    control={control}
                    name="selected_date"
                    defaultLabel="Pick a date"
                    disabledDays={[
                      { before: DateTime.now().toJSDate() },
                      ...modifiers,
                    ]}
                    disabled={
                      !watch("patients.0.patient_uuid") ||
                      !watch("patients.0.reason_uuid") ||
                      availabilityError
                    }
                    size="medium"
                    loading={fetchingAvailability}
                    onMonthChange={(date) => setCurrentMonth(date as DateTime)}
                  />
                  {availabilityError && (
                    <ErrorMessage message="Unable to fetch availability, please try again later" />
                  )}
                </div>
              </div>

              {/* Additional pets */}
              {fields.map((field, idx) => {
                return idx > 0 ? (
                  <AddPatientSection
                    index={idx}
                    key={field.id}
                    control={control}
                    onRemove={() => remove(idx)}
                  />
                ) : (
                  <Fragment key={field.id} />
                );
              })}
            </div>

            <div className="mt-8 w-max">
              <Button
                type="link"
                onClick={handleAddPet}
                disabled={
                  currentNotification?.type === ReasonType.EMERGENCY ||
                  patients?.length === getValues("patients")?.length ||
                  !watch(
                    `patients.${formState.patients?.length - 1}.patient_uuid`,
                  ) ||
                  !watch(
                    `patients.${formState.patients?.length - 1}.reason_uuid`,
                  ) ||
                  !watch("patients.0.patient_uuid") ||
                  !watch("patients.0.reason_uuid")
                }
              >
                Add another pet
              </Button>
            </div>
          </div>

          <div>
            <Button
              type="submit"
              size="medium"
              danger={currentNotification?.type === ReasonType.EMERGENCY}
              disabled={
                !(currentNotification?.type === ReasonType.EMERGENCY) &&
                (!watch(
                  `patients.${formState.patients?.length - 1}.patient_uuid`,
                ) ||
                  !watch(
                    `patients.${formState.patients?.length - 1}.reason_uuid`,
                  ) ||
                  !watch("patients.0.patient_uuid") ||
                  !watch("patients.0.reason_uuid") ||
                  !watch("selected_date"))
              }
            >
              {currentNotification?.type === ReasonType.EMERGENCY
                ? "Call Now"
                : "Continue"}
            </Button>
          </div>
        </form>
      </div>

      <BookAppointmentModal
        onConfirm={handleConfirm}
        isOpen={isBookApptModalOpen}
        onClose={() => setIsBookApptModalOpen(false)}
      />
    </div>
  );
};

export default AppointmentWidget;
