import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { serialize } from "object-to-formdata";
import { UploadFileRequest } from "api/types/requests";
import UploadedFile from "api/types/models/uploaded_file";
import { QueryTags } from "api/types/QueryTags";

const fileEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    QueryTags,
    "data"
  >,
) => ({
  uploadFile: builder.mutation<UploadedFile, UploadFileRequest>({
    query: (body) => ({
      url: "/files",
      method: "post",
      body: serialize(body),
    }),
  }),

  deleteFile: builder.mutation<void, number>({
    query: (id) => ({
      url: `/files/${id}`,
      method: "delete",
    }),
  }),
});

export default fileEndpoints;
