import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Status, SuccessCircleTickGraphic } from "@mwi/ui";
import { clearChangePasswordStep } from "slices/auth";

const ChangePasswordStep3 = ({
  onDone,
}: {
  onDone?: () => void;
}): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnClick = () => {
    navigate("/settings/account");
    dispatch(clearChangePasswordStep());
    if (onDone) {
      onDone();
    }
  };

  return (
    <div className="flex flex-col flex-grow">
      <div className="flex max-w-[344px] w-full mx-auto flex-grow">
        <Status
          onClick={handleOnClick}
          title="Your password has been changed"
          primaryLinkText="Go back to your account"
          graphic={<SuccessCircleTickGraphic className="text-primary" />}
        />
      </div>
    </div>
  );
};

export default ChangePasswordStep3;
