import classNames from "classnames";
import { NavLink, To } from "react-router-dom";

type ActionProps = {
  to: To;
  icon: JSX.Element;
  onDisable?: () => void;
  className?: string;
  disabled?: boolean;
};

const Action = ({
  to,
  icon,
  onDisable,
  className,
  disabled = false,
}: ActionProps) => {
  const Inner = (
    <span
      onClick={onDisable}
      className={classNames(
        "flex items-center justify-center w-16 h-16 -m-6 text-white rounded-full transition",
        {
          "bg-disabled-grey shadow-disabled": disabled,
          "bg-primary shadow-primary hover:bg-light focus:outline-none focus:shadow-focus-medium":
            !disabled,
        },
        className,
      )}
    >
      <div className="w-8 h-8">{icon}</div>
    </span>
  );

  if (disabled) {
    return Inner;
  }

  return <NavLink to={to}>{Inner}</NavLink>;
};

export default Action;
