import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import BookingFlowStep4 from "./steps/4_book";
import BookingFlowStep2 from "./steps/2_reason";
import BookingFlowStep3a from "./steps/3a_site";
import BookingFlowStep5 from "./steps/5_status";
import BookingFlowStep1 from "./steps/1_patient";
import { CrossIcon, BackButton, ProgressBar } from "@mwi/ui";
import BookingFlowStep3 from "./steps/3_appointment";
import {
  AvailableDay,
  AvailableMergedSlot,
} from "api/types/models/availability";
import {
  clearBookingState,
  setPatientReasonIndex,
  setStep,
} from "slices/booking";

const BookAppointmentPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { step, formState } = useSelector((state: RootState) => ({
    step: state.bookingFlow.step,
    formState: state.bookingFlow.formState,
  }));

  const handleCancel = () => {
    dispatch(clearBookingState());
    navigate("/home");
  };

  const handleBack = () => {
    if (step === "1") {
      dispatch(clearBookingState());
      navigate("/home");
    }

    if (step === "2") {
      const patients = formState.patients.map((p) => ({
        ...p,
        reason_uuid: "",
        comment: "",
      }));
      dispatch(
        setStep({
          step: "1",
          patients: patients,
          notification: {
            is_active: false,
            type: undefined,
            message: undefined,
          },
        }),
      );
      dispatch(setPatientReasonIndex(0));
    }

    if (step === "3") {
      dispatch(
        setStep({
          step: "2",
          selected_slot: {} as AvailableMergedSlot,
          selected_day: {} as AvailableDay,
          confirmed_selection: false,
          availability_date_range: {
            date_start: "",
            date_end: "",
          },
        }),
      );
    }

    if (step === "3a" || step === "4") {
      dispatch(setStep({ step: "3" }));
    }
  };

  useEffect(() => {
    dispatch(clearBookingState());
  }, []);

  return (
    <div className="flex flex-col flex-grow">
      {step !== "5" && (
        <>
          <div className="w-full mb-12">
            <div className="flex items-center justify-between w-full gap-4">
              <BackButton onClick={handleBack} className="tablet:hidden" />

              <button onClick={handleCancel}>
                <CrossIcon className="w-6 h-6" />
              </button>
            </div>
          </div>

          <ProgressBar
            max={4}
            value={step === "3a" ? parseInt("3") : parseInt(step ?? 1)}
            showStepIndicator
          />
        </>
      )}

      {step === "1" && <BookingFlowStep1 />}
      {step === "2" && <BookingFlowStep2 />}
      {step === "3" && <BookingFlowStep3 />}
      {step === "3a" && <BookingFlowStep3a />}
      {step === "4" && <BookingFlowStep4 />}
      {step === "5" && <BookingFlowStep5 />}
    </div>
  );
};

export default BookAppointmentPage;
