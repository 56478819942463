import VerificationMethod from "api/types/VerificationMethod";
import { Radio, InlineLink, Button, FormGroup } from "@mwi/ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setBailed, setVerifyMethod } from "slices/registration";
import { useSendCodeMutation } from "api";
import useErrorHandler from "hooks/useErrorHandler";
import { RootState } from "store";
import { gtmEvent } from "helpers/gtm";

type Inputs = {
  method: VerificationMethod | undefined;
};

const RegistrationCodeSelect = () => {
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const { user, flow, formState, verifyMethods } = useSelector(
    (state: RootState) => ({
      user: state.auth.user,
      flow: state.registration.flow,
      formState: state.registration.formState,
      verifyMethods: state.registration.verifyMethods,
    }),
  );

  const { register, handleSubmit, setError, watch, getValues } =
    useForm<Inputs>({
      defaultValues: {
        method: undefined,
      },
    });

  const [sendCode, { isLoading, error, isSuccess }] = useSendCodeMutation();

  useEffect(() => {
    if (error) {
      errorHandler(error, setError);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setVerifyMethod(getValues("method")));
    }
  }, [isSuccess]);

  const bailout = () => {
    dispatch(setBailed(true));
  };

  const sendVerificationCode = () => {
    sendCode({
      method: getValues("method")!,
      phone: formState.phone ?? "",
    });
  };

  useEffect(() => gtmEvent({ name: "onRegFlowVerifyMethodStepPageView" }), []);

  return (
    <>
      <h1 className="text-4xl font-bold tablet:text-6xl">
        {flow === "match"
          ? "You may already be registered with our vet practice."
          : "You're nearly there!"}
      </h1>

      <p className="mt-6 tablet:mt-8 tablet:text-lg">
        We will send you a 4-digit code to verify your account. Please select
        your preferred method.
      </p>

      <form
        className="flex flex-col flex-grow mt-8 tablet:mt-12"
        onSubmit={handleSubmit(sendVerificationCode)}
      >
        {(
          verifyMethods ?? [
            { method: "sms", display: user?.phone },
            { method: "email", display: user?.email },
          ]
        )?.map((method) => (
          <FormGroup key={method.method}>
            <Radio
              label={`Send via ${method.method === "sms" ? `text to ${flow === "match" ? "mobile ending" : ""}` : "email"} ${method.display ?? (method.method === "sms" ? user?.phone : user?.email)}`}
              value={method.method}
              {...register("method")}
            />
          </FormGroup>
        ))}

        <div className="mt-auto tablet:mt-12 tablet:max-w-xs">
          <Button
            type="submit"
            block
            loading={isLoading}
            disabled={!watch("method")}
          >
            Next
          </Button>
        </div>

        {flow === "match" && (
          <div className="mt-6 text-center tablet:text-left tablet:mt-8 tablet:text-lg">
            <InlineLink onClick={bailout}>
              I don&apos;t recognise these details
            </InlineLink>
          </div>
        )}
      </form>
    </>
  );
};

export default RegistrationCodeSelect;
