import { Swiper } from "swiper/react";
import { Navigation } from "swiper";

type SliderProps = {
  children?: any;
  draggable?: boolean;
  spaceBetween?: number;
  slidesPerView?: number;
  onChange?: () => void;
};

const Slider = ({
  onChange,
  draggable = true,
  spaceBetween = 30,
  slidesPerView = 1,
  children,
}: SliderProps) => {
  return (
    <Swiper
      navigation
      modules={[Navigation]}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      scrollbar={{ draggable: draggable }}
      onSlideChange={onChange}
    >
      {children}
    </Swiper>
  );
};

export default Slider;
