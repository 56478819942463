import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

import { RootState } from "store";
import { login } from "slices/auth";
import { useRegisterPatientsMutation } from "api";
import useErrorHandler from "hooks/useErrorHandler";
import CreatePatientForm from "components/patient/create_form";
import PatientProfileImage from "components/patient/profile_image";
import {
  Accordion,
  Button,
  ChevronDownIcon,
  InlineLink,
  TrashIcon,
} from "@mwi/ui";
import { PatientsInputs } from "types/forms/patient/PatientsInputs";
import { gtmEvent } from "helpers/gtm";

const RegistrationCreatePatient = () => {
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }));

  const [openAccordionId, setOpenAccordionId] = useState(0);

  const [
    registerPatients,
    {
      error: errorPatients,
      isSuccess: didRegisterPatients,
      isLoading: isRegisteringPatients,
    },
  ] = useRegisterPatientsMutation();

  const methods = useForm<PatientsInputs>({
    defaultValues: {
      patients: [
        {
          name: undefined,
          date_of_birth: undefined,
          species_uuid: undefined,
          breed_uuid: undefined,
          colour_uuid: undefined,
          gender_uuid: undefined,
          profile_image: {
            id: undefined,
            url: undefined,
          },
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "patients",
    control: methods.control,
  });

  useEffect(() => {
    if (errorPatients) {
      errorHandler(errorPatients, methods.setError);
    }
  }, [errorPatients]);

  useEffect(() => {
    if (didRegisterPatients) {
      dispatch(
        login({
          user: {
            ...user!,
            patient_required: false,
          },
        }),
      );
    }
  }, [didRegisterPatients]);

  const handleAddPatient = () => {
    append({
      name: "",
      date_of_birth: undefined,
      species_uuid: "",
      breed_uuid: "",
      colour_uuid: "",
      gender_uuid: "",
      profile_image: undefined,
    });
    setOpenAccordionId(fields?.length);
  };

  const handleRemovePatient = (idx: number) => {
    remove(idx);
  };

  const handleOnSubmit = (data: PatientsInputs) => {
    registerPatients({
      patients: data.patients.map((patient) => ({
        ...patient,
        colour_uuid:
          patient.colour_uuid !== "optional-breed-colour"
            ? patient.colour_uuid
            : null,
        gender_uuid:
          patient.gender_uuid !== "optional-species-gender"
            ? patient.gender_uuid
            : null,
        profile_image: patient.profile_image?.id,
      })),
    });
  };

  useEffect(() => gtmEvent({ name: "onRegFlowCreatePatientStepPageView" }), []);

  return (
    <>
      <h1 className="text-4xl font-bold">
        Congratulations you are now registered
      </h1>

      <p className="mt-6">Tell us about yours pets</p>

      <form
        className="mt-6 max-w-[500px]"
        onSubmit={methods.handleSubmit(handleOnSubmit)}
      >
        <div className="divide-y divide-element-grey">
          <FormProvider {...methods}>
            {fields &&
              fields?.length > 0 &&
              fields?.map((patient, idx) => (
                <Accordion
                  key={patient.id}
                  isOpen={openAccordionId === idx}
                  trigger={
                    <button
                      key={patient.id}
                      onClick={() => setOpenAccordionId(idx)}
                      className={classNames(
                        "flex items-center justify-between py-4 w-full",
                        {
                          "select-none cursor-default": openAccordionId === idx,
                        },
                      )}
                    >
                      <div className="flex-shrink-0 w-16 h-16">
                        <PatientProfileImage
                          patient={{
                            profile_image: methods.watch(
                              `patients.${idx}.profile_image.url`,
                            ),
                          }}
                        />
                      </div>

                      <div className="flex-grow min-w-0 mx-4 align-baseline">
                        <p className="text-2xl font-semibold text-left truncate">
                          {methods.watch(`patients.${idx}.name`)}
                        </p>
                      </div>

                      {idx === 0 ? (
                        <ChevronDownIcon
                          className={classNames(
                            "transition-all transform w-4 h-4 text-black",
                            { "-rotate-180": openAccordionId === idx },
                          )}
                        />
                      ) : (
                        <div onClick={() => handleRemovePatient(idx)}>
                          <TrashIcon className="w-7 h-7 text-error" />
                        </div>
                      )}
                    </button>
                  }
                >
                  <CreatePatientForm patientIndex={idx} />
                </Accordion>
              ))}
          </FormProvider>
        </div>

        <div className="mt-12 mb-14">
          <InlineLink
            size="medium"
            onClick={handleAddPatient}
            disabled={
              !methods.watch(`patients.${fields?.length - 1}.name`) ||
              !methods.watch(`patients.${fields?.length - 1}.date_of_birth`) ||
              !methods.watch(`patients.${fields?.length - 1}.species_uuid`) ||
              !methods.watch(`patients.${fields?.length - 1}.breed_uuid`)
            }
          >
            + Add another pet
          </InlineLink>
        </div>

        <Button
          block
          type="submit"
          className="mt-14"
          loading={isRegisteringPatients}
          disabled={
            !methods.watch(`patients.${fields?.length - 1}.name`) ||
            !methods.watch(`patients.${fields?.length - 1}.date_of_birth`) ||
            !methods.watch(`patients.${fields?.length - 1}.species_uuid`) ||
            !methods.watch(`patients.${fields?.length - 1}.breed_uuid`)
          }
        >
          Finish
        </Button>
      </form>
    </>
  );
};

export default RegistrationCreatePatient;
