import { useFormContext } from "react-hook-form";
import { Button, Input, Label, FormGroup, PasswordStrength } from "@mwi/ui";

const ChangePasswordStep2 = ({
  isLoading,
}: {
  isLoading: boolean;
}): JSX.Element => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <h2 className="my-8">New Password</h2>

      <p className="mb-10">Enter a new password</p>

      <FormGroup>
        <Label>Password</Label>
        <Input type="password" autoComplete="off" {...register("password")} />
        <PasswordStrength
          className="mt-2"
          password={watch("password")}
          error={errors?.password?.message?.toString()}
        />
      </FormGroup>

      <FormGroup
        error={
          errors?.password_confirmation?.message?.toString() ??
          (watch("password") !== "" &&
          watch("password_confirmation") !== "" &&
          watch("password") !== watch("password_confirmation")
            ? "Password Doesn't Match"
            : undefined)
        }
        success={
          watch("password") &&
          watch("password") === watch("password_confirmation")
            ? "Password Matches"
            : undefined
        }
      >
        <Label>Confirm Password</Label>
        <Input
          type="password"
          autoComplete="off"
          {...register("password_confirmation")}
        />
      </FormGroup>

      <div className="flex items-end flex-grow mt-9 tablet:items-start">
        <Button
          block
          type="submit"
          loading={isLoading}
          disabled={!watch("password") || !watch("password_confirmation")}
        >
          Set new password
        </Button>
      </div>
    </>
  );
};

export default ChangePasswordStep2;
