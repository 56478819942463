import { ChangeEvent, createRef, useEffect, useState } from "react";
import api, { useUpdatePatientMutation, useUploadFileMutation } from "api";
import {
  CheckIcon,
  Avatar,
  CameraIcon,
  CrossIcon,
  NotifierNotification,
} from "@mwi/ui";
import Patient from "api/types/models/patient";
import { useNotifier } from "react-headless-notifier";

type PatientProfileImageProps = {
  patient: Partial<Patient> & { profile_image?: string };
  canUpdate?: boolean;
};

const PatientProfileImage = ({
  patient,
  canUpdate = false,
}: PatientProfileImageProps): JSX.Element => {
  const [previewUrl, setPreviewUrl] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedFileId, setSelectedFileId] = useState<number>();

  const inputRef = createRef<HTMLInputElement>();
  const { notify } = useNotifier();

  const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation();
  const [updatePatient, { data, isLoading }] = useUpdatePatientMutation();

  useEffect(() => {
    if (data) {
      api.util.updateQueryData("getPatients", undefined, (patients) => {
        const index = patients.findIndex((p) => p.id === data.id);
        patients[index].profile_image = data.profile_image;
      });
    }
  }, [data]);

  useEffect(() => {
    if (selectedFile) {
      setPreviewUrl(URL.createObjectURL(selectedFile));
    }
  }, [selectedFile]);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);

      try {
        const uploadedFile = await uploadFile({
          file: e.target.files[0],
          type: "patient_profile_images",
        }).unwrap();

        setSelectedFileId(uploadedFile.id);
      } catch (err: any) {
        if (err?.data?.errors) {
          setSelectedFile(undefined);
          setPreviewUrl(undefined);
          notify(
            <NotifierNotification
              title="Unable to set image"
              message={err.data.errors[0].message}
              type="error"
            />,
          );
        }
      }
    }
  };

  const discardSelectedImage = () => {
    setPreviewUrl(undefined);
    setSelectedFile(undefined);
    setSelectedFileId(undefined);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const saveSelectedImage = async () => {
    await updatePatient({
      id: patient.id!,
      body: {
        profile_image: selectedFileId,
      },
    });
    discardSelectedImage();
    // TODO: Update patient in state
  };

  return (
    <div className="relative">
      {canUpdate && patient.id && !selectedFile && (
        <button
          disabled={isLoading}
          onClick={() => inputRef.current?.click()}
          className="absolute z-10 grid w-8 h-8 bg-black rounded-full -bottom-1 -right-1 place-content-center"
        >
          <CameraIcon className="pointer-events-none text-white h-[20px] w-[20px]" />
          <span className="sr-only">Change pet profile image</span>
        </button>
      )}

      {selectedFile && !isUploading && (
        <div className="absolute z-10 flex justify-between w-full -bottom-1">
          <button
            disabled={isLoading}
            onClick={saveSelectedImage}
            className="grid w-8 h-8 rounded-full place-content-center bg-success-lightest"
          >
            <CheckIcon className="pointer-events-none h-[20px] w-[20px] text-success" />
            <span className="sr-only">Save new profile image</span>
          </button>
          <button
            disabled={isLoading}
            onClick={discardSelectedImage}
            className="grid w-8 h-8 rounded-full place-content-center bg-error-lightest"
          >
            <CrossIcon className="pointer-events-none h-[20px] w-[20px] text-error" />
            <span className="sr-only">Discard new profile image</span>
          </button>
        </div>
      )}

      <div className="aspect-square">
        <Avatar
          isPatient
          className="h-full"
          name={patient.name ?? ""}
          url={previewUrl || patient.profile_image}
          isLoading={isUploading || isLoading}
        />
      </div>
      <input
        type="file"
        className="hidden"
        ref={inputRef}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default PatientProfileImage;
