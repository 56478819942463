import classNames from "classnames";

const ProgressBar = ({
  max,
  value,
  className,
  showStepIndicator = false,
}: {
  max: number;
  value: number;
  className?: string;
  showStepIndicator?: boolean;
}) => {
  const percent = (value / max) * 100;

  return (
    <div>
      <div className={classNames("relative w-full h-2", className)}>
        <div className="absolute inset-0 rounded-full bg-grey-light" />

        <div
          className="absolute inset-y-0 left-0 transition-all rounded-full bg-light"
          style={{ width: `${percent}%` }}
        />
      </div>

      {showStepIndicator && (
        <div className="mt-4 mb-10">
          <span className="text-sm font-semibold leading-7">
            {`Step ${value} of ${max}`}
          </span>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
