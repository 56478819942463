import classNames from "classnames";
import { MouseEvent } from "react";
import DateTimeFormat from "../../date_time_format";

type AvailableMergedSlot = {
  id: string;
  start_time: string;
  end_time: string;
  practitioner_uuid: string;
  slots: AvailableSlot[];
};

type AvailableSlot = {
  id: string;
  practitioner_uuid: string;
  start_time: string;
  end_time: string;
  duration_minutes: number;
};

type AvailableSlotOverviewProps = {
  slot: AvailableMergedSlot;
  onClick(event: MouseEvent<HTMLElement>): void;
  isSelected: boolean;
  size?: "small" | "medium";
};

const AvailableSlotOverview = ({
  slot,
  onClick,
  isSelected,
  size = "small",
}: AvailableSlotOverviewProps): JSX.Element => {
  // fixed heigt on card required due to change in border width
  const classes = classNames(
    `
    w-full
    h-[48px] w-[133px]
    rounded-lg
    font-semibold
    border border-pinball 
    flex items-center justify-center
    select-none
  `,
    {
      "p-2 text-xs leading-[18px] border": size === "small",
      "px-4 py-[10px] text-base": size === "medium",
      "border-light shadow-focus-small": isSelected,
      "border-2": isSelected && size === "medium",
    },
  );

  return (
    <button onClick={onClick} className={classes}>
      {slot.start_time && slot.end_time && (
        <>
          <DateTimeFormat date={slot.start_time} format="HH:mm" />
          <span>&nbsp;-&nbsp;</span>
          <DateTimeFormat date={slot.end_time} format="HH:mm" />
        </>
      )}
    </button>
  );
};

export default AvailableSlotOverview;
