import { Fragment } from "react";
import { useGetAppointmentsQuery } from "api";
import {
  Button,
  EmptyStateCard,
  InlineLink,
  CalendarOffIcon,
  PastAppointmentCard,
  Loader,
  Status,
  WarningIcon,
} from "@mwi/ui";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { gtmEvent } from "helpers/gtm";

const PastAppointmentSection = (): JSX.Element => {
  const { data: appointments, isFetching, isError } = useGetAppointmentsQuery();

  const appointmentsBlocked = useSelector(
    (state: RootState) => state.practice.appointments_blocked,
  );

  const tablet = window.innerWidth > 1024;

  if (isError) {
    return (
      <>
        <div className="mb-6 tablet:mb-8">
          <h3 className="text-lg font-semibold tablet:text-[21px] tablet:font-bold">
            Past appointments
          </h3>
        </div>
        <div className="grid order-1 w-full tablet:order-2 place-content-center">
          <Status
            danger
            graphic={<WarningIcon />}
            description="Unable to load past appointments. Please try again later."
          />
        </div>
      </>
    );
  }

  if (isFetching) {
    return (
      <>
        <div className="mb-6 tablet:mb-8">
          <h3 className="text-lg font-semibold tablet:text-[21px] tablet:font-bold">
            Past appointments
          </h3>
        </div>
        <div className="grid order-1 w-full tablet:order-2 place-content-center">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <>
      {appointments?.past && appointments.past?.length > 0 ? (
        <>
          <div className="mb-6 tablet:mb-8">
            <h3 className="text-lg font-semibold tablet:text-[21px] tablet:font-bold">
              Past appointments
            </h3>
          </div>

          <div className="flex flex-col items-center gap-2 small:gap-4">
            {appointments.past.map((app, idx) =>
              idx > 5 ? (
                <Fragment key={app.id} />
              ) : (
                <PastAppointmentCard
                  key={app.id}
                  appointment={app}
                  to={`/appointments/${app?.id}`}
                  onClick={() => gtmEvent({ name: "onHomepageViewPastAppt" })}
                />
              ),
            )}
            {appointments.past.length > 5 && (
              <div className="flex justify-center">
                <InlineLink
                  to="/appointments"
                  onClick={() =>
                    gtmEvent({ name: "onHomepageViewAllPastAppts" })
                  }
                >
                  See all appointments
                </InlineLink>
              </div>
            )}
          </div>
        </>
      ) : (
        <div>
          {appointments?.past && appointments?.past.length !== 0 && (
            <EmptyStateCard
              icon={<CalendarOffIcon />}
              title="No appointments"
              message="You have no past appointments"
              cta={
                appointmentsBlocked ? undefined : (
                  <Button
                    type="link"
                    size="small"
                    variant="outlined"
                    to={tablet ? "/home" : "/appointments/book"}
                    className="tablet:hidden"
                  >
                    Book Now
                  </Button>
                )
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export default PastAppointmentSection;
