import ChevronLeftIcon from "../../../icons/ChevronLeftIcon";
import ChevronRightIcon from "../../../icons/ChevronRightIcon";

type ChangeWeekButtonProps = {
  onClick: () => void;
  type?: "next" | "previous";
};

const ChangeWeekButton = ({
  onClick,
  type,
}: ChangeWeekButtonProps): JSX.Element => {
  return (
    <button
      onClick={onClick}
      className="px-1 border-2 rounded-lg border-primary h-[80px] w-[80px] outline-none"
    >
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="flex-shrink-0 w-6 h-6">
          <span className="text-primary">
            {type === "next" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </span>
        </div>

        <div className="max-w-[40px] w-full">
          <span className="text-sm font-semibold text-primary">
            {type === "next" ? "Next Week" : "Prev Week"}
          </span>
        </div>
      </div>
    </button>
  );
};

export default ChangeWeekButton;
