import { CookieTemplate, InlineLink, Modal, PrivacyTemplate } from "@mwi/ui";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

type PrivacyModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const PrivacyModal = ({ isOpen, onClose }: PrivacyModalProps): JSX.Element => {
  const [showCookiePolicy, setShowCookiePolicy] = useState<boolean>(false);

  const practice = useSelector((state: RootState) => state.practice);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      afterLeave={() => setShowCookiePolicy(false)}
      padMobile
    >
      <Modal.Content>
        <div className="flex flex-col gap-16 tablet:gap-8">
          <div className="flex flex-col gap-4">
            <p className="text-xl font-semibold tablet:text-2xl">
              {showCookiePolicy ? "Cookie Policy" : "Privacy Policy"}
            </p>
            <div className="flex flex-col text-lg">
              {showCookiePolicy ? (
                <CookieTemplate />
              ) : (
                <>
                  <PrivacyTemplate
                    email={practice?.email ?? null}
                    phone={practice?.phone ?? null}
                    address={practice?.address ?? null}
                    displayName={practice?.displayName ?? null}
                    privacyLink={practice?.privacyLink ?? null}
                  />
                  <br />
                  <p>
                    You can click{" "}
                    <InlineLink onClick={() => setShowCookiePolicy(true)}>
                      here
                    </InlineLink>{" "}
                    to view our cookie policy.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default PrivacyModal;
