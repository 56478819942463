import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Input,
  Label,
  FormGroup,
  NotifierNotification,
  BackButton,
} from "@mwi/ui";
import { useForgotPasswordMutation } from "api";
import useErrorHandler from "hooks/useErrorHandler";
import { useNotifier } from "react-headless-notifier";

type Inputs = {
  email: string;
};

const ForgotPasswordPage = (): JSX.Element => {
  const errorHandler = useErrorHandler();
  const { notify } = useNotifier();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<Inputs>();

  const [forgotPasswordMutation, { error, isSuccess, isLoading }] =
    useForgotPasswordMutation();

  useEffect(() => {
    if (error) {
      errorHandler(error, setError);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      notify(
        <NotifierNotification
          title="Check your email"
          message="If we found an account we'll send a link to your email. Click the link and follow the instructions to reset your password." // eslint-disable-line max-len
          type="success"
        />,
      );
    }
  }, [isSuccess]);

  const handleOnSubmit = (data: Inputs) => {
    forgotPasswordMutation({
      email: data.email,
    });
  };

  return (
    <>
      <BackButton to="/" />

      <div className="flex flex-col justify-start flex-grow mx-auto tablet:justify-center tablet:max-w-lg desktop:max-w-3xl">
        <div className="mt-24 mb-6 tablet:mb-8 tablet:mt-0">
          <h1 className="text-4xl font-bold leading-10 tablet:text-6xl">
            Forgot Password
          </h1>
        </div>

        <p className="mb-10 tablet:text-lg">
          Enter the email associated with your account and we&apos;ll send an
          email with instructions to reset your password.
        </p>

        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className="mt-10 tablet:mt-14"
        >
          <FormGroup error={errors.email?.message}>
            <Label>Email</Label>
            <Input type="email" autoComplete="off" {...register("email")} />
          </FormGroup>

          <div className="mt-20">
            <div className="w-full mobile:max-w-sm">
              <Button
                block
                type="submit"
                loading={isLoading}
                disabled={!watch("email")}
              >
                Send instructions
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
