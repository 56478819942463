import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectedPatient } from "types/prescription";

export interface FormState {
  patients: SelectedPatient[];

  status: "complete" | "error";
}

export type PrescriptionSteps =
  | "verify"
  | "patients"
  | "prescriptions"
  | "review"
  | "confirmation";

export interface PrescriptionFlowState {
  step: PrescriptionSteps;
  formState: FormState;
  patient_index: number;
}

const initialState: PrescriptionFlowState = {
  step: "verify",
  patient_index: 0,
  formState: JSON.parse(
    localStorage.getItem("prescription_state") ??
      `{
      "patients": []
    }`,
  ),
};

export const prescriptionSlice = createSlice({
  name: "prescriptions",
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<Partial<PrescriptionFlowState>>) => {
      if (action.payload.step) {
        state.step = action.payload.step;
      }

      // prevent adding step into formState
      delete action.payload.step;

      state.formState = {
        ...state.formState,
        ...action.payload,
      };

      localStorage.setItem(
        "prescription_state",
        JSON.stringify(state.formState),
      );
    },

    setPatients: (state, action: PayloadAction<SelectedPatient[]>) => {
      state.formState.patients = action.payload;

      localStorage.setItem(
        "prescription_state",
        JSON.stringify(state.formState),
      );
    },

    setPatient: (
      state,
      action: PayloadAction<{
        patientIdx: number;
        updatedPatient: SelectedPatient;
      }>,
    ) => {
      state.formState.patients[action.payload.patientIdx] =
        action.payload.updatedPatient;
    },

    setPatientIndex: (state, action: PayloadAction<number>) => {
      state.patient_index = action.payload;

      localStorage.setItem(
        "prescription_state",
        JSON.stringify(state.formState),
      );
    },

    setRequestStatus: (
      state,
      action: PayloadAction<Pick<FormState, "status">>,
    ) => {
      state.formState.status = action.payload.status;
    },

    resetPrescriptionState: (state) => {
      localStorage.removeItem("prescription_state");

      state.step = "verify";
      state.patient_index = 0;
      state.formState.patients = [];
    },
  },
});

export const {
  setStep,
  setPatients,
  setPatient,
  setPatientIndex,
  setRequestStatus,
  resetPrescriptionState,
} = prescriptionSlice.actions;

export default prescriptionSlice.reducer;
