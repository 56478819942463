import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setStep } from "slices/booking";
import {
  Button,
  InlineLink,
  CalendarOffIcon,
  GraphicBackground,
  CalendarCheckIcon,
} from "@mwi/ui";
import {
  AvailableDay,
  AvailableMergedSlot,
} from "api/types/models/availability";
import { useEffect } from "react";
import { gtmEvent } from "helpers/gtm";

const BookAppointmentModalStep5 = ({
  onClose,
}: {
  onClose: () => void;
}): JSX.Element => {
  const dispatch = useDispatch();

  const { user, apptConfirmed, errorMessage } = useSelector(
    (state: RootState) => ({
      user: state.auth.user,
      apptConfirmed: state.bookingFlow.formState.appointment_confirmed,
      errorMessage: state.bookingFlow.formState.error_message,
    }),
  );

  const handleButton = () => {
    if (apptConfirmed) {
      onClose();
    } else {
      dispatch(
        setStep({
          step: "3",
          selected_slot: {} as AvailableMergedSlot,
          selected_day: {} as AvailableDay,
          confirmed_selection: false,
          appointment_confirmed: false,
          availability_date_range: {
            date_start: "",
            date_end: "",
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (apptConfirmed !== undefined) {
      gtmEvent({
        name: apptConfirmed
          ? "onDesktopApptFlowConfirmedApptPageView"
          : "onDesktopApptFlowFailedApptPageView",
      });
    }
  }, [apptConfirmed]);

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="relative w-[240px] h-[193px] flex items-center justify-center mb-10">
        <GraphicBackground
          className={classNames("text-lightest", {
            "!text-error-lightest": !apptConfirmed,
          })}
        />

        <div className="absolute w-[103px] h-[103px]">
          {apptConfirmed ? (
            <CalendarCheckIcon className="text-primary" />
          ) : (
            <CalendarOffIcon className="text-error" />
          )}
        </div>
      </div>

      <div className="mb-6">
        <h1 className="text-4xl font-bold leading-[43px] text-center">
          {apptConfirmed
            ? "Your booking is confirmed"
            : "We could not confirm your booking."}
        </h1>
      </div>

      <div className="mb-16 max-w-[500px] text-center">
        {apptConfirmed ? (
          <p className="text-base mobile:text-lg">
            We sent a confirmation email to <strong>{user?.email}</strong>
          </p>
        ) : (
          <p className="text-base mobile:text-lg">
            {errorMessage ??
              "Unfortunately the timeslot you picked got blocked before you got a chance to confirm your booking."}
          </p>
        )}
      </div>

      <div className="flex flex-col items-center w-full gap-6 mt-auto">
        {!errorMessage && (
          <Button size="medium" onClick={handleButton}>
            {apptConfirmed ? "Go to dashboard" : "Find another timeslot"}
          </Button>
        )}
        <InlineLink onClick={() => onClose()} size="medium">
          {apptConfirmed ? "Book another appointment" : "Go to dashboard"}
        </InlineLink>
      </div>
    </div>
  );
};

export default BookAppointmentModalStep5;
