import { useEffect } from "react";
import { useNotifier } from "react-headless-notifier";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  Button,
  Loader,
  NotifierNotification,
  PatientOverviewCard,
} from "@mwi/ui";
import { setStep, setPatients } from "slices/booking";
import Patient from "api/types/models/patient";
import { SelectedPatient } from "types/booking-flow/SelectedPatient";
import { useGetPatientsQuery, useVerifyAppointmentsMutation } from "api";
import { gtmEvent } from "helpers/gtm";

const BookingFlowStep1 = (): JSX.Element => {
  const dispatch = useDispatch();
  const { notify } = useNotifier();

  const { formState, registeredSite } = useSelector((state: RootState) => ({
    formState: state.bookingFlow.formState,
    registeredSite: state.auth.registered_site,
  }));

  const { data: patients, isLoading } = useGetPatientsQuery();

  const [
    verifyPatients,
    { isSuccess: didVerify, isLoading: verifying, isError: errorVerifying },
  ] = useVerifyAppointmentsMutation();

  const handleSelectedPatient = (selectedPatient: Patient) => {
    const isAlreadySelected = formState.patients.some(
      (p) => p.patient_uuid === selectedPatient.id,
    );

    let patients: SelectedPatient[] = [];
    if (isAlreadySelected) {
      patients = formState.patients.filter(
        (p) => p.patient_uuid !== selectedPatient.id,
      );
    } else {
      const newPatient = {
        patient_uuid: selectedPatient.id,
        reason_uuid: "",
        comment: "",
        duration_minutes: 0,
      };

      formState.patients.length === 1 && !formState.patients[0]?.patient_uuid
        ? (patients = [newPatient])
        : (patients = [...formState.patients, newPatient]);
    }

    dispatch(setPatients({ patients: patients }));
  };

  useEffect(() => {
    if (didVerify) {
      dispatch(
        setStep({
          step: "2",
          patients: [...formState.patients],
          vet_site_uuid: registeredSite?.id ?? undefined,
        }),
      );
    }
  }, [didVerify]);

  useEffect(() => {
    if (errorVerifying) {
      notify(
        <NotifierNotification
          type="error"
          title="Select pets"
          message="You must select at least one pet to book an appointment"
        />,
      );
    }
  }, [errorVerifying]);

  useEffect(
    () => gtmEvent({ name: "onMobileApptFlowSelectPatientsPageView" }),
    [],
  );

  return (
    <>
      {isLoading && (
        <div className="grid h-full place-content-center">
          <Loader />
        </div>
      )}

      {!isLoading && (
        <>
          <div className="mb-8">
            <h2 className="text-4xl font-bold leading-[43px]">
              Who are you booking for?
            </h2>
          </div>

          <div className="mb-10">
            <p className="text-base">
              You can select
              <span className="font-bold"> more than one </span>
              pet at the same time.
            </p>
          </div>

          <div className="mb-[72px]">
            <div className="flex flex-col gap-4">
              {patients &&
                patients?.length > 0 &&
                patients.map((patient) => (
                  <PatientOverviewCard
                    key={patient.id}
                    patient={patient}
                    onClick={() => handleSelectedPatient(patient)}
                    isSelected={formState.patients.some(
                      (p) => p.patient_uuid === patient.id,
                    )}
                    showLastAppointment
                  />
                ))}
            </div>
          </div>

          <Button
            block
            loading={verifying}
            disabled={
              (formState.patients?.length === 0 ||
                formState.patients.length === 1) &&
              !formState.patients[0]?.patient_uuid
            }
            onClick={() =>
              verifyPatients({
                step: "1",
                patients: formState.patients,
              })
            }
          >
            Continue
          </Button>
        </>
      )}
    </>
  );
};

export default BookingFlowStep1;
