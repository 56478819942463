/* eslint-disable max-len */
const AlertIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 9.5V11.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.5H12.01"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 19.5H19C19.6625 19.4954 20.2798 19.1629 20.6482 18.6122C21.0166 18.0616 21.0885 17.3642 20.84 16.75L13.74 4.5C13.3878 3.86337 12.7176 3.46825 11.99 3.46825C11.2624 3.46825 10.5922 3.86337 10.24 4.5L3.14 16.75C2.89639 17.3497 2.95812 18.0303 3.30565 18.5764C3.65318 19.1225 4.24353 19.4667 4.89 19.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AlertIcon;
