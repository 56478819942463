import { Link } from "react-router-dom";
import BandageIcon from "../../../icons/BandageIcon";
import DateTimeFormat from "../../date_time_format";
import CalendarEventIcon from "../../../icons/CalendarEventIcon";
import Avatar from "../../avatar";

type PastAppointmentCardProps = {
  to: string;
  appointment: {
    start_date: string;
    reason: string;
    patient_name: string;
    patient_profile_image?: string;
  };
  onClick?: () => void;
};

const PastAppointmentCard = ({
  to,
  appointment,
  onClick,
}: PastAppointmentCardProps): JSX.Element => {
  return (
    <Link to={to} className="w-full" onClick={onClick}>
      <div className="w-full px-5 py-6 bg-white shadow-default focus:shadow-focus-large tablet:p-6">
        <div className="flex gap-x-4">
          <div className="flex flex-col flex-grow gap-1 xs:gap-2 tablet:gap-3">
            <div className="flex items-center gap-2 text-left">
              <div className="self-start flex-shrink-0 w-4 h-4 xs:w-6 xs:h-6">
                <CalendarEventIcon className="text-black" />
              </div>
              <span className="block tablet:leading-[17px]">
                <DateTimeFormat
                  date={appointment.start_date}
                  format={"dd LLLL yyyy"}
                />
              </span>
            </div>
            <div className="flex items-center gap-2 text-left">
              <div className="self-start flex-shrink-0 w-4 h-4 xs:w-6 xs:h-6">
                <BandageIcon className="text-black" />
              </div>
              <span className="block tablet:leading-[17px]">
                {appointment.reason}
              </span>
            </div>
          </div>

          <div className="self-start flex-shrink-0">
            <Avatar
              name={appointment.patient_name}
              url={appointment.patient_profile_image}
              className="w-[1.875rem] h-[1.875rem] xs:w-9 xs:h-9"
              isPatient
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PastAppointmentCard;
