import classNames from "classnames";

const TableHead = ({
  children,
  borderTop = false,
}: {
  children: JSX.Element | JSX.Element[];
  borderTop?: boolean;
}) => {
  return (
    <thead
      className={classNames(
        "py-[0.125rem] border-b border-grey-light text-grey-dark",
        {
          "border-t": borderTop,
        },
      )}
    >
      {children}
    </thead>
  );
};

export default TableHead;
