import { DateTime } from "luxon";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import {
  useGetSpeciesQuery,
  useUploadFileMutation,
  useDeleteFileMutation,
  useGetBreedsForSpeciesQuery,
  useGetColoursForBreedQuery,
  useGetGendersForSpeciesQuery,
} from "api";
import useErrorHandler from "hooks/useErrorHandler";
import {
  DatePicker,
  FormGroup,
  Input,
  Label,
  Select,
  SelectOption,
  Upload,
} from "@mwi/ui";

type CreatePatientFormProps = {
  patientIndex: number;
};

const CreatePatientForm = ({ patientIndex }: CreatePatientFormProps) => {
  const errorHandler = useErrorHandler();

  const {
    control,
    register,
    watch,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useFormContext();

  const [uploadFile] = useUploadFileMutation();
  const [deleteFile] = useDeleteFileMutation();

  const { data: species, isFetching: isFetchingSpecies } = useGetSpeciesQuery(
    {},
  );

  const {
    data: breeds,
    error: errorBreeds,
    isFetching: isFetchingBreeds,
  } = useGetBreedsForSpeciesQuery(
    watch(`patients.${patientIndex}.species_uuid`),
    {
      skip: !watch(`patients.${patientIndex}.species_uuid`),
    },
  );

  const {
    data: gendersForSpecies,
    error: errorGendersForSpecies,
    isFetching: isFetchingGendersForSpecies,
  } = useGetGendersForSpeciesQuery(
    watch(`patients.${patientIndex}.species_uuid`),
    {
      skip: !watch(`patients.${patientIndex}.species_uuid`),
    },
  );

  const {
    data: breedColours,
    error: errorBreedColours,
    isFetching: isFetchingColoursForBreeds,
  } = useGetColoursForBreedQuery(watch(`patients.${patientIndex}.breed_uuid`), {
    skip: !watch(`patients.${patientIndex}.breed_uuid`),
  });

  useEffect(() => {
    // Clear breeds, colours and gender if they're not linked to selected species
    if (getValues(`patients.${patientIndex}.species_uuid`)) {
      if (
        !breeds?.some(
          (b) => b.id === getValues(`patients.${patientIndex}.breed_uuid`),
        )
      ) {
        setValue(`patients.${patientIndex}.breed_uuid`, undefined);
      }

      if (
        !breedColours?.some(
          (b) => b.id === getValues(`patients.${patientIndex}.colour_uuid`),
        ) &&
        getValues(`patients.${patientIndex}.colour_uuid`) !==
          "optional-breed-colour"
      ) {
        setValue(`patients.${patientIndex}.colour_uuid`, undefined);
      }

      if (
        !gendersForSpecies?.some(
          (g) => g.id === getValues(`patients.${patientIndex}.gender_uuid`),
        ) &&
        getValues(`patients.${patientIndex}.colour_uuid`) !==
          "optional-species-gender"
      ) {
        setValue(`patients.${patientIndex}.gender_uuid`, undefined);
      }
    }
  }, [watch(`patients.${patientIndex}.species_uuid`)]);

  useEffect(() => {
    // Clear colours if they're not linked to selected breed
    if (getValues(`patients.${patientIndex}.breed_uuid`)) {
      if (
        !breedColours?.some(
          (b) => b.id === getValues(`patients.${patientIndex}.colour_uuid`),
        ) &&
        getValues(`patients.${patientIndex}.colour_uuid`) !==
          "optional-breed-colour"
      ) {
        setValue(`patients.${patientIndex}.colour_uuid`, undefined);
      }
    }
  }, [watch(`patients.${patientIndex}.breed_uuid`)]);

  useEffect(() => {
    if (errorGendersForSpecies) {
      errorHandler(errorGendersForSpecies, setError);
    }

    if (errorBreeds) {
      errorHandler(errorBreeds, setError);
    }

    if (errorBreedColours) {
      errorHandler(errorBreedColours, setError);
    }
  }, [errorGendersForSpecies, errorBreeds, errorBreedColours]);

  const optionalBreedColours = useMemo(() => {
    let colours = [{ id: "optional-breed-colour", name: "Other" }];
    if (breedColours) {
      colours = [...breedColours, ...colours];
    }

    return colours;
  }, [breedColours]);

  const optionalSpeciesGenders = useMemo(() => {
    let genders = [{ id: "optional-species-gender", name: "Other" }];
    if (gendersForSpecies) {
      genders = [...gendersForSpecies, ...genders];
    }

    return genders;
  }, [gendersForSpecies]);

  return (
    <>
      <FormGroup error={errors.patients?.[patientIndex]?.name?.message}>
        <Label>Pet&apos;s name</Label>
        <Input maxChars={70} {...register(`patients.${patientIndex}.name`)} />
        <div className="flex justify-end mt-1">
          <p className="text-sm tablet:text-base text-primary">
            {watch(`patients.${patientIndex}.name`)?.length ?? 0} / 70
          </p>
        </div>
      </FormGroup>

      <FormGroup
        error={errors.patients?.[patientIndex]?.date_of_birth?.message}
      >
        <Label>Date of Birth (if unsure, enter approx. date)</Label>
        <DatePicker
          control={control}
          defaultLabel="DD/MM/YY"
          name={`patients.${patientIndex}.date_of_birth`}
          disabledDays={{ after: DateTime.now().toJSDate() }}
        />
      </FormGroup>

      <FormGroup error={errors.patients?.[patientIndex]?.species_uuid?.message}>
        <Label>Species</Label>
        <Select
          name={`patients.${patientIndex}.species_uuid`}
          placeholder="Select a species"
          control={control}
        >
          {species?.data?.map((s) => (
            <SelectOption key={s.id} value={s.id}>
              {s?.translation ?? s.name}
            </SelectOption>
          ))}
        </Select>
      </FormGroup>

      <FormGroup error={errors.patients?.[patientIndex]?.breed_uuid?.message}>
        <Label>Breed</Label>
        <Select
          name={`patients.${patientIndex}.breed_uuid`}
          control={control}
          placeholder="Select a breed"
          disabled={
            !watch(`patients.${patientIndex}.species_uuid`) ||
            isFetchingSpecies ||
            isFetchingBreeds
          }
          loading={isFetchingBreeds}
        >
          {breeds?.map((b) => (
            <SelectOption key={b.id} value={b.id}>
              {b?.translation ?? b.name}
            </SelectOption>
          ))}
        </Select>
      </FormGroup>

      <FormGroup error={errors?.patients?.[patientIndex]?.colour_uuid?.message}>
        <Label>Colour (optional)</Label>
        <Select
          name={`patients.${patientIndex}.colour_uuid`}
          control={control}
          placeholder="Colour of animal"
          disabled={
            !watch(`patients.${patientIndex}.breed_uuid`) ||
            isFetchingBreeds ||
            isFetchingColoursForBreeds
          }
          loading={isFetchingColoursForBreeds}
        >
          {optionalBreedColours?.map((bc) => (
            <SelectOption key={bc.id} value={bc.id}>
              {bc.name}
            </SelectOption>
          ))}
        </Select>
      </FormGroup>

      <FormGroup error={errors?.patients?.[patientIndex]?.gender_uuid?.message}>
        <Label>Sex (optional)</Label>
        <Select
          name={`patients.${patientIndex}.gender_uuid`}
          control={control}
          placeholder="Sex of animal"
          disabled={
            !watch(`patients.${patientIndex}.species_uuid`) ||
            isFetchingSpecies ||
            isFetchingGendersForSpecies
          }
          loading={isFetchingGendersForSpecies}
        >
          {optionalSpeciesGenders?.map((g) => (
            <SelectOption key={g.id} value={g.id}>
              {g.name}
            </SelectOption>
          ))}
        </Select>
      </FormGroup>

      <FormGroup error={errors?.patients?.[patientIndex]?.message}>
        <Upload
          control={control}
          uploadFile={uploadFile}
          defaultUrl={watch(`patients.${patientIndex}.profile_image.url`)}
          deleteFile={deleteFile}
          name={`patients.${patientIndex}.profile_image`}
          type="patient_profile_images"
          text="Upload pet profile picture"
        />
      </FormGroup>
    </>
  );
};

export default CreatePatientForm;
