import { useEffect, useState } from "react";
import classNames from "classnames";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  Button,
  Dropdown,
  Navbar,
  Loader,
  Avatar,
  HomeIcon,
  CalendarIcon,
  PawIcon,
  UserCircleIcon,
  PlusIcon,
  Status,
  AlertTriangleIcon,
  StatusModal,
  CalendarOffIcon,
  CookiePopup,
  PillIcon,
} from "@mwi/ui";
import { login, logout } from "slices/auth";
import { setPractice, clearPractice } from "slices/practice";
import {
  useGetMeQuery,
  useGetPracticeQuery,
  useGetHeartbeatQuery,
  useLogoutMutation,
} from "api";
import { gtmEvent } from "helpers/gtm";

const App = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [openApptBlockedModal, setOpenApptBlockedModal] =
    useState<boolean>(false);

  const {
    data: loggedInUser,
    isError: isErrorMe,
    isLoading: isLoadingMe,
  } = useGetMeQuery();
  const {
    data: practiceData,
    isError: isErrorPractice,
    isLoading: isLoadingPractice,
  } = useGetPracticeQuery(window.location.hostname.split(".")[0]);
  const [submitLogout] = useLogoutMutation();
  const { isAuthenticated, user, theme, practiceName, appointmentsBlocked } =
    useSelector((state: RootState) => ({
      isAuthenticated: state.auth.authenticated,
      user: state.auth.user,
      theme: state.practice.theme,
      practiceName: state.practice.name,
      appointmentsBlocked: state.practice.appointments_blocked,
    }));

  useGetHeartbeatQuery(undefined, {
    pollingInterval: 60000, // 1m
    skip: !isAuthenticated,
  });

  const showNavbar =
    isAuthenticated && user?.onboarding_completed_at && !user?.patient_required;

  const triggerLogout = async () => {
    await submitLogout();
    dispatch(logout());
  };

  const handleApptDisabled = () => {
    if (appointmentsBlocked) {
      setOpenApptBlockedModal(true);
    } else {
      gtmEvent({ name: "onMobileHomepageBookAppt" });
    }
  };

  useEffect(() => {
    if (!practiceData) {
      return;
    }
    if (isErrorPractice) {
      dispatch(clearPractice());
    } else {
      dispatch(setPractice(practiceData));
    }
  }, [dispatch, practiceData, isErrorPractice]);

  useEffect(() => {
    if (isErrorMe) {
      dispatch(logout());
    } else if (loggedInUser) {
      dispatch(
        login({
          user: loggedInUser.user,
          registered_site: loggedInUser.registered_location,
        }),
      );
      if (loggedInUser.practice.theme) {
        dispatch(setPractice(loggedInUser.practice));
      }
    }
  }, [dispatch, loggedInUser, isErrorMe]);

  if (window.top !== window.self) {
    return (
      <div className="fixed inset-0 flex items-center justify-center px-2">
        <Status
          graphic={<AlertTriangleIcon />}
          danger
          title="Access Restricted"
          description="MWI Pet cannot be accessed from an iframe or popup"
        />
      </div>
    );
  }

  if (isLoadingMe || isLoadingPractice) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (
    !isLoadingMe &&
    user?.patient_required &&
    location.pathname !== "/register"
  ) {
    return <Navigate to="/register" />;
  }

  if (isErrorPractice) {
    return (
      <div className="fixed inset-0 flex items-center justify-center px-2">
        <Status
          graphic={<AlertTriangleIcon />}
          danger
          title="Sorry, this site is unavailable."
          description="Please contact your veterinary practice for more information"
        />
      </div>
    );
  }

  return (
    <>
      <style>{`
        :root {
          ${theme.darkColor && `--mwi-dark: ${theme.darkColor};`}
          ${theme.onDarkColor && `--mwi-on-dark: ${theme.onDarkColor};`}
        }
      `}</style>
      <style>{`
        :root {
          ${theme.primaryColor && `--mwi-primary: ${theme.primaryColor};`}
          ${theme.onPrimaryColor && `--mwi-on-primary: ${theme.onPrimaryColor};`}
        }
      `}</style>

      <style>{`
        :root {
          ${theme.lightColor && `--mwi-light: ${theme.lightColor};`}
          ${theme.onLightColor && `--mwi-on-light: ${theme.onLightColor};`}
        }
      `}</style>

      <style>{`
        :root {
          ${theme.lightestColor && `--mwi-lightest: ${theme.lightestColor};`}
          ${theme.onLightestColor && `--mwi-on-lightest: ${theme.onLightestColor};`}
        }
      `}</style>

      <div>
        {isAuthenticated &&
          user?.onboarding_completed_at &&
          !user.patient_required &&
          location.pathname !== "/appointments/book" &&
          location.pathname !== "/prescriptions" && (
            <Navbar practiceLogoUrl={theme.logoUrl} practiceName={practiceName}>
              {
                <>
                  <Navbar.Item title="Home" to="/home" icon={<HomeIcon />} />
                  <Navbar.Item
                    title="Appointments"
                    to="/appointments"
                    icon={<CalendarIcon />}
                  />
                  <span className="hidden tablet:flex">
                    <Navbar.Item
                      title="Request prescription"
                      to="/prescription-request"
                      icon={<PillIcon />}
                      onClick={() =>
                        gtmEvent({ name: "onDesktopStartPrescriptionRequest" })
                      }
                    />
                  </span>
                  <span className="tablet:hidden">
                    <Navbar.Action
                      to="/appointments/book"
                      icon={<PlusIcon />}
                      className="-mx-2"
                      onDisable={handleApptDisabled}
                      disabled={appointmentsBlocked}
                    />
                  </span>
                  <Navbar.Item title="Pets" to="/pets" icon={<PawIcon />} />
                  <Navbar.Item
                    title="Account"
                    to="/settings"
                    icon={<UserCircleIcon />}
                  />
                </>
              }
            </Navbar>
          )}

        <div
          className={classNames("flex justify-center min-h-screen", {
            "tablet:pl-[80px] desktop:pl-[230px]":
              isAuthenticated &&
              user?.onboarding_completed_at &&
              !user?.patient_required,
          })}
        >
          <div className="flex flex-col flex-grow w-full px-4 tablet:px-16 mx-auto max-w-[1400px]">
            {showNavbar && (
              <nav className="hidden w-full tablet:block">
                <div className="border-b border-pinball">
                  <div className="flex justify-end h-[72px]">
                    <div className="flex items-center">
                      {location.pathname !== "/home" &&
                        location.pathname !== "/prescription-request" &&
                        !appointmentsBlocked && (
                          <>
                            <Button type="link" to="/home" size="small">
                              Book now
                            </Button>

                            <div className="h-7 w-[1px] bg-pinball mx-8"></div>
                          </>
                        )}

                      <Dropdown
                        trigger={
                          <Avatar
                            name={`${user?.first_name ?? ""} ${user?.last_name ?? ""}`}
                            className="w-10 h-10"
                          />
                        }
                      >
                        <Dropdown.Item onClick={triggerLogout}>
                          Logout
                        </Dropdown.Item>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </nav>
            )}

            <main className="flex justify-center flex-grow w-full">
              <div
                className={classNames("flex flex-col w-full py-10", {
                  "mb-14 tablet:mb-0":
                    isAuthenticated &&
                    user?.onboarding_completed_at &&
                    !user.patient_required &&
                    location.pathname !== "/appointments/book" &&
                    location.pathname !== "/prescriptions",
                })}
              >
                {children}
              </div>
            </main>
          </div>
        </div>
      </div>

      <CookiePopup />

      <StatusModal
        danger
        graphic={<CalendarOffIcon />}
        title="Appointments Blocked"
        description="We're not accepting appointments at the moment. Please try again later."
        primaryLinkText="Back to dashboard"
        onClick={() => setOpenApptBlockedModal(false)}
        onClose={() => setOpenApptBlockedModal(false)}
        isOpen={openApptBlockedModal}
      />
    </>
  );
};

export default App;
