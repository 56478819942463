import classNames from "classnames";
import { Controller } from "react-hook-form";

type CheckboxProps = {
  name: string;
  control: any;
  label?: string;
  className?: string;
};

const Checkbox = ({ name, control, label, className }: CheckboxProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <div
            className={classNames("flex items-center gap-3", {
              "rounded hover:bg-grey-lightest py-2 px-[0.625rem]": label,
            })}
          >
            <input
              name={name}
              type="checkbox"
              checked={field.value}
              onChange={field.onChange}
              style={{
                backgroundImage:
                  'url("data:image/svg+xml,%3csvg viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27white%27 stroke-width=%272%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M5 13l4 4L19 7%27/%3e%3c/svg%3e")',
                backgroundSize: 0,
                backgroundRepeat: "no-repeat",
              }}
              className={classNames(
                "cursor-pointer",
                "transition border rounded-none appearance-none w-7 h-7 border-primary checked:bg-primary",
                "focus:outline-none focus:shadow-focus-medium focus:checked:shadow-focus-large",
                className,
              )}
            />
            {label && (
              <p className="text-sm tablet:text-lg tablet:leading-[1.3125rem]">
                {label}
              </p>
            )}
          </div>
        );
      }}
    />
  );
};

export default Checkbox;
