/* eslint-disable max-len */
import { isEqual } from "lodash";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import React, { createRef, useEffect, useState } from "react";
import { RootState } from "store";
import { setSelectedSlot } from "slices/booking";
import { AvailableMergedSlot } from "api/types/models/availability";
import { AvailableSlotOverview } from "@mwi/ui";

const AppointmentSlotsList = (): JSX.Element => {
  const dispatch = useDispatch();

  const scrollRef = createRef<HTMLUListElement>();

  const [isScrollTop, setIsScrollTop] = useState<boolean>(true);
  const [isScrollBottom, setIsScrollBottom] = useState<boolean>(false);

  const { selectedDay, selectedSlot } = useSelector((state: RootState) => ({
    selectedSlot: state.bookingFlow.formState.selected_slot,
    selectedDay: state.bookingFlow.formState.selected_day,
  }));

  const onScroll = () => {
    if (scrollRef.current?.scrollTop === 0) {
      setIsScrollTop(true);
    }

    if (scrollRef.current!.scrollTop > 0 && isScrollTop) {
      setIsScrollTop(false);
    }

    if (
      scrollRef.current!.scrollHeight - scrollRef.current!.scrollTop ===
      scrollRef.current!.clientHeight
    ) {
      setIsScrollBottom(true);
    }

    if (
      scrollRef.current!.scrollHeight - scrollRef.current!.scrollTop >
        scrollRef.current!.clientHeight &&
      isScrollBottom
    ) {
      setIsScrollBottom(false);
    }
  };

  const handleOnClick = (
    event: React.MouseEvent<HTMLElement>,
    slot: AvailableMergedSlot,
  ) => {
    scrollRef.current?.scrollTo({
      behavior: "smooth",
      top: event?.currentTarget.offsetTop - 115,
    });

    dispatch(setSelectedSlot(slot));
  };

  useEffect(() => {
    if (selectedDay) {
      scrollRef.current?.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    }
  }, [selectedDay]);

  return (
    <>
      <div
        className={classNames(
          "absolute inset-0 z-10 h-full pointer-events-none bg-gradient-to-b transition-all",
          {
            "from-transparent via-transparent to-white":
              isScrollTop && !isScrollBottom,
            "from-white via-transparent to-transparent":
              isScrollBottom && !isScrollTop,
            "from-white via-transparent to-white":
              !isScrollTop && !isScrollBottom,
          },
        )}
      ></div>

      <ul
        ref={scrollRef}
        onScroll={onScroll}
        className="absolute inset-0 grid grid-cols-2 gap-4 py-1 overflow-y-auto auto-rows-max"
      >
        {selectedDay.mergedSlots.map((mergedSlot) => (
          <li key={mergedSlot.id} className="px-1">
            <AvailableSlotOverview
              slot={mergedSlot}
              size="medium"
              isSelected={isEqual(mergedSlot, selectedSlot)}
              onClick={(event) => handleOnClick(event, mergedSlot)}
            />
          </li>
        ))}
      </ul>
    </>
  );
};

export default AppointmentSlotsList;
