import DefaultLoginImage1 from "assets/images/login-images/default-1.jpg";
import DefaultLoginImage2 from "assets/images/login-images/default-2.jpg";
import DefaultLoginImage3 from "assets/images/login-images/default-3.jpg";
import DefaultLoginImage4 from "assets/images/login-images/default-4.jpg";
import DefaultLoginImage5 from "assets/images/login-images/default-5.jpg";
import DefaultLoginImage6 from "assets/images/login-images/default-6.jpg";
import DefaultLoginImage7 from "assets/images/login-images/default-7.jpg";
import DefaultLoginImage8 from "assets/images/login-images/default-8.jpg";
import DefaultLoginImage9 from "assets/images/login-images/default-9.jpg";
import DefaultLoginImage10 from "assets/images/login-images/default-10.jpg";
import DefaultLoginImage11 from "assets/images/login-images/default-11.jpg";
import DefaultLoginImage12 from "assets/images/login-images/default-12.jpg";
import DefaultLoginImage13 from "assets/images/login-images/default-13.jpg";
import DefaultLoginImage14 from "assets/images/login-images/default-14.jpg";
import DefaultLoginImage15 from "assets/images/login-images/default-15.jpg";
import DefaultLoginImage16 from "assets/images/login-images/default-16.jpg";
import DefaultLoginImage17 from "assets/images/login-images/default-17.jpg";
import DefaultLoginImage18 from "assets/images/login-images/default-18.jpg";
import DefaultLoginImage19 from "assets/images/login-images/default-19.jpg";
import DefaultLoginImage20 from "assets/images/login-images/default-20.jpg";
import DefaultLoginImage21 from "assets/images/login-images/default-21.jpg";
import DefaultLoginImage22 from "assets/images/login-images/default-22.jpg";
import DefaultLoginImage23 from "assets/images/login-images/default-23.jpg";
import DefaultLoginImage24 from "assets/images/login-images/default-24.jpg";
import DefaultLoginImage25 from "assets/images/login-images/default-25.jpg";
import DefaultLoginImage26 from "assets/images/login-images/default-26.jpg";
import DefaultLoginImage27 from "assets/images/login-images/default-27.jpg";

const DefaultLoginImages = {
  DefaultLoginImage1,
  DefaultLoginImage2,
  DefaultLoginImage3,
  DefaultLoginImage4,
  DefaultLoginImage5,
  DefaultLoginImage6,
  DefaultLoginImage7,
  DefaultLoginImage8,
  DefaultLoginImage9,
  DefaultLoginImage10,
  DefaultLoginImage11,
  DefaultLoginImage12,
  DefaultLoginImage13,
  DefaultLoginImage14,
  DefaultLoginImage15,
  DefaultLoginImage16,
  DefaultLoginImage17,
  DefaultLoginImage18,
  DefaultLoginImage19,
  DefaultLoginImage20,
  DefaultLoginImage21,
  DefaultLoginImage22,
  DefaultLoginImage23,
  DefaultLoginImage24,
  DefaultLoginImage25,
  DefaultLoginImage26,
  DefaultLoginImage27,
};

export default DefaultLoginImages;
