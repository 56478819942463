import Status from "../../status";
import Modal from "../../modal";

type StatusModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  graphic: JSX.Element;
  danger?: boolean;
  title?: string;
  onClick?: () => void;
  description?: string;
  primaryLink?: string;
  primaryLinkText?: string;
  secondaryLink?: string;
  secondaryLinkText?: string;
};

const StatusModal = ({
  isOpen,
  onClose,
  graphic,
  danger = false,
  title,
  onClick,
  description,
  primaryLink,
  primaryLinkText,
  secondaryLink,
  secondaryLinkText,
}: StatusModalProps): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      className="flex flex-col flex-grow"
      onClose={onClose}
    >
      <Modal.Content className="flex flex-col flex-grow">
        <Status
          title={title}
          danger={danger}
          graphic={graphic}
          onClick={onClick}
          primaryLink={primaryLink}
          description={description}
          secondaryLink={secondaryLink}
          primaryLinkText={primaryLinkText}
          secondaryLinkText={secondaryLinkText}
        />
      </Modal.Content>
    </Modal>
  );
};

export default StatusModal;
