interface IPushEvent {
  name: string;
  options?: { [key: string]: string };
}

export const gtmEvent = (args: IPushEvent) => {
  window.dataLayer.push({
    event: args?.name,
    ...args?.options,
  });
};
