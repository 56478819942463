import { Link } from "react-router-dom";
import { useGetSitesQuery } from "api";
import { Avatar, MapPinIcon, DateTimeFormat, CalendarEventIcon } from "@mwi/ui";
import Appointment from "api/types/models/appointment";

type UpcomingAppointmentCardProps = {
  to: string;
  onClick?: () => void;
  appointment: Appointment;
};

const UpcomingAppointmentCard = ({
  to,
  onClick,
  appointment,
}: UpcomingAppointmentCardProps): JSX.Element => {
  const { data: sites } = useGetSitesQuery();

  const appointmentSite = sites?.find(
    (s) => s.id === appointment.location_uuid,
  );

  return (
    <Link to={to} onClick={onClick} className="w-full">
      <div className="w-full p-4 bg-white mobile:px-5 mobile:py-6 shadow-default focus:shadow-focus-large tablet:p-6">
        <div className="flex justify-between gap-x-2">
          <div className="flex-grow">
            <div className="mb-1 mobile:mb-4">
              {appointment.start_date && appointment.end_date && (
                <span className="mobile:text-lg leading-7 font-semibold tablet:text-[21px]">
                  <DateTimeFormat
                    date={appointment.start_date}
                    format={"hh.mm a"}
                  />
                  {" - "}
                  <DateTimeFormat
                    date={appointment.end_date}
                    format={"hh.mm a"}
                  />
                </span>
              )}
            </div>

            <div className="flex flex-col gap-y-3 tablet:gap-y-[14px]">
              <div className="flex items-center gap-2 text-left">
                <div className="self-start flex-shrink-0 w-4 h-4 mobile:w-6 mobile:h-6">
                  <span className="sr-only">Appointment start date</span>
                  <CalendarEventIcon aria-hidden className="text-black" />
                </div>
                <span className="block text-base tablet:leading-[17px]">
                  <DateTimeFormat
                    date={appointment.start_date}
                    format={"dd LLLL yyyy"}
                  />
                </span>
              </div>
              {appointmentSite?.address_1 && appointmentSite?.county && (
                <div className="flex items-center gap-2 text-left">
                  <div className="self-start flex-shrink-0 w-4 h-4 mobile:w-6 mobile:h-6">
                    <span className="sr-only">Appointment vet address</span>
                    <MapPinIcon aria-hidden className="text-black" />
                  </div>
                  <span className="block text-base tablet:leading-[17px]">
                    {`${appointmentSite?.address_1}, ${appointmentSite?.county}`}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="flex-shrink-0">
            <Avatar
              name={appointment.patient_name}
              url={appointment.patient_profile_image}
              className="w-[1.875rem] h-[1.875rem] mobile:w-9 mobile:h-9"
              isPatient
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default UpcomingAppointmentCard;
