import { PasswordStrength, Button, FormGroup, Input, Label } from "@mwi/ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { login } from "slices/auth";
import { reset } from "slices/registration";
import { useSetPasswordMutation } from "api";
import useErrorHandler from "hooks/useErrorHandler";
import { useNavigate } from "react-router-dom";
import { setPractice } from "slices/practice";
import { gtmEvent } from "helpers/gtm";

type Inputs = {
  password: string;
  password_confirmation: string;
};

const RegistrationSetPassword = () => {
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<Inputs>();

  const [setPassword, { data: setPasswordResult, isLoading, error }] =
    useSetPasswordMutation();

  useEffect(() => {
    if (error) {
      errorHandler(error, setError);
    }
  }, [error]);

  useEffect(() => {
    if (setPasswordResult) {
      dispatch(
        login({
          user: setPasswordResult.user,
          registered_site: setPasswordResult.registered_location,
        }),
      );
      dispatch(setPractice(setPasswordResult.practice));
      dispatch(reset());
      if (!setPasswordResult.user.patient_required) {
        navigate("/pets");
      }
    }
  }, [setPasswordResult]);

  const submitForm = (data: Inputs) => {
    setPassword(data);
  };

  useEffect(() => gtmEvent({ name: "onRegFlowSetPasswordStepPageView" }), []);

  return (
    <>
      <h1 className="text-4xl font-bold tablet:text-6xl">Set your password</h1>

      <p className="mt-6 tablet:mt-8 tablet:text-lg">
        Set a memorable password, not your pets name!
      </p>

      <form
        className="flex flex-col flex-grow mt-8 tablet:mt-12"
        onSubmit={handleSubmit(submitForm)}
      >
        <FormGroup>
          <Label>Password</Label>
          <Input type="password" {...register("password")} autoComplete="off" />
          <PasswordStrength
            className="mt-2"
            password={watch("password")}
            error={errors?.password?.message}
          />
        </FormGroup>

        <FormGroup
          error={
            errors?.password_confirmation?.message ??
            (watch("password") !== "" &&
            watch("password_confirmation") !== "" &&
            watch("password") !== watch("password_confirmation")
              ? "Password Doesn't Match"
              : undefined)
          }
          success={
            watch("password") &&
            watch("password") === watch("password_confirmation")
              ? "Password Matches"
              : undefined
          }
        >
          <Label>Confirm Password</Label>
          <Input
            type="password"
            {...register("password_confirmation")}
            autoComplete="off"
          />
        </FormGroup>

        <div className="mt-auto tablet:mt-12 tablet:max-w-xs">
          <Button type="submit" block loading={isLoading}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default RegistrationSetPassword;
