import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import StartPage from "./pages";
import LoginPage from "./pages/login";
import HomePage from "./pages/home";
import ProtectedRoute from "./ProtectedRoute";
import AppointmentsPage from "./pages/appointments";
import PetsPage from "./pages/pets";
import RegisterPage from "./pages/register";
import BookAppointmentPage from "./pages/booking";
import ForgotPasswordPage from "pages/password/forgot";
import ResetPasswordPage from "pages/password/reset";
import ChangePasswordPage from "pages/settings/sections/change-password";
import SettingsPage from "pages/settings";
import SettingsAccount from "pages/settings/sections/account";
import SettingsPractice from "pages/settings/sections/practice";
import TermsPage from "pages/policies/terms";
import PrivacyPage from "pages/policies/privacy";
import CookiePage from "pages/policies/cookie";
import { RequestPrescriptionsPage } from "features/prescription";

const Router = () => {
  return (
    <BrowserRouter>
      <App>
        <Routes>
          <Route path="/" element={<StartPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot" element={<ForgotPasswordPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />

          <Route path="/terms" element={<TermsPage />} />
          <Route path="/cookie" element={<CookiePage />} />
          <Route path="/privacy" element={<PrivacyPage />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/home" element={<HomePage />} />

            <Route path="/prescription-request" element={<HomePage />} />

            <Route path="/prescriptions">
              <Route path="" element={<RequestPrescriptionsPage />} />
            </Route>

            <Route path="/appointments">
              <Route path="" element={<AppointmentsPage />} />
              <Route path=":appointmentId" element={<AppointmentsPage />} />
              <Route path="book" element={<BookAppointmentPage />} />
            </Route>

            <Route path="/pets">
              <Route path="" element={<PetsPage />} />
              <Route path=":patientId" element={<PetsPage />} />
            </Route>

            <Route path="/settings" element={<SettingsPage />}>
              <Route path="" element={<SettingsPage />} />
              <Route path="account" element={<SettingsAccount />} />
              <Route
                path="account/change-password"
                element={<ChangePasswordPage />}
              />
              <Route path="practice" element={<SettingsPractice />} />
            </Route>
          </Route>
        </Routes>
      </App>
    </BrowserRouter>
  );
};

export default Router;
