import {
  ClipboardCheckIcon,
  ClipboardXIcon,
  GraphicBackground,
  Button,
} from "@mwi/ui";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { resetPrescriptionState } from "slices/prescription";

type PrescriptionStatusViewProps = {
  onClose: () => void;
};

export const PrescriptionStatusView = ({
  onClose,
}: PrescriptionStatusViewProps): JSX.Element => {
  const dispatch = useDispatch();

  const { status, user } = useSelector((state: RootState) => ({
    status: state.prescription.formState.status,
    user: state.auth.user,
  }));

  const closeModal = () => {
    onClose();
    dispatch(resetPrescriptionState());
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="relative w-[240px] h-[193px] flex items-center justify-center mb-10">
        <GraphicBackground
          className={classNames("text-lightest", {
            "!text-error-lightest": status === "error",
          })}
        />

        <div className="absolute w-[103px] h-[103px]">
          {status === "complete" ? (
            <ClipboardCheckIcon className="text-primary" />
          ) : (
            <ClipboardXIcon className="text-error" />
          )}
        </div>
      </div>

      <div className="mb-6">
        <h1 className="text-4xl font-bold leading-[43px] text-center">
          {status === "complete"
            ? "Your prescription has been requested."
            : "We could not confirm your prescription request."}
        </h1>
      </div>

      <div className="mb-16 max-w-[500px] text-center">
        <p className="text-base mobile:text-lg">
          {status === "complete" ? (
            <>
              We sent a confirmation email to <strong>{user?.email}</strong>
            </>
          ) : (
            <>
              Unfortunately we were not able to confirm your prescription
              request. Please contact the practice.
            </>
          )}
        </p>
      </div>

      <div className="flex flex-col items-center w-full gap-6 mt-auto">
        <Button size="medium" onClick={() => closeModal()}>
          Go to dashboard
        </Button>
      </div>
    </div>
  );
};
