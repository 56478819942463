import InlineLink from "./../../navigation/inline_link";

const CookieTemplate = (): JSX.Element => {
  return (
    <div className="text-lg">
      <div className="mb-4">
        <span>Last updated: 1st August 2022</span>
      </div>
      <p>
        This Cookie and Tracking Technologies Policy (this “Cookie Policy”) sets
        forth the cookie and tracking policies and practices of
        AmerisourceBergen Corporation (“AmerisourceBergen”) and its
        subsidiaries, affiliates, service providers and advertising partners as
        they relate to your use and interaction with AmerisourceBergen.com or
        its subsidiaries’ or affiliates’ web sites (collectively, the “Web
        Site”), which allow us to distinguish you from other users of the Web
        Site. Our use of cookies and tracking technologies on the Web Site
        allows us to (i) provide you a better experience while you browse the
        Web Site, (ii) improve the Web Site, and (iii) advertise products or
        services in compliance with our Privacy Statement.
      </p>
      <br />
      <p>
        By continuing to browse the Web Site after the notification banner or
        message appears on the Web Site, and any subsequent times thereafter,
        you are expressly agreeing to this Cookie Policy and our use of cookies
        and tracking technologies for the purposes described in this Cookie
        Policy. See the “Managing Cookies & Opting Out” section below to manage
        your cookie preferences.
      </p>
      <br />
      <h3 className="mb-4 text-xl font-semibold">
        What Are Cookies and Tracking Technologies?
      </h3>
      <span className="italic font-normal">Cookies</span>
      <p>
        A cookie is a small file of letters and numbers sent by a website to
        your computer or mobile device, where it is stored by your browser if
        you agree to allow it. Cookies contain information that enables a
        website to recognize you when you open, close, and act within each
        session on that website. Cookies may be sent by the website that you are
        visiting (“first-party cookies”) or by another organization that
        provides services to that website, such as an advertising network or
        analytics company (“third-party cookies”).
      </p>
      <br />
      <span className="italic font-normal">Web Beacons</span>
      <p>
        Web beacons are small elements of a website, email, or other electronic
        media that collect certain device and network access information when
        they are accessed. We may use web beacons to deliver or communicate with
        cookies, to count users who have visited a web page, or to understand
        usage patterns or other third-party functionality. We also may include
        web beacons in emails to learn if messages have been opened, acted on,
        or forwarded, and to help us or our third-party service providers better
        analyze and improve our services and target advertising based on your
        browsing behavior and interests.
      </p>
      <br />
      <span className="italic font-normal">Other Technologies</span>
      <p>
        From time to time, we may use other technologies or techniques, such as
        cross-device tracking, to provide better service to you. “Cross-device
        tracking” associates multiple devices with the same consumer and links a
        consumer’s activity across his or her devices (e.g., smartphones,
        tablets, personal computers, and other connected devices).
      </p>
      <br />
      <h3 className="text-xl font-semibold">Our Use of Cookies</h3>
      <span>The cookies used on the Web Site are categorized as follows:</span>
      <br />
      <br />
      <span className="italic font-normal">Strictly Necessary Cookies</span>
      <p>
        These are cookies that are required for the operation of the Web Site.
        They include, for example, cookies that enable you to log in to secure
        areas of the Web Site, use a shopping cart or make use of e-billing
        services. These cookies do not gather any information about you that
        could be used for marketing or for remembering what sites you have
        visited on the Internet. We may use “strictly necessary” cookies to:
      </p>
      <br />
      <ul className="pl-3 space-y-4 list-disc">
        <li>
          <span>
            Make sure you connect to the right service on the Web Site when we
            make any changes to the way the Web Site works;
          </span>
        </li>
        <li>
          <span>Identify you as being logged in to the Web Site; and</span>
        </li>
        <li>
          <span>
            Remember information you have entered when you navigate to different
            pages on the Web Site during a browser session.
          </span>
        </li>
      </ul>
      <br />
      <p>
        If you block these cookies, there is no guarantee that you can use the
        Web Site or that the security on the Web Site will perform as intended
        during your visit.
      </p>
      <br />
      <span className="italic font-normal">Analytical/Performance Cookies</span>
      <p>
        These are cookies that allow us to recognize and count the number of
        visitors and to see how visitors move around the Web Site when they are
        using it. This helps us to improve the way the Web Site works, for
        example, by ensuring that users are finding what they are looking for
        easily. We may use “analytical/performance” cookies for:
      </p>
      <br />
      <ul className="pl-3 space-y-4 list-disc">
        <li>
          <span>
            Web analytics: To provide statistics on how the Web Site is used;
          </span>
        </li>
        <li>
          <span>
            Error management: To help us improve and maintain the Web Site by
            measuring any errors that occur;
          </span>
        </li>
        <li>
          <span>
            Affiliate tracking: To provide feedback, including but not limited
            to details of any products or services purchased, to partners about
            our visitors who also visited their website; and
          </span>
        </li>
        <li>
          <span>
            Testing designs: To test different designs of the Web Site.
          </span>
        </li>
      </ul>
      <br />
      <p>
        Some of these cookies may be managed by third parties and you should
        refer to the third parties’ own website privacy notifications and
        policies for further information.
      </p>
      <br />
      <span className="italic font-normal">Functionality Cookies</span>
      <p>
        These are used to recognize you when you return to the Web Site. This
        enables us to personalize our content for you, greet you by name and
        remember your preferences (e.g., your choice of language or region). We
        may use “functionality” cookies to:
      </p>
      <br />
      <ul className="pl-3 space-y-4 list-disc">
        <li>
          <span>
            Remember settings you have selected, such as layout, text size,
            preferences and colors; and
          </span>
        </li>
        <li>
          <span>Show when you are logged into the Web Site.</span>
        </li>
      </ul>
      <br />
      <p>
        If you block these cookies there is no guarantee that you can use the
        Web Site or that the security on the Web Site will perform as intended
        during your visit. Some of these cookies may be managed by third
        parties, and you should refer to the third parties’ own website privacy
        notifications and policies for further information.
      </p>
      <br />
      <span className="italic font-normal">Targeting Cookies</span>
      <p>
        These cookies record your visit to the Web Site, the pages you have
        visited and the links you have followed. We will use this information to
        make the Web Site and the advertising displayed on it more relevant to
        your interests. We also may share this information with third parties
        for this purpose. We and our third-party service providers use
        “targeting” cookies to:
      </p>
      <br />
      <ul className="pl-3 space-y-4 list-disc">
        <li>
          <span>
            Link to social networks, like Facebook, that may subsequently use
            information about your visit to the Web Site in order to provide
            advertising or other offers that may be of interest to you when you
            visit other websites; and
          </span>
        </li>
        <li>
          <span>
            Provide third parties with information on your visit so that they
            can present you with advertisements or other offers that may be of
            interest to you..
          </span>
        </li>
      </ul>
      <br />
      <span className="italic font-normal">
        Managing Cookies and Opting Out
      </span>
      <p>
        Most browsers automatically accept cookies. You can choose to decline
        cookies using the methods described below. You also can disable the
        ability of web beacons to store cookie information by declining cookies.
      </p>
      <br />
      <span className="italic font-normal">
        Opting Out of Advertising Cookies
      </span>
      <p>
        There are three (3) main associations that represent the advertising
        networks allowed on the Web Site. You can visit these associations’
        websites to opt out of all cookies served by their members (see below).
        These independent websites are not sponsored by or affiliated with
        AmerisourceBergen.
      </p>
      <br />
      <ul className="flex flex-col gap-y-4">
        <li>
          <InlineLink
            to="https://www.networkadvertising.org/choices"
            target="_blank"
          >
            Network Advertising Initiative (NAI)
          </InlineLink>
        </li>
        <li>
          <InlineLink to="https://www.aboutads.info/choices" target="_blank">
            Digital Advertising Alliance (DAA)
          </InlineLink>
        </li>
        <li>
          <InlineLink to="https://www.youronlinechoices.com" target="_blank">
            European Digital Advertising Alliance (EDDA)
          </InlineLink>
        </li>
      </ul>
      <br />
      <p>
        When opting out, a cookie will be set to let advertisers know not to use
        your data for targeted advertising purposes. You will still see
        advertisements but they will not be tailored to your interests.
      </p>
      <br />
      <span className="italic font-normal">
        Managing Cookies on Your Device
      </span>
      <p>
        You can block cookies by activating the setting on your browser that
        allows you to refuse the setting of all or some cookies. However, if you
        use your browser settings to block all cookies (including strictly
        necessary cookies) you may not be able to use all of the features and
        functionality of the Web Site. In the following list you will find a
        summary of links that provide detailed information on the deactivation
        of cookies in commonly used browsers:
      </p>
      <br />
      <ul className="flex flex-col pl-4 list-disc gap-y-4">
        <li>
          <InlineLink
            to="https://support.google.com/chrome/answer/95647?hl=en"
            target="_blank"
          >
            Cookie settings in Chrome™
          </InlineLink>
        </li>
        <li>
          <InlineLink
            to="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
            target="_blank"
          >
            Cookie settings in Firefox®
          </InlineLink>
        </li>
        <li>
          <InlineLink
            to="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
            target="_blank"
          >
            Cookie settings in Internet Explorer®
          </InlineLink>
        </li>
        <li>
          <InlineLink
            to="https://support.apple.com/en-gb/HT201265"
            target="_blank"
          >
            Cookie settings in Safari®
          </InlineLink>
        </li>
      </ul>
      <br />
      <p>
        Except for strictly necessary cookies (which have a longer expiration
        date and, in some cases, do not expire), all cookies will expire at
        various times.
      </p>
      <br />
      <span className="font-semibold">Privacy</span>
      <p>
        AmerisourceBergen is committed to using cookies and tracking
        technologies fairly and in accordance with applicable privacy rights and
        its Privacy Statement, which includes, without limitation, the
        transferring of any data to AmerisourceBergen affiliates or subsidiaries
        located in different countries.{" "}
        <InlineLink
          to="https://www.mwiah.co.uk/global-privacy-statement"
          target="_blank"
        >
          View the AmerisourceBergen Privacy Statement.
        </InlineLink>
      </p>
      <br />
      <span className="font-semibold">Contact Us</span>
      <p>
        If you have questions or concerns regarding our use of cookies and other
        tracking technologies, please contact AmerisourceBergen directly as
        follows:
      </p>
      <br />
      <p className="flex flex-col justify-start">
        <span>AmerisourceBergen Corporation</span>
        <span>Corporate Headquarters</span>
        <span>1 West First Avenue</span>
        <span>Conshohocken, PA 19428</span>
        <span>Phone: 1-800-829-3132</span>
        <span>E-mail: info@amerisourcebergen.com </span>
      </p>
      <br />
      <span className="font-semibold">
        Modifications and Updates to Privacy Statement
      </span>
      <p>
        This Cookie Policy becomes effective on December 1, 2021.
        AmerisourceBergen may update and modify this Cookie Policy at any time
        and from time to time, and such updates and modifications will be
        effective immediately upon posting the updated/modified Cookie Policy.
        You agree to review this Cookie Policy periodically to be aware of such
        updates and modifications and your continued access or use of the Web
        Site after any updates and modifications will be deemed your conclusive
        acceptance of the updated/modified Cookie Policy.
      </p>
      <br />
      <p>© Copyright AmerisourceBergen Corporation – November 2021</p>
    </div>
  );
};

export default CookieTemplate;
