import Item from "../../navigation/segmented_control/components/Item";

type SegmentedControlProps = {
  children: JSX.Element | JSX.Element[];
};

const SegmentedControl = ({ children }: SegmentedControlProps): JSX.Element => {
  return (
    <div className="w-full rounded-full bg-grey-lightest">
      <div className="p-2">
        <div className="flex justify-between gap-[10px]">{children}</div>
      </div>
    </div>
  );
};

SegmentedControl.Item = Item;

export default SegmentedControl;
