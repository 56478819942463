import dataApi from "api";
import Prescription from "api/types/models/prescription";
import {
  CreatePrescriptionsRequest,
  GetPrescriptionsRequest,
  VerifyPatientsRequest,
  VerifyPrescriptionsRequest,
} from "./types";

const prescriptionBasePath = "/prescription-requests";

const prescriptionEndpoints = dataApi.injectEndpoints({
  endpoints: (builder) => ({
    verifyEnabled: builder.mutation<void, void>({
      query: () => ({
        url: `${prescriptionBasePath}/verify/enabled`,
        method: "POST",
      }),
    }),

    verifyPatients: builder.mutation<void, VerifyPatientsRequest>({
      query: (body) => ({
        url: `${prescriptionBasePath}/verify/patients`,
        method: "POST",
        body: {
          patient_uuids: body.patients,
        },
      }),
    }),

    verifyPrescriptions: builder.mutation<void, VerifyPrescriptionsRequest>({
      query: (body) => ({
        url: `${prescriptionBasePath}/verify/prescriptions`,
        method: "POST",
        body,
      }),
    }),

    createPrescriptions: builder.mutation<void, CreatePrescriptionsRequest>({
      query: (body) => ({
        url: prescriptionBasePath,
        method: "POST",
        body,
      }),
    }),

    getPrescriptions: builder.query<Prescription[], GetPrescriptionsRequest>({
      query: (params) => ({
        url: `${prescriptionBasePath}/prescriptions`,
        params: {
          patient_uuid: params.patient_uuid,
          ...(params.location_uuid && { location_uuid: params.location_uuid }),
        },
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useVerifyPatientsMutation,
  useGetPrescriptionsQuery,
  useVerifyPrescriptionsMutation,
  useCreatePrescriptionsMutation,
  useVerifyEnabledMutation,
} = prescriptionEndpoints;
