import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";
import Site from "api/types/models/site";
import { QueryTags } from "api/types/QueryTags";

const siteEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    QueryTags,
    "data"
  >,
) => ({
  getSite: builder.query<Site, string>({
    query: (id) => `/locations/${id}`,
  }),

  getSites: builder.query<Site[], void>({
    query: () => "/locations",
  }),
});

export default siteEndpoints;
