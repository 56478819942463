import { DateTime } from "luxon";
import { RootState } from "store";
import { useMemo } from "react";
import { useNotifier } from "react-headless-notifier";
import DayPicker, { Modifier } from "react-day-picker";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, NotifierNotification } from "@mwi/ui";
import { AvailableDay } from "api/types/models/availability";
import { setAvailabilityDateRange, setSelectedDay } from "slices/booking";

type PropTypes = {
  availability: AvailableDay[];
  error?: string;
};

const CalendarView = ({ availability, error }: PropTypes): JSX.Element => {
  const dispatch = useDispatch();
  const { notify } = useNotifier();

  const { selectedDay, availabilityDateRange } = useSelector(
    (state: RootState) => ({
      selectedDay: state.bookingFlow.formState.selected_day,
      availabilityDateRange:
        state.bookingFlow.formState.availability_date_range,
    }),
  );

  const modifiers = useMemo(() => {
    if (error) {
      return [{ before: new Date(), after: new Date() }, new Date()];
    }

    const unavailableDays = availability?.filter(
      (a) => a.mergedSlots.length === 0,
    );

    const disabledDays: Modifier[] = [{ before: new Date() }];
    if (unavailableDays) {
      unavailableDays.forEach((day) => {
        disabledDays.push(DateTime.fromISO(day.date).toJSDate());
      });
    }

    return disabledDays;
  }, [availability, error]);

  const handleMonthChange = (month: Date) => {
    const currentStartDate = DateTime.fromISO(availabilityDateRange.date_start);

    if (DateTime.fromJSDate(month) >= currentStartDate) {
      const nextMonth = currentStartDate.plus({ month: 1 });

      dispatch(
        setAvailabilityDateRange({
          date_start: nextMonth.hasSame(DateTime.now(), "month")
            ? DateTime.now().toISO()!
            : nextMonth.startOf("month").toISO()!,
          date_end: nextMonth.endOf("month").toISO()!,
        }),
      );
    } else {
      const prevMonth = currentStartDate.minus({ month: 1 });

      dispatch(
        setAvailabilityDateRange({
          date_start:
            prevMonth < DateTime.now()
              ? DateTime.now().toISO()!
              : prevMonth.startOf("month").toISO()!,
          date_end:
            prevMonth < DateTime.now()
              ? DateTime.now().endOf("month").toISO()!
              : prevMonth.endOf("month").toISO()!,
        }),
      );
    }
  };

  const handleDayChange = (date: DateTime) => {
    const availableDay = availability?.find((a) =>
      DateTime.fromISO(a.date).hasSame(date, "day"),
    );

    // prevent selecting disabled days
    if (!availableDay || availableDay?.mergedSlots?.length === 0) {
      return;
    }

    if (!availableDay) {
      notify(
        <NotifierNotification
          type="error"
          title="No availability"
          message="We're unable to show availability on this date, please try a different date."
        />,
      );
      return;
    }

    dispatch(setSelectedDay(availableDay));
  };

  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-[350px]">
        <DayPicker
          disabledDays={modifiers}
          onMonthChange={handleMonthChange}
          initialMonth={
            DateTime.fromISO(availabilityDateRange.date_end).isValid
              ? DateTime.fromISO(availabilityDateRange.date_end).toJSDate()
              : DateTime.now().toJSDate()
          }
          onDayClick={(date) => handleDayChange(DateTime.fromJSDate(date))}
          selectedDays={
            DateTime.fromISO(selectedDay.date).isValid
              ? DateTime.fromISO(selectedDay.date).toJSDate()
              : DateTime.now().toJSDate()
          }
        />

        {error && (
          <div className="mt-4">
            <ErrorMessage message={error} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarView;
