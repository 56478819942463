import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNotifier } from "react-headless-notifier";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "store";
import { Modal, ReadOnly, Tabs, NotifierNotification } from "@mwi/ui";
import PatientProfileImage from "components/patient/profile_image";
import { useGetPatientQuery } from "api";

type ViewPatientModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ViewPatientModal = ({
  isOpen,
  onClose,
}: ViewPatientModalProps): JSX.Element => {
  const navigate = useNavigate();
  const { notify } = useNotifier();
  const { patientId } = useParams();

  const { registeredSite } = useSelector((state: RootState) => ({
    registeredSite: state.auth.registered_site,
  }));

  const [selectedTab, setSelectedTab] = useState<string>("pet_details");

  const {
    data: patient,
    isError: isPatientError,
    isFetching: fetchingPatient,
  } = useGetPatientQuery(patientId!, {
    skip: !patientId || patientId === "add",
  });

  useEffect(() => {
    if (isPatientError) {
      navigate("/pets");
      notify(
        <NotifierNotification
          type="error"
          title="Cannot view pet"
          message="We're unable show your pet at this time, please try again later."
        />,
      );
    }
  }, [isPatientError]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      loading={fetchingPatient}
      className="!max-w-[855px]"
      afterLeave={() => setSelectedTab("pet_details")}
    >
      <Modal.Content className="h-full">
        {patient && (
          <>
            <div className="mb-[60px] tablet:mb-8">
              <div className="flex flex-wrap items-center gap-4">
                <div className="flex-shrink-0 w-[60px] h-[60px] tablet:w-[72px] tablet:h-[72px]">
                  <PatientProfileImage patient={patient} canUpdate />
                </div>
                <h2 className="font-bold text-[28px] leading-[33px]">
                  {patient.name}
                </h2>
              </div>
            </div>

            <div className="mb-8">
              <Tabs>
                <Tabs.Item
                  title="Pet Details"
                  isActive={selectedTab === "pet_details"}
                  onClick={() => setSelectedTab("pet_details")}
                />
                <Tabs.Item
                  title="Records"
                  isActive={selectedTab === "records"}
                  onClick={() => setSelectedTab("records")}
                />
              </Tabs>
            </div>

            <div className="w-full tablet:max-w-md">
              {selectedTab === "pet_details" && (
                <>
                  {patient.name && (
                    <ReadOnly
                      heading="Pet Name"
                      value={patient.name}
                      hasDivider
                    />
                  )}

                  {patient.date_of_birth && (
                    <ReadOnly
                      heading="Date of Birth"
                      value={patient.date_of_birth}
                      dateFormat="dd/LL/yy"
                      hasDivider
                    />
                  )}

                  {patient.species_name && (
                    <ReadOnly
                      heading="Species"
                      value={patient.species_name}
                      hasDivider
                    />
                  )}

                  {patient.breed_name && (
                    <ReadOnly
                      heading="Breed"
                      value={patient.breed_name}
                      hasDivider
                    />
                  )}

                  {patient.sex && (
                    <ReadOnly heading="Sex" value={patient.sex} hasDivider />
                  )}

                  {patient.colour_name && (
                    <ReadOnly
                      heading="Colour"
                      value={patient.colour_name}
                      hasDivider
                    />
                  )}

                  {patient.microchip && (
                    <ReadOnly
                      heading="Microchip number"
                      value={patient.microchip}
                      hasDivider
                    />
                  )}
                </>
              )}

              {selectedTab === "records" && (
                <div className="mb-[270px]">
                  {patient.last_appointment_date && (
                    <ReadOnly
                      heading="Last appointment"
                      value={patient.last_appointment_date}
                      dateFormat="dd/LL/yy"
                      hasDivider
                    />
                  )}

                  {patient.last_appointment_reason && (
                    <ReadOnly
                      heading="Last appointment reason"
                      value={patient.last_appointment_reason}
                      hasDivider
                    />
                  )}

                  {registeredSite &&
                    registeredSite?.name &&
                    registeredSite?.address && (
                      <ReadOnly
                        heading="Vet practice location"
                        value={`${registeredSite.name}${registeredSite.address?.city ? `, ${registeredSite.address?.city}` : ""}`}
                        hasDivider
                      />
                    )}
                </div>
              )}
            </div>
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ViewPatientModal;
