import { DateTime } from "luxon";
import classNames from "classnames";
import { Fragment, useState } from "react";
import { Controller } from "react-hook-form";
import DayPicker, { Modifier } from "react-day-picker";
import { Popover } from "@headlessui/react";

import ChevronDownIcon from "../../../icons/ChevronDownIcon";
import CalendarEventIcon from "../../../icons/CalendarEventIcon";
import ChevronsLeftIcon from "../../../icons/ChevronsLeftIcon";
import ChevronsRightIcon from "../../../icons/ChevronsRightIcon";
import Loader from "../../indicators/loader";

type DatePickerProps = {
  name: string;
  control: any;
  disabled?: boolean;
  defaultLabel: string;
  disabledDays?: Modifier | Modifier[];
  size?: "small" | "medium" | "large";
  buttonClassName?: string;
  isRelativeToSelf?: boolean;
  panelClassName?: string;
  loading?: boolean;
  onMonthChange?: (date: DateTime) => void;
};

const DatePicker = ({
  name,
  control,
  defaultLabel,
  disabledDays,
  disabled = false,
  size = "large",
  buttonClassName,
  isRelativeToSelf = true,
  panelClassName,
  loading = false,
  onMonthChange,
}: DatePickerProps) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [date, setDate] = useState<DateTime>(DateTime.now());
  const [initialDateSet, setInitialDateSet] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        if (
          !initialDateSet &&
          field.value &&
          field.value.ts !== date.toMillis()
        ) {
          setDate(DateTime.fromMillis(field.value.ts));
          setInitialDateSet(true);
        }

        const onDateChange = (selectedDate: Date) => {
          const luxonDate = DateTime.fromJSDate(selectedDate);
          let isValidDate = true;

          if (disabledDays && !Array.isArray(disabledDays)) {
            disabledDays = [disabledDays];
          }

          disabledDays?.forEach((period: any) => {
            if (
              period?.before &&
              luxonDate.startOf("day") <
                DateTime.fromJSDate(period?.before).startOf("day")
            ) {
              isValidDate = false;
            }

            if (
              period?.after &&
              luxonDate.startOf("day") >
                DateTime.fromJSDate(period?.after).startOf("day")
            ) {
              isValidDate = false;
            }

            if (
              !period?.before &&
              !period?.after &&
              DateTime.fromJSDate(period)
                .startOf("day")
                .equals(luxonDate.startOf("day"))
            ) {
              isValidDate = false;
            }
          });

          if (isValidDate) {
            setDate(luxonDate);
            field.onChange(luxonDate);
          }
        };

        const handleYearChange = (period: "prev" | "next") => {
          let innerDateYear = date;

          if (innerDateYear && innerDateYear.isValid) {
            innerDateYear =
              period === "next"
                ? innerDateYear.plus({ year: 1 })
                : innerDateYear.minus({ year: 1 });

            setDate(innerDateYear);
          }
        };

        const handleMonthChange = (month: Date) => {
          let innerDateMonth = DateTime.fromJSDate(month);

          if (innerDateMonth && innerDateMonth?.isValid) {
            innerDateMonth.set({
              month: DateTime.fromJSDate(month).get("month"),
            });

            setDate(innerDateMonth);
            if (onMonthChange) {
              onMonthChange(innerDateMonth);
            }
          }
        };

        return (
          <Popover
            className={classNames("relative", { "!static": !isRelativeToSelf })}
          >
            {({ open, close }) => (
              <>
                <Popover.Button
                  disabled={disabled}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  className={classNames(
                    "w-full outline-none p-4",
                    "bg-white border border-primary shadow-default",
                    {
                      "border-light !shadow-focus-small": focused,
                      "border-black shadow-default bg-grey-light": disabled,

                      "p-4": size === "large",
                      "py-3 px-4": size === "medium",
                      "py-2 px-[0.625rem]": size === "small",
                    },
                    buttonClassName,
                  )}
                >
                  {/* Prefix and Label */}
                  <div className="flex items-center justify-between">
                    <div className="flex items-center text-left">
                      <div className="mr-[10px]">
                        <div className="flex flex-shrink-0 w-6 h-6">
                          <CalendarEventIcon
                            className={classNames("text-focus-grey", {
                              "text-light": focused,
                            })}
                          />
                        </div>
                      </div>
                      <div className="flex items-center w-full mr-[10px]">
                        <span
                          className={classNames("text-lg", {
                            "text-grey-dark": !field.value,
                            "text-black": field.value,
                          })}
                        >
                          {date && field.value
                            ? date.toFormat("dd/LL/yy")
                            : defaultLabel}
                        </span>
                      </div>
                    </div>

                    {/* Right Chevon */}
                    <div className="flex-shrink-0">
                      {loading ? (
                        <Loader />
                      ) : (
                        <ChevronDownIcon
                          className={classNames(
                            "transition-all transform w-4 h-4 text-black",
                            {
                              "-rotate-180": open,
                            },
                          )}
                        />
                      )}
                    </div>
                  </div>
                </Popover.Button>

                <Popover.Panel
                  className={classNames(
                    "absolute top-[58px] z-10 inset-x-0 max-w-sm",
                    panelClassName,
                  )}
                >
                  <div
                    className={classNames("bg-white border border-light p-4", {
                      "shadow-focus-small": focused,
                    })}
                  >
                    <div className="relative">
                      <div
                        tabIndex={0}
                        onClick={() => handleYearChange("prev")}
                        className="absolute -top-[1.5px] left-0 z-10 cursor-pointer"
                      >
                        <ChevronsLeftIcon className="w-5 h-5" />
                      </div>

                      <div
                        tabIndex={0}
                        onClick={() => handleYearChange("next")}
                        className="absolute right-0 z-10 cursor-pointer -top-[1.5px]"
                      >
                        <ChevronsRightIcon className="w-5 h-5" />
                      </div>
                      <DayPicker
                        month={date?.toJSDate()}
                        onMonthChange={handleMonthChange}
                        disabledDays={
                          loading || disabled
                            ? [
                                { before: new Date(), after: new Date() },
                                new Date(),
                              ]
                            : disabledDays
                        }
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        onDayClick={(date: Date) => {
                          if (loading || disabled) {
                            return;
                          }
                          onDateChange(date);
                          close();
                        }}
                        selectedDays={
                          DateTime.fromISO(field.value)?.isValid &&
                          DateTime.fromISO(field.value).hasSame(date, "year")
                            ? DateTime.fromISO(field.value).toJSDate()
                            : DateTime.fromISO("").toJSDate() // little hack to prevent showing selected days on diff years
                        }
                      />
                    </div>
                  </div>
                </Popover.Panel>
              </>
            )}
          </Popover>
        );
      }}
    />
  );
};

export default DatePicker;
