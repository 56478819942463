import { Fragment, useEffect } from "react";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setStep } from "slices/booking";
import {
  Button,
  MapPinIcon,
  CalendarEventIcon,
  PatientOverviewCard,
} from "@mwi/ui";
import {
  useCreateAppointmentsMutation,
  useGetPatientsQuery,
  useGetReasonsQuery,
  useGetSiteQuery,
} from "api";
import { gtmEvent } from "helpers/gtm";

const BookingFlowStep4 = (): JSX.Element => {
  const dispatch = useDispatch();

  const { registeredSite, selectedPatients, selectedSlot, vetSite } =
    useSelector((state: RootState) => ({
      registeredSite: state.auth.registered_site,
      selectedPatients: state.bookingFlow.formState.patients,
      vetSite: state.bookingFlow.formState.vet_site_uuid,
      selectedSlot: state.bookingFlow.formState.selected_slot,
    }));

  const [
    createAppt,
    { error: apptError, isLoading: creatingAppt, isSuccess: didCreateAppt },
  ] = useCreateAppointmentsMutation();

  const { data: patients } = useGetPatientsQuery();

  const { data: reasons } = useGetReasonsQuery({
    locationUuid: vetSite ? vetSite : registeredSite!.id,
  });

  const { data: site } = useGetSiteQuery(vetSite);

  const handleConfirmBooking = () => {
    createAppt({
      vet_site_uuid: vetSite,
      selected_slot: selectedSlot,
      patients: selectedPatients,
    });
  };

  useEffect(() => {
    if (didCreateAppt || apptError) {
      dispatch(
        setStep({
          step: "5",
          appointment_confirmed: didCreateAppt,
          error_message: (apptError as any)?.data?.message,
        }),
      );
    }
  }, [didCreateAppt, apptError]);

  const slotStartTime = DateTime.fromISO(selectedSlot.start_time);
  const slotEndTime = DateTime.fromISO(selectedSlot.end_time);

  useEffect(
    () => gtmEvent({ name: "onMobileApptFlowConfirmApptPageView" }),
    [],
  );

  return (
    <>
      <div className="mb-6">
        <h1 className="text-4xl font-bold leading-[43px]">
          Review your appointment
        </h1>
      </div>

      <div className="mb-6">
        <div className="flex flex-col gap-4">
          {patients &&
            selectedPatients &&
            selectedPatients.map((selectedPatient) => {
              const patient = patients.find(
                (p) => selectedPatient.patient_uuid === p.id,
              );
              const reason = reasons?.data?.find(
                (r) => r.id === selectedPatient.reason_uuid,
              );
              return patient ? (
                <PatientOverviewCard
                  key={patient.id}
                  patient={patient}
                  reason={reason?.translation ?? reason?.name}
                  comment={selectedPatient.comment}
                />
              ) : (
                <Fragment key={selectedPatient.patient_uuid} />
              );
            })}
        </div>
      </div>

      <div className="flex flex-col flex-grow">
        <div className="mb-4">
          <h3 className="text-base font-semibold">Details:</h3>
        </div>

        {site && site?.name && (
          <div className="mb-4">
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-white rounded-full">
                <div className="w-6 h-6">
                  <MapPinIcon className="text-primary" />
                </div>
              </div>
              <span className="text-base">
                {`${site.name}${site?.city ? `, ${site.city}` : ""}`}
              </span>
            </div>
          </div>
        )}

        {slotStartTime.isValid && slotEndTime.isValid && (
          <div className="mb-16">
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-white rounded-full">
                <div className="w-6 h-6">
                  <CalendarEventIcon className="text-primary" />
                </div>
              </div>
              <span className="text-base">
                {`${slotStartTime.toFormat("d LLLL yyyy, hh.mm a")} - ${slotEndTime.toFormat("hh.mm a")}`}
              </span>
            </div>
          </div>
        )}

        <div className="mt-auto">
          <Button
            block
            size="medium"
            disabled={!selectedSlot.id}
            loading={creatingAppt}
            onClick={handleConfirmBooking}
          >
            Confirm booking
          </Button>
        </div>
      </div>
    </>
  );
};

export default BookingFlowStep4;
