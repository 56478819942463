import { Transition } from "@headlessui/react";
import Loader from "../indicators/loader";
import { Fragment } from "react";
import TableBody from "../table/components/body";
import TableHead from "../table/components/head";
import TableItem from "../table/components/item";
import TableRow from "../table/components/row";

const Table = ({
  children,
  loading = false,
}: {
  children: JSX.Element | JSX.Element[];
  loading?: boolean;
}) => {
  return (
    <div className="relative w-full">
      <table className="w-full bg-white shadow-default">{children}</table>

      <Transition
        show={loading}
        as={Fragment}
        enterFrom="opacity-0"
        enterTo="opacity-1"
        leaveFrom="opacity-1"
        leaveTo="opacity-0"
      >
        <div className="absolute inset-0 flex items-center justify-center transition bg-white/80">
          <Loader />
        </div>
      </Transition>
    </div>
  );
};

Table.Head = TableHead;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Item = TableItem;

export default Table;
