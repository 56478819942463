import {
  Accordion,
  Button,
  ChevronDownIcon,
  FormGroup,
  FormInputLimit,
  InlineLink,
  Input,
  Label,
  Loader,
  PatientOverviewCard,
  PillIcon,
  PlusIcon,
  Select,
  SelectOption,
  TrashIcon,
} from "@mwi/ui";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetPatientQuery } from "api";
import {
  useGetPrescriptionsQuery,
  useVerifyPrescriptionsMutation,
} from "./../../../api";
import classNames from "classnames";
import { gtmEvent } from "helpers/gtm";
import useErrorHandler from "hooks/useErrorHandler";
import { cloneDeep } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setPatient, setPatientIndex, setStep } from "slices/prescription";
import { RootState } from "store";
import { SelectedPrescription } from "types/prescription";

type FieldValues = {
  prescriptions: SelectedPrescription[];
};

export const SelectPrescriptionsView = (): JSX.Element => {
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const { patientIndex, selectedPatients } = useSelector(
    (state: RootState) => ({
      patientIndex: state.prescription.patient_index,
      selectedPatients: state.prescription.formState.patients,
    }),
  );

  const currentPatient = useMemo(
    () => selectedPatients?.[patientIndex],
    [patientIndex],
  );

  useEffect(
    () => gtmEvent({ name: "prescriptionRequestSelectPrescriptionsPageView" }),
    [],
  );

  const { data: patient, isFetching: loadingPatient } = useGetPatientQuery(
    currentPatient?.patientUuid ?? skipToken,
  );

  const { data: prescriptions, isFetching: loadingPrescription } =
    useGetPrescriptionsQuery(
      {
        patient_uuid: currentPatient?.patientUuid,
      },
      { skip: !currentPatient?.patientUuid },
    );

  const [
    verifyPrescriptions,
    { reset: resetVerify, isLoading: verifyingPrescriptions },
  ] = useVerifyPrescriptionsMutation();

  const {
    reset,
    watch,
    control,
    register,
    setError,
    getValues,
    clearErrors,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      prescriptions: currentPatient.prescriptions,
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "prescriptions",
    control,
  });

  const handleCurrentPatient = async () => {
    const formState = getValues();

    formState.prescriptions = formState.prescriptions.map((prescription) => ({
      ...prescription,
      item_name:
        prescription.formulary_uuid === "new-item"
          ? prescription.item_name
          : (prescriptionOptions.find(
              (option) => option.id === prescription.formulary_uuid,
            )?.value ?? ""),
    }));

    dispatch(
      setPatient(
        cloneDeep({
          patientIdx: patientIndex,
          updatedPatient: {
            patientUuid: currentPatient.patientUuid,
            prescriptions: formState.prescriptions,
          },
        }),
      ),
    );

    clearErrors();

    await verifyPrescriptions({
      prescriptions: formState.prescriptions.map((prescription) => ({
        formulary_uuid:
          prescription.formulary_uuid !== "new-item"
            ? prescription.formulary_uuid
            : null,
        item_name:
          prescription.formulary_uuid === "new-item"
            ? prescription.item_name
            : null,
        notes: prescription.notes,
      })),
    })
      .unwrap()
      .then(() => {
        const isLastPatient = selectedPatients?.length === patientIndex + 1;
        if (isLastPatient) {
          dispatch(setStep({ step: "review" }));
          return;
        }

        const nextPatientIdx = patientIndex + 1;

        dispatch(setPatientIndex(nextPatientIdx));
        reset({
          prescriptions: selectedPatients[nextPatientIdx].prescriptions,
        });
      })
      .catch((err) => {
        errorHandler(err, setError);
        resetVerify();
      });
  };

  const prescriptionOptions = useMemo(() => {
    return [
      { id: "new-item", value: "Request New Item" },
      ...(prescriptions?.map((prescription) => ({
        id: prescription.id,
        value: prescription.description,
      })) ?? []),
    ];
  }, [prescriptions]);

  const handleAddPrescription = (resetIndexes = false) => {
    append({
      formulary_uuid: "",
      item_name: null,
      notes: null,
    });
    setOpenIndexes(resetIndexes ? [0] : [...openIndexes, fields.length]);
  };

  const handleRemovePrescription = (prescriptionIdx: number) => {
    remove(prescriptionIdx);
  };

  const toggleOpenIndex = (index: number) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  useEffect(() => {
    if (currentPatient) {
      reset({
        prescriptions: currentPatient.prescriptions,
      });
      if (!currentPatient.prescriptions.length) {
        // Wait for reset to finish, run on the next frame
        setTimeout(() => handleAddPrescription(true));
      }
    }
  }, [currentPatient]);

  if (loadingPatient || loadingPrescription) {
    return (
      <div className="grid min-h-full place-content-center tablet:h-[500px]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-grow px-1 space-y-6 tablet:space-y-10">
      {patient && (
        <div className="w-full mobile:max-w-lg">
          <PatientOverviewCard patient={patient} />
        </div>
      )}

      <div className="divide-y divide-element-grey">
        {fields?.map((_, prescriptionIdx) => {
          const formulary =
            prescriptionOptions?.find(
              (option) =>
                option?.id ===
                watch(`prescriptions.${prescriptionIdx}.formulary_uuid`),
            )?.value ?? null;

          return (
            <Accordion
              key={prescriptionIdx}
              isOpen={openIndexes.includes(prescriptionIdx)}
              className="flex flex-col gap-4 mt-2"
              trigger={
                <>
                  <div className="flex items-center py-4 cursor-pointer">
                    <div
                      className="flex items-center w-full min-w-0"
                      onClick={() => toggleOpenIndex(prescriptionIdx)}
                    >
                      <div className="grid self-start w-8 h-8 mr-4 bg-white border-2 rounded-full shadow-md border-primary place-content-center shrink-0">
                        <PillIcon className="w-5 h-5" />
                      </div>
                      <p className="pr-4 font-semibold truncate">
                        {watch(
                          `prescriptions.${prescriptionIdx}.formulary_uuid`,
                        ) ? (
                          <>
                            {formulary ?? "Request Repeat Item"}
                            {watch(
                              `prescriptions.${prescriptionIdx}.item_name`,
                            ) &&
                              ` (${watch(`prescriptions.${prescriptionIdx}.item_name`)})`}
                          </>
                        ) : (
                          "Select an item..."
                        )}
                      </p>
                    </div>
                    <div className="flex items-center">
                      {prescriptionIdx !== 0 && (
                        <div
                          className="mr-4"
                          onClick={() =>
                            handleRemovePrescription(prescriptionIdx)
                          }
                        >
                          <TrashIcon className="w-6 h-6 text-error" />
                        </div>
                      )}
                      <div onClick={() => toggleOpenIndex(prescriptionIdx)}>
                        <ChevronDownIcon
                          className={classNames("w-6 h-6 transition", {
                            "rotate-180": openIndexes.includes(prescriptionIdx),
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </>
              }
            >
              <FormGroup
                error={
                  errors?.prescriptions?.[prescriptionIdx]?.formulary_uuid
                    ?.message
                }
              >
                <Select
                  name={`prescriptions.${prescriptionIdx}.formulary_uuid`}
                  control={control}
                  placeholder="Select item required"
                  block
                >
                  {prescriptionOptions?.map((prescription) => (
                    <SelectOption key={prescription.id} value={prescription.id}>
                      {prescription.value}
                    </SelectOption>
                  ))}
                </Select>
              </FormGroup>

              {watch(`prescriptions.${prescriptionIdx}.formulary_uuid`) ===
                "new-item" && (
                <FormGroup
                  error={
                    errors?.prescriptions?.[prescriptionIdx]?.item_name?.message
                  }
                >
                  <Input
                    {...register(`prescriptions.${prescriptionIdx}.item_name`)}
                    maxChars={250}
                    placeHolder="Please enter the item you wish to request"
                  />
                  <div className="flex justify-end">
                    <FormInputLimit
                      value={
                        watch(`prescriptions.${prescriptionIdx}.item_name`)
                          ?.length ?? 0
                      }
                      limit={250}
                    />
                  </div>
                </FormGroup>
              )}

              <FormGroup
                error={errors?.prescriptions?.[prescriptionIdx]?.notes?.message}
              >
                <Label>Additional Notes (optional):</Label>
                <Input
                  {...register(`prescriptions.${prescriptionIdx}.notes`)}
                  maxChars={250}
                  placeHolder="Provide further information for your request"
                />
                <div className="flex justify-end">
                  <FormInputLimit
                    value={
                      watch(`prescriptions.${prescriptionIdx}.notes`)?.length ??
                      0
                    }
                    limit={250}
                  />
                </div>
              </FormGroup>
            </Accordion>
          );
        })}
      </div>

      <InlineLink
        onClick={() => handleAddPrescription()}
        className="flex items-center"
      >
        <PlusIcon className="w-5 h-5 mr-1" /> Add another item
      </InlineLink>

      <div className="pt-20 !mt-auto flex justify-end">
        <Button
          onClick={handleCurrentPatient}
          className="w-full tablet:w-min"
          loading={verifyingPrescriptions || loadingPrescription}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
