import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useNotifier } from "react-headless-notifier";
import {
  Modal,
  Status,
  Avatar,
  Button,
  MapPinIcon,
  BandageIcon,
  NotifierNotification,
  DateTimeFormat,
  CalendarEventIcon,
  CalendarCheckIcon,
  Loader,
  InlineLink,
  AddToCalendarModal,
  ConfirmationModal,
} from "@mwi/ui";
import CancelAppointmentSheet from "components/sheets/cancel-appointment";
import { useCancelAppointmentMutation, useGetAppointmentQuery } from "api";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { gtmEvent } from "helpers/gtm";

type ViewAppointmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ViewAppointmentModal = ({
  onClose,
  isOpen = false,
}: ViewAppointmentModalProps): JSX.Element => {
  const { appointmentId } = useParams();

  const { notify } = useNotifier();

  const { cancellationsBlocked, cancellationMessage } = useSelector(
    (state: RootState) => ({
      cancellationsBlocked: state.practice.cancellations_blocked,
      cancellationMessage: state.practice.cancellation_message,
    }),
  );

  const [cancelled, setCancelled] = useState(false);
  const [addToCalendarModalOpen, setAddToCalendarModalOpen] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);

  const { data: appointment, isLoading: loadingAppointment } =
    useGetAppointmentQuery(appointmentId!, {
      skip: !appointmentId,
    });

  const [
    cancelAppointment,
    { isSuccess: didCancel, isError, isLoading: isCancelling },
  ] = useCancelAppointmentMutation();

  const isUpcomingAppointment = useMemo(() => {
    if (appointment && appointment.start_date) {
      return DateTime.fromISO(appointment.start_date) > DateTime.now();
    }
  }, [appointment]);

  const tablet = window.innerWidth > 1024;

  useEffect(() => {
    setCancelled(false);
  }, [appointment]);

  useEffect(() => {
    if (isError) {
      notify(
        <NotifierNotification
          type="error"
          title="Cancellation Unsuccessful"
          message="Your appointment cannot be cancelled at the moment, please try again later."
        />,
      );
    }
  }, [isError]);

  useEffect(() => {
    if (didCancel) {
      gtmEvent({ name: "onCancelledAppointment" });
      setCancelled(true);
    }
  }, [didCancel]);

  const addressLine = useMemo(() => {
    return encodeURIComponent(
      [
        appointment?.site?.address_1,
        appointment?.site?.address_2,
        appointment?.site?.city,
        appointment?.site?.county,
        appointment?.site?.postcode,
      ]
        .filter((v) => v)
        .join(", "),
    );
  }, [appointment?.site]);

  const handleConfirm = () => {
    setOpenConfirmationModal(false);
    if (appointmentId) {
      cancelAppointment(appointmentId);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content className="flex flex-col">
        {cancelled ? (
          <Status
            title="Your appointment has been cancelled."
            description="Thank you for updating us!"
            primaryLink="/home"
            primaryLinkText="Go back to your dashboard"
            secondaryLink={tablet ? "/home" : "/appointments/book"}
            secondaryLinkText="Book another appointment"
            graphic={<CalendarCheckIcon />}
          />
        ) : (
          <div
            className={classNames("flex flex-col h-full", {
              "justify-center items-center": loadingAppointment,
            })}
          >
            {loadingAppointment && <Loader />}

            {!loadingAppointment && (
              <>
                <h1 className="text-2xl font-bold">
                  {isUpcomingAppointment
                    ? "Next appointment"
                    : "Past appointment"}
                </h1>

                {appointment && (
                  <>
                    <div className="flex flex-col flex-grow mt-12">
                      <div className="mb-4">
                        <div className="flex flex-col gap-10 tablet:gap-12">
                          <div className="flex gap-4 tablet:gap-6">
                            <div className="w-6 tablet:w-8">
                              <CalendarEventIcon className="w-full text-black" />
                            </div>

                            <div>
                              <div className="mb-1">
                                <div className="text-base font-semibold tablet:text-lg">
                                  <DateTimeFormat
                                    date={appointment.start_date!}
                                    format={"dd LLLL yyyy"}
                                  />
                                </div>
                              </div>

                              <div className="text-sm tablet:text-base text-welded-iron">
                                <DateTimeFormat
                                  date={appointment.start_date!}
                                  format="hh.mm a"
                                />
                                <span>&nbsp;-&nbsp;</span>
                                <DateTimeFormat
                                  date={appointment.end_date!}
                                  format="hh.mm a"
                                />
                              </div>

                              {isUpcomingAppointment && (
                                <div className="mt-4">
                                  <InlineLink
                                    size="small"
                                    onClick={() =>
                                      setAddToCalendarModalOpen(true)
                                    }
                                  >
                                    Add to Calendar
                                  </InlineLink>

                                  <AddToCalendarModal
                                    appointment={appointment}
                                    isOpen={addToCalendarModalOpen}
                                    onClose={() =>
                                      setAddToCalendarModalOpen(false)
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="flex items-center gap-3">
                            <Avatar
                              url={appointment.patient_profile_image}
                              name={appointment.patient_name}
                              className="w-10 h-10 tablet:w-[60px] tablet:h-[60px]"
                              isPatient
                            />

                            <span className="text-base font-medium tablet:text-lg">
                              {appointment.patient_name}
                            </span>
                          </div>

                          <div className="flex gap-4 tablet:gap-6">
                            <div className="mb-1">
                              <div className="w-6 tablet:w-8">
                                <BandageIcon className="w-full h-full text-black" />
                              </div>
                            </div>

                            <div>
                              <span className="block text-base font-semibold tablet:text-lg">
                                {appointment.reason}
                              </span>
                            </div>
                          </div>

                          {appointment.site && (
                            <div className="flex gap-4 tablet:gap-6">
                              <div className="mb-1">
                                <div className="w-6 tablet:w-8">
                                  <MapPinIcon className="w-full h-full" />
                                </div>
                              </div>

                              <div>
                                <div className="mb-1">
                                  <span className="text-base font-semibold tablet:text-lg">
                                    {appointment.site.name}
                                  </span>
                                </div>

                                <div className="text-sm tablet:text-base text-welded-iron">
                                  {appointment.site.address_1 &&
                                    appointment.site.postcode && (
                                      <span className="block">
                                        {`
                                                    ${appointment.site.address_1},
                                                    ${appointment.site.postcode}
                                                  `}
                                      </span>
                                    )}
                                </div>

                                {isUpcomingAppointment && (
                                  <div className="mt-4">
                                    <InlineLink
                                      size="small"
                                      to={`https://www.google.com/maps/dir/?api=1&destination=${addressLine}`}
                                      target="_blank"
                                    >
                                      Get Directions
                                    </InlineLink>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {isUpcomingAppointment &&
                        !cancellationsBlocked &&
                        !appointment.cancellation_blocked &&
                        (tablet ? (
                          <div className="w-full max-w-sm mt-8">
                            <Button
                              block
                              danger
                              size="large"
                              variant="outlined"
                              loading={isCancelling}
                              onClick={() => {
                                // Trigger event for start of cancellation journey
                                gtmEvent({ name: "onCancelAppointment" });
                                setOpenConfirmationModal(true);
                              }}
                            >
                              Cancel Booking
                            </Button>
                          </div>
                        ) : (
                          <div className="flex items-end flex-grow w-full mt-4 tablet:mt-12 tablet:max-w-sm tablet:items-start">
                            <CancelAppointmentSheet
                              onClick={() =>
                                gtmEvent({ name: "onCancelAppointment" })
                              }
                              onCancel={() => setCancelled(true)}
                              appointmentId={appointment.id}
                            />
                          </div>
                        ))}
                    </div>

                    <ConfirmationModal
                      title="Are you sure?"
                      description={
                        <div>
                          <p>
                            {cancellationMessage
                              ? cancellationMessage
                              : `Clicking confirm will cancel your appointment for ${appointment.patient_name} on the ${DateTime.fromISO(appointment.start_date).toFormat("dd LLLL")} at ${DateTime.fromISO(appointment.start_date).toFormat("hh.mma")}`}
                          </p>
                        </div>
                      }
                      isOpen={openConfirmationModal}
                      onConfirm={handleConfirm}
                      onClose={() => setOpenConfirmationModal(false)}
                    />
                  </>
                )}
              </>
            )}
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};
export default ViewAppointmentModal;
