import {
  InlineLink,
  Button,
  FormGroup,
  Label,
  NotifierNotification,
  Code,
} from "@mwi/ui";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useSendCodeMutation, useVerifyCodeMutation } from "api";
import useErrorHandler from "hooks/useErrorHandler";
import { login } from "slices/auth";
import { setBailed, setVerifyMethod } from "slices/registration";
import { RootState } from "store";
import { useNotifier } from "react-headless-notifier";
import { gtmEvent } from "helpers/gtm";

type Inputs = {
  code: string;
};

const RegistrationCodeVerify = () => {
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();
  const { notify } = useNotifier();

  const [resendSuccess, setResendSuccess] = useState(false);
  const [newCodeRequired, setNewCodeRequred] = useState(false);

  const { user, formState, method, verifyMethods, flow } = useSelector(
    (state: RootState) => ({
      user: state.auth.user,
      formState: state.registration.formState,
      method: state.registration.verifyMethod,
      verifyMethods: state.registration.verifyMethods,
      flow: state.registration.flow,
    }),
  );

  const {
    handleSubmit,
    watch,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      code: "",
    },
  });

  const [sendCode, { isLoading: isLoadingSend }] = useSendCodeMutation();
  const [
    verifyCode,
    {
      data: verifyResult,
      isLoading,
      error: failedVerifying,
      reset: resetVerifyCode,
    },
  ] = useVerifyCodeMutation();

  useEffect(() => {
    if (failedVerifying) {
      clearErrors();
      resetVerifyCode();
      setValue("code", "");
      errorHandler(failedVerifying, setError);
      if ((failedVerifying as any)?.data?.errors?.[0]?.new_code_required) {
        setNewCodeRequred(true);
      }
    }
  }, [failedVerifying]);

  useEffect(() => {
    if (verifyResult) {
      dispatch(login(verifyResult));
    }
  }, [verifyResult]);

  useEffect(() => {
    if (resendSuccess) {
      setTimeout(() => setResendSuccess(false), 5000);
      setNewCodeRequred(false);
      clearErrors();
      resetVerifyCode();
      setValue("code", "");
      notify(
        <NotifierNotification
          title="New code sent"
          message={`We've just sent a new code to the ${method === "sms" ? "phone number" : "email"} displayed below.`}
          type="success"
        />,
      );
    }
  }, [resendSuccess]);

  const submitForm = async (data: Inputs) => {
    await verifyCode({ code: data.code });
  };

  const changeMethod = () => {
    dispatch(setVerifyMethod(undefined));
  };

  const resendCode = async () => {
    await sendCode({
      method: method!,
      phone: formState.phone ?? "",
    }).unwrap();
    setResendSuccess(true);
  };

  const bailout = () => {
    dispatch(setBailed(true));
  };

  useEffect(() => gtmEvent({ name: "onRegFlowVerifyCodeStepPageView" }), []);

  return (
    <>
      <h1 className="text-4xl font-bold tablet:text-6xl">
        {flow === "match"
          ? "You may already be registered with our vet practice"
          : "Enter the code"}
      </h1>

      <p className="mt-6 tablet:mt-8 tablet:text-lg">
        {flow === "match"
          ? `Enter the code we've sent to ${method === "sms" ? "the mobile ending " : "the email "}`
          : "Sent to "}
        {verifyMethods?.find((m) => m.method === method)?.display ??
          (method === "sms" ? user?.phone : user?.email)}
        &nbsp;
        {verifyMethods!.length > 1 && (
          <InlineLink className="ml-2" size="medium" onClick={changeMethod}>
            Change
          </InlineLink>
        )}
      </p>

      <form
        className="flex flex-col flex-grow mt-8 tablet:mt-12"
        onSubmit={handleSubmit(submitForm)}
      >
        <FormGroup error={errors?.code?.message}>
          <Label>Code</Label>
          <Code
            fields={4}
            capitalize
            disabled={isLoading}
            value={watch("code") ?? ""}
            onChange={(value) => setValue("code", value)}
          />
        </FormGroup>

        <p className="tablet:text-lg">
          {!isLoadingSend && !resendSuccess && (
            <InlineLink size="medium" onClick={resendCode} disabled={isLoading}>
              Resend Code
            </InlineLink>
          )}
          {isLoadingSend && "Resending code..."}
          {resendSuccess && !isLoadingSend && "Code resent!"}
        </p>

        <div className="pt-12 mt-auto tablet:max-w-xs">
          <Button
            block
            type="submit"
            loading={isLoading}
            disabled={watch("code")?.length !== 4 || newCodeRequired}
          >
            Submit
          </Button>
        </div>

        {flow === "match" && (
          <div className="mt-6 text-center tablet:text-left tablet:mt-8 tablet:text-lg">
            <InlineLink size="medium" onClick={bailout}>
              I don&apos;t recognise these details
            </InlineLink>
          </div>
        )}
      </form>
    </>
  );
};

export default RegistrationCodeVerify;
