/* eslint-disable max-len */
const CalendarOffIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="1.5"
      className={className}
    >
      <path
        d="M27.6141 26.741C27.7847 26.3636 27.6171 25.9193 27.2397 25.7486C26.8623 25.5779 26.418 25.7456 26.2473 26.123L27.6141 26.741ZM24.5 28L24.5003 27.25H24.5V28ZM5.83337 9.33332H6.58337L6.58337 9.33292L5.83337 9.33332ZM7.70942 7.58587C8.08675 7.41501 8.25412 6.97061 8.08326 6.59328C7.9124 6.21595 7.468 6.04858 7.09067 6.21944L7.70942 7.58587ZM12.5 5.91666C12.0858 5.91666 11.75 6.25244 11.75 6.66666C11.75 7.08087 12.0858 7.41666 12.5 7.41666V5.91666ZM26.4167 21.3333C26.4167 21.7475 26.7525 22.0833 27.1667 22.0833C27.5809 22.0833 27.9167 21.7475 27.9167 21.3333H26.4167ZM26.2473 26.123C25.937 26.8093 25.2535 27.2502 24.5003 27.25L24.4998 28.75C25.8425 28.7504 27.0609 27.9644 27.6141 26.741L26.2473 26.123ZM24.5 27.25H8.50004V28.75H24.5V27.25ZM8.50004 27.25C7.4415 27.25 6.58337 26.3919 6.58337 25.3333H5.08337C5.08337 27.2203 6.61307 28.75 8.50004 28.75V27.25ZM6.58337 25.3333V9.33332H5.08337V25.3333H6.58337ZM6.58337 9.33292C6.58297 8.57994 7.02348 7.89648 7.70942 7.58587L7.09067 6.21944C5.86792 6.77312 5.08265 7.99146 5.08337 9.33373L6.58337 9.33292ZM12.5 7.41666H24.5V5.91666H12.5V7.41666ZM24.5 7.41666C25.5586 7.41666 26.4167 8.27478 26.4167 9.33332H27.9167C27.9167 7.44635 26.387 5.91666 24.5 5.91666V7.41666ZM26.4167 9.33332V21.3333H27.9167V9.33332H26.4167Z"
        fill="currentColor"
      />
      <path
        d="M21.8333 4V9.33333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1667 4V5.33333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83337 13.9167C5.41916 13.9167 5.08337 14.2525 5.08337 14.6667C5.08337 15.0809 5.41916 15.4167 5.83337 15.4167V13.9167ZM15.1667 15.4167C15.5809 15.4167 15.9167 15.0809 15.9167 14.6667C15.9167 14.2525 15.5809 13.9167 15.1667 13.9167V15.4167ZM20.5 13.9167C20.0858 13.9167 19.75 14.2525 19.75 14.6667C19.75 15.0809 20.0858 15.4167 20.5 15.4167V13.9167ZM27.1667 15.4167C27.5809 15.4167 27.9167 15.0809 27.9167 14.6667C27.9167 14.2525 27.5809 13.9167 27.1667 13.9167V15.4167ZM5.83337 15.4167H15.1667V13.9167H5.83337V15.4167ZM20.5 15.4167H27.1667V13.9167H20.5V15.4167Z"
        fill="currentColor"
      />
      <path
        d="M15.1666 20H16.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 20V24"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 4L28.5 28"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarOffIcon;
