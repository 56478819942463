import { Select, SelectOption, Button, FormGroup, Label } from "@mwi/ui";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { login } from "slices/auth";
import { useVerifyPatientMutation } from "api";
import useErrorHandler from "hooks/useErrorHandler";
import { RootState } from "store";
import {
  setBailed,
  setVerifyMethods,
  setReferredSite,
  updateFormState,
} from "slices/registration";
import { gtmEvent } from "helpers/gtm";

type Inputs = {
  patient: string;
};

const RegistrationPatientVerify = () => {
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const { patientOptions, oldVerifiedPatient } = useSelector(
    (state: RootState) => ({
      patientOptions: state.registration.patientOptions,
      oldVerifiedPatient: state.registration.oldFormState.verified_patient,
    }),
  );

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      patient: oldVerifiedPatient ?? "",
    },
  });

  const [verifyPatient, { data: verifyResult, isLoading, error }] =
    useVerifyPatientMutation();

  useEffect(() => {
    if (error) {
      if ((error as any)?.data?.errors) {
        errorHandler(error, setError);
      } else {
        dispatch(setBailed(true));
      }
    }
  }, [error]);

  useEffect(() => {
    if (verifyResult) {
      dispatch(login({ user: verifyResult.user }));
      dispatch(updateFormState({ verified_patient: getValues("patient") }));
      dispatch(setReferredSite({ ...verifyResult.referred_location }));
      dispatch(setVerifyMethods(verifyResult.verify_methods));
    }
  }, [verifyResult]);

  useEffect(() => {
    if (oldVerifiedPatient && oldVerifiedPatient !== getValues("patient")) {
      setValue("patient", oldVerifiedPatient);
    }
  }, [oldVerifiedPatient]);

  useEffect(() => gtmEvent({ name: "onRegFlowVerifyPatientStepPageView" }), []);

  return (
    <>
      <h1 className="text-4xl font-bold tablet:text-6xl">Verify Details</h1>

      <p className="mt-6 tablet:mt-8 tablet:text-lg max-w-[700px]">
        We&apos;ve matched you in our practice records, please choose the pet
        associated with the account you are looking to access.
      </p>

      <form
        className="flex flex-col flex-grow mt-8 tablet:mt-12 max-w-[600px]"
        onSubmit={handleSubmit(verifyPatient)}
      >
        <FormGroup error={errors?.patient?.message}>
          <Label>Select your pet</Label>
          <Select
            name="patient"
            control={control}
            placeholder="Select your pet"
          >
            {Object.entries(patientOptions ?? {}).map(([id, name]) => (
              <SelectOption key={id} value={id}>
                {name}
              </SelectOption>
            ))}
          </Select>
        </FormGroup>

        <div className="mt-auto tablet:mt-12 tablet:max-w-xs">
          <Button
            type="submit"
            block
            loading={isLoading}
            disabled={!watch("patient")}
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};

export default RegistrationPatientVerify;
