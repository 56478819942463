import classNames from "classnames";

const FormError = ({
  children,
  className,
}: {
  children: JSX.Element | string;
  className?: string;
}) => {
  return (
    <p
      className={classNames(
        "text-sm font-semibold tablet:text-base text-error",
        className,
      )}
    >
      {children}
    </p>
  );
};

export default FormError;
