/* eslint-disable max-len */
const SuccessCircleTickGraphic = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.5 32C61.5 48.0163 48.5163 61 32.5 61C16.4837 61 3.5 48.0163 3.5 32C3.5 15.9837 16.4837 3 32.5 3"
        stroke="currentColor"
        strokeWidth="6"
      />
      <path
        d="M21.5 28.375L32.1047 38L59.5 10"
        stroke="currentColor"
        strokeWidth="6"
      />
    </svg>
  );
};

export default SuccessCircleTickGraphic;
