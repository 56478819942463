const TableRow = ({
  children,
  className,
}: {
  children: JSX.Element | JSX.Element[];
  className?: string;
}) => {
  return <tr className={className}>{children}</tr>;
};

export default TableRow;
