import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import User from "api/types/models/user";
import { QueryTags } from "api/types/QueryTags";
import {
  RegisterRequest,
  VerifyCodeRequest,
  CheckForMatchesRequest,
  SendCodeRequest,
  SetPasswordRequest,
  VerifyPatientRequest,
} from "api/types/requests";
import MatchResponse from "api/types/responses/match_response";
import VerifyPatientResponse from "api/types/responses/register/verify_patient";
import VerifyCodeResponse from "api/types/responses/register/verify_code";
import SetPasswordResponse from "api/types/responses/register/set_password";

const registerEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    QueryTags,
    "data"
  >,
) => ({
  checkForMatches: builder.mutation<MatchResponse, CheckForMatchesRequest>({
    query: (body) => ({
      url: "/auth/register/match",
      method: "post",
      body,
    }),
  }),

  register: builder.mutation<User, RegisterRequest>({
    query: (body) => ({
      url: "/auth/register",
      method: "post",
      body,
    }),
  }),

  sendCode: builder.mutation<void, SendCodeRequest>({
    query: (body) => ({
      url: "/auth/register/code",
      method: "post",
      body,
    }),
  }),

  verifyCode: builder.mutation<VerifyCodeResponse, VerifyCodeRequest>({
    query: (body) => ({
      url: "/auth/register/verify",
      method: "post",
      body,
    }),
  }),

  verifyPatient: builder.mutation<VerifyPatientResponse, VerifyPatientRequest>({
    query: (body) => ({
      url: "/auth/register/patient",
      method: "post",
      body,
    }),
  }),

  setPassword: builder.mutation<SetPasswordResponse, SetPasswordRequest>({
    query: (body) => ({
      url: "/auth/register/password",
      method: "post",
      body,
    }),
  }),
});

export default registerEndpoints;
