import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { QueryTags } from "api/types/QueryTags";
import Appointment from "api/types/models/appointment";
import CreateAppointmentRequest from "api/types/requests/appointment/create_appointment";
import AppointmentsResponse from "api/types/responses/appointments_response";
import VerifyAppointmentsRequest from "api/types/requests/appointment/verify_appointment";
import AppointmentStatsResponse from "api/types/responses/appointment_stats_response";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";

const appointmentEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    QueryTags,
    "data"
  >,
) => ({
  verifyAppointments: builder.mutation<void, VerifyAppointmentsRequest>({
    query: (body) => ({
      url: "/appointments/verify",
      method: "post",
      body,
    }),
  }),

  createAppointments: builder.mutation<void, CreateAppointmentRequest>({
    query: (body) => ({
      url: "/appointments",
      method: "post",
      body,
    }),
    invalidatesTags: ["Appointments"],
  }),

  getAppointment: builder.query<Appointment, string>({
    query: (id) => `/appointments/${id}`,
    providesTags: ["Appointment"],
  }),

  getAppointments: builder.query<AppointmentsResponse, void>({
    query: () => "/appointments",
    providesTags: ["Appointments"],
  }),

  cancelAppointment: builder.mutation<void, string>({
    query: (id) => ({
      url: `/appointments/${id}`,
      method: "delete",
    }),
    invalidatesTags: ["Appointments"],
  }),

  getAppointmentStats: builder.query<AppointmentStatsResponse, void>({
    query: () => ({
      url: "/appointments/stats",
    }),
  }),
});

export default appointmentEndpoints;
