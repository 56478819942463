import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Practice from "api/types/models/practice";
import PracticeLink from "api/types/models/practice_link";

import DefaultLoginImages from "assets/images/login-images";

export interface DefaultLoginImage {
  id: string;
  url: string;
}

export interface PracticeState {
  id?: number;
  name?: string;
  email?: string;
  phone?: string;
  address?: string;
  displayName?: string;
  websiteLink?: string;
  privacyLink?: string;
  registrations_blocked?: boolean;
  appointments_blocked?: boolean;
  cancellations_blocked?: boolean;
  cancellation_message?: string;
  theme: ThemeState;
  links: PracticeLink[];
}

export interface ThemeState {
  darkColor: string | null;
  onDarkColor: string | null;
  primaryColor: string | null;
  onPrimaryColor: string | null;
  lightColor: string | null;
  onLightColor: string | null;
  lightestColor: string | null;
  onLightestColor: string | null;
  logoUrl?: string;
  defaultLoginImage: string | null;
  customLoginImage: string | null;
  defaultLoginImages: DefaultLoginImage[];
}

const savedState = JSON.parse(localStorage.getItem("mwi_theme") ?? "{}");

const initialState: PracticeState = {
  id: undefined,
  name: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
  displayName: undefined,
  websiteLink: undefined,
  privacyLink: undefined,
  registrations_blocked: undefined,
  appointments_blocked: undefined,
  cancellations_blocked: undefined,
  cancellation_message: undefined,
  links: [],
  theme: {
    darkColor: null,
    onDarkColor: null,
    primaryColor: null,
    onPrimaryColor: null,
    lightColor: null,
    onLightColor: null,
    lightestColor: null,
    onLightestColor: null,
    defaultLoginImage: null,
    customLoginImage: null,
    defaultLoginImages: [
      ...Object.values(DefaultLoginImages).map((image, idx) => ({
        id: `default-${idx + 1}`,
        url: image,
      })),
    ],
    ...savedState,
  },
};

export const practiceSlice = createSlice({
  name: "practice",
  initialState,
  reducers: {
    setPractice: (state, practice: PayloadAction<Practice>) => {
      state.id = practice.payload.id;
      state.name = practice.payload.name;
      state.email = practice.payload.email;
      state.phone = practice.payload.phone;
      state.address = practice.payload.address;
      state.displayName = practice.payload.display_name;
      state.websiteLink = practice.payload.website_link;
      state.privacyLink = practice.payload.privacy_link;
      state.registrations_blocked = practice.payload.registrations_blocked;
      state.appointments_blocked = practice.payload.appointments_blocked;
      state.cancellations_blocked = practice.payload.cancellations_blocked;
      state.cancellation_message = practice.payload.cancellation_message;
      state.links = practice.payload.links;

      state.theme.darkColor = practice.payload.theme?.dark_color;
      state.theme.onDarkColor = practice.payload.theme?.on_dark_color;
      state.theme.primaryColor = practice.payload.theme.primary_color;
      state.theme.onPrimaryColor = practice.payload.theme.on_primary_color;
      state.theme.lightColor = practice.payload.theme.light_color;
      state.theme.onLightColor = practice.payload.theme.on_light_color;
      state.theme.lightestColor = practice.payload.theme.lightest_color;
      state.theme.onLightestColor = practice.payload.theme.on_lightest_color;
      state.theme.logoUrl = practice.payload.theme.logo_url;
      state.theme.defaultLoginImage =
        practice.payload.theme.default_login_image;
      state.theme.customLoginImage = practice.payload.theme.custom_login_image;

      localStorage.setItem("mwi_theme", JSON.stringify(state));

      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute("content", `rgb(${state.theme.primaryColor})`);

      document
        .querySelector('link[rel="icon"]')
        ?.setAttribute("href", state.theme.logoUrl);

      document
        .querySelector('link[rel="apple-touch-icon"]')
        ?.setAttribute("href", state.theme.logoUrl);
    },

    clearPractice: (state) => {
      localStorage.removeItem("mwi_theme");

      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute("content", "rgb(60 40 155)");

      document
        .querySelector('link[rel="icon"]')
        ?.setAttribute("href", "/logo.png");

      document
        .querySelector('link[rel="apple-touch-icon"]')
        ?.setAttribute("href", "/logo.png");

      return initialState;
    },
  },
});

export const { setPractice, clearPractice } = practiceSlice.actions;

export default practiceSlice.reducer;
