import { DateTime } from "luxon";

const DateTimeFormat = ({
  date,
  format = "LLL d, yyyy",
}: {
  date: DateTime | string;
  format: string;
}) => {
  if (typeof date === "string") {
    date = DateTime.fromISO(date);
  }

  return <>{date.toFormat(format)}</>;
};

export default DateTimeFormat;
