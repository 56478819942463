import classNames from "classnames";
import { NavLink } from "react-router-dom";

type ItemProps = {
  to?: string;
  title: string;
  isActive: boolean;
  className?: string;
  onClick?: () => void;
};

const Item = ({
  to,
  title,
  onClick,
  className,
  isActive,
}: ItemProps): JSX.Element => {
  const classes = classNames(
    "border-b-[3px] border-transparent pb-2 cursor-pointer transition-colors",
    {
      "border-b-light font-semibold": isActive,
    },
    className,
  );

  if (to) {
    return (
      <NavLink to={to} className={classes}>
        {title}
      </NavLink>
    );
  }

  return (
    <button onClick={onClick} className={classes} type="button">
      {title}
    </button>
  );
};

export default Item;
