import { Disclosure, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";

const Accordion = ({
  isOpen,
  trigger,
  children,
  className,
}: {
  isOpen: boolean;
  trigger: ReactNode;
  children?: ReactNode;
  className?: string;
}) => {
  return (
    <Disclosure as="div">
      <Disclosure.Button as={Fragment}>{trigger}</Disclosure.Button>

      <Transition
        show={isOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Disclosure.Panel className={className}>{children}</Disclosure.Panel>
      </Transition>
    </Disclosure>
  );
};

export default Accordion;
