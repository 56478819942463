import { RootState } from "store";
import { useSelector } from "react-redux";

import { MailIcon, PhoneCallIcon, Button } from "@mwi/ui";
import { useEffect } from "react";
import { gtmEvent } from "helpers/gtm";

const RegistrationBailout = () => {
  const { email, phone } = useSelector((state: RootState) => ({
    email: state.registration.referredSite.email,
    phone: state.registration.referredSite.phone_number,
  }));

  useEffect(() => gtmEvent({ name: "onRegFlowBailoutStepPageView" }), []);

  return (
    <>
      <h1 className="text-4xl font-bold tablet:text-6xl">
        Sorry we couldn&apos;t verify your account
      </h1>

      <p className="mt-6 tablet:mt-8 tablet:text-lg">
        Please contact us to ensure the account details we hold for you are up
        to date.
      </p>

      <div className="flex flex-col flex-grow mt-6 font-bold tablet:mt-8 tablet:text-lg">
        {email && (
          <a href={`mailto:${email}`} className="block">
            <MailIcon className="inline w-6 h-6 mr-2" />
            {email}
          </a>
        )}

        {phone && (
          <>
            <a href={`tel:${phone}`} className="block mt-3">
              <PhoneCallIcon className="inline w-6 h-6 mr-2" />
              {phone}
            </a>

            <div className="pt-6 mt-auto tablet:mt-12 tablet:max-w-xs">
              <Button block to={`tel:${phone}`} type="link">
                Call us now
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RegistrationBailout;
