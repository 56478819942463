import Button from "../buttons/button";
import GraphicBackground from "../../icons/GraphicBackground";
import classNames from "classnames";
import InlineLink from "../navigation/inline_link";

type StatusProps = {
  graphic: JSX.Element;
  danger?: boolean;
  title?: string;
  onClick?: () => void;
  description?: string;
  primaryLink?: string;
  primaryLinkText?: string;
  secondaryLink?: string;
  secondaryLinkText?: string;
};

const Status = ({
  graphic,
  danger = false,
  title,
  onClick,
  description,
  primaryLink,
  primaryLinkText,
  secondaryLink,
  secondaryLinkText,
}: StatusProps): JSX.Element => {
  return (
    <div className="flex flex-col flex-grow mt-6">
      <div className="mb-4">
        <div className="flex flex-col items-center">
          {graphic && (
            <div className="relative w-[235px] height-[189px] pb-[189px] mb-11">
              <div className="absolute inset-0">
                <GraphicBackground
                  className={danger ? "text-error-lightest" : "text-lightest"}
                />
              </div>
              <div className="absolute inset-0 flex items-center justify-center">
                <div
                  className={classNames(
                    "w-20 h-20",
                    danger ? "text-error" : "text-primary",
                  )}
                >
                  {graphic}
                </div>
              </div>
            </div>
          )}

          {title && (
            <div className="mb-6">
              <h2 className="text-4xl font-bold text-center">{title}</h2>
            </div>
          )}

          {description && (
            <p className="text-base font-normal text-center">{description}</p>
          )}
        </div>
      </div>

      <div className="w-full mx-auto mt-auto tablet:mt-24 tablet:max-w-sm">
        {primaryLinkText && (
          <Button
            block
            to={primaryLink}
            onClick={onClick}
            type={primaryLink ? "link" : "button"}
          >
            {primaryLinkText}
          </Button>
        )}

        {secondaryLink && (
          <div className="mt-6 text-center">
            <InlineLink to={secondaryLink} size="medium">
              {secondaryLinkText}
            </InlineLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default Status;
