import { BackButton, CookieTemplate } from "@mwi/ui";
import { useNavigate } from "react-router-dom";

const CookiePage = (): JSX.Element => {
  const navigate = useNavigate();

  const handleBack = () => {
    window.history.length > 0 ? navigate(-1) : navigate("/");
  };

  return (
    <div className="text-lg">
      <BackButton className="mb-14" onClick={handleBack} />

      <h1 className="mb-8">Cookie Policy</h1>

      <CookieTemplate />
    </div>
  );
};

export default CookiePage;
