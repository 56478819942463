import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MatchReferredSite,
  MatchVerifyMethods,
} from "api/types/responses/match_response";
import VerificationMethod from "api/types/VerificationMethod";
import Site from "api/types/models/site";

export interface FormState {
  email: string;

  first_name?: string;
  last_name?: string;
  phone?: string;

  name_no?: string;
  line_1?: string;
  line_2?: string;
  city?: string;
  county?: string;
  postcode?: string;

  verified_patient?: string;

  vet_site?: string;
}

export type FlowOptions = "login" | "match" | "register";

export interface RegistrationState {
  flow?: FlowOptions;
  formState: FormState;
  oldFormState: FormState;
  verifyMethods?: MatchVerifyMethods;
  patientOptions?: { [key: string]: string };
  verifyMethod?: VerificationMethod;
  bailed: boolean;
  locations?: Site[];
  referredSite: MatchReferredSite;
}

const initialFormState = JSON.parse(
  localStorage.getItem("registration_form_state") ??
    `{
  "email": ""
}`,
);

const initialState: RegistrationState = {
  flow: undefined,
  formState: initialFormState,
  oldFormState: initialFormState,
  verifyMethods: undefined,
  patientOptions: undefined,
  verifyMethod: undefined,
  bailed: false,
  locations: undefined,
  referredSite: {
    email: undefined,
    phone_number: undefined,
  },
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setFlow: (state, flow: PayloadAction<FlowOptions>) => {
      state.flow = flow.payload;
    },

    updateFormState: (state, formState: PayloadAction<Partial<FormState>>) => {
      state.formState = {
        ...state.formState,
        ...formState.payload,
      };
      state.oldFormState = {
        ...state.oldFormState,
        ...Object.fromEntries(
          Object.entries(formState.payload).filter(([key, value]) => value),
        ),
      };
    },

    updateOldFormState: (
      state,
      formState: PayloadAction<Partial<FormState>>,
    ) => {
      state.oldFormState = {
        ...state.oldFormState,
        ...formState.payload,
      };
    },

    setVerifyMethod: (
      state,
      method: PayloadAction<VerificationMethod | undefined>,
    ) => {
      state.verifyMethod = method.payload;
    },

    setReferredSite: (state, action: PayloadAction<MatchReferredSite>) => {
      state.referredSite.email = action.payload.email;
      state.referredSite.phone_number = action.payload.phone_number;
    },

    setVerifyMethods: (
      state,
      methods: PayloadAction<MatchVerifyMethods | undefined>,
    ) => {
      state.verifyMethods = methods.payload;
    },

    setBailed: (state, bailed: PayloadAction<boolean>) => {
      state.bailed = bailed.payload;
    },

    setPatientOptions: (
      state,
      options: PayloadAction<{ [key: string]: string } | undefined>,
    ) => {
      state.patientOptions = options.payload;
    },

    setLocations: (state, locations: PayloadAction<Site[] | undefined>) => {
      state.locations = locations.payload;
    },

    reset: () => {
      localStorage.removeItem("registration_form_state");
      return initialState;
    },
  },
});

export const {
  reset,
  setVerifyMethod,
  setReferredSite,
  updateOldFormState,
  setFlow,
  updateFormState,
  setVerifyMethods,
  setBailed,
  setPatientOptions,
  setLocations,
} = registrationSlice.actions;

export default registrationSlice.reducer;
