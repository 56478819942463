import Divider from "../divider";
import DateTimeFormat from "../date_time_format";
import { Fragment, MouseEventHandler } from "react";
import InlineLink from "../navigation/inline_link";
import classNames from "classnames";

type ReadOnlyProps = {
  heading?: string;
  value?: (string | undefined)[] | string | null;
  dateFormat?: string;
  hasDivider?: boolean;
  actionText?: string;
  actionOnClick?: MouseEventHandler;
  className?: string;
};

const ReadOnly = ({
  heading,
  value,
  dateFormat,
  hasDivider = false,
  actionText,
  actionOnClick,
  className,
}: ReadOnlyProps): JSX.Element => {
  return (
    <div className={classNames("mb-4 tablet:mb-6", className)}>
      <div className="flex flex-col w-full gap-y-[10px]">
        {heading && (
          <h3 className="text-sm font-semibold text-grey-dark">{heading}</h3>
        )}

        <div className="flex items-center justify-between">
          <p>
            {Array.isArray(value) &&
              value
                .filter((v) => v)
                .map((v, i) => (
                  <Fragment key={i}>
                    {v}
                    <br />
                  </Fragment>
                ))}
            {!Array.isArray(value) && dateFormat && value && (
              <DateTimeFormat date={value} format={dateFormat} />
            )}
            {!Array.isArray(value) &&
              !(dateFormat && value) &&
              (value ?? "Not Specified")}
          </p>

          {actionText && actionOnClick && (
            <InlineLink size="small" onClick={actionOnClick}>
              {actionText}
            </InlineLink>
          )}
        </div>

        {hasDivider && <Divider />}
      </div>
    </div>
  );
};

export default ReadOnly;
