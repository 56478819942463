import BookingFlowStep3a from "pages/booking/steps/3a_site";

const BookAppointmentModalStep3a = ({
  onConfirm,
}: {
  onConfirm?: () => void;
}): JSX.Element => {
  return <BookingFlowStep3a onConfirm={onConfirm} />;
};

export default BookAppointmentModalStep3a;
