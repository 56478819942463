import Patient from "api/types/models/patient";
import { QueryTags } from "api/types/QueryTags";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";
import Gender from "api/types/models/gender";
import Colours from "api/types/models/colour";
import {
  CreatePatientsRequest,
  UpdatePatientRequest,
} from "api/types/requests";

const patientEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    QueryTags,
    "data"
  >,
) => ({
  registerPatients: builder.mutation<void, CreatePatientsRequest>({
    query: (body) => ({
      url: "/patients",
      method: "post",
      body,
    }),
    invalidatesTags: ["Patients"],
  }),

  updatePatient: builder.mutation<
    Patient,
    { id: string; body: UpdatePatientRequest }
  >({
    query: ({ id, body }) => ({
      url: `/patients/${id}`,
      method: "put",
      body,
    }),
    invalidatesTags: ["Patient", "Patients"],
  }),

  getPatient: builder.query<Patient, string>({
    query: (id) => `/patients/${id}`,
    providesTags: ["Patient"],
  }),

  getPatients: builder.query<Patient[], void>({
    query: () => "/patients",
    providesTags: ["Patients"],
  }),

  getGenders: builder.query<Gender[], void>({
    query: () => "/genders",
  }),

  getColours: builder.query<Colours[], void>({
    query: () => "/colours",
  }),
});

export default patientEndpoints;
