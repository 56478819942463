import { useSelector } from "react-redux";
import { RootState } from "store";
import useTitle from "hooks/useTitle";
import PatientsSection from "./sections/PatientsSection";
import AppointmentWidget from "components/widgets/appointment";
import PastAppointmentSection from "./sections/PastAppointmentSection";
import UpcomingAppointmentSection from "./sections/UpcomingAppointmentSection";
import { useLocation, useNavigate } from "react-router-dom";
import { PrescriptionModal } from "features/prescription";

const HomePage = (): JSX.Element => {
  useTitle("Home");

  const location = useLocation();
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.auth.user);

  return (
    <>
      <div className="mb-11 tablet:mb-6">
        <h1 className="text-4xl leading-[43px] tablet:text-[64px] tablet:leading-[77px] capitalize">
          {`Welcome ${user?.first_name ?? ""}`}
        </h1>
      </div>

      <div className="hidden tablet:block tablet:mb-10">
        <p className="text-[21px] leading-[31px]">
          Book an appointment with us today.
        </p>
      </div>

      <div className="hidden tablet:block tablet:mb-14">
        <AppointmentWidget />
      </div>

      <div className="grid gap-6 tablet:grid-cols-10">
        <div className="tablet:col-span-4">
          <div className="mb-8 tablet:mb-12">
            <UpcomingAppointmentSection />
          </div>

          <div className="mb-8 tablet:mb-12">
            <div className="block tablet:hidden">
              <PatientsSection />
            </div>
          </div>

          <PastAppointmentSection />
        </div>

        <div className="hidden tablet:block tablet:col-start-6 tablet:col-end-11">
          <PatientsSection />
        </div>
      </div>

      <PrescriptionModal
        isOpen={location?.pathname === "/prescription-request"}
        onClose={() => navigate("/home", { replace: true })}
      />
    </>
  );
};

export default HomePage;
