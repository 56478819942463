import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logout } from "slices/auth";
import appointmentEndpoints from "api/endpoints/appointments";
import authEndpoints from "api/endpoints/auth";
import availabilityEndpoints from "api/endpoints/availability";
import fileEndpoints from "api/endpoints/files";
import siteEndpoints from "api/endpoints/site";
import patientEndpoints from "api/endpoints/patients";
import practiceEndpoints from "api/endpoints/practices";
import reasonEndpoints from "api/endpoints/reasons";
import registerEndpoints from "api/endpoints/register";
import speciesEndpoints from "api/endpoints/species";
import breedEndpoints from "api/endpoints/breed";
import addressEndpoints from "api/endpoints/address";
import userEndpoints from "api/endpoints/users";

type DownloadQueryProps = {
  url: string;
  params?: { [key: string]: any };
  filename: string;
};

const baseUrl = (() => {
  if (process.env.NODE_ENV === "production") {
    return process.env.REACT_APP_API_BASE_URL!;
  }
  const url = new URL(process.env.REACT_APP_API_BASE_URL!);
  url.hostname = window.location.hostname;
  return url.toString();
})();

const prepareHeaders = (headers: Headers) => {
  const match = document.cookie.match(
    new RegExp("(^|;\\s*)(XSRF-TOKEN)=([^;]*)"),
  );
  if (match) {
    headers.set("X-XSRF-TOKEN", decodeURIComponent(match[3]));
  }

  return headers;
};

export const downloadQuery = async ({
  url,
  params,
  filename,
}: DownloadQueryProps) => {
  const searchParams = new URLSearchParams(params);
  const urlParams = searchParams.toString();
  const response = await fetch(
    `${baseUrl}${url}${urlParams ? `?${urlParams}` : ""}`,
    {
      credentials: "include",
      headers: prepareHeaders(new Headers()),
    },
  );
  const blob = await response.blob();
  const objUrl = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = objUrl;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  URL.revokeObjectURL(objUrl);
};

const baseQuery = fetchBaseQuery({
  baseUrl,
  credentials: "include",
  prepareHeaders,
});

const baseQueryIntercep: typeof baseQuery = async (args, api, extraOptions) => {
  const queryResult = await baseQuery(args, api, extraOptions);
  if (
    queryResult.error &&
    queryResult.error?.status === 401 &&
    api.endpoint !== "getMe"
  ) {
    api.dispatch(logout());
  }

  return queryResult;
};

const dataApi = createApi({
  reducerPath: "data",
  tagTypes: [
    "AuthMe",

    "Patient",
    "Patients",

    "Practice",
    "Practices",

    "Appointment",
    "Appointments",

    "Reason",
    "Reasons",

    "Availability",

    "SiteSchedule",

    "User",
  ],
  baseQuery: baseQueryIntercep,
  endpoints: (builder) => ({
    ...authEndpoints(builder),
    ...registerEndpoints(builder),
    ...practiceEndpoints(builder),
    ...appointmentEndpoints(builder),
    ...patientEndpoints(builder),
    ...speciesEndpoints(builder),
    ...fileEndpoints(builder),
    ...reasonEndpoints(builder),
    ...availabilityEndpoints(builder),
    ...breedEndpoints(builder),
    ...siteEndpoints(builder),
    ...addressEndpoints(builder),
    ...speciesEndpoints(builder),
    ...userEndpoints(builder),
  }),
});

export default dataApi;

export const {
  // Auth
  useGetMeQuery,
  useGetHeartbeatQuery,
  useLoginMutation,
  useLogoutMutation,
  useVerifyPasswordMutation,
  useForgotPasswordMutation,
  useUpdatePasswordMutation,
  useVerifyAccountMutation,

  // Register
  useCheckForMatchesMutation,
  useRegisterMutation,
  useSendCodeMutation,
  useVerifyCodeMutation,
  useVerifyPatientMutation,
  useSetPasswordMutation,

  // Practices
  useGetPracticeQuery,

  // Appointments
  useGetAppointmentQuery,
  useGetAppointmentsQuery,
  useVerifyAppointmentsMutation,
  useCancelAppointmentMutation,
  useGetAppointmentStatsQuery,

  useCreateAppointmentsMutation,

  // Availability
  useGetAvailabilityQuery,

  // Patients
  useGetPatientQuery,
  useGetPatientsQuery,
  useGetColoursQuery,
  useGetGendersQuery,
  useRegisterPatientsMutation,
  useUpdatePatientMutation,

  // Species
  useGetSpeciesQuery,
  useGetBreedsForSpeciesQuery,
  useGetGendersForSpeciesQuery,

  // Breeds
  useGetColoursForBreedQuery,

  // Files
  useUploadFileMutation,
  useDeleteFileMutation,

  // Reasons
  useGetReasonsQuery,
  useGetReasonQuery,

  // Sites (locations)
  useGetSitesQuery,
  useGetSiteQuery,

  // Address
  useGetPostcodeAddressesQuery,

  // Users
  useUpdateUserMutation,
} = dataApi;
