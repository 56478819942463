const ChevronsRightIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <polyline points="7 7 12 12 7 17"></polyline>
      <polyline points="13 7 18 12 13 17"></polyline>
    </svg>
  );
};

export default ChevronsRightIcon;
