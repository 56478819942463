import classNames from "classnames";
import { Controller } from "react-hook-form";
import { Popover, Transition } from "@headlessui/react";
import { createRef, Fragment, useMemo, useState } from "react";

import { isEqual } from "lodash";
import Option from "./option";
import Selected from "./selected";
import Button from "../../buttons/button";
import CheckIcon from "../../../icons/CheckIcon";
import CrossIcon from "../../../icons/CrossIcon";
import ChevronDownIcon from "../../../icons/ChevronDownIcon";

type Item = {
  id: string;
  value: string;
};

type MultiselectProps = {
  name: string;
  control: any;
  disabled?: boolean;
  placeholder: string;
  buttonClassName?: string;
  onChange?: (value: string[]) => void;
  size?: "small" | "medium" | "large";
  children: undefined | JSX.Element | JSX.Element[];
  optionsContainerClassName?: string;
};

const Multiselect = ({
  size = "large",
  name,
  control,
  disabled,
  children,
  onChange,
  placeholder,
  buttonClassName,
  optionsContainerClassName,
}: MultiselectProps) => {
  const childrenArray = useMemo(
    () => (children ? (Array.isArray(children) ? children : [children]) : []),
    [children],
  );

  const scrollRef = createRef<HTMLUListElement>();

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [isAtTop, setIsAtTop] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(childrenArray.length < 5);

  const onScroll = () => {
    if (scrollRef.current?.scrollTop === 0) {
      setIsAtTop(true);
    } else if (
      scrollRef.current!.scrollHeight - scrollRef.current!.scrollTop ===
      scrollRef.current?.clientHeight
    ) {
      setIsAtEnd(true);
    }
    if (scrollRef.current!.scrollTop > 0 && isAtTop) {
      setIsAtTop(false);
    }
    if (
      scrollRef.current!.scrollHeight - scrollRef.current!.scrollTop >
        scrollRef.current!.clientHeight &&
      isAtEnd
    ) {
      setIsAtEnd(false);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const selectedItems: Item[] = childrenArray
          .filter((child) =>
            field.value?.some((v: any) => v === child.props.value),
          )
          .map((child) => ({
            id: child.props.value,
            value: child.props.children,
          }));

        const handleRemove = (value: string) => {
          const filteredItems = selectedValues.filter((sv) => sv !== value);

          setSelectedValues(filteredItems);
          if (onChange) {
            onChange(filteredItems);
          }

          field.onChange(filteredItems);
        };

        if (!isEqual(selectedValues, field.value)) {
          setTimeout(() => {
            setSelectedValues(field.value);
          }, 0);
        }

        return (
          <Popover>
            <Popover.Button
              disabled={disabled}
              className={classNames(
                "rounded-none",
                "transition appearance-none",
                "flex items-center w-full p-4 !pr-12 tablet:text-lg",
                "leading-6 placeholder-placeholder-grey shadow-default",
                "border",
                "focus:outline-none relative",
                {
                  "border-black bg-grey-light cursor-default": disabled,
                  "border-primary focus:border-light focus:shadow-focus-small bg-white":
                    !disabled,

                  "p-4": size === "large",
                  "py-3 px-4": size === "medium",
                  "py-2 px-[0.625rem] pr-8": size === "small",
                },
                buttonClassName,
              )}
            >
              {placeholder && selectedItems?.length === 0 && (
                <span className="pr-6 truncate">{placeholder}</span>
              )}

              <div className="flex flex-wrap gap-2">
                {selectedItems.map((item) => (
                  <Selected
                    key={item.id}
                    value={item.value}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemove(item.id);
                    }}
                  />
                ))}
              </div>

              <span className="absolute inset-y-0 right-0 grid content-center pr-4 pointer-events-none">
                <ChevronDownIcon className="w-4 h-4" />
              </span>
            </Popover.Button>

            <div className="relative">
              <Transition as={Fragment}>
                <Popover.Panel className="fixed inset-0 z-[999] tablet:absolute">
                  {({ close }) => {
                    const handleConfirm = () => {
                      if (selectedValues?.length) {
                        if (onChange) {
                          onChange(selectedValues);
                        }

                        field.onChange(selectedValues);
                        close();
                      }
                    };

                    const onOptionClick = (
                      value: string,
                      disabled: boolean,
                    ) => {
                      if (disabled) {
                        return;
                      }

                      let selection = selectedValues.some((sv) => sv === value)
                        ? selectedValues.filter((sv) => sv !== value)
                        : [...selectedValues, value];

                      setSelectedValues(selection);
                      if (window.innerWidth > 768) {
                        if (onChange) {
                          onChange(selection);
                        }

                        field.onChange(selection);
                      }
                    };
                    return (
                      <>
                        {/* Overlay */}
                        <Transition.Child
                          enter="transition"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          className="absolute inset-0 bg-black/30 tablet:hidden"
                          onClick={() => close()}
                        />

                        {/* Panel */}
                        <Transition.Child
                          enter="transition-all"
                          enterFrom="translate-y-full tablet:-translate-y-4 tablet:opacity-0"
                          enterTo="translate-y-0 tablet:opacity-1"
                          leave="transition-all"
                          leaveFrom="translate-y-0 tablet:opacity-0"
                          leaveTo="translate-y-full tablet:-translate-y-4 tablet:opacity-0"
                          className={classNames(
                            "absolute inset-x-0 bottom-0",
                            "tablet:bottom-auto tablet:mt-2 tablet:pb-4",
                          )}
                        >
                          <div
                            className={classNames(
                              "px-4 pt-8 pb-6 bg-white rounded-t-3xl",
                              "tablet:rounded-none tablet:p-0",
                              "tablet:border tablet:border-light tablet:shadow-focus-small",
                            )}
                          >
                            <div
                              className="absolute right-4 top-8 tablet:hidden"
                              onClick={() => close()}
                            >
                              <CrossIcon className="w-6 h-6" />
                            </div>

                            {placeholder && (
                              <h3 className="text-lg font-semibold text-center tablet:hidden">
                                {placeholder}
                              </h3>
                            )}

                            <ul
                              ref={scrollRef}
                              onScroll={onScroll}
                              className={classNames(
                                "min-h-[168px] max-h-[280px] overflow-y-auto my-4",
                                "tablet:my-0 tablet:space-y-1",
                                optionsContainerClassName,
                              )}
                            >
                              {childrenArray.map((child) => {
                                const isSelected = selectedValues?.some(
                                  (sv) => sv === child.props.value,
                                );

                                return (
                                  <li
                                    key={child.key}
                                    onClick={() =>
                                      onOptionClick(
                                        child.props.value,
                                        child.props.disabled,
                                      )
                                    }
                                    className="relative tablet:mx-2 first:mt-2 last:pb-2"
                                  >
                                    <div
                                      className={classNames(
                                        "rounded-2xl cursor-pointer",
                                        {
                                          "font-semibold bg-grey-lightest":
                                            isSelected,
                                          "hover:bg-grey-lightest": !isSelected,
                                          "bg-grey-light text-grey-dark cursor-default hover:bg-grey-light":
                                            child.props.disabled,
                                        },
                                      )}
                                    >
                                      {child}
                                      {isSelected && (
                                        <div className="absolute inset-y-0 flex items-center right-4">
                                          <CheckIcon className="w-6 h-6" />
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>

                            <Transition
                              show={!isAtTop}
                              enter="transition"
                              enterFrom="opacity-0"
                              enterTo="opacity-1"
                              leave="transition"
                              leaveFrom="opacity-1"
                              leaveTo="opacity-0"
                              className={classNames(
                                "absolute inset-x-4 tablet:inset-x-[1px] h-20 top-[76px]",
                                "tablet:top-[1px] bg-gradient-scroll-start pointer-events-none",
                              )}
                            />

                            <Transition
                              show={!isAtEnd}
                              enter="transition"
                              enterFrom="opacity-0"
                              enterTo="opacity-1"
                              leave="transition"
                              leaveFrom="opacity-1"
                              leaveTo="opacity-0"
                              className={classNames(
                                "absolute inset-x-4 h-20 bottom-[104px] bg-gradient-scroll-end",
                                "tablet:bottom-[17px] tablet:inset-x-[1px] pointer-events-none",
                              )}
                            />

                            <Button
                              block
                              variant="outlined"
                              onClick={handleConfirm}
                              disabled={!selectedValues?.length}
                              className="tablet:hidden"
                            >
                              Confirm Selection
                            </Button>
                          </div>
                        </Transition.Child>
                      </>
                    );
                  }}
                </Popover.Panel>
              </Transition>
            </div>
          </Popover>
        );
      }}
    />
  );
};

Multiselect.Option = Option;
Multiselect.Selected = Selected;

export default Multiselect;
