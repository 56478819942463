import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useTitle from "hooks/useTitle";
import { useGetPatientsQuery } from "api";
import {
  Button,
  PawIcon,
  Loader,
  PatientOverviewCard,
  EmptyStateCard,
  Notice,
  Status,
  WarningIcon,
} from "@mwi/ui";
import AddPatientModal from "components/modals/patient/add";
import ViewPatientModal from "components/modals/patient/view";

const PetsPage = (): JSX.Element => {
  useTitle("Your Pets");

  const navigate = useNavigate();
  const { patientId } = useParams();

  const [isViewPatientModalOpen, setIsViewPatientModalOpen] = useState(false);
  const [isAddPatientModalOpen, setIsAddPatientModalOpen] = useState(false);
  const [hidePictureNotice, setHidePictureNotice] = useState(
    localStorage.getItem("hidePatientPictureNotice") ?? false,
  );

  const { data: patients, isFetching, isError } = useGetPatientsQuery();

  useEffect(() => {
    if (patientId) {
      if (patientId === "add") {
        setIsAddPatientModalOpen(true);
      } else {
        setIsViewPatientModalOpen(true);
      }
    }
  }, [patientId]);

  const handleCloseViewProfile = () => {
    setIsViewPatientModalOpen(false);
    navigate("/pets");
  };

  const handleCloseAdd = () => {
    setIsAddPatientModalOpen(false);
    navigate("/pets");
  };

  const handleCloseNotice = () => {
    localStorage.setItem("hidePatientPictureNotice", "1");
    setHidePictureNotice(true);
  };

  return (
    <div className="flex flex-col flex-grow">
      <div className="mb-10 tablet:mb-12">
        <h2 className="text-4xl leading-[43px] tablet:text-5xl font-bold">
          Your pets
        </h2>
      </div>

      {!hidePictureNotice &&
        (patients?.filter((p) => !p.profile_image)?.length ?? 0) > 0 && (
          <Notice className="mb-10" onClose={handleCloseNotice}>
            Try adding a picture to your pets!
          </Notice>
        )}

      <div className="mb-5">
        {isError && (
          <>
            <div className="grid order-1 w-full tablet:order-2 place-content-center">
              <Status
                danger
                graphic={<WarningIcon />}
                description="Unable to load past appointments. Please try again later."
              />
            </div>
          </>
        )}

        {isFetching && (
          <div className="grid w-full py-8 place-content-center">
            <Loader />
          </div>
        )}

        {!isFetching &&
          (patients && patients?.length > 0 ? (
            <div className="grid grid-cols-1 gap-6 desktop:grid-cols-2">
              {patients.map((patient) => (
                <PatientOverviewCard
                  showViewProfile
                  key={patient.id}
                  patient={patient}
                  onClick={() => navigate(`/pets/${patient.id}`)}
                />
              ))}
            </div>
          ) : (
            <EmptyStateCard
              icon={<PawIcon />}
              title="No registered pets"
              message="You have no registered pets with your practice"
            />
          ))}
      </div>

      <div className="mt-auto tablet:mt-9">
        <div className="w-full tablet:max-w-xs">
          <Button block type="link" to="/pets/add">
            Add Pet
          </Button>
        </div>
      </div>

      <ViewPatientModal
        isOpen={isViewPatientModalOpen}
        onClose={handleCloseViewProfile}
      />

      <AddPatientModal
        isOpen={isAddPatientModalOpen}
        onClose={handleCloseAdd}
      />
    </div>
  );
};

export default PetsPage;
