import { useSelector } from "react-redux";

import { RootState } from "store";
import { Modal, TermsTemplate } from "@mwi/ui";

type TermsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const TermsModal = ({ isOpen, onClose }: TermsModalProps): JSX.Element => {
  const practice = useSelector((state: RootState) => state.practice);

  return (
    <Modal isOpen={isOpen} onClose={onClose} padMobile>
      <Modal.Content>
        <div className="flex flex-col gap-16 tablet:gap-8">
          <div className="flex flex-col gap-4">
            <p className="text-xl font-semibold tablet:text-2xl">
              Terms & Conditions
            </p>
          </div>
          <TermsTemplate
            email={practice?.email ?? null}
            phone={practice?.phone ?? null}
            address={practice?.address ?? null}
            displayName={practice?.displayName ?? null}
            privacyLink={practice?.privacyLink ?? null}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default TermsModal;
