import classNames from "classnames";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { FileRejection, ErrorCode } from "react-dropzone";

type MimeTypes = "image/png" | "image/jpeg";

type MimeSubTypes = ".jpg" | ".jpeg" | ".png";

type AcceptedMimes = Record<MimeTypes, MimeSubTypes[]>;

type DroppableProps = {
  maxFiles?: number;
  disabled?: boolean;
  inputStyling?: string;
  maxSizeInBytes?: number;
  containerStyling?: string;
  acceptedMimes?: AcceptedMimes;
  onChange: (files: File[]) => void;
  children: JSX.Element | JSX.Element[];
};

const Droppable = ({
  children,
  disabled,
  onChange,
  maxFiles = 1,
  inputStyling,
  acceptedMimes = { "image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"] },
  containerStyling,
  maxSizeInBytes = 2097152, // 2 MB
}: DroppableProps) => {
  const [filesRejections, setFilesRejections] = useState<string[]>([]);

  const handleFileRejection = (fileRejections: FileRejection[]) => {
    setFilesRejections(
      fileRejections
        .map((fileErrors) => {
          return fileErrors.errors
            .map((fileError) => {
              switch (fileError.code) {
                case ErrorCode.FileTooLarge:
                  return `File is too large. It must not exceed ${maxSizeInBytes / 1024 / 1024}MB`;
                case ErrorCode.TooManyFiles:
                  return `You can only upload a maximum of ${maxFiles} files.`;
                case ErrorCode.FileInvalidType:
                  return "File type must be PNG or JPEG.";
                default:
                  return "";
              }
            })
            .filter((m) => m);
        })
        .flat(),
    );
  };

  return (
    <Dropzone
      onDrop={onChange}
      disabled={disabled}
      maxFiles={maxFiles}
      useFsAccessApi={false}
      accept={acceptedMimes}
      maxSize={maxSizeInBytes}
      onDropAccepted={() => setFilesRejections([])}
      onDropRejected={handleFileRejection}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps({
            className: classNames(
              "px-20 py-16 h-full w-full",
              "cursor-pointer",
              "flex flex-col items-center justify-center",
              "border border-dashed border-primary",
              {
                "cursor-default": disabled,
              },
              containerStyling,
            ),
          })}
        >
          <input {...getInputProps({ className: inputStyling })} />
          {children}
          <div className="mt-2">
            {filesRejections &&
              filesRejections?.length > 0 &&
              filesRejections?.map((rejection, idx) => (
                <p key={idx} className="text-base font-bold text-error">
                  {rejection}
                </p>
              ))}
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default Droppable;
