import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import Router from "./router";
import { Provider } from "react-redux";
import { store } from "./store";
import { NotifierContextProvider } from "react-headless-notifier";

const notifierConfig = {
  max: null,
  duration: 5000,
  position: "topRight",
};

ReactDOM.render(
  <React.StrictMode>
    <NotifierContextProvider config={notifierConfig}>
      <Provider store={store}>
        <Router />
      </Provider>
    </NotifierContextProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
