import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  Avatar,
  Label,
  FormGroup,
  Button,
  TextArea,
  MapPinIcon,
  InlineLink,
  CalendarEventIcon,
} from "@mwi/ui";
import { useGetPatientsQuery } from "api";
import { BookingFlowSteps, setStep } from "slices/booking";
import { SelectedPatient } from "types/booking-flow/SelectedPatient";
import {
  useCreateAppointmentsMutation,
  useGetReasonsQuery,
  useGetSiteQuery,
} from "api";
import { DateTime } from "luxon";
import { gtmEvent } from "helpers/gtm";

type Inputs = {
  selected_patients: SelectedPatient[];
};

const BookAppointmentModalStep4 = (): JSX.Element => {
  const dispatch = useDispatch();

  const { vetSite, selectedSlot, registeredSite, selectedPatients } =
    useSelector((state: RootState) => ({
      registeredSite: state.auth.registered_site,
      vetSite: state.bookingFlow.formState.vet_site_uuid,
      selectedSlot: state.bookingFlow.formState.selected_slot,
      selectedPatients: state.bookingFlow.formState.patients,
    }));

  const { register, getValues, watch } = useForm<Inputs>({
    defaultValues: {
      selected_patients: selectedPatients,
    },
  });

  const { data: patients } = useGetPatientsQuery();

  const { data: reasons } = useGetReasonsQuery({
    locationUuid: vetSite ? vetSite : registeredSite!.id,
  });

  const { data: site } = useGetSiteQuery(
    vetSite ? vetSite : registeredSite!.id,
  );

  const [
    createAppt,
    { error: apptError, isLoading: isCreating, isSuccess: didCreateAppt },
  ] = useCreateAppointmentsMutation();

  const handleConfirmBooking = () => {
    createAppt({
      vet_site_uuid: vetSite,
      selected_slot: selectedSlot,
      patients: getValues("selected_patients"),
    });
  };

  useEffect(() => {
    if (didCreateAppt || apptError) {
      dispatch(
        setStep({
          step: "5",
          appointment_confirmed: didCreateAppt,
          error_message: (apptError as any)?.data?.message,
        }),
      );
    }
  }, [didCreateAppt, apptError]);

  const handleLink = (step: BookingFlowSteps) => {
    // persist any comments made before editing a prev step
    dispatch(
      setStep({
        step: step,
        patients: getValues("selected_patients"),
      }),
    );
  };

  useEffect(
    () => gtmEvent({ name: "onDesktopApptFlowConfirmApptPageView" }),
    [],
  );

  return (
    <>
      <div className="mb-12">
        <h1 className="text-4xl leading-[43px] font-bold">
          Review your appointment
        </h1>
      </div>

      <div className="mb-14">
        <div className="flex justify-between gap-x-4">
          <div className="max-w-[360px] w-full">
            {selectedPatients && patients && patients?.length > 0 && (
              <div className="overflow-y-auto max-h-[310px] flex flex-col gap-8 px-1">
                {selectedPatients.map((selectedPatient, idx) => {
                  const patient = patients?.find(
                    (p) => selectedPatient.patient_uuid === p.id,
                  );
                  const reason = reasons?.data?.find(
                    (r) => r.id === selectedPatient.reason_uuid,
                  );

                  if (!patient) {
                    return <></>;
                  }

                  return (
                    <div className="w-full" key={selectedPatient.patient_uuid}>
                      <div className="mb-4">
                        <div className="flex items-center gap-3">
                          <div className="flex-shrink-0">
                            <Avatar
                              isPatient
                              name={patient.name}
                              url={patient.profile_image}
                              className="w-[60px] h-[60px]"
                            />
                          </div>

                          <div className="self-center">
                            <h3 className="text-lg leading-[18px] font-semibold">
                              {patient.name}
                            </h3>
                            {patient.species_name && patient.breed_name && (
                              <span className="text-sm leading-[14px]">
                                {`${patient.species_name}, ${patient.breed_name}`}
                              </span>
                            )}
                          </div>
                        </div>
                        {(reason?.translation || reason?.name) && (
                          <div className="mt-4">
                            <span className="text-base text-focus-grey">
                              {reason?.translation ?? reason?.name}
                            </span>
                          </div>
                        )}
                      </div>

                      <FormGroup hideError>
                        <Label className="!text-sm !mb-3">
                          Additional Notes:
                        </Label>
                        <div className="pb-[2px]">
                          <TextArea
                            rows={2}
                            maxLength={250}
                            className="!p-3 !text-base"
                            placeholder="Please provide more detail on the reason for your visit."
                            {...register(`selected_patients.${idx}.comment`)}
                          />
                          <p className="mt-2 text-sm text-grey-dark tabular-nums">
                            {watch(`selected_patients.${idx}.comment`).length}
                            /250
                          </p>
                        </div>
                      </FormGroup>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="max-w-[360px] w-full">
            {site && (
              <div className="mb-8">
                <div className="mb-2">
                  <h3 className="text-base font-semibold">Vet Site Location</h3>
                </div>

                <div className="flex items-center gap-2">
                  <div className="self-start flex-shrink-0 w-6 h-6">
                    <MapPinIcon className="text-grey-dark" />
                  </div>
                  <span className="text-lg">
                    {`${site.name}${site.city ? `, ${site.city}` : ""}`}
                  </span>
                  <InlineLink size="medium" onClick={() => handleLink("3a")}>
                    Edit
                  </InlineLink>
                </div>
              </div>
            )}

            {selectedSlot && selectedSlot?.start_time && (
              <div className="mb-8">
                <div className="mb-2">
                  <h3 className="text-base font-semibold">Date</h3>
                </div>

                <div className="flex items-center gap-2">
                  <div className="self-start flex-shrink-0 w-6 h-6">
                    <CalendarEventIcon className="text-grey-dark" />
                  </div>
                  <span className="text-lg">
                    {`
                      ${DateTime.fromISO(selectedSlot.start_time).toFormat("d LLLL yyyy, hh.mm a")} 
                      - 
                      ${DateTime.fromISO(selectedSlot.end_time).toFormat("hh.mm a")}
                    `}
                  </span>
                  <InlineLink onClick={() => handleLink("3")} size="medium">
                    Edit
                  </InlineLink>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="max-w-[200px] w-full">
        <Button
          block
          size="medium"
          loading={isCreating}
          onClick={handleConfirmBooking}
        >
          Confirm Booking
        </Button>
      </div>
    </>
  );
};

export default BookAppointmentModalStep4;
