import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import Practice from "api/types/models/practice";
import { QueryTags } from "api/types/QueryTags";

const practiceEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    QueryTags,
    "data"
  >,
) => ({
  getPractice: builder.query<Practice, number | string>({
    query: (idOrSubdomain) => `/practices/${idOrSubdomain}`,
    providesTags: ["Practice"],
  }),
});

export default practiceEndpoints;
