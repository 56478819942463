import classNames from "classnames";
import Item from "../../navigation/navbar/item";
import Action from "../../navigation/navbar/action";
import { ReactNode } from "react";
import PracticeLogo from "../../practice_logo";

type NavbarProps = {
  practiceLogoUrl?: string;
  practiceName?: string;
  children: ReactNode;
};

const Navbar = ({
  children,
  practiceLogoUrl,
  practiceName,
}: NavbarProps): JSX.Element => {
  return (
    <div
      className={classNames(
        "fixed bottom-0 inset-x-0 bg-white z-50 shadow-navbar transition-colors",
        "tablet:right-auto tablet:w-20 desktop:w-[230px] tablet:inset-y-0 tablet:block tablet:bg-dark",
      )}
    >
      <div className="flex flex-col flex-grow">
        <div className="hidden w-full mt-8 mb-20 tablet:block desktop:pl-11">
          {practiceLogoUrl ? (
            <PracticeLogo
              logo={practiceLogoUrl}
              name={practiceName}
              className="tablet:w-[60px] tablet:mx-auto desktop:mx-0 desktop:w-[150px]"
            />
          ) : (
            <div className="px-1">
              <h3 className="text-center text-white break-words select-none desktop:text-left tablet:text-lg desktop:text-2xl">
                {practiceName}
              </h3>
            </div>
          )}
        </div>

        <div className="flex justify-between mx-4 mt-3 mb-5 tablet:flex-col gap-y-4 tablet:mx-0">
          {children}
        </div>
      </div>
    </div>
  );
};

Navbar.Item = Item;
Navbar.Action = Action;

export default Navbar;
