import useTitle from "hooks/useTitle";
import {
  Button,
  Loader,
  ErrorMessage,
  EmptyStateCard,
  CalendarOffIcon,
  AppointmentStatCard,
  PastAppointmentCard,
} from "@mwi/ui";
import UpcomingAppointmentCard from "components/cards/upcoming_appointment";
import ViewAppointmentModal from "components/modals/appointment/view";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetAppointmentsQuery, useGetAppointmentStatsQuery } from "api";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "store";

const AppointmentsPage = () => {
  useTitle("Your Appointments");

  const tablet = window.innerWidth > 1024;

  const navigate = useNavigate();

  const appointmentsBlocked = useSelector(
    (state: RootState) => state.practice.appointments_blocked,
  );

  const {
    data: appointments,
    isError: isErrorAppointments,
    isLoading: isLoadingAppointments,
  } = useGetAppointmentsQuery();

  const {
    data: stats,
    isError: isErrorStats,
    isLoading: isLoadingStats,
  } = useGetAppointmentStatsQuery();

  const { appointmentId } = useParams();
  const [showViewAppointmentModal, setViewAppointmentModal] = useState(false);

  useEffect(() => {
    appointmentId && !isLoadingAppointments
      ? setViewAppointmentModal(true)
      : setViewAppointmentModal(false);
  }, [appointmentId, isLoadingAppointments]);

  return (
    <>
      <div className="mb-11">
        <h1 className="text-4xl font-bold tablet:text-5xl">Appointments</h1>
      </div>

      <div className="mb-11">
        {isLoadingStats && (
          <div className="grid w-full place-content-center">
            <Loader />
          </div>
        )}

        {isErrorStats && (
          <ErrorMessage message="We're unable to load your appointment stats at the moment. Please try again later." />
        )}

        {/* Stats Cards */}

        {!isLoadingStats && !isErrorStats && (
          <>
            {/* Mobile */}
            <div className="relative block w-full overflow-auto tablet:hidden full-width scrollbar-none">
              <div className="flex w-full gap-4 px-6 py-2 overflow-x-auto snap-x scrollbar-none scroll-smooth">
                {stats?.map((stat, index) => (
                  <div
                    key={index}
                    className="flex flex-shrink-0 phablet:max-w-[45vw] w-full scroll-mx-4 snap-start"
                  >
                    <AppointmentStatCard
                      key={index}
                      timespan={stat.timespan}
                      subheading={stat.subheading}
                      patientName={stat.patient_name}
                      image={stat.image}
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Desktop */}
            <div className="hidden grid-cols-1 gap-6 tablet:grid tablet:grid-cols-3 tablet:gap-8">
              {stats?.map((stat, index) => (
                <AppointmentStatCard
                  key={index}
                  timespan={stat.timespan}
                  subheading={stat.subheading}
                  patientName={stat.patient_name}
                  image={stat.image}
                />
              ))}
            </div>
          </>
        )}
      </div>

      {/* Appointment Cards */}

      <div className="grid gap-6 grid-col-1 tablet:grid-cols-10">
        <div className="tablet:col-span-4">
          <div className="mb-4 tablet:mb-8">
            <h2 className="text-lg font-semibold tablet:text-[21px] tablet:font-bold">
              Upcoming appointments
            </h2>
          </div>

          {isErrorAppointments && (
            <ErrorMessage message="We're unable to get your appointments. Please try again later." />
          )}

          {appointments?.upcoming && appointments.upcoming?.length > 0 ? (
            <>
              {/* Mobile */}
              <div className="relative block w-full overflow-auto tablet:hidden full-width scrollbar-none">
                <div className="flex w-full gap-4 px-4 py-2 overflow-x-auto snap-x scrollbar-none scroll-smooth">
                  {appointments.upcoming.map((app) => (
                    <div
                      key={app.id}
                      className={classNames(
                        "flex flex-shrink-0 w-full scroll-mx-4 snap-start",
                        {
                          "max-w-[90vw]": appointments.upcoming.length > 1,
                        },
                      )}
                    >
                      <UpcomingAppointmentCard
                        key={app.id}
                        appointment={app}
                        to={`/appointments/${app.id}`}
                      />
                    </div>
                  ))}
                </div>
              </div>

              {/* Desktop */}
              <div className="hidden tablet:flex tablet:flex-col tablet:gap-y-4">
                {appointments.upcoming.map((app) => (
                  <UpcomingAppointmentCard
                    key={app.id}
                    appointment={app}
                    to={`/appointments/${app.id}`}
                  />
                ))}
              </div>
            </>
          ) : (
            <EmptyStateCard
              icon={<CalendarOffIcon />}
              title="No upcoming appointments"
              message="You have no appointments scheduled"
              cta={
                appointmentsBlocked ? undefined : (
                  <Button
                    type="link"
                    size="small"
                    variant="outlined"
                    to={tablet ? "/home" : "/appointments/book"}
                  >
                    Book Now
                  </Button>
                )
              }
            />
          )}
        </div>

        <div className="tablet:col-span-5 tablet:col-start-6">
          <div className="mb-6 tablet:mb-8">
            <h2 className="text-lg font-semibold tablet:text-[21px] tablet:font-bold">
              Past appointments
            </h2>
          </div>
          <div className="flex flex-col gap-y-4">
            {appointments?.past && appointments.past?.length > 0 ? (
              <>
                {appointments.past.map((appointment) => (
                  <PastAppointmentCard
                    key={appointment.id}
                    appointment={appointment}
                    to={`/appointments/${appointment.id}`}
                  />
                ))}
              </>
            ) : (
              <EmptyStateCard
                icon={<CalendarOffIcon />}
                title="No past appointments"
                message="You have no past appointments scheduled"
                cta={
                  appointmentsBlocked ? undefined : (
                    <Button
                      type="link"
                      size="small"
                      variant="outlined"
                      to={tablet ? "/home" : "/appointments/book"}
                    >
                      Book Now
                    </Button>
                  )
                }
              />
            )}
          </div>
        </div>
      </div>

      <ViewAppointmentModal
        isOpen={showViewAppointmentModal}
        onClose={() => navigate("/appointments", { replace: true })}
      />
    </>
  );
};

export default AppointmentsPage;
