import classNames from "classnames";
import { createRef, useState } from "react";
import { Controller } from "react-hook-form";

import ImagePreview from "./preview";
import Droppable from "../droppable";
import FormError from "../../form/error";
import InlineLink from "../../navigation/inline_link";
import CloudUploadIcon from "../../../icons/CloudUploadIcon";
import Loader from "../../indicators/loader";

type UploadProps = {
  name: string;
  control: any;
  type: string;
  text?: string;
  uploadWidth?: number;
  description?: string;
  droppable?: boolean;
  defaultUrl?: string | null;
  containerClassName?: string;
  uploadFile: (params: {
    file: File;
    type: string;
    height?: number;
    width?: number;
  }) => { unwrap: () => Promise<{ id: number }> };
  deleteFile: (id: number) => Promise<{}> | void;
  renderPreview?:
    | ((params: {
        previewUrl: string;
        removeImage?: () => void;
      }) => JSX.Element)
    | JSX.Element
    | JSX.Element[];
};

const Upload = ({
  name,
  control,
  type,
  text,
  droppable,
  uploadFile,
  defaultUrl,
  deleteFile,
  description,
  renderPreview,
  containerClassName,
}: UploadProps) => {
  const inputRef = createRef<HTMLInputElement>();
  const [fileId, setFileId] = useState<number>();
  const [previewUrl, setPreviewUrl] = useState<string | null>(
    defaultUrl ?? null,
  );
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const triggerSelect = () => {
    inputRef.current?.click();
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const handleChange = async (files: File[] | FileList | null) => {
          if (files && files?.length) {
            setError(undefined);

            const preview = URL.createObjectURL(files[0]);
            setPreviewUrl(preview);

            try {
              setLoading(true);
              const uploadedFile = await uploadFile({
                file: files[0],
                type,
              }).unwrap();

              setFileId(uploadedFile.id);

              field.onChange({ id: uploadedFile.id, url: preview });

              setLoading(false);
            } catch (err: any) {
              if (err?.data?.errors) {
                const message = err.data.errors.find(
                  (e: any) => e.field === name,
                )?.message;
                if (message) {
                  setError(message);
                }
              }
            }
          }
        };

        const removeImage = async () => {
          if (fileId && !droppable) {
            await deleteFile(fileId);
          }

          setPreviewUrl(null);
          setFileId(undefined);
          field.onChange({ id: null, url: null });
        };

        if (previewUrl && !droppable) {
          return (
            <div className="flex items-center">
              <div className="relative w-24 h-24 mr-4 rounded-full">
                <img
                  src={previewUrl}
                  className="object-cover w-full h-full rounded-full"
                  alt=""
                />
                {loading && (
                  <div className="absolute inset-0 grid rounded-full place-items-center bg-black/10">
                    <Loader />
                  </div>
                )}
              </div>
              <InlineLink onClick={removeImage}>Remove Image</InlineLink>
            </div>
          );
        }

        if (droppable && previewUrl && field.value?.url === previewUrl) {
          return (
            <div className={containerClassName}>
              {renderPreview ? (
                typeof renderPreview === "function" ? (
                  renderPreview({ previewUrl, removeImage })
                ) : (
                  renderPreview
                )
              ) : (
                <ImagePreview url={field.value?.url} onCancel={removeImage} />
              )}
            </div>
          );
        }

        return (
          <>
            {droppable ? (
              <div className={containerClassName}>
                <Droppable
                  disabled={!!field.value?.url}
                  onChange={(files: File[]) => handleChange(files)}
                  containerStyling="!px-6 !py-0 text-center text-primary !static"
                >
                  <CloudUploadIcon className="w-[45px] mb-4" />
                  <p className="mb-1 text-lg font-bold">{text ? text : ""}</p>
                  <p className="text-base">{description ? description : ""}</p>
                </Droppable>
              </div>
            ) : (
              <>
                <div
                  className={classNames(
                    "flex items-center justify-center w-full px-4 py-6 font-semibold tablet:text-lg",
                    "border border-dashed border-primary cursor-pointer text-primary font-semibold",
                    containerClassName,
                  )}
                  onClick={triggerSelect}
                >
                  <CloudUploadIcon className="w-6 h-6" />
                  <span className="ml-2">{text}</span>
                </div>

                <div className="mt-2">
                  {error ? (
                    <FormError>{error}</FormError>
                  ) : (
                    <p className="text-sm not-sr-only tablet:text-base">
                      &nbsp;
                    </p>
                  )}
                </div>

                <input
                  type="file"
                  accept="image/jpeg, image/jpg, image/png"
                  className="hidden"
                  ref={inputRef}
                  onChange={(e) => handleChange(e.target?.files)}
                />
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default Upload;
