import classNames from "classnames";
import { ReactNode } from "react";
import Item from "../../navigation/tabs/components/Item";

type TabsProps = {
  className?: string;
  children: ReactNode;
};

const Tabs = ({ className, children }: TabsProps) => {
  return (
    <div className={classNames("flex items-center gap-x-6", className)}>
      {children}
    </div>
  );
};

Tabs.Item = Item;

export default Tabs;
