import { Dialog } from "@headlessui/react";
import classNames from "classnames";

const ModalHeader = ({
  title,
  className,
}: {
  title: string;
  className?: string;
}): JSX.Element => {
  return (
    <div
      className={classNames("pt-4 pb-2 tablet:pt-0 tablet:pb-10", className)}
    >
      <Dialog.Title
        as="h2"
        className="text-xl font-bold tablet:text-[1.75rem] capitalize"
      >
        {title}
      </Dialog.Title>
    </div>
  );
};

export default ModalHeader;
