import { TrashIcon } from "@mwi/ui";

type ImagePreviewProps = {
  url: string | undefined;
  onCancel: () => void;
};

const ImagePreview = ({ url, onCancel }: ImagePreviewProps) => {
  return (
    <div className="w-full h-full">
      <button
        type="button"
        onClick={onCancel}
        className="absolute grid w-8 h-8 rounded-full right-2 top-2 place-content-center bg-error-lightest"
      >
        <TrashIcon className="w-6 h-6 pointer-events-none text-error" />
        <span className="sr-only">Discard image</span>
      </button>

      {url && <img className="object-contain w-full h-full" src={url} alt="" />}
    </div>
  );
};

export default ImagePreview;
