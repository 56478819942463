import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import DropdownItem from "../dropdown/item";
import classNames from "classnames";

type DropdownProps = {
  trigger: JSX.Element;
  children: JSX.Element | JSX.Element[];
  containerClass?: string;
};

const Dropdown = ({ trigger, children, containerClass }: DropdownProps) => {
  return (
    <Menu
      as="div"
      className={classNames("relative flex items-center", containerClass)}
    >
      <Menu.Button>{trigger}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`
            absolute -left-[120px] top-full w-40 mt-2 p-1 origin-top-right bg-white
            rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none
          `}
        >
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

Dropdown.Item = DropdownItem;

export default Dropdown;
