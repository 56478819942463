import classNames from "classnames";

type LabelProps = {
  children: JSX.Element | string;
  className?: string;
};

const Label = ({ children, className }: LabelProps) => {
  return (
    <label
      className={classNames(
        "inline-block mb-1 text-sm font-semibold tablet:text-base text-primary tablet:mb-3",
        className,
      )}
    >
      {children}
    </label>
  );
};

export default Label;
