/* eslint-disable max-len */
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import ModalHeader from "../modal/components/header";
import ModalContent from "../modal/components/content";
import ModalFooter from "../modal/components/footer";
import classNames from "classnames";
import CrossIcon from "../../icons/CrossIcon";
import Loader from "../indicators/loader";

type ModalProps = {
  isOpen: boolean;
  className?: string;
  onClose: () => void;
  afterLeave?: () => void;
  loading?: boolean;
  children?: React.ReactNode;
  padMobile?: boolean;
};

const Modal = ({
  onClose,
  children,
  className,
  loading,
  afterLeave,
  isOpen = false,
  padMobile = false,
}: ModalProps): JSX.Element => {
  return (
    <Transition show={isOpen} as={Fragment} afterLeave={afterLeave}>
      <Dialog onClose={onClose} className="relative z-[999]">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className={classNames(
              "fixed inset-0 justify-center w-full tablet:p-4 flex items-center",
              {
                "px-2": padMobile,
              },
            )}
          >
            <Dialog.Panel
              className={classNames(
                "relative z-20 max-h-full w-full h-full tablet:max-w-[892px] bg-white px-4 py-20 tablet:p-10 tablet:h-min overflow-y-auto",
                className,
              )}
            >
              {/* Close button */}
              <div className="absolute right-6 top-6">
                <button
                  type="button"
                  className="p-1 rounded-full outline-none cursor-pointer focus-visible:bg-grey-lightest hover:bg-grey-lightest active:bg-grey-light"
                  onClick={onClose}
                >
                  <CrossIcon className="w-6 h-6" />
                </button>
              </div>

              {loading && (
                <div className="grid h-full place-content-center">
                  <Loader />
                </div>
              )}

              {!loading && children}
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;

export default Modal;
