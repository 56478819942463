/* eslint-disable max-len */
const SuccessIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5 12.5L10 17.5L20 7.5"
        stroke="#14532D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SuccessIcon;
