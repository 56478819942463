import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

const useTitle = (title: string) => {
  const { practice } = useSelector((state: RootState) => ({
    practice: state.practice,
  }));

  useEffect(() => {
    document.title = title
      ? `${title} • ${practice?.displayName ? `${practice.displayName}` : "MWI Pet"}`
      : "MWI Pet";
  }, [title, practice]);
};

export default useTitle;
