import classNames from "classnames";
import { MouseEventHandler, ReactNode } from "react";

type PropTypes = {
  value: string;
  children: ReactNode;
  disabled?: boolean;
  selected?: boolean;
  onClick?: MouseEventHandler;
};

const SelectOption = ({
  selected,
  children,
  onClick = () => {},
}: PropTypes) => {
  return (
    <div
      onClick={onClick}
      className={classNames("p-4 text-lg transition", {
        "bg-grey-lightest rounded-2xl font-semibold truncate !pr-10": selected,
      })}
    >
      {children}
    </div>
  );
};

export default SelectOption;
