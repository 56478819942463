import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import User from "api/types/models/user";
import Practice from "api/types/models/practice";
import { QueryTags } from "api/types/QueryTags";
import {
  LoginRequest,
  ForgotPasswordRequest,
  UpdatePasswordRequest,
  VerifyPasswordRequest,
  VerifyAccountRequest,
} from "api/types/requests";
import CreateLoginResponse from "api/types/responses/auth/create_login";
import GetMeResponse from "api/types/responses/auth/get_me";

const authEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    QueryTags,
    "data"
  >,
) => ({
  getMe: builder.query<GetMeResponse, void>({
    query: () => "/auth/me",
    providesTags: ["AuthMe"],
  }),

  login: builder.mutation<CreateLoginResponse, LoginRequest>({
    query: (body) => ({
      url: "/auth/local/login",
      method: "post",
      body,
    }),
  }),

  logout: builder.mutation<void, void>({
    query: () => ({
      url: "/auth/local/logout",
      method: "post",
    }),
  }),

  verifyPassword: builder.mutation<void, VerifyPasswordRequest>({
    query: (body) => ({
      url: "/password/verify",
      method: "post",
      body,
    }),
  }),

  forgotPassword: builder.mutation<void, ForgotPasswordRequest>({
    query: (body) => ({
      url: "/password/forgot",
      method: "post",
      body,
    }),
  }),

  updatePassword: builder.mutation<void, UpdatePasswordRequest>({
    query: (body) => ({
      url: "/password/update",
      method: "put",
      body,
    }),
  }),

  verifyAccount: builder.mutation<
    { user: User; practice: Practice },
    VerifyAccountRequest
  >({
    query: (body) => ({
      url: "/auth/verify",
      method: "post",
      body,
    }),
  }),

  getHeartbeat: builder.query<void, void>({
    query: () => "/auth/heartbeat",
  }),
});

export default authEndpoints;
