import { configureStore } from "@reduxjs/toolkit";
import dataApi from "./api";
import authReducer from "./slices/auth";
import practiceReducer from "./slices/practice";
import registrationReducer from "./slices/registration";
import bookingReducer from "./slices/booking";
import prescriptionReducer from "slices/prescription";

const appReducers = {
  auth: authReducer,
  practice: practiceReducer,
  registration: registrationReducer,
  bookingFlow: bookingReducer,
  prescription: prescriptionReducer,
};

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(dataApi.middleware),
  reducer: {
    ...appReducers,
    [dataApi.reducerPath]: dataApi.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
