import { DateTime } from "luxon";
import classNames from "classnames";
import Avatar from "../../avatar";
import Button from "../../buttons/button";

type PatientOverviewCardProps = {
  reason?: string;
  patient: {
    name: string;
    profile_image?: string;
    species_name: string | null;
    breed_name: string | null;
    last_appointment_date: string;
    last_appointment_reason: string;
  };
  isSelected?: boolean;
  onClick?: () => void;
  onViewProfile?: () => void;
  showViewProfile?: boolean;
  showLastAppointment?: boolean;
  comment?: string;
};

const PatientOverviewCard = ({
  reason,
  patient,
  onClick,
  isSelected,
  onViewProfile,
  showViewProfile = false,
  showLastAppointment = false,
  comment,
}: PatientOverviewCardProps): JSX.Element => {
  return (
    <div
      onClick={onClick}
      className={classNames("w-full bg-white shadow-default", {
        "shadow-focus-large transition-all": isSelected,
        "cursor-pointer": onClick,
      })}
    >
      <div className="flex flex-col justify-between h-full p-4 tablet:p-6 gap-x-3 tablet:flex-row">
        <div className="flex gap-3 fitems-center">
          <div className="flex-shrink-0">
            <Avatar
              isPatient
              name={patient.name}
              url={patient.profile_image}
              className="w-[60px] h-[60px] tablet:w-[72px] tablet:h-[72px]"
            />
          </div>

          <div className="self-center">
            <h3 className="text-lg leading-[18px] font-semibold">
              {patient.name}
            </h3>
            <span className="text-sm leading-[14px]">
              {patient.species_name && patient.breed_name
                ? `${patient.species_name}, ${patient.breed_name}`
                : "Unknown"}
            </span>
          </div>
        </div>

        {showLastAppointment && (
          <div className="mt-4 tablet:hidden">
            {patient.last_appointment_date &&
            patient.last_appointment_reason ? (
              <>
                <p className="text-grey-dark text-tablet leading-[24px] font-semibold">
                  {`Last Appointment: ${DateTime.fromISO(patient.last_appointment_date).toRelative()}`}
                </p>
                <span className="text-grey-dark leading-[24px] font-normal">
                  {patient.last_appointment_reason}
                </span>
              </>
            ) : (
              <span className="text-grey-dark text-tablet leading-[24px] font-semibold">
                No previous appointments
              </span>
            )}
          </div>
        )}

        {showViewProfile && (
          <div className="flex-shrink-0 mt-4 tablet:mt-0 tablet:mb-auto">
            <Button
              size="small"
              onClick={onViewProfile}
              variant="outlined"
              className="!border-0 px-0 py-0 tablet:py-[6px] tablet:px-5 tablet:!border-2 focus:shadow-none"
            >
              <span className="text-sm tablet:text-xs">View Profile</span>
            </Button>
          </div>
        )}

        {reason && (
          <div className="mt-4">
            <span className="text-base text-focus-grey">{reason}</span>
          </div>
        )}

        {comment && <p className="tablet:text-lg">{comment}</p>}
      </div>
    </div>
  );
};

export default PatientOverviewCard;
