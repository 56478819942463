import classNames from "classnames";
import React, { FocusEventHandler, forwardRef } from "react";

type TextAreaProps = {
  id?: string;
  name: string;
  rows?: number;
  value?: string;
  className?: string;
  onBlur: FocusEventHandler;
  columns?: number;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  disabled?: boolean;
  maxLength?: number;
};

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(
    {
      id,
      name,
      value,
      onBlur,
      onChange,
      rows = 3,
      className,
      columns = 1,
      placeholder,
      disabled = false,
      maxLength,
    },
    ref,
  ) {
    return (
      <textarea
        id={id}
        ref={ref}
        rows={rows}
        value={value}
        cols={columns}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        maxLength={maxLength}
        className={classNames(
          "transition appearance-none",
          "block w-full p-4 bg-white tablet:text-lg",
          "leading-6 text-black placeholder:text-focus-grey",
          "border border-primary focus:border-light rounded-none",
          "shadow-default focus:shadow-focus-small",
          "focus:outline-none disabled:bg-grey-light",
          className,
        )}
      />
    );
  },
);

export default TextArea;
