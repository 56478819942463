import classNames from "classnames";
import { Link, LinkProps } from "react-router-dom";

type PropTypes = Partial<LinkProps> & {
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  underline?: boolean;
  target?: string;
};

const InlineLink = ({
  to,
  children,
  className,
  disabled = false,
  size = "large",
  underline = true,
  target,
  ...props
}: PropTypes) => {
  const classes = classNames(
    "font-semibold transition cursor-pointer text-primary border-primary pb-[2px] w-max",
    "hover:text-light hover:border-light",
    "focus:outline-none focus:text-light focus:border-light focus:shadow-",
    {
      // Sizes
      "text-sm leading-[21px]": size === "small",
      "text-base": size === "medium",
      "text-lg pb-[4px]": size === "large",

      // Disabled
      "text-grey-dark border-b-grey-dark hover:text-grey-dark hover:border-b-grey-dark cursor-default":
        disabled,

      // Underline
      "border-b-[1px]": underline,
    },
    className,
  );

  if (to) {
    return /^https?:\/\/|tel:|mailto:/i.test(to.toString()) ? (
      <a
        href={to.toString()}
        target={target}
        className={classes}
        rel="noreferrer noopener"
        {...props}
      >
        {children}
      </a>
    ) : (
      <Link to={to} target={target} className={classes} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <>
      {disabled && <span className={classes}>{children}</span>}

      {!disabled && (
        <span className={classes} {...props}>
          {children}
        </span>
      )}
    </>
  );
};

export default InlineLink;
