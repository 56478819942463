import classNames from "classnames";
import { ChangeEventHandler, forwardRef, useRef } from "react";

type PropTypes = {
  name?: string;
  value?: string;
  label: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const Radio = forwardRef<HTMLInputElement, PropTypes>(function Radio(
  { name, value, label, onChange },
  ref,
) {
  const itemRef = useRef<HTMLInputElement | null>();

  return (
    <label
      className={classNames(
        "transition flex items-center gap-3 tablet:text-lg py-2 px-3 -mt-2 -mb-2 rounded",
        {
          "bg-lightest": itemRef.current?.checked,
        },
      )}
    >
      <input
        type="radio"
        ref={(value) => {
          itemRef.current = value;
          if (typeof ref === "function") {
            ref(value);
          }
        }}
        value={value}
        name={name}
        onChange={onChange}
        style={{
          backgroundImage:
            'url("data:image/svg+xml,%3csvg viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27white%27 stroke-width=%272%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M5 13l4 4L19 7%27/%3e%3c/svg%3e")',
          backgroundSize: 0,
        }}
        className={classNames(
          "transition border rounded-none appearance-none w-7 h-7 border-primary checked:bg-primary",
          "hover:border-light",
          "focus:outline-none focus:border-light focus:shadow-focus-large",
        )}
      />
      <p>{label}</p>
    </label>
  );
});

export default Radio;
