import { NotifierNotification } from "@mwi/ui";
import { useCallback } from "react";
import { useNotifier } from "react-headless-notifier";

type ToastParams = {
  title: string;
  message: string;
};

export function useToast() {
  const { notify } = useNotifier();

  const successToast = useCallback((params: ToastParams) => {
    notify(<NotifierNotification {...params} />);
  }, []);

  const errorToast = useCallback((params: ToastParams) => {
    notify(<NotifierNotification type="error" {...params} />);
  }, []);

  return { successToast, errorToast };
}
