import { BackButton, ProgressBar } from "@mwi/ui";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  PrescriptionSteps,
  setPatient,
  setPatientIndex,
  setStep,
} from "slices/prescription";
import { RootState } from "store";
import {
  VerifyPrescriptionsView,
  SelectPatientsView,
  SelectPrescriptionsView,
  ReviewPrescriptionsView,
  PrescriptionStatusView,
} from "../../views";

type ViewParams = { onClose: () => void };

type PrescriptionViews = Record<
  PrescriptionSteps,
  {
    stepIdx: number;
    title?: string;
    description?: string | null;
    errorTitle?: string;
    view: (params: ViewParams) => JSX.Element;
  }
>;

const views: PrescriptionViews = {
  verify: {
    stepIdx: 0,
    view: () => <VerifyPrescriptionsView />,
  },
  patients: {
    stepIdx: 1,
    title: "Who is your prescription request for?",
    description: "You can select more than one pet at a time",
    view: (params?: ViewParams) => <SelectPatientsView {...params} />,
  },
  prescriptions: {
    stepIdx: 2,
    title: "What items are required?",
    description:
      "Select from the list of previously prescribed items or enter a new item below",
    view: () => <SelectPrescriptionsView />,
  },
  review: {
    stepIdx: 3,
    title: "Review your prescription request?",
    description: null,
    view: () => <ReviewPrescriptionsView />,
  },
  confirmation: {
    stepIdx: 4,
    view: (params: ViewParams) => <PrescriptionStatusView {...params} />,
  },
};

const RequestPrescriptionHeading = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { step, patientIndex, selectedPatients } = useSelector(
    (state: RootState) => ({
      step: state.prescription.step,
      patientIndex: state.prescription.patient_index,
      selectedPatients: state.prescription.formState.patients,
    }),
  );

  const activeStep = useMemo(() => views[step], [step]);

  const tablet = window.innerWidth > 1024;

  const handleBack = () => {
    // We might want to add a confirmation prompt in future.
    if (!tablet && (step === "verify" || step === "patients")) {
      navigate("/home", { replace: true });
    }

    if (step === "prescriptions") {
      dispatch(
        setPatient(
          cloneDeep({
            patientIdx: patientIndex,
            updatedPatient: {
              patientUuid: selectedPatients[patientIndex].patientUuid,
              prescriptions: [],
              additionalNotes: null,
            },
          }),
        ),
      );

      if (patientIndex === 0) {
        dispatch(setStep({ step: "patients" }));
        return;
      }

      dispatch(setPatientIndex(patientIndex - 1));
    } else if (step === "review") {
      dispatch(setStep({ step: "prescriptions" }));
    }
  };

  return (
    <>
      {/* Desktop Back Btn */}
      {tablet &&
        step !== "verify" &&
        step !== "patients" &&
        step !== "confirmation" && (
          <div className="mb-8">
            <BackButton onClick={handleBack} />
          </div>
        )}

      {/* Mobile Back Btn */}
      {!tablet && step !== "confirmation" && (
        <div className="mb-8">
          <BackButton onClick={handleBack} />
        </div>
      )}

      {!tablet && activeStep?.stepIdx !== 0 && step !== "confirmation" && (
        <ProgressBar max={3} value={activeStep.stepIdx} showStepIndicator />
      )}

      <div className="px-1 space-y-6">
        <h1 className="text-4xl leading-[43px] font-bold">
          {activeStep.title}
        </h1>
        {activeStep.description && (
          <p className="text-lg">{activeStep.description}</p>
        )}
      </div>
    </>
  );
};

export const PrescriptionRootView = ({
  onClose,
}: {
  onClose: () => void;
}): JSX.Element => {
  const step = useSelector((state: RootState) => state.prescription.step);

  const activeStep = useMemo(() => views[step], [step]);

  return (
    <>
      {/* Heading */}
      <RequestPrescriptionHeading />

      {/* Content */}
      <div className="flex flex-col flex-grow pt-10">
        {activeStep && activeStep.view({ onClose })}
      </div>
    </>
  );
};
