import classNames from "classnames";

type ItemProps = {
  title: string;
  onClick: () => void;
  isSelected: boolean;
};

const Item = ({ title, onClick, isSelected }: ItemProps): JSX.Element => {
  const classes = classNames(
    "outline-none select-none",
    "transition-all w-full",
    "flex items-center justify-center px-4 py-2",
    "bg-grey-lightest border-2 border-transparent",
    "text-grey-dark text-sm leading-[21px] font-semibold",
    {
      "!bg-white shadow-focus-small border-light !text-primary rounded-full":
        isSelected,
    },
  );

  return (
    <button onClick={onClick} className={classes}>
      {title}
    </button>
  );
};

export default Item;
