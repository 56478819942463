import { useEffect } from "react";
import { useNotifier } from "react-headless-notifier";
import { FormProvider, useForm } from "react-hook-form";

import { useRegisterPatientsMutation } from "api";
import useErrorHandler from "hooks/useErrorHandler";
import { Button, Modal, NotifierNotification } from "@mwi/ui";
import CreatePatientForm from "components/patient/create_form";
import { PatientsInputs } from "types/forms/patient/PatientsInputs";
import { gtmEvent } from "helpers/gtm";

type PropTypes = {
  isOpen: boolean;
  onClose: () => void;
};

const AddPatientModal = ({ isOpen, onClose }: PropTypes) => {
  const { notify } = useNotifier();
  const errorHandler = useErrorHandler();

  const methods = useForm<PatientsInputs>();

  const [
    registerPatients,
    {
      error: errorRegisteringPatient,
      isLoading: isRegisteringPatient,
      isSuccess: didRegisterPatient,
    },
  ] = useRegisterPatientsMutation();

  const handleRegisterPatient = (data: PatientsInputs) => {
    const patient = data.patients[0];

    if (patient) {
      registerPatients({
        patients: [
          {
            ...patient,
            colour_uuid:
              patient.colour_uuid !== "optional-breed-colour"
                ? patient.colour_uuid
                : null,
            gender_uuid:
              patient.gender_uuid !== "optional-species-gender"
                ? patient.gender_uuid
                : null,
            profile_image: patient.profile_image?.id,
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (errorRegisteringPatient) {
      errorHandler(errorRegisteringPatient, methods.setError);
    }
  }, [errorRegisteringPatient]);

  useEffect(() => {
    if (didRegisterPatient) {
      gtmEvent({ name: "onPatientRegistered" });
      notify(
        <NotifierNotification
          title="Pet Registered"
          message={`Your pet ${methods.getValues("patients.0.name")} has been registered`}
        />,
      );
      methods.reset();
      onClose();
    }
  }, [didRegisterPatient]);

  const handleAfterLeave = () => {
    methods.reset();
  };

  useEffect(() => {
    if (isOpen) {
      gtmEvent({ name: "onStartPatientRegistration" });
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="tablet:!max-w-[755px]"
      afterLeave={handleAfterLeave}
    >
      <Modal.Content className="h-full">
        <h2 className="text-2xl">Add new pet</h2>

        <form
          className="mt-9"
          onSubmit={methods.handleSubmit(handleRegisterPatient)}
        >
          <FormProvider {...methods}>
            <CreatePatientForm patientIndex={0} />
          </FormProvider>

          <div className="flex items-end flex-grow max-w-sm pb-10 my-4 tablet:pb-0">
            <Button
              block
              type="submit"
              disabled={
                !methods.watch("patients.0.name") ||
                !methods.watch("patients.0.date_of_birth") ||
                !methods.watch("patients.0.species_uuid") ||
                !methods.watch("patients.0.breed_uuid")
              }
              loading={isRegisteringPatient}
            >
              Save
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default AddPatientModal;
