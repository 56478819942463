import Cookies from "js-cookie";
import { useState } from "react";

import { Button, InlineLink } from "@mwi/ui";

const CookiePopup = (): JSX.Element => {
  const [active, setActive] = useState<boolean>(true);

  const handleCookiePreference = (value: string) => {
    Cookies.set("CONSENT", value, { expires: 7 });
    setActive(false);
  };

  if (!active || Cookies.get("CONSENT")) {
    return <></>;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 flex py-8 bg-white border-t-[1px] border-primary transition shadow-default">
      <div className="flex flex-wrap items-center justify-center gap-6 mx-8 tablet:mx-13 tablet:justify-between tablet:flex-nowrap">
        <div className="flex text-center">
          <p className="text-sm tablet:text-base">
            We use cookies and tracking technologies to offer you a better
            browsing experience, analyse site traffic, and improve the website.
            By clicking &quot;Accept Cookies&quot;, you expressly agree to our
            use of cookies and tracking technologies in accordance with our{" "}
            <InlineLink size="small" to="/cookie">
              Cookie Policy
            </InlineLink>
            . If you wish to prevent your data from being used by Google
            Analytics you can opt-out:{" "}
            <InlineLink
              size="small"
              target="_blank"
              to="https://tools.google.com/dlpage/gaoptout"
            >
              Google Analytics Opt Out
            </InlineLink>
          </p>
        </div>
        <div className="flex items-center flex-shrink-0 gap-4">
          <Button
            size="small"
            variant="outlined"
            onClick={() => handleCookiePreference("false")}
          >
            Reject Cookies
          </Button>
          <Button
            className="border-2 !border-primary hover:!border-light"
            size="small"
            onClick={() => handleCookiePreference("true")}
          >
            Accept Cookies
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookiePopup;
