import {
  AlertIcon,
  Button,
  GraphicBackground,
  InlineLink,
  Loader,
} from "@mwi/ui";
import { useVerifyEnabledMutation } from "../../../api";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStep } from "slices/prescription";
import { RootState } from "store";

export const VerifyPrescriptionsView = () => {
  const dispatch = useDispatch();
  const [verifyEnabled, { isError }] = useVerifyEnabledMutation();

  const { practice } = useSelector((state: RootState) => ({
    practice: state.practice,
  }));

  useEffect(() => {
    verifyEnabled()
      .unwrap()
      .then(() => dispatch(setStep({ step: "patients" })))
      // Catch the error to prevent an [object Object] error from appearing if the result is a 400
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }, []);

  if (isError) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="relative w-[240px] h-[193px] flex items-center justify-center mb-10">
          <GraphicBackground className="text-error-lightest" />

          <div className="absolute w-[103px] h-[103px]">
            <AlertIcon className="text-error" />
          </div>
        </div>

        <div className="mb-6">
          <h1 className="text-4xl font-bold leading-[43px] text-center">
            Unable to request prescriptions
          </h1>
        </div>

        <div className="mb-16 max-w-[500px] text-center">
          <p className="text-base mobile:text-lg">
            Unfortunately we are currently not accepting repeat prescriptions
            online at the moment. Please contact the practice to request your
            prescription.
          </p>
        </div>

        <div className="flex flex-col items-center w-full gap-6 mt-auto">
          <span className="tablet:hidden">
            <Button size="medium" type="link" to={`tel:${practice.phone}`}>
              Call practice
            </Button>

            <p className="mt-4 text-center">
              Or email{" "}
              <InlineLink to={`mailto:${practice.email}`}>
                {practice.email}
              </InlineLink>
            </p>
          </span>

          <span className="hidden tablet:block">
            <Button size="medium" type="link" to={`mailto:${practice.email}`}>
              Email practice
            </Button>

            <p className="mt-4 text-center">
              Or call{" "}
              <InlineLink to={`tel:${practice.phone}`}>
                {practice.phone}
              </InlineLink>
            </p>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <Loader />
    </div>
  );
};
