import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ProgressBar, BackButton, PracticeLogo } from "@mwi/ui";
import useTitle from "hooks/useTitle";
import { RootState } from "store";
import RegistrationDetails from "./steps/details";
import RegistrationAddress from "./steps/address";
import RegistrationPractice from "./steps/practice";
import RegistrationPatientVerify from "./steps/patient_verify";
import RegistrationBailout from "./steps/bailout";
import RegistrationCodeVerify from "./steps/code_verify";
import RegistrationSetPassword from "./steps/set_password";
import RegistrationCodeSelect from "./steps/code_select";
import {
  reset,
  setPatientOptions,
  setVerifyMethod,
  setVerifyMethods,
  updateFormState,
  updateOldFormState,
} from "slices/registration";
import RegistrationCreatePatient from "./steps/create_patient";
import { login, logout } from "slices/auth";
import { useEffect, useState } from "react";

const RegisterPage = () => {
  useTitle("Register");

  const dispatch = useDispatch();

  const [step, setStep] = useState(1);

  const {
    isAuthenticated,
    user,
    themeLogo,
    practiceName,
    patientOptions,
    verifyMethods,
    verifyMethod,
    verifiedPatient,
    bailed,
    formState,
    flow,
  } = useSelector((state: RootState) => ({
    isAuthenticated: state.auth.authenticated,
    user: state.auth.user,
    themeLogo: state.practice.theme.logoUrl,
    practiceName: state.practice.name,
    patientOptions: state.registration.patientOptions,
    verifyMethods: state.registration.verifyMethods,
    verifyMethod: state.registration.verifyMethod,
    verifiedPatient: state.registration.formState.verified_patient,
    bailed: state.registration.bailed,
    formState: state.registration.formState,
    flow: state.registration.flow,
  }));

  useEffect(() => {
    if (bailed) return;

    // Step 5: Set password
    if (user?.verified_at && !user.onboarding_completed_at) {
      setStep(5);
      return;
    }

    // Step 6: Create patient(s)
    if (user?.onboarding_completed_at && user.patient_required) {
      setStep(6);
      return;
    }

    // Step 1: Details
    // All flows must enter their details to accept terms, except verified accounts
    if (formState?.email && !formState.phone) {
      setStep(1);
      return;
    }

    if (
      flow === "match" &&
      (patientOptions || verifyMethods?.length || verifyMethod) &&
      !user?.verified_at
    ) {
      setStep(4);
      return;
    }

    if (flow === "register") {
      // Step 2: Address
      if (!formState.postcode) {
        setStep(2);
        return;
      }

      // Step 3: Vet Site
      if (!formState.vet_site || !verifyMethods?.length) {
        setStep(3);
        return;
      }

      // Step 4: Select Verification
      if (verifyMethods?.length && !user?.verified_at) {
        setStep(4);
        return;
      }
    }
  }, [bailed, user, verifyMethods, verifyMethod, formState]);

  const onBack = () => {
    if (step === 1) {
      dispatch(reset());
      if (isAuthenticated) dispatch(logout());
    } else if (step === 2) {
      dispatch(updateFormState({ first_name: "", last_name: "", phone: "" }));
      dispatch(
        updateOldFormState({
          name_no: "",
          line_1: "",
          line_2: "",
          city: "",
          county: "",
          postcode: "",
          vet_site: "",
        }),
      );
    } else if (step === 3) {
      dispatch(
        updateFormState({
          name_no: "",
          line_1: "",
          line_2: "",
          city: "",
          county: "",
          postcode: "",
        }),
      );
      dispatch(updateOldFormState({ vet_site: "" }));
    } else if (step === 4 && patientOptions && !verifyMethods?.length) {
      dispatch(setPatientOptions(undefined));
      dispatch(updateFormState({ first_name: "", last_name: "", phone: "" }));
      dispatch(updateOldFormState({ verified_patient: "" }));
    } else if (step === 4 && verifyMethods?.length && !verifyMethod) {
      dispatch(setVerifyMethods(undefined));
      dispatch(logout());

      if (flow === "match") {
        patientOptions
          ? dispatch(updateFormState({ verified_patient: "" }))
          : dispatch(
              updateFormState({ first_name: "", last_name: "", phone: "" }),
            );
      }
    } else if (step === 4 && verifyMethod) {
      dispatch(setVerifyMethod(undefined));
      dispatch(login({ user: { ...user!, verified_at: undefined } }));
    } else {
      dispatch(reset());
    }
  };

  if (!formState.email && !isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (
    isAuthenticated &&
    user?.onboarding_completed_at &&
    !user?.patient_required
  ) {
    return <Navigate to="/home" />;
  }

  const NavItem = ({
    children,
    disabled = false,
    active = false,
  }: {
    children: string;
    disabled?: boolean;
    active?: boolean;
  }) => {
    return (
      <li
        className={classNames("py-2 pl-8 font-semibold", {
          "text-disabled-grey": disabled,
          "text-primary border-l-4 border-primary": active,
        })}
      >
        {children}
      </li>
    );
  };

  return (
    <>
      <div
        className={classNames(
          "flex items-center tablet:border-b border-element-grey tablet:pb-6",
          {
            "pb-4": isAuthenticated,
          },
        )}
      >
        {!bailed && step !== 5 && step !== 6 && (
          <BackButton onClick={onBack} className="tablet:hidden" />
        )}

        <PracticeLogo
          logo={themeLogo}
          name={practiceName}
          className="hidden tablet:block"
        />
      </div>

      <div className="flex h-full pt-12 tablet:gap-8 desktop:gap-52 tablet:pt-20">
        <ul className="flex-col hidden gap-4 tablet:flex whitespace-nowrap">
          <NavItem active={step === 1}>Step 1: Contact Details</NavItem>
          <NavItem active={step === 2} disabled={step < 2}>
            Step 2: Your Address
          </NavItem>
          <NavItem active={step === 3} disabled={step < 3}>
            Step 3: Preferred Practice
          </NavItem>
          <NavItem active={step === 4} disabled={step < 4}>
            Step 4: Verify Details
          </NavItem>
          <NavItem active={step === 5} disabled={step < 5}>
            Step 5: Set Password
          </NavItem>
          {user?.patient_required && (
            <NavItem active={step === 6} disabled={step < 6}>
              Step 6: Register your pet
            </NavItem>
          )}
        </ul>

        <div className="flex flex-col w-full">
          {!bailed && step !== 5 && step !== 6 && (
            <BackButton onClick={onBack} className="hidden mb-6 tablet:flex" />
          )}

          {!bailed && (flow === "register" || user?.patient_required) && (
            <div className="mb-10 tablet:mb-16">
              <div className="flex-grow">
                <ProgressBar
                  max={user?.patient_required ? 6 : 5}
                  value={step}
                />
                <p className="mt-4 text-sm font-semibold tablet:text-lg">
                  Step {step} of {user?.patient_required ? 6 : 5}
                </p>
              </div>
            </div>
          )}

          <div className="flex flex-col flex-grow col-span-4 tablet:col-span-4">
            {!bailed && step === 1 && <RegistrationDetails />}
            {!bailed && step === 2 && <RegistrationAddress />}
            {!bailed && step === 3 && <RegistrationPractice />}
            {!bailed && step === 4 && patientOptions && !verifiedPatient && (
              <RegistrationPatientVerify />
            )}
            {!bailed &&
              step === 4 &&
              !verifyMethod &&
              (patientOptions ? verifiedPatient : true) &&
              verifyMethods?.length && <RegistrationCodeSelect />}
            {!bailed && step === 4 && verifyMethod && (
              <RegistrationCodeVerify />
            )}
            {!bailed && step === 5 && <RegistrationSetPassword />}
            {!bailed && step === 6 && <RegistrationCreatePatient />}
            {bailed && <RegistrationBailout />}
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
