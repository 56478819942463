import Button from "../../buttons/button";
import Modal from "../../modal";

type ConfirmationModalProps = {
  title?: string;
  isOpen: boolean;
  icon?: JSX.Element;
  description?: string | JSX.Element;
  onClose: () => void;
  onConfirm?: () => void;
  danger?: boolean;
  confirmText?: string;
  confirmLoading?: boolean;
};

const ConfirmationModal = ({
  icon,
  title,
  isOpen,
  onClose,
  description,
  onConfirm,
  danger = false,
  confirmText = "Confirm",
  confirmLoading = false,
}: ConfirmationModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} padMobile>
      <Modal.Content>
        <div className="flex flex-col gap-16 tablet:gap-8">
          <div>
            {icon && (
              <div className="mb-auto">
                <span className="w-8 h-8">{icon}</span>
              </div>
            )}

            <div className="flex flex-col gap-4">
              {title && (
                <p className="text-xl font-semibold tablet:text-2xl">{title}</p>
              )}
              {description && <div className="text-base">{description}</div>}
            </div>
          </div>

          <div className="flex flex-wrap mobile:flex-nowrap self-center mobile:self-end w-full gap-3 mobile:max-w-[350px]">
            <Button block size="medium" onClick={onClose} variant="outlined">
              Cancel
            </Button>
            <Button
              block
              size="medium"
              onClick={onConfirm}
              danger={danger}
              loading={confirmLoading}
            >
              {confirmText}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ConfirmationModal;
