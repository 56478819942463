import { useMemo } from "react";
import classNames from "classnames";
import PawIcon from "../../icons/PawIcon";
import Loader from "../indicators/loader";
import UserCircleIcon from "../../icons/UserCircleIcon";

type AvatarProps = {
  url?: string;
  name?: string;
  className?: string;
  isPatient?: boolean;
  isLoading?: boolean;
};

const Avatar = ({
  url,
  name,
  className,
  isPatient = false,
  isLoading = false,
}: AvatarProps) => {
  const initials = useMemo(() => {
    return name
      ?.split(" ")
      ?.slice(0, 2)
      ?.reduce((p, c) => p + c.charAt(0).toUpperCase(), "");
  }, [name]);

  return (
    <div
      className={classNames(
        "relative rounded-full bg-lightest text-primary flex items-center justify-center",
        className,
      )}
    >
      {isLoading ? (
        <Loader />
      ) : url ? (
        <img
          src={url}
          alt=""
          className="object-cover w-full h-full rounded-full"
        />
      ) : isPatient ? (
        <PawIcon className="absolute scale-[0.6]" />
      ) : initials ? (
        <p className="text-lg font-semibold">{initials.toUpperCase()}</p>
      ) : (
        <UserCircleIcon className="absolute scale-[0.6]" />
      )}
    </div>
  );
};

export default Avatar;
