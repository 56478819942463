import { NotifierNotification } from "@mwi/ui";
import { useNotifier } from "react-headless-notifier";

const useErrorHandler = () => {
  const { notify } = useNotifier();

  return (error: any, setError: Function) => {
    if (
      error?.data?.errors &&
      Object.values(error.data.errors).some((e: any) =>
        e.message.includes("E_TOO_MANY_REQUESTS"),
      )
    ) {
      notify(
        <NotifierNotification
          type="error"
          title="Too many attempts, try again in a few minutes"
          message={error?.data?.message}
        />,
      );
    } else if (error?.data?.errors && setError) {
      const fields: string[] = [];

      error.data.errors.forEach((error: { field: string; message: string }) => {
        if (!fields.includes(error.field)) {
          setError(error.field, { message: error.message });
          fields.push(error.field);
        }
      });
    } else if (error?.data?.message) {
      notify(
        <NotifierNotification
          type="error"
          title="Oops. Looks like an error occurred."
          message={error?.data?.message}
        />,
      );
    } else {
      throw error;
    }
  };
};

export default useErrorHandler;
