import { useLogoutMutation } from "api";
import { Button, FormGroup, Modal, ReadOnly } from "@mwi/ui";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearChangePasswordStep, logout } from "slices/auth";
import { RootState } from "store";
import ChangePasswordPage from "./change-password";

const SettingsAccount = () => {
  const tablet = window.innerWidth > 768;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const user = useSelector((state: RootState) => state.auth.user);

  const [submitLogout] = useLogoutMutation();

  const triggerLogout = async () => {
    await submitLogout();
    dispatch(logout());
  };

  const handleEditPassword = () => {
    tablet
      ? setShowChangePasswordModal(true)
      : navigate("/settings/account/change-password");
  };

  const closeChangePasswordModal = () => {
    setShowChangePasswordModal(false);
    dispatch(clearChangePasswordStep());
  };

  return (
    <>
      <h2 className="my-8 tablet:hidden">Account Details</h2>

      <FormGroup hideError>
        <ReadOnly
          heading="Login Email Address"
          value={user!.email}
          hasDivider
        />
      </FormGroup>

      <FormGroup hideError>
        <ReadOnly
          heading="Contact Email Address"
          value={user!.merlin_email}
          hasDivider
        />
      </FormGroup>

      <FormGroup hideError>
        <ReadOnly
          heading="Password"
          value="•••••••••"
          hasDivider
          actionText="Edit"
          actionOnClick={handleEditPassword}
        />
      </FormGroup>

      <FormGroup hideError>
        <ReadOnly heading="Mobile Number" value={user!.phone} hasDivider />
      </FormGroup>

      <FormGroup hideError>
        <ReadOnly
          heading="Address"
          value={[
            user!.address?.name_no,
            user!.address?.line1,
            user!.address?.line2,
            user!.address?.city,
            user!.address?.county,
            user!.address?.postcode,
          ]}
          hasDivider
        />
      </FormGroup>

      <div className="flex items-end flex-grow mt-4 tablet:max-w-sm">
        <Button variant="outlined" block onClick={triggerLogout}>
          Log out
        </Button>
      </div>

      <Modal
        isOpen={showChangePasswordModal}
        onClose={closeChangePasswordModal}
      >
        <ChangePasswordPage onDone={closeChangePasswordModal} />
      </Modal>
    </>
  );
};

export default SettingsAccount;
