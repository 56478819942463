import { useSelector } from "react-redux";

import { RootState } from "store";
import { BackButton, InlineLink, PrivacyTemplate } from "@mwi/ui";
import { useNavigate } from "react-router-dom";

const PrivacyPage = (): JSX.Element => {
  const navigate = useNavigate();

  const practice = useSelector((state: RootState) => state.practice);

  const handleBack = () => {
    window.history.length > 0 ? navigate(-1) : navigate("/");
  };

  return (
    <div className="flex flex-col text-lg">
      <BackButton className="mb-14" onClick={handleBack} />

      <h1 className="mb-8">Privacy Policy</h1>

      <PrivacyTemplate
        email={practice?.email ?? null}
        phone={practice?.phone ?? null}
        address={practice?.address ?? null}
        displayName={practice?.displayName ?? null}
        privacyLink={practice?.privacyLink ?? null}
      />

      <br />

      <p>
        You can click <InlineLink to="/cookie">here</InlineLink> to view our
        cookie policy.
      </p>
    </div>
  );
};

export default PrivacyPage;
