import classNames from "classnames";
import { useGetAppointmentsQuery } from "api";
import {
  Button,
  EmptyStateCard,
  CalendarOffIcon,
  InlineLink,
  Loader,
  Status,
  WarningIcon,
} from "@mwi/ui";
import UpcomingAppointmentCard from "components/cards/upcoming_appointment";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { gtmEvent } from "helpers/gtm";

const UpcomingAppointmentSection = (): JSX.Element => {
  const { data: appointments, isFetching, isError } = useGetAppointmentsQuery();

  const appointmentsBlocked = useSelector(
    (state: RootState) => state.practice.appointments_blocked,
  );

  const tablet = window.innerWidth > 1024;

  if (isError) {
    return (
      <>
        <div className="mb-6 tablet:mb-8">
          <h3 className="text-lg font-semibold tablet:text-[21px] tablet:font-bold">
            <span className="hidden tablet:block">Upcoming appointment</span>
            <span className="block tablet:hidden">Next appointment</span>
          </h3>
        </div>
        <div className="grid order-1 w-full tablet:order-2 place-content-center">
          <Status
            danger
            graphic={<WarningIcon />}
            description="Unable to load past appointments. Please try again later."
          />
        </div>
      </>
    );
  }

  if (isFetching) {
    return (
      <>
        <div className="mb-6 tablet:mb-8">
          <h3 className="text-lg font-semibold tablet:text-[21px] tablet:font-bold">
            <span className="hidden tablet:block">Upcoming appointment</span>
            <span className="block tablet:hidden">Next appointment</span>
          </h3>
        </div>
        <div className="grid order-1 w-full tablet:order-2 place-content-center">
          <Loader />
        </div>
      </>
    );
  }

  return (
    <>
      {appointments &&
      appointments?.upcoming &&
      appointments?.upcoming.length > 0 ? (
        <>
          <div className="mb-4 tablet:mb-8">
            <h3 className="text-lg font-semibold tablet:text-[21px] tablet:font-bold">
              <span className="hidden tablet:block">Upcoming appointment</span>
              <span className="block tablet:hidden">Next appointment</span>
            </h3>
          </div>

          {/* Mobile */}
          <div className="relative block w-full overflow-auto tablet:hidden full-width scrollbar-none">
            <div className="flex w-full gap-2 px-4 py-2 overflow-x-auto xs:gap-4 snap-x scrollbar-none scroll-smooth">
              {appointments.upcoming.map((app, idx) =>
                idx > 5 ? (
                  <Fragment key={app.id} />
                ) : (
                  <div
                    key={app.id}
                    className={classNames(
                      "flex flex-shrink-0 w-full scroll-mx-4 snap-start",
                      {
                        "max-w-[90vw]": appointments.upcoming.length > 1,
                      },
                    )}
                  >
                    <UpcomingAppointmentCard
                      appointment={app}
                      to={`/appointments/${app.id}`}
                      onClick={() =>
                        gtmEvent({ name: "onHomepageViewUpcomingAppt" })
                      }
                    />
                  </div>
                ),
              )}
              {appointments.upcoming.length > 5 && (
                <div className="flex justify-center">
                  <InlineLink
                    to="/appointments"
                    onClick={() =>
                      gtmEvent({ name: "onHomepageViewAllUpcomingAppts" })
                    }
                  >
                    See all appointments
                  </InlineLink>
                </div>
              )}
            </div>
          </div>

          {/* Desktop */}
          <div className="hidden tablet:flex tablet:flex-col tablet:gap-y-4">
            {appointments.upcoming.map((app, idx) =>
              idx > 5 ? (
                <Fragment key={app.id} />
              ) : (
                <UpcomingAppointmentCard
                  key={app.id}
                  appointment={app}
                  to={`/appointments/${app.id}`}
                  onClick={() =>
                    gtmEvent({ name: "onHomepageViewUpcomingAppt" })
                  }
                />
              ),
            )}
            {appointments.upcoming.length > 5 && (
              <div className="flex justify-center">
                <InlineLink
                  to="/appointments"
                  onClick={() =>
                    gtmEvent({ name: "onHomepageViewAllUpcomingAppts" })
                  }
                >
                  See all appointments
                </InlineLink>
              </div>
            )}
          </div>
        </>
      ) : (
        <EmptyStateCard
          icon={<CalendarOffIcon />}
          title="No appointments"
          message="You have no appointments scheduled"
          cta={
            appointmentsBlocked ? undefined : (
              <Button
                type="link"
                size="small"
                variant="outlined"
                to={tablet ? "/home" : "/appointments/book"}
                className="tablet:hidden"
              >
                Book Now
              </Button>
            )
          }
        />
      )}
    </>
  );
};

export default UpcomingAppointmentSection;
