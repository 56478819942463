/* eslint-disable max-len */
const CalendarCheckIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 103 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.6202 90.125H21.4583C19.1819 90.125 16.9987 89.2207 15.389 87.611C13.7793 86.0013 12.875 83.8181 12.875 81.5417V30.0417C12.875 27.7652 13.7793 25.582 15.389 23.9723C16.9987 22.3627 19.1819 21.4583 21.4583 21.4583H72.9583C75.2348 21.4583 77.418 22.3627 79.0277 23.9723C80.6374 25.582 81.5417 27.7652 81.5417 30.0417V47.2083"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.375 12.875V30.0417"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0417 12.875V30.0417"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.875 47.2083H81.5417"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92 77.5C92 86.6127 84.6127 94 75.5 94C66.3873 94 59 86.6127 59 77.5C59 68.3873 66.3873 61 75.5 61"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.5 75.5L75.5 81L91 65"
        stroke="currentColor"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarCheckIcon;
