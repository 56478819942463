import {
  ChangeEvent,
  ClipboardEvent,
  FocusEventHandler,
  forwardRef,
  HTMLInputTypeAttribute,
  KeyboardEvent,
  useState,
} from "react";
import classNames from "classnames";
import EyeIcon from "../../../icons/EyeIcon";
import EyeOffIcon from "../../../icons/EyeOffIcon";

type PropsType = {
  type?: HTMLInputTypeAttribute;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: FocusEventHandler;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void;
  value?: string;
  name?: string;
  prefixIcon?: JSX.Element;
  maxChars?: number;
  disabled?: boolean;
  centered?: boolean;
  className?: string;
  placeHolder?: string;
  autoFocus?: boolean;
  autoComplete?: "on" | "off";
  size?: "small" | "medium" | "large";
};

const Input = forwardRef<HTMLInputElement, PropsType>(function Input(
  {
    type = "text",
    onChange,
    onBlur,
    onKeyDown,
    onPaste,
    value,
    name,
    prefixIcon,
    maxChars,
    disabled = false,
    centered = false,
    className,
    placeHolder,
    autoFocus = false,
    autoComplete,
    size = "large",
  },
  ref,
) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="relative w-full">
      <input
        ref={ref}
        maxLength={maxChars}
        autoFocus={autoFocus}
        className={classNames(
          "transition appearance-none",
          "block w-full bg-white tablet:text-lg",
          "leading-6 text-body-copy placeholder-placeholder-grey",
          "border border-primary focus:border-light rounded-none",
          "shadow-default focus:shadow-focus-small",
          "focus:outline-none disabled:bg-disabled-input",
          {
            // Size
            "text-base py-2 px-[10px]": size === "small",
            "py-3 px-4": size === "medium",
            "p-4": size === "large",

            "!text-center": centered,
            "pl-11": prefixIcon,
            "pr-11": type === "password",
          },
          className,
        )}
        type={type === "password" && showPassword ? "text" : type}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        value={value}
        name={name}
        disabled={disabled}
        placeholder={placeHolder}
        autoComplete={autoComplete}
      />

      {prefixIcon && (
        <div className="absolute inset-y-0 flex items-center left-4">
          <span className="block w-6 h-6 text-grey-dark">{prefixIcon}</span>
        </div>
      )}

      {type === "password" && (
        <div className="absolute inset-y-0 flex items-center right-4 text-grey-dark">
          {showPassword ? (
            <div onClick={() => setShowPassword(false)}>
              <EyeOffIcon className="w-6 h-6 cursor-pointer" />
            </div>
          ) : (
            <div onClick={() => setShowPassword(true)}>
              <EyeIcon className="w-6 h-6 cursor-pointer" />
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default Input;
