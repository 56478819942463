import { useCancelAppointmentMutation } from "api";
import { Button, SelectOption, NotifierNotification, Sheet } from "@mwi/ui";
import { useEffect, useState } from "react";
import { useNotifier } from "react-headless-notifier";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { gtmEvent } from "helpers/gtm";

type PropTypes = {
  onClick?: () => void;
  onCancel: () => void;
  appointmentId: string;
};

const CancelAppointmentSheet = ({
  onClick,
  onCancel,
  appointmentId,
}: PropTypes) => {
  const { notify } = useNotifier();

  const [selectedOption, setSelectedOption] = useState<string>();

  const [cancelAppointment, { isSuccess: didCancel, isError, isLoading }] =
    useCancelAppointmentMutation();

  const cancellationMessage = useSelector(
    (state: RootState) => state.practice.cancellation_message,
  );

  const handleCancel = () => {
    cancelAppointment(appointmentId);
  };

  useEffect(() => {
    if (isError) {
      notify(
        <NotifierNotification
          type="error"
          title="Cancellation Unsuccessful"
          message="Your appointment cannot be cancelled at the moment, please try again later."
        />,
      );
    }
  }, [isError]);

  useEffect(() => {
    if (didCancel) {
      gtmEvent({ name: "onCancelledAppointment" });
      onCancel();
    }
  }, [didCancel]);

  return (
    <Sheet
      title="Are you sure?"
      trigger={
        <Button variant="outlined" size="large" danger block onClick={onClick}>
          Cancel Booking
        </Button>
      }
    >
      {({ close }) => (
        <div className="mt-6">
          {cancellationMessage && (
            <p className="mb-6 text-center">{cancellationMessage}</p>
          )}

          <SelectOption
            value="yes"
            selected={selectedOption === "yes"}
            onClick={() => setSelectedOption("yes")}
          >
            Yes
          </SelectOption>
          <SelectOption
            value="no"
            selected={selectedOption === "no"}
            onClick={() => setSelectedOption("no")}
          >
            No
          </SelectOption>

          <Button
            block
            disabled={!selectedOption}
            className="mt-6"
            onClick={() =>
              selectedOption === "yes" ? handleCancel() : close()
            }
            loading={isLoading}
          >
            Confirm Selection
          </Button>
        </div>
      )}
    </Sheet>
  );
};

export default CancelAppointmentSheet;
