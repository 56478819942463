import { useNavigate } from "react-router-dom";
import { PrescriptionRootView } from "../../components";

export const RequestPrescriptionsPage = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <PrescriptionRootView
      onClose={() => navigate("/home", { replace: true })}
    />
  );
};
