import InlineLink from "./../../navigation/inline_link";

/* 
  All fields are relating to the practice details
*/
type PrivacyTemplateProps = {
  displayName: string | null;
  address: string | null;
  phone: string | null;
  email: string | null;
  privacyLink: string | null;
};

const PrivacyTemplate = ({
  phone,
  email,
  address,
  privacyLink,
  displayName,
}: PrivacyTemplateProps): JSX.Element => {
  return (
    <div className="flex flex-col text-lg">
      <div className="mb-4">
        <span>Last updated: 1st August 2022</span>
      </div>

      <p>
        MWI provides and makes available this portal on behalf of{" "}
        {displayName ?? ""}. This means that {displayName ?? ""}, and not MWI,
        is the data controller that determines and is responsible for how your
        personal data collected via this portal is handled. MWI acts a data
        processor for {displayName ?? ""}, and processes your personal data on
        its behalf, to the extent needed, and for the purposes of, providing and
        making the portal available
      </p>
      <br />
      <p>
        To find out more about how {displayName ?? ""} handles your personal
        data, and your rights in respect of it, please visit{" "}
        <InlineLink target="_blank" to={privacyLink ?? ""}>
          Practice Privacy Policy
        </InlineLink>
        . You can also contact them at:
      </p>
      <br />
      <div className="flex flex-col gap-y-4">
        <span>
          {displayName ?? ""}
          {address ? `, ${address}` : ""}
        </span>
        <span>{phone ?? ""}</span>
        <span>{email ?? ""}</span>
      </div>
    </div>
  );
};

export default PrivacyTemplate;
