type FormInputLimitProps = {
  value: number;
  limit: number;
};

export default function FormInputLimit({
  value,
  limit,
}: FormInputLimitProps): JSX.Element {
  return (
    <p className="mt-2 text-sm text-grey-dark tabular-nums">
      {value}/{limit}
    </p>
  );
}
