import classNames from "classnames";

type PropTypes = {
  logo?: string;
  name?: string;
  className?: string;
};

const PracticeLogo = ({ logo, name, className }: PropTypes) => {
  if (!logo && !name) {
    return <></>;
  }

  if (logo) {
    return (
      <img
        src={logo}
        alt={name}
        className={classNames(
          "max-w-[200px] max-h-[100px] object-contain",
          className,
        )}
      />
    );
  }

  return (
    <div
      className={classNames(
        "self-start p-2 font-extrabold leading-6 tablet:p-4 tablet:text-2xl bg-primary text-on-primary",
        className,
      )}
    >
      {name}
    </div>
  );
};

export default PracticeLogo;
