/* eslint-disable max-len */
const GraphicBackground = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 235 189"
      fill="none"
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.55465 142.231C-3.38282 130.987 0.144644 116.269 11.4335 109.359L154.924 21.5236C166.212 14.6133 180.988 18.127 187.925 29.3715C194.863 40.6161 191.298 55.2593 180.009 62.1695L36.5562 150.079C25.2674 156.99 10.4921 153.476 3.55465 142.231Z"
        fill="currentColor"
      />
      <path
        d="M3.66669 86.6256C-3.27078 75.3811 0.256684 60.6637 11.5455 53.7534L93.5741 3.54072C104.863 -3.36956 119.638 0.14408 126.576 11.3887C133.513 22.6332 129.822 36.8895 118.533 43.7998L36.5787 93.9668C25.2899 100.877 10.6042 97.8702 3.66669 86.6256Z"
        fill="currentColor"
      />
      <path
        d="M64.4092 160.965C57.4718 149.721 61.0495 135.086 72.3383 128.176L198.444 50.8684C209.733 43.9581 224.508 47.4717 231.445 58.7163C238.383 69.9609 234.855 84.6783 223.567 91.5886L97.4108 168.813C86.122 175.723 71.3467 172.21 64.4092 160.965Z"
        fill="currentColor"
      />
      <path
        d="M128.029 177.611C121.091 166.367 124.918 151.975 136.207 145.064L175.845 120.801C187.134 113.89 201.673 117.04 208.61 128.285C215.548 139.529 212.02 154.247 200.731 161.157L161.031 185.459C149.742 192.37 134.966 188.856 128.029 177.611Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GraphicBackground;
