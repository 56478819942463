import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store";
import { setStep } from "slices/booking";
import AppointmentSheet from "components/sheets/appointment";
import { useGetSiteQuery, useVerifyAppointmentsMutation } from "api";
import {
  Label,
  FormError,
  Button,
  MapPinIcon,
  InlineLink,
  Status,
  CalendarOffIcon,
} from "@mwi/ui";
import { gtmEvent } from "helpers/gtm";

const BookingFlowStep3 = (): JSX.Element => {
  const dispatch = useDispatch();

  const [error, setError] = useState<string>();

  const { registeredSite, patients, selectedSlot, vetSite } = useSelector(
    (state: RootState) => ({
      registeredSite: state.auth.registered_site,
      patients: state.bookingFlow.formState.patients,
      vetSite: state.bookingFlow.formState.vet_site_uuid,
      selectedSlot: state.bookingFlow.formState.selected_slot,
    }),
  );

  const [
    verifyAppt,
    { error: verifyError, isSuccess: didVerify, isLoading: verifying },
  ] = useVerifyAppointmentsMutation();

  const { data: site } = useGetSiteQuery(
    vetSite ? vetSite : registeredSite!.id,
  );

  const handleNextStep = () => {
    setError(undefined);
    verifyAppt({
      step: "3",
      patients: patients,
      selected_slot: selectedSlot,
      vet_site_uuid: vetSite ? vetSite : registeredSite!.id,
    });
  };

  const handleChangeSite = () => {
    dispatch(setStep({ step: "3a" }));
  };

  useEffect(() => {
    if (verifyError) {
      const message = (verifyError as any)?.data?.errors?.find(
        (e: any) => e.field === "vet_site_uuid",
      )?.message;
      if (message) {
        setError(message);
      }
    }
  }, [verifyError]);

  useEffect(() => {
    if (didVerify) {
      dispatch(setStep({ step: "4" }));
    }
  }, [didVerify]);

  useEffect(() => gtmEvent({ name: "onMobileApptFlowSelectSlotPageView" }), []);

  if (site?.appointments_blocked) {
    return (
      <Status
        danger
        title="No Appointments Available"
        description="Sorry! This vet site is currently not accpeting any bookings. Please try again later or check another vet site."
        primaryLinkText="Change vet site"
        onClick={() => dispatch(setStep({ step: "3a" }))}
        graphic={<CalendarOffIcon />}
      />
    );
  }

  return (
    <>
      <div className="mb-6">
        <h1 className="text-4xl leading-[43px]">
          When would you like to come in?
        </h1>
      </div>

      <div className="mb-4">
        <div className="mb-4">
          <h3 className="text-lg leading-[27px] font-semibold">
            Appointment slots at:
          </h3>
        </div>

        <div className="mb-6">
          <div className="flex flex-wrap items-center gap-4">
            <div className="flex items-center justify-center w-8 h-8 bg-white rounded-full">
              <div className="w-6 h-6">
                <MapPinIcon className="text-primary" />
              </div>
            </div>

            {site?.name || site?.city ? (
              <p className="text-base">{`${site?.name}${site?.city ? `, ${site?.city}` : ""}`}</p>
            ) : (
              <span className="text-base">Please select a location</span>
            )}
          </div>
        </div>

        <InlineLink onClick={handleChangeSite} size="small">
          Change practice site
        </InlineLink>

        <div className="mt-1">
          {error ? (
            <FormError>{error}</FormError>
          ) : (
            <p className="text-sm not-sr-only tablet:text-base">&nbsp;</p>
          )}
        </div>
      </div>

      <div className="flex flex-col flex-grow">
        <div className="mb-16">
          <Label>Timeslot:</Label>
          <AppointmentSheet />
        </div>

        <div className="mt-auto">
          <Button
            block
            size="large"
            loading={verifying}
            onClick={handleNextStep}
            disabled={!selectedSlot.id}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default BookingFlowStep3;
