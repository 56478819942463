import {
  Checkbox,
  InlineLink,
  Button,
  Divider,
  FormGroup,
  Input,
  Label,
  Notice,
} from "@mwi/ui";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useCheckForMatchesMutation } from "api";
import useErrorHandler from "hooks/useErrorHandler";
import {
  setFlow,
  setPatientOptions,
  setReferredSite,
  setVerifyMethod,
  setVerifyMethods,
  updateFormState,
} from "slices/registration";
import { RootState } from "store";
import TermsModal from "components/modals/terms";
import PrivacyModal from "components/modals/privacy";
import { gtmEvent } from "helpers/gtm";

type Inputs = {
  registration_blocked: string;
  first_name: string;
  last_name: string;
  phone: string;
  terms: boolean;
};

const RegistrationDetails = () => {
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const [isTermsModalOpen, setTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setPrivacyModalOpen] = useState(false);

  const { formState, oldFormState } = useSelector((state: RootState) => ({
    formState: state.registration.formState,
    oldFormState: state.registration.oldFormState,
  }));

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      first_name: oldFormState.first_name,
      last_name: oldFormState.last_name,
      phone: oldFormState.phone,
      terms: false,
    },
  });

  const [submitForMatches, { data: matchResult, isLoading, error }] =
    useCheckForMatchesMutation();

  useEffect(() => {
    if (error) {
      errorHandler(error, setError);
    }
  }, [error]);

  useEffect(() => {
    if (matchResult) {
      dispatch(updateFormState(getValues()));
      dispatch(setVerifyMethods(matchResult.verify_methods));
      if (matchResult.verify_methods?.length === 1) {
        dispatch(setVerifyMethod(matchResult.verify_methods![0].method));
      }
      if (matchResult?.referred_location) {
        dispatch(setReferredSite(matchResult.referred_location));
      }
      if (matchResult?.flow) {
        dispatch(setFlow(matchResult.flow));
      }
      dispatch(setPatientOptions(matchResult.patient_options));
    }
  }, [matchResult]);

  const submitForm = (data: Inputs) => {
    let error = false;
    if (!data.first_name) {
      error = true;
      setError("first_name", { message: "Your first name is required" });
    }
    if (!data.last_name) {
      error = true;
      setError("last_name", { message: "Your last name is required" });
    }
    if (!data.phone) {
      error = true;
      setError("phone", { message: "Your mobile number is required" });
    }
    if (!data.terms) {
      error = true;
      setError("terms", { message: "You must accept the terms & conditions" });
    }
    if (!error) {
      submitForMatches({
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
        email: formState.email,
      });
    }
  };

  useEffect(() => gtmEvent({ name: "onRegFlowDetailsStepPageView" }), []);

  return (
    <>
      <h1 className="text-4xl font-bold tablet:text-6xl">
        Enter the details to create your account
      </h1>

      <p className="mt-6 tablet:text-lg tablet:mt-8">
        <InlineLink to="/" size="medium">
          I already have an online account - Login
        </InlineLink>
      </p>

      <form
        className="flex flex-col flex-grow mt-12"
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="grid grid-cols-1 tablet:gap-6 tablet:grid-cols-2">
          <FormGroup error={errors.first_name?.message}>
            <Label>First Name</Label>
            <Input {...register("first_name")} />
          </FormGroup>

          <FormGroup error={errors.last_name?.message}>
            <Label>Last Name</Label>
            <Input {...register("last_name")} />
          </FormGroup>
        </div>

        <Divider className="mt-2 mb-8" />

        <FormGroup error={errors.phone?.message}>
          <Label>Mobile Number</Label>
          <Input type="tel" maxChars={11} {...register("phone")} />
        </FormGroup>

        <Divider className="mt-2 mb-8" />

        <p className="mb-8 text-sm tablet:text-base">
          The MWI{" "}
          <InlineLink size="medium" onClick={() => setTermsModalOpen(true)}>
            Terms of use
          </InlineLink>{" "}
          apply to your use of this portal, and are separate to any other
          veterinary practice terms and conditions. By clicking accept you agree
          to them. Please also see the{" "}
          <InlineLink size="medium" onClick={() => setPrivacyModalOpen(true)}>
            Privacy Policy
          </InlineLink>{" "}
          for details of how your personal data collected via this portal is
          handled.
        </p>

        <FormGroup error={errors.terms?.message} className="w-max">
          <Checkbox
            label="I accept all Terms and Conditions"
            control={control}
            name="terms"
          />
        </FormGroup>

        {errors?.registration_blocked?.message && (
          <Notice className="mb-10 tablet:mb-4" danger>
            {errors?.registration_blocked?.message}
          </Notice>
        )}

        <div className="mt-auto tablet:mt-12 tablet:max-w-xs">
          <Button type="submit" block loading={isLoading}>
            Submit
          </Button>
        </div>
      </form>

      <TermsModal
        isOpen={isTermsModalOpen}
        onClose={() => setTermsModalOpen(false)}
      />
      <PrivacyModal
        isOpen={isPrivacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
      />
    </>
  );
};

export default RegistrationDetails;
