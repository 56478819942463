import { useNavigate } from "react-router-dom";
import { useGetPatientsQuery } from "api";
import {
  Button,
  PawIcon,
  EmptyStateCard,
  PatientOverviewCard,
  Loader,
  Status,
  WarningIcon,
} from "@mwi/ui";
import classNames from "classnames";
import { gtmEvent } from "helpers/gtm";

const PatientsSection = (): JSX.Element => {
  const navigate = useNavigate();

  const { data: patients, isFetching, isError } = useGetPatientsQuery();

  const tablet = window.innerWidth > 1024;

  return (
    <>
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold tablet:text-[21px] tablet:font-bold">
          Your pets
        </h3>

        <Button
          type="link"
          variant="primary"
          size="small"
          to={tablet ? "/prescription-request" : "/prescriptions"}
          onClick={() =>
            gtmEvent({ name: "onHomepageStartPrescriptionRequest" })
          }
        >
          Request prescriptions
        </Button>
      </div>

      <div className="flex flex-col mt-6">
        {isError && (
          <div className="grid order-1 w-full tablet:order-2 place-content-center">
            <Status
              danger
              graphic={<WarningIcon />}
              description="Unable to load past appointments. Please try again later."
            />
          </div>
        )}

        {isFetching && (
          <div className="grid order-1 w-full tablet:order-2 place-content-center">
            <Loader />
          </div>
        )}

        {!isFetching && (
          <div className="flex order-1 gap-4 tablet:order-2 tablet:flex-col">
            {patients && patients?.length > 0 ? (
              <>
                {/* Desktop */}
                <div className="hidden tablet:flex tablet:flex-col tablet:gap-4">
                  {patients.map((patient) => (
                    <PatientOverviewCard
                      key={patient.id}
                      showViewProfile
                      patient={patient}
                      onClick={() => {
                        gtmEvent({ name: "onHomepageViewPatient" });
                        navigate(`/pets/${patient.id}`);
                      }}
                    />
                  ))}
                </div>

                {/* Mobile */}
                <div className="relative block w-full overflow-auto tablet:hidden full-width scrollbar-none">
                  <div
                    className={classNames(
                      "flex w-full gap-2 xs:gap-4 px-4 py-2 overflow-x-auto snap-x scrollbar-none scroll-smooth",
                    )}
                  >
                    {patients.map((patient) => (
                      <div
                        key={patient.id}
                        className={classNames(
                          "flex flex-shrink-0 w-full scroll-mx-4 snap-start",
                          {
                            "max-w-[80vw] phablet:max-w-[45vw]":
                              patients.length > 1,
                          },
                        )}
                      >
                        <PatientOverviewCard
                          showViewProfile
                          patient={patient}
                          onClick={() => {
                            gtmEvent({ name: "onHomepageViewPatient" });
                            navigate(`/pets/${patient.id}`);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <EmptyStateCard
                icon={<PawIcon />}
                title="No registered pets"
                message="You have no registered pets with your practice"
                cta={
                  <Button
                    to="/pets"
                    type="link"
                    size="small"
                    variant="outlined"
                  >
                    Add pet
                  </Button>
                }
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PatientsSection;
