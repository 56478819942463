import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store";
import useErrorHandler from "hooks/useErrorHandler";
import { metersToMiles } from "helpers/distance";
import { useGetSitesQuery, useVerifyAppointmentsMutation } from "api";
import {
  AvailableDay,
  AvailableMergedSlot,
} from "api/types/models/availability";
import {
  setPatientReasonIndex,
  setSelectedVetSite,
  setStep,
} from "slices/booking";
import {
  Label,
  Select,
  FormGroup,
  Button,
  Loader,
  SelectOption,
  ConfirmationModal,
  InlineLink,
} from "@mwi/ui";
import { gtmEvent } from "helpers/gtm";

type Inputs = {
  vet_site_uuid: string;
};

const BookingFlowStep3a = ({
  onConfirm,
}: {
  onConfirm?: () => void;
}): JSX.Element => {
  const dispatch = useDispatch();
  const errorHandler = useErrorHandler();

  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);

  // Wrapped in a useMemo so GTM event isn't triggered on each render due to ref change
  const md = useMemo(() => window.innerWidth > 768, []);

  const { registeredSite, vetSite, patients } = useSelector(
    (state: RootState) => ({
      registeredSite: state.auth.registered_site,
      patients: state.bookingFlow.formState.patients,
      vetSite: state.bookingFlow.formState.vet_site_uuid,
    }),
  );

  const {
    watch,
    control,
    setError,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      vet_site_uuid: vetSite ? vetSite : registeredSite?.id,
    },
  });

  const { data: sites, isLoading: loadingSites } = useGetSitesQuery();

  const [verifySite, { isSuccess: didVerify, isLoading: verifying, error }] =
    useVerifyAppointmentsMutation();

  const handleVerification = () => {
    verifySite({
      step: "3a",
      patients: patients,
      vet_site_uuid: getValues("vet_site_uuid"),
    });
  };

  useEffect(() => {
    if (didVerify) {
      if (md && onConfirm) {
        dispatch(setSelectedVetSite(getValues("vet_site_uuid")));
        onConfirm();
      } else {
        dispatch(
          setStep({
            step: "2",
            patients: patients?.map((p) => ({
              ...p,
              reason_uuid: "",
              comment: "",
            })),
            selected_day: {} as AvailableDay,
            selected_slot: {} as AvailableMergedSlot,
            confirmed_selection: false,
            availability_date_range: {
              date_start: "",
              date_end: "",
            },
            vet_site_uuid: getValues("vet_site_uuid"),
          }),
        );
      }

      dispatch(setPatientReasonIndex(0));
    }
  }, [didVerify]);

  useEffect(() => {
    if (error) {
      setOpenConfirmationModal(false);
      errorHandler(error, setError);
    }
  }, [error]);

  const addressLine = useMemo(() => {
    const site = sites?.find((s) => s.id === getValues("vet_site_uuid"));

    return encodeURIComponent(
      [
        site?.address_1,
        site?.address_2,
        site?.city,
        site?.county,
        site?.postcode,
      ]
        .filter((v) => v)
        .join(", "),
    );
  }, [watch("vet_site_uuid")]);

  useEffect(() => {
    if (md !== undefined) {
      gtmEvent({
        name: md
          ? "onDesktopApptFlowChangeLocationPageView"
          : "onMobileApptFlowChangeLocationPageView",
      });
    }
  }, [md]);

  return (
    <>
      <div className="mb-6">
        <h1 className="text-4xl leading-[43px] font-bold">
          Which vet pratice would you like to visit?
        </h1>
      </div>

      <div className="mb-10">
        <p className="text-base">
          Select the vet practice you would like you visit.
        </p>
      </div>

      {loadingSites && (
        <div className="grid h-full place-content-center">
          <Loader />
        </div>
      )}

      {!loadingSites && (
        <form
          className="flex flex-col flex-grow max-w-[500px]"
          onSubmit={handleSubmit(() => setOpenConfirmationModal(true))}
        >
          <FormGroup error={errors.vet_site_uuid?.message}>
            <Label>Vet practice:</Label>
            <Select
              control={control}
              name={"vet_site_uuid"}
              placeholder="Select a vet site"
              optionsContainerClassName="max-h-[200px]"
            >
              {sites?.map((site) => {
                const miles = site?.distance
                  ? metersToMiles(site.distance)
                  : null;
                return (
                  <SelectOption key={site.id} value={site.id}>
                    {`${site.name ?? ""}${site.city ? `, ${site.city}` : ""} ${miles ? `(${miles} mi)` : ""}`}
                  </SelectOption>
                );
              })}
            </Select>
          </FormGroup>

          <InlineLink
            target="_blank"
            size="small"
            to={`https://www.google.com/maps/dir/?api=1&destination=${addressLine}`}
          >
            View on map
          </InlineLink>

          <div className="mt-auto tablet:mt-24 tablet:max-w-[200px] w-full">
            <Button
              block
              size="large"
              type="submit"
              loading={verifying}
              disabled={!watch("vet_site_uuid")}
            >
              Continue
            </Button>
          </div>
        </form>
      )}

      <ConfirmationModal
        title="Are you sure?" // eslint-disable-next-line max-len
        description="Changing the appointment vet practice location will require you to re-select the appointment reasons"
        isOpen={openConfirmationModal}
        onConfirm={handleVerification}
        onClose={() => setOpenConfirmationModal(false)}
      />
    </>
  );
};

export default BookingFlowStep3a;
