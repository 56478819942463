import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import Button from "../../buttons/button";
import CheckIcon from "../../../icons/CheckIcon";
import ChevronDownIcon from "../../../icons/ChevronDownIcon";
import CrossIcon from "../../../icons/CrossIcon";
import { useMemo, useState, Fragment, createRef, ReactNode } from "react";
import { Controller } from "react-hook-form";
import Loader from "../../indicators/loader";

type PropTypes = {
  prefixIcon?: JSX.Element;
  children: ReactNode;
  name: string;
  placeholder?: string;
  emptyStateErrorMessage?: string;
  control?: any;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  buttonClassName?: string;
  optionsContainerClassName?: string;
  onChange?: (value: string) => void;
  loading?: boolean;
  block?: boolean;
};

const Select = ({
  prefixIcon,
  children,
  name,
  placeholder,
  emptyStateErrorMessage,
  control,
  size = "large",
  disabled = false,
  buttonClassName,
  onChange,
  loading = false,
  optionsContainerClassName,
  block,
}: PropTypes) => {
  const childrenArray = useMemo(
    () =>
      children
        ? (Array.isArray(children) ? children : [children])
            .filter((c) => c)
            .flat()
        : [],
    [children],
  );

  const scrollRef = createRef<HTMLUListElement>();

  const [selectedValue, setSelectedValue] = useState<string>();
  const [isAtTop, setIsAtTop] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(childrenArray.length < 5);

  const onScroll = () => {
    if (scrollRef.current?.scrollTop === 0) {
      setIsAtTop(true);
    } else if (
      scrollRef.current!.scrollHeight - scrollRef.current!.scrollTop ===
      scrollRef.current?.clientHeight
    ) {
      setIsAtEnd(true);
    }
    if (scrollRef.current!.scrollTop > 0 && isAtTop) {
      setIsAtTop(false);
    }
    if (
      scrollRef.current!.scrollHeight - scrollRef.current!.scrollTop >
        scrollRef.current!.clientHeight &&
      isAtEnd
    ) {
      setIsAtEnd(false);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const displayValue = childrenArray.find(
          (c) => c?.props?.value === field.value,
        )?.props?.children;

        if (selectedValue !== field.value && window.innerWidth > 768) {
          setTimeout(() => {
            setSelectedValue(field.value);
          }, 0);
        }

        return (
          <Popover className={classNames({ "w-full": block })}>
            <Popover.Button
              disabled={disabled}
              className={classNames(
                "relative group rounded-none",
                "transition appearance-none",
                "flex items-center w-full p-4 bg-white tablet:text-lg",
                "leading-6 placeholder-placeholder-grey",
                "border border-primary focus:border-light",
                "shadow-default focus:shadow-focus-small",
                "focus:outline-none relative",
                {
                  "border-black shadow-default bg-grey-light cursor-default":
                    disabled,
                  "text-black": displayValue,
                  "text-grey-dark": !displayValue,

                  "p-4": size === "large",
                  "py-3 px-4": size === "medium",
                  "py-2 px-[0.625rem]": size === "small",
                },
                buttonClassName,
              )}
            >
              {prefixIcon && (
                <span className="flex-shrink-0 block w-6 h-6 mr-3 transition-colors group-focus:text-light">
                  {prefixIcon}
                </span>
              )}

              <span className="pr-6 truncate">
                {displayValue ?? placeholder}
              </span>

              <span
                className={classNames(
                  "absolute inset-y-0 flex items-center pr-2 pointer-events-none right-4",
                  {
                    "pr-0": size === "small",
                  },
                )}
              >
                {loading ? <Loader /> : <ChevronDownIcon className="w-4 h-4" />}
              </span>
            </Popover.Button>

            <div className="relative">
              <Transition as={Fragment}>
                <Popover.Panel className="fixed inset-0 z-[999] tablet:absolute">
                  {({ close }) => {
                    const handleConfirm = (value?: string) => {
                      if (value) {
                        if (onChange) {
                          onChange(value);
                        }
                        field.onChange(value);
                        close();
                      }
                    };

                    const onOptionClick = (
                      value: string,
                      disabled: boolean,
                    ) => {
                      if (disabled) {
                        return;
                      }

                      setSelectedValue(value);
                      if (window.innerWidth > 768) {
                        handleConfirm(value);
                      }
                    };

                    return (
                      <>
                        {/* Overlay */}
                        <Transition.Child
                          enter="transition"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          className="absolute inset-0 bg-black/30 tablet:hidden"
                          onClick={() => close()}
                        />

                        {/* Panel */}
                        <Transition.Child
                          enter="transition-all"
                          enterFrom="translate-y-full tablet:-translate-y-4 tablet:opacity-0"
                          enterTo="translate-y-0 tablet:opacity-1"
                          leave="transition-all"
                          leaveFrom="translate-y-0 tablet:opacity-0"
                          leaveTo="translate-y-full tablet:-translate-y-4 tablet:opacity-0"
                          className={classNames(
                            "absolute inset-x-0 bottom-0",
                            "tablet:bottom-auto tablet:mt-2 tablet:pb-4",
                          )}
                        >
                          <div
                            className={classNames(
                              "px-4 pt-8 pb-6 bg-white rounded-t-3xl",
                              "tablet:rounded-none tablet:p-0",
                              "tablet:border tablet:border-light tablet:shadow-focus-small",
                            )}
                          >
                            <div
                              className="absolute right-4 top-8 tablet:hidden"
                              onClick={() => close()}
                            >
                              <CrossIcon className="w-6 h-6" />
                            </div>

                            {placeholder && (
                              <h3 className="text-lg font-semibold text-center tablet:hidden">
                                {placeholder}
                              </h3>
                            )}

                            {childrenArray.length === 0 && (
                              <p className="text-center py-4">
                                {emptyStateErrorMessage ??
                                  "No options available"}
                              </p>
                            )}

                            {childrenArray.length > 0 && (
                              <ul
                                ref={scrollRef}
                                onScroll={onScroll}
                                className={classNames(
                                  "min-h-[168px] max-h-[280px] overflow-y-auto my-4",
                                  "tablet:my-0 tablet:space-y-1",
                                  optionsContainerClassName,
                                )}
                              >
                                {childrenArray.map((child) => (
                                  <li
                                    key={child.key}
                                    onClick={() =>
                                      onOptionClick(
                                        child.props.value,
                                        child.props.disabled,
                                      )
                                    }
                                    className="relative tablet:mx-2 first:mt-2 last:pb-2"
                                  >
                                    <div
                                      className={classNames(
                                        "rounded-2xl cursor-pointer",
                                        {
                                          "font-semibold bg-grey-lightest tablet:bg-lightest tablet:hover:bg-lightest":
                                            selectedValue === child.props.value,
                                          "hover:bg-grey-lightest":
                                            selectedValue !== child.props.value,
                                          "bg-grey-light text-grey-dark cursor-default hover:bg-grey-light":
                                            child.props.disabled,
                                        },
                                      )}
                                    >
                                      {child}
                                      {selectedValue === child.props.value && (
                                        <div className="absolute inset-y-0 flex items-center right-4">
                                          <CheckIcon className="w-6 h-6" />
                                        </div>
                                      )}
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            )}

                            <Transition
                              show={!isAtTop}
                              enter="transition"
                              enterFrom="opacity-0"
                              enterTo="opacity-1"
                              leave="transition"
                              leaveFrom="opacity-1"
                              leaveTo="opacity-0"
                              className={classNames(
                                "absolute inset-x-4 tablet:inset-x-[1px] h-20 top-[76px]",
                                "tablet:top-[1px] bg-gradient-scroll-start pointer-events-none",
                              )}
                            />

                            <Transition
                              show={!isAtEnd}
                              enter="transition"
                              enterFrom="opacity-0"
                              enterTo="opacity-1"
                              leave="transition"
                              leaveFrom="opacity-1"
                              leaveTo="opacity-0"
                              className={classNames(
                                "absolute inset-x-4 h-20 bottom-[104px] bg-gradient-scroll-end",
                                "tablet:bottom-[17px] tablet:inset-x-[1px] pointer-events-none",
                              )}
                            />

                            <Button
                              block
                              variant="outlined"
                              onClick={() => handleConfirm(selectedValue)}
                              disabled={!selectedValue}
                              className="tablet:hidden"
                            >
                              Confirm Selection
                            </Button>
                          </div>
                        </Transition.Child>
                      </>
                    );
                  }}
                </Popover.Panel>
              </Transition>
            </div>
          </Popover>
        );
      }}
    />
  );
};

export default Select;
