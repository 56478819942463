import qs from "qs";
import { QueryTags } from "api/types/QueryTags";
import { AvailableDay } from "api/types/models/availability";
import { GetAvailabilityRequest } from "api/types/requests";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";

const availabilityEndpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      {},
      FetchBaseQueryMeta
    >,
    QueryTags,
    "data"
  >,
) => ({
  getAvailability: builder.query<AvailableDay[], GetAvailabilityRequest>({
    query: (body) => ({
      url: `/availability?${qs.stringify(body, { arrayFormat: "brackets" })}`,
    }),
    keepUnusedDataFor: 0,
    providesTags: ["Availability"],
  }),
});

export default availabilityEndpoints;
