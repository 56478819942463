import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import RegisteredSite from "api/types/models/registered_site";
import User from "api/types/models/user";

export interface AuthState {
  authenticated: boolean;
  user: User | null;
  registered_site: RegisteredSite | null;
  change_password_step: number;
}

const initialState: AuthState = {
  authenticated: false,
  user: null,
  registered_site: null,
  change_password_step: 1,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{ user: User; registered_site?: RegisteredSite }>,
    ) => {
      state.authenticated = true;
      state.user = action.payload.user;

      if (action.payload?.registered_site) {
        state.registered_site = action.payload.registered_site;
      }
    },
    logout: (state) => {
      state.authenticated = false;
      state.user = null;
      state.change_password_step = 1;
    },
    nextChangePasswordStep: (state) => {
      state.change_password_step++;
    },
    previousChangePasswordStep: (state) => {
      state.change_password_step--;
    },
    clearChangePasswordStep: (state) => {
      state.change_password_step = initialState.change_password_step;
    },
  },
});

export const {
  login,
  logout,
  nextChangePasswordStep,
  clearChangePasswordStep,
  previousChangePasswordStep,
} = authSlice.actions;

export default authSlice.reducer;
