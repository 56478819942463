import { ReactNode } from "react";

const Section = ({
  prefix,
  title,
  children,
}: {
  prefix: string;
  title: string;
  children: ReactNode;
}): JSX.Element => {
  return (
    <section className="flex gap-x-8 tablet:gap-x-14">
      <div className="text-xl font-semibold">
        <span>{prefix}</span>
      </div>
      <div className="flex flex-col gap-y-3">
        <h3 className="mb-3 text-xl font-semibold">{title}</h3>
        {children}
      </div>
    </section>
  );
};

export default Section;
