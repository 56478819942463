import Avatar from "../../avatar";
import CarIcon from "../../../icons/CarIcon";

type PropTypes = {
  timespan: string;
  subheading: string;
  image?: string;
  patientName?: string;
};

const AppointmentStatCard = ({
  timespan,
  subheading,
  image,
  patientName,
}: PropTypes): JSX.Element => {
  return (
    <div className="w-full px-5 py-6 bg-white shadow-default focus:shadow-focus-large tablet:p-6">
      <div className="flex gap-x-6">
        <div className="grid flex-shrink-0 w-16 h-16 rounded-full place-content-center text-primary bg-lightest">
          {patientName ? (
            <Avatar
              url={image}
              name={patientName}
              isPatient
              className="w-16 h-16"
            />
          ) : (
            <CarIcon className="w-12 h-12" />
          )}
        </div>

        <div className="flex flex-col justify-between">
          <h3 className="text-2xl tablet:text-[2rem]">{timespan}</h3>
          <p>{subheading}</p>
        </div>
      </div>
    </div>
  );
};

export default AppointmentStatCard;
