import React, { Fragment } from "react";
import classNames from "classnames";
import Item from "./components/item";
import Section from "./components/section";
import { Popover, Transition } from "@headlessui/react";
import ChevronDownIcon from "../../icons/ChevronDownIcon";

type FilterProps = {
  buttonText: string;
  prefixIcon: JSX.Element;
  buttonClassName?: string;
  size?: "small" | "medium" | "large";
  children?:
    | JSX.Element
    | JSX.Element[]
    | ((params: {
        close: (
          focusableElement?:
            | HTMLElement
            | React.MutableRefObject<HTMLElement | null>
            | undefined,
        ) => void;
      }) => JSX.Element | JSX.Element[]);
};

const Filter = ({
  size,
  prefixIcon,
  buttonText,
  buttonClassName,
  children,
}: FilterProps): JSX.Element => {
  return (
    <Popover as={Fragment}>
      {({ open, close }) => {
        return (
          <div className="relative">
            <Popover.Button
              className={classNames(
                "rounded-none w-full",
                "transition appearance-none",
                "flex items-center justify-between w-full p-4 bg-white tablet:text-lg",
                "leading-6 !text-placeholder-grey",
                "border border-primary focus:border-light",
                "shadow-default focus:shadow-focus-small",
                "focus:outline-none relative",
                {
                  "p-4": size === "large",
                  "py-3 px-4": size === "medium",
                  "py-2 px-[0.625rem]": size === "small",
                },
                buttonClassName,
              )}
            >
              <div className="flex items-center">
                {prefixIcon && (
                  <span className="flex-shrink-0 block w-6 h-6 mr-3 transition-colors">
                    {prefixIcon}
                  </span>
                )}

                <span className="pr-6 truncate">{buttonText}</span>
              </div>

              {/* Right Chevon */}
              <div className="flex-shrink-0 w-4 h-4">
                <div
                  className={classNames("transition-all transform", {
                    "-rotate-180": open,
                  })}
                >
                  <ChevronDownIcon className="text-black" />
                </div>
              </div>
            </Popover.Button>

            <div className="relative">
              <Transition as={Fragment}>
                <Popover.Panel className="fixed inset-0 z-[99] tablet:absolute">
                  <Transition.Child
                    enter="transition-all"
                    enterFrom="translate-y-full tablet:-translate-y-4 tablet:opacity-0"
                    enterTo="translate-y-0 tablet:opacity-1"
                    leave="transition-all"
                    leaveFrom="translate-y-0 tablet:opacity-0"
                    leaveTo="translate-y-full tablet:-translate-y-4 tablet:opacity-0"
                    className={classNames(
                      "absolute inset-x-0 bottom-0",
                      "tablet:bottom-auto tablet:mt-2 tablet:pb-4",
                    )}
                  >
                    <div
                      className={classNames(
                        "px-4 pt-8 pb-6 bg-white rounded-t-3xl",
                        "tablet:rounded-none tablet:p-0",
                        "tablet:border tablet:border-light tablet:shadow-focus-small",
                      )}
                    >
                      {typeof children === "function"
                        ? children({ close })
                        : children}
                    </div>
                  </Transition.Child>
                </Popover.Panel>
              </Transition>
            </div>
          </div>
        );
      }}
    </Popover>
  );
};

Filter.Item = Item;
Filter.Section = Section;

export default Filter;
