import classNames from "classnames";
import { MouseEventHandler } from "react";
import { Link, To } from "react-router-dom";
import ChevronLeftIcon from "../../../icons/ChevronLeftIcon";

type PropTypes = {
  to?: To;
  replace?: boolean;
  onClick?: MouseEventHandler;
  className?: string;
};

const BackButton = ({ onClick, to, replace, className }: PropTypes) => {
  const Inner = (
    <button
      className={classNames(
        "outline-none transition",
        "flex items-center justify-center w-[50px] h-[50px] rounded-full",
        "cursor-pointer bg-grey-lightest hover:bg-grey-light",
        className,
      )}
      onClick={onClick}
    >
      <ChevronLeftIcon className="w-6 h-6" />
    </button>
  );

  if (to) {
    return (
      <Link to={to} replace={replace} onClick={onClick}>
        {Inner}
      </Link>
    );
  }

  return <>{Inner}</>;
};

export default BackButton;
