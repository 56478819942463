import tinycolor from "tinycolor2";
import { RgbaColor, HexColorPicker, HslColor } from "react-colorful";
import FormGroup from "../../components/form/group";
import Label from "../../components/form/label";
import Input from "../../components/form/input";
import React, { Fragment, useMemo } from "react";
import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";

type ColourTypes = {
  rgb: RgbaColor;
  hex: string;
  hsl: HslColor;
};

type ColourPickerProps = {
  value: string;
  panelClassName?: string;
  onChange: (colour: ColourTypes) => void;
  trigger: (params: {
    open: boolean;
    close: (
      focusableElement?:
        | HTMLElement
        | React.MutableRefObject<HTMLElement | null>
        | undefined,
    ) => void;
  }) => JSX.Element;
};

const ColourPicker = ({
  value,
  onChange,
  trigger,
  panelClassName,
}: ColourPickerProps): JSX.Element => {
  const handleOnChange = (colour: string) => {
    if (/^[a-fA-F0-9\s#]*$/.test(colour)) {
      onChange({
        rgb: tinycolor(colour).toRgb(),
        hex: colour.indexOf("#") !== -1 ? colour : `#${colour}`,
        hsl: tinycolor(colour).toHsl(),
      });
    }
  };

  const colour = useMemo(() => tinycolor(value), [value]);

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button>{trigger({ open, close })}</Popover.Button>

          <Transition
            show={open}
            as={Fragment}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel
              static
              className={classNames(
                "absolute top-0 z-10",
                "min-w-[160px] w-full h-full",
                panelClassName,
              )}
            >
              <div className="bg-white shadow-default">
                <HexColorPicker color={value} onChange={handleOnChange} />

                <div
                  className="absolute w-8 h-8 rounded-full top-[171px] left-3"
                  style={{ backgroundColor: colour.toString("rgb") }}
                ></div>

                <div className="px-3 pb-4">
                  <FormGroup hideError>
                    <Label className="!text-xs !mb-1">HEX Code</Label>
                    <Input
                      maxChars={7} // includes #
                      value={value}
                      className="px-2 h-7 !text-sm"
                      onChange={(e) => handleOnChange(e.target.value)}
                    />
                  </FormGroup>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ColourPicker;
