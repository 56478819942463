/* eslint-disable max-len */
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useNotifier } from "react-headless-notifier";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "store";
import CalendarView from "components/sheets/appointment/components/CalendarView";
import {
  setAvailabilityDateRange,
  setSelectedDay,
  setStep,
} from "slices/booking";
import {
  useGetAvailabilityQuery,
  useGetSiteQuery,
  useVerifyAppointmentsMutation,
} from "api";
import AppointmentSlotsList from "components/modals/appointment/book/sections/AppointmentSlotsList";
import {
  Button,
  Loader,
  MapPinIcon,
  NotifierNotification,
  InlineLink,
  Status,
  CalendarOffIcon,
} from "@mwi/ui";
import { gtmEvent } from "helpers/gtm";

const BookAppointmentModalStep3 = (): JSX.Element => {
  const dispatch = useDispatch();
  const { notify } = useNotifier();

  const {
    vetSite,
    patients,
    selectedDay,
    selectedSlot,
    selectedDate,
    registeredSite,
    availabilityDateRange,
  } = useSelector((state: RootState) => ({
    registeredSite: state.auth.registered_site,
    patients: state.bookingFlow.formState.patients,
    vetSite: state.bookingFlow.formState.vet_site_uuid,
    selectedDay: state.bookingFlow.formState.selected_day,
    selectedDate: state.bookingFlow.formState.selected_date,
    selectedSlot: state.bookingFlow.formState.selected_slot,
    availabilityDateRange: state.bookingFlow.formState.availability_date_range,
  }));

  const { data: site } = useGetSiteQuery(
    vetSite ? vetSite : registeredSite!.id,
  );

  const [
    verify,
    {
      reset: resetVerify,
      isSuccess: verified,
      isLoading: isVerifying,
      isError: failedVerifying,
    },
  ] = useVerifyAppointmentsMutation();

  const {
    data: availability,
    isFetching: fetchingAvailability,
    isError: availabilityError,
  } = useGetAvailabilityQuery(
    {
      reasons: patients.map((p) => p.reason_uuid),
      patients: patients?.map((p) => p.patient_uuid),
      location_uuid: vetSite ? vetSite : registeredSite!.id,
      date_end: DateTime.fromISO(availabilityDateRange?.date_end).toFormat(
        "yyyy-MM-dd",
      ),
      date_start: DateTime.fromISO(availabilityDateRange?.date_start).toFormat(
        "yyyy-MM-dd",
      ),
    },
    {
      skip:
        !availabilityDateRange.date_start || !availabilityDateRange.date_end,
    },
  );

  const handleNextStep = () => {
    verify({
      step: "3",
      patients: patients,
      selected_slot: selectedSlot,
      vet_site_uuid: vetSite ? vetSite : registeredSite!.id,
    });
  };

  useEffect(() => {
    if (selectedDate) {
      const initialDate = DateTime.fromISO(selectedDate).isValid
        ? DateTime.fromISO(selectedDate)
        : DateTime.now();

      dispatch(
        setAvailabilityDateRange({
          date_start: initialDate.hasSame(DateTime.now(), "month")
            ? DateTime.now().toISO()!
            : initialDate.startOf("month").toISO()!,
          date_end: initialDate.endOf("month").toISO()!,
        }),
      );
    }
  }, [selectedDate]);

  useEffect(() => {
    if (availability) {
      const availableDay = availability.find((a) =>
        DateTime.fromISO(a.date).hasSame(DateTime.fromISO(selectedDate), "day"),
      );
      if (availableDay) {
        dispatch(setSelectedDay(availableDay));
      }
    }
  }, [availability]);

  useEffect(() => {
    if (verified) {
      dispatch(
        setStep({
          step: "4",
          vet_site_uuid: vetSite ? vetSite : registeredSite!.id,
        }),
      );
    }

    if (failedVerifying) {
      notify(
        <NotifierNotification
          type="error"
          title="Cannot verify slot"
          message="We're unable to verify your selected slot. Please try a different slot."
        />,
      );

      resetVerify();
    }
  }, [verified, failedVerifying]);

  useEffect(
    () => gtmEvent({ name: "onDesktopApptFlowSelectSlotPageView" }),
    [],
  );

  if (site?.appointments_blocked) {
    return (
      <Status
        danger
        title="No Appointments Available"
        description="Sorry! This vet site is currently not accpeting any bookings. Please try again later or check another vet site."
        primaryLinkText="Change vet site"
        onClick={() => dispatch(setStep({ step: "3a" }))}
        graphic={<CalendarOffIcon />}
      />
    );
  }

  return (
    <>
      <div className="mb-10">
        <h1 className="text-4xl leading-[43px] font-bold">
          When would you like to come in?
        </h1>
      </div>

      <div className="flex justify-between mb-14 gap-x-4">
        <div className="max-w-[295px] w-full">
          {site && (
            <div className="mb-10">
              <div className="mb-2">
                <h3 className="text-base font-semibold">
                  Appointment slots at:
                </h3>
              </div>

              <div className="flex items-center gap-2">
                <div className="self-start flex-shrink-0 w-6 h-6">
                  <MapPinIcon className="text-grey-dark" />
                </div>
                <span className="text-lg">
                  {`${site.name}${site.city ? `, ${site.city}` : ""}`}
                </span>
              </div>
              <div className="ml-8">
                <InlineLink
                  size="medium"
                  onClick={() => dispatch(setStep({ step: "3a" }))}
                >
                  Change
                </InlineLink>
              </div>
            </div>
          )}

          <div className="relative max-h-[280px] h-full">
            {selectedDay &&
            selectedDay?.mergedSlots &&
            selectedDay.mergedSlots?.length > 0 &&
            !fetchingAvailability ? (
              <AppointmentSlotsList />
            ) : (
              <div className="flex items-center justify-center w-full">
                <span className="font-semibold text-center text-md">
                  {!fetchingAvailability &&
                  availability &&
                  availability?.length > 0
                    ? "Select a date to show current availability"
                    : "No available slots"}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="h-[380px] w-[1px] bg-pinball"></div>

        <div className="max-w-[346px] w-full flex-shrink-0">
          {fetchingAvailability && (
            <div className="flex items-center justify-center h-[330px] w-full">
              <Loader />
            </div>
          )}

          {!fetchingAvailability && availability && (
            <CalendarView
              availability={availability}
              error={
                availabilityError
                  ? "Unable to fetch availability, please try again later"
                  : undefined
              }
            />
          )}
        </div>
      </div>

      <div className="max-w-[200px] w-full">
        <Button
          block
          onClick={handleNextStep}
          disabled={!selectedSlot.id}
          loading={fetchingAvailability || isVerifying}
        >
          Confirm
        </Button>
      </div>
    </>
  );
};

export default BookAppointmentModalStep3;
