/* eslint-disable max-len */
import classNames from "classnames";
import { NavLink, To } from "react-router-dom";
import Loader from "../../indicators/loader";
import { forwardRef } from "react";

type TButtonProps = {
  to?: To;
  block?: boolean;
  danger?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  children: JSX.Element | string;
  size?: "small" | "medium" | "large";
  type?: "button" | "submit" | "link";
  variant?: "primary" | "secondary" | "outlined";
  className?: string;
};

const Button = forwardRef<HTMLButtonElement, TButtonProps>(function Button(
  {
    to,
    onClick,
    children,
    block = false,
    size = "large",
    type = "button",
    loading = false,
    disabled = false,
    variant = "primary",
    danger = false,
    className,
  },
  ref,
) {
  const innerClassName = classNames(
    `
    transition
    block
    flex justify-center items-center
    text-center font-semibold 
    rounded-full outline-none
  `,
    {
      "w-full": block,

      "border-2": variant === "outlined",

      // Sizes
      "text-sm py-2 px-4 leading-5": size === "small",
      "text-base px-8 py-4 leading-6": size === "medium",
      "text-lg px-8 py-4 leading-7": size === "large",

      // Variants
      "bg-primary text-on-primary hover:bg-light focus:shadow-focus-small focus:bg-primary":
        !danger && variant === "primary",
      "bg-lightest text-primary hover:text-on-primary hover:bg-light focus:bg-lightest focus:text-primary focus:shadow-focus-large":
        !danger && variant === "secondary",
      "!border-primary text-primary hover:text-light hover:!border-light focus:shadow-focus-medium focus:border-primary focus:text-primary":
        !danger && variant === "outlined",

      // Danger
      "bg-error text-on-primary hover:bg-error-light focus:bg-error focus:text-on-primary":
        danger && variant === "primary",
      "bg-error-lightest text-error hover:bg-error-light hover:text-on-primary focus:bg-error-lightest focus:text-error":
        danger && variant === "secondary",
      "border-error text-error hover:border-error-light hover:text-error-light focus:text-error focus:border-error":
        danger && variant === "outlined",

      // Disabled
      "cursor-default": disabled,
      "opacity-60 hover:bg-primary":
        (disabled || loading) && variant === "primary",
      "bg-grey-lightest text-grey-dark hover:bg-grey-lightest hover:text-grey-dark focus:bg-grey-lightest focus:text-grey-dark":
        (disabled || loading) && variant === "secondary",
      "!border-grey-lightest !text-grey-dark hover:!text-grey-dark hover:!border-grey-lightest focus:!border-grey-lightest focus:!text-grey-dark":
        (disabled || loading) && variant === "outlined",
      "hover:bg-error":
        (disabled || loading) && variant === "primary" && danger,

      // Link
      "!bg-white !text-primary !rounded-none !border-primary py-[2px] !px-0 !border-b-[1px] border-t-0 border-l-0 border-r-0":
        type === "link" && !to,
    },
    className,
  );

  if (type === "link" && to !== undefined) {
    return /^https?:\/\/|tel:|mailto:/i.test(to.toString()) ? (
      <a href={to.toString()} className={innerClassName} onClick={onClick}>
        {loading ? <Loader /> : children}
      </a>
    ) : (
      <NavLink to={to} className={innerClassName} onClick={onClick}>
        {loading ? <Loader /> : children}
      </NavLink>
    );
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled || loading}
      className={innerClassName}
      type={type === "link" ? "button" : type}
      ref={ref}
    >
      {loading ? <Loader /> : children}
    </button>
  );
});

export default Button;
