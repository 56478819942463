import classNames from "classnames";
import CrossIcon from "../../icons/CrossIcon";

type PropTypes = {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
  onClose?: () => void;
  danger?: boolean;
};

const Notice = ({
  children,
  className,
  onClose,
  danger = false,
}: PropTypes) => {
  return (
    <div
      className={classNames(
        "p-4 rounded bg-lightest text-primary flex pointer-default",
        {
          "bg-error-lightest text-error": danger,
        },
        className,
      )}
    >
      <div className="flex-grow">{children}</div>

      {onClose && (
        <div className="cursor-pointer" onClick={onClose}>
          <CrossIcon className="w-5 h-5" />
        </div>
      )}
    </div>
  );
};

export default Notice;
