import { isEqual } from "lodash";
import { DateTime } from "luxon";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setStep,
  setSelectedDay,
  setSelectedSlot,
  setConfirmedSelection,
  setAvailabilityDateRange,
  clearSelectedAvailability,
} from "slices/booking";
import { RootState } from "store";
import { useGetAvailabilityQuery } from "api";
import WeekView from "components/sheets/appointment/components/WeekView";
import CalendarView from "components/sheets/appointment/components/CalendarView";
import {
  Sheet,
  Button,
  InlineLink,
  SegmentedControl,
  AvailableSlotOverview,
  CalendarEventIcon,
} from "@mwi/ui";

const AppointmentSheet = (): JSX.Element => {
  const dispatch = useDispatch();

  const {
    vetSite,
    confirmed,
    patients,
    selectedDay,
    selectedSlot,
    registeredSite,
    availabilityDateRange,
  } = useSelector((state: RootState) => ({
    registeredSite: state.auth.registered_site,
    patients: state.bookingFlow.formState.patients,
    vetSite: state.bookingFlow.formState.vet_site_uuid,
    selectedDay: state.bookingFlow.formState.selected_day,
    selectedSlot: state.bookingFlow.formState.selected_slot,
    confirmed: state.bookingFlow.formState.confirmed_selection,
    availabilityDateRange: state.bookingFlow.formState.availability_date_range,
  }));

  const [selectedPeriod, setSelectedPeriod] = useState<"week" | "month">(
    "week",
  );

  const {
    data: availability,
    isLoading: loadingAvailability,
    isFetching: fetchingAvailability,
  } = useGetAvailabilityQuery(
    {
      reasons: patients.map((p) => p.reason_uuid),
      patients: patients?.map((p) => p.patient_uuid),
      location_uuid: vetSite ? vetSite : registeredSite!.id,
      date_end: DateTime.fromISO(availabilityDateRange.date_end).toFormat(
        "yyyy-MM-dd",
      ),
      date_start: DateTime.fromISO(availabilityDateRange.date_start).toFormat(
        "yyyy-MM-dd",
      ),
    },
    {
      skip: !availabilityDateRange.date_start,
    },
  );

  useEffect(() => {
    const currentStartDate = DateTime.fromISO(availabilityDateRange.date_start);
    const currentEndDate = DateTime.fromISO(availabilityDateRange.date_end);

    // Switch to selected availability daterange or default to now
    if (
      currentStartDate.isValid &&
      currentEndDate.isValid &&
      !currentStartDate.hasSame(DateTime.now(), selectedPeriod)
    ) {
      dispatch(
        setAvailabilityDateRange({
          date_start:
            selectedPeriod === "week"
              ? currentStartDate.startOf("week").toISO()!
              : currentEndDate.startOf("month").toISO()!,
          date_end:
            selectedPeriod === "week"
              ? currentStartDate.endOf("week").toISO()!
              : currentEndDate.endOf("month").toISO()!,
        }),
      );
    } else {
      dispatch(
        setAvailabilityDateRange({
          date_start: DateTime.now().toISO()!,
          date_end: DateTime.now().endOf(selectedPeriod).toISO()!,
        }),
      );
    }
  }, [selectedPeriod]);

  useEffect(() => {
    if (availability) {
      if (DateTime.fromISO(selectedDay.date).isValid) {
        return;
      }

      const availableDate = availability.find((a) => a.mergedSlots.length > 0);
      if (availableDate) {
        dispatch(setSelectedDay(availableDate));
      }
    }
  }, [availability]);

  const handleChangeSite = () => {
    dispatch(setStep({ step: "3a" }));
  };

  const handleCloseOptions = () => {
    if (!confirmed) {
      dispatch(clearSelectedAvailability());
    }
  };

  const displayDate = useMemo((): string => {
    const startTime = DateTime.fromISO(selectedSlot.start_time);
    const endTime = DateTime.fromISO(selectedSlot.end_time);

    if (!startTime.isValid && !endTime.isValid) {
      return "";
    }

    return `${startTime.toFormat("d LLLL yyyy, hh.mm")} - ${endTime.toFormat("hh.mm")}`;
  }, [selectedSlot.id]);

  return (
    <Sheet
      title="When will you visit?"
      closeOptions={handleCloseOptions}
      trigger={
        <div
          className={classNames(
            "transition appearance-none",
            "flex items-center justify-between w-full p-4 bg-white",
            "leading-6 placeholder-placeholder-grey",
            "border border-primary focus:border-light",
            "shadow-default focus:shadow-focus-small",
            "focus:outline-none relative",
          )}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center text-left">
              <div className="mr-[10px]">
                <div className="flex flex-shrink-0 w-6 h-6">
                  <span
                    className={classNames("text-focus-grey w-full", {
                      "text-light": displayDate,
                    })}
                  >
                    <CalendarEventIcon />
                  </span>
                </div>
              </div>
              <div className="flex items-center w-full">
                <span
                  className={classNames("text-base text-grey-dark", {
                    "!text-black": displayDate,
                  })}
                >
                  {displayDate ? displayDate : "Chose date & time"}
                </span>
              </div>
            </div>
          </div>
        </div>
      }
    >
      {({ close }) => (
        <div className="pt-6">
          <div className="flex flex-col items-center">
            <div className="w-full mb-6">
              <div className="max-w-[267px] px-4 mx-auto w-full">
                <SegmentedControl>
                  <SegmentedControl.Item
                    title="Weekly"
                    isSelected={selectedPeriod === "week"}
                    onClick={() => setSelectedPeriod("week")}
                  />
                  <SegmentedControl.Item
                    title="Calendar"
                    isSelected={selectedPeriod === "month"}
                    onClick={() => setSelectedPeriod("month")}
                  />
                </SegmentedControl>
              </div>
            </div>

            <div className="w-full mb-10">
              {fetchingAvailability && (
                <div
                  className={classNames(
                    "flex items-center justify-center h-[88px]",
                    {
                      "h-[300px]": selectedPeriod === "month",
                    },
                  )}
                >
                  <span className="font-semibold text-md">
                    Retrieving availability...
                  </span>
                </div>
              )}

              {!loadingAvailability &&
                !fetchingAvailability &&
                availability && (
                  <>
                    {selectedPeriod === "week" ? (
                      <WeekView availability={availability} />
                    ) : (
                      <CalendarView availability={availability} />
                    )}
                  </>
                )}
            </div>

            <div className="mb-14">
              {selectedDay &&
              selectedDay?.mergedSlots &&
              selectedDay.mergedSlots.length > 0 ? (
                <div className="relative w-full overflow-auto full-width">
                  <div className="flex w-full gap-2 px-4 overflow-x-auto snap-x scrollbar-none scroll-smooth">
                    {selectedDay.mergedSlots.map((mergedSlot) => (
                      <div
                        key={mergedSlot.id}
                        className="flex flex-shrink-0 py-1 scroll-mx-4 snap-start"
                      >
                        <AvailableSlotOverview
                          slot={mergedSlot}
                          size="medium"
                          isSelected={isEqual(mergedSlot, selectedSlot)}
                          onClick={() => dispatch(setSelectedSlot(mergedSlot))}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="h-[56px] w-full flex items-center justify-center">
                  <span className="font-semibold text-center text-md">
                    {availability && availability?.length > 0
                      ? "Select a date above to show availability"
                      : "No available slots"}
                  </span>
                </div>
              )}
            </div>

            <div className="flex flex-col items-center w-full gap-y-6">
              <Button
                block
                size="medium"
                variant="outlined"
                disabled={!selectedSlot?.id}
                loading={loadingAvailability || fetchingAvailability}
                onClick={() => {
                  if (!confirmed) {
                    dispatch(setConfirmedSelection(true));
                  }
                  close();
                }}
              >
                Confirm Selection
              </Button>

              <InlineLink size="medium" onClick={handleChangeSite}>
                Check another site
              </InlineLink>
            </div>
          </div>
        </div>
      )}
    </Sheet>
  );
};

export default AppointmentSheet;
